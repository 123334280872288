import PropTypes from 'prop-types';
import { comingSoonLong, comingSoonShort } from '../../shared/appText';
import '../../forms/StudentApplication/StudentApplicationForms.scss';
import Field from '../Field';

export default function PersonalStatementFormPlaceholder({ onContinue }) {
  return (
    <div className="student-app-form">
      <p>
        These documents are essential to painting a picture of who you are as a student, a person and what your graduate and career goals are. Need help creating one of these documents?&nbsp;
        <span className="student-cta-link">These Resources Can Help.</span>&nbsp;
        <span className="coming-soon">{comingSoonShort}</span>
      </p>
      <p>Your .PDF, .DOC, .TXT, .XLS files must be 2 MB or smaller.</p>

      <fieldset>

        <div className="dotted-box">
          <p>Personal Statement</p>
          <p>{comingSoonLong}</p>
          <p>The personal statement is an essay about who you are as a student, professional and person. It gives recruiters a picture of how you would fit into their program.</p>
          <button disabled>Upload</button>
        </div>

        <div className="dotted-box">
          <p>Statement of Purpose</p>
          <p>{comingSoonLong}</p>
          <p>A statement of purpose is an essay about why you want to participate in this program and what you plan to do with your degree afterwards. It gives recruiters a picture of why you would be a good fit for this program.</p>
          <button disabled>Upload</button>
        </div>

      </fieldset>

      <Field>
        <button type="button" onClick={onContinue} className="submit">Continue</button>
      </Field>
    </div>
  );
}

PersonalStatementFormPlaceholder.propTypes = {
  onContinue: PropTypes.func.isRequired,
}