import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { studentWorkPropTypes } from '../../shared/propTypes';
import Field from '../../components/Field';
import PopupCard from '../../components/StudentUI/PopupCard';
import deleteImg from '../../assets/student/images/delete.svg';
import './StudentOnboardingForm.scss';

export default function StudentOnboardingWorkExperienceForm({ labelText, defaultValues, saveData, deleteItem, isCurrent }) {
  const { register, getValues, reset } = useForm({ defaultValues });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const deleteForm = () => {
    deleteItem();
    reset({
      isCurrent: false,
      title: '',
      company: '',
      location: '',
      startDate: null,
      endDate: null,
      description: '',
    });
  }

  const handleBlur = (e) => {
    if (!e.target.classList.contains('btn--delete')) {
      const data = getValues();
      saveData(data);
    }
  }

  return (
    <form onBlur={handleBlur} className="student-onboarding-form">
      <input type="hidden" {...register('isCurrent')} value={isCurrent} />

      <Field>
        <label>
          {labelText}
          <input type="text" {...register('title')} placeholder="Title" />
        </label>
        {deleteItem ? (
          <PopupCard
            triggerButton={<button type="button" className="btn--delete"><img src={deleteImg} alt="Delete" /></button>}
          >
            <div className="student-delete-warning">
              <p>Are you sure you want to delete this?</p>
              <button onClick={deleteForm}>Delete</button>
            </div>
          </PopupCard>
        ) : null}
      </Field>

      <Field>
        <label>
          at
          <input type="text" {...register('company')} placeholder="Company" />
        </label>
      </Field>

      <Field>
        <label>
          located in
          <input type="text" {...register('location')} placeholder="Location" />
        </label>
      </Field>

      <Field>
        <label>
          My start date was
          <input type="date" {...register('startDate')} />
        </label>
      </Field>

      {!isCurrent ? (
        <Field>
          <label>
            My end date was
            <input type="date" {...register('endDate')} />
          </label>
        </Field>
      ) : null}
    </form>
  )
}

StudentOnboardingWorkExperienceForm.defaultProps = {
  defaultValues: {
    isCurrent: false,
    title: '',
    company: '',
    location: '',
    startDate: null,
    endDate: null,
    description: '',
  },
};

StudentOnboardingWorkExperienceForm.propTypes = {
  labelText: PropTypes.string.isRequired,
  defaultValues: PropTypes.shape(studentWorkPropTypes),
  saveData: PropTypes.func.isRequired,
  deleteItem: PropTypes.func,
  isCurrent: PropTypes.bool,
};