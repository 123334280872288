import PropTypes from 'prop-types';
import add from '../../assets/student/images/add.svg';
import './AddButton.scss';

export default function AddButton({ onClick, disabled, children }) {
  return (
    <button onClick={onClick} disabled={disabled} className="student-add-button">
      <img src={add} alt="" />
      {children}
    </button>
  );
}

AddButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
}