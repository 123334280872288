import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Field from '../../components/Field';
import { studentWorkPropTypes } from '../../shared/propTypes';
import { comingSoonLong } from '../../shared/appText';
import './StudentProfileModalForms.scss';

export default function StudentExperienceForm({ defaultValues, saveData }) {
  const { register, handleSubmit, formState: { errors, isValid } } = useForm({ defaultValues, mode: 'onBlur' });
  const registerOptions = {
    title: { required: 'Required' },
    company: { required: 'Required' },
    startDate: { required: 'Required' },
    endDate: {}
  };

  return (
    <div className="student-profile-modal-form">
      <h3 className="student-text-h1">Experience</h3>

      <form onSubmit={handleSubmit(saveData)}>
        <Field>
          <label className="checkbox">
            <input type="checkbox" {...register('isCurrent')} />
            I currently work here
          </label>
        </Field>

        <Field error={errors?.title}>
          <label>
            Title *<br />
            <input type="text" {...register('title', registerOptions.title)} />
          </label>
        </Field>

        <Field error={errors?.company}>
          <label>
            Company *<br />
            <input type="text" {...register('company', registerOptions.company)} />
          </label>
        </Field>

        <Field error={errors?.location}>
          <label>
            Location<br />
            <input type="text" disabled placeholder={comingSoonLong} />
          </label>
        </Field>

        <div className="flex">
          <Field error={errors?.startDate}>
            <label>
              Start Date *<br />
              <input type="date" {...register('startDate', registerOptions.startDate)} />
            </label>
          </Field>

          <Field error={errors?.endDate}>
            <label>
              End Date<br />
              <input type="date" {...register('endDate', registerOptions.endDate)} />
            </label>
          </Field>
        </div>

        <Field>
          <label>
            Description<br />
            <textarea {...register('description')}></textarea>
          </label>
        </Field>

        <Field center>
          <button type="submit" disabled={!isValid} className="profile-form-button">Save</button>
        </Field>
      </form>
    </div>
  );
}

StudentExperienceForm.defaultProps = {
  defaultValues: {
    isCurrent: false,
    title: '',
    company: '',
    location: '',
    startDate: null,
    endDate: null,
    description: '',
  },
};

StudentExperienceForm.propTypes = {
  defaultValues: PropTypes.shape(studentWorkPropTypes),
  saveData: PropTypes.func.isRequired,
};