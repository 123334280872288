import { useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Field from '../../components/Field';
import ControlledMultiSelect from '../../components/ControlledMultiSelect';
import ControlledSlider from '../../components/ControlledSlider';
import {
  stateOptions,
  cipFieldOptions,
  countryOptions,
  ethnicGroupOptions,
  degreeOptions,
  startDateOptions,
} from '../../shared/formOptions';
import {
  institutionGraduationYearOptions,
  institutionCitizenshipStatusOptions,
  institutionGenderOptions
} from '../../shared/filterOptions';
import downCarat from '../../assets/inst/images/down-carat-green.svg';
import './CandidateBuilderForm.scss';

export const filterDefaultValues = {
  title: '',
  major: '',
  majorImportance: 'less',
  gpaRange: [0, 4],
  gpaRangeImportance: 'less',
  graduationYear: '',
  graduationYearImportance: 'less',
  citizenshipCountry: '',
  citizenshipCountryImportance: 'less',
  citizenshipStatus: '',
  citizenshipStatusImportance: 'less',
  ethnicGroups: '',
  ethnicGroupsImportance: 'less',
  gender: '',
  genderImportance: 'less',
  earliestStartDate: '',
  latestStartDate: '',
  expectedStartDateImportance: 'less',
  degree: '',
  degreeImportance: 'less',
  greVerbal: [130, 170],
  greVerbalImportance: 'less',
  greQuantitative: [130, 170],
  greQuantitativeImportance: 'less',
  greWriting: [0, 6],
  greWritingImportance: 'less',
  state: '',
  stateImportance: 'less',
  zipCode: '',
  distance: [100],
  distanceImportance: 'less',
};

export default function CandidateBuilderForm({ defaultValues, onSubmit }) {
  const [preExpanded, setPreExpanded] = useState(['undergradMajor']);
  const { control, register, handleSubmit, reset } = useForm({ defaultValues });


  const handleReset = () => {
    reset(filterDefaultValues);
  }

  if (defaultValues) return (
    <form onSubmit={handleSubmit(onSubmit)} className="inst-candidate-builder-form">
      <div className="inst-candidate-builder-form__content">
        <h1 className="inst-text-h1">Create a customized candidate profile</h1>
        <p className="inst-text-p">Please choose your criteria from the filters listed below. The more details you can provide on these topics, the more accurate your student recommendations will be for your specific program.</p>
        <br />
        <Field>
          <label>
            Choose a Title for this Profile
            <input type="text" {...register('title')} placeholder="Write here" />
          </label>
        </Field>
        <div className="flex-end">
          <button type="button" onClick={() => setPreExpanded([
            'undergradMajor',
            'gpaRange',
            'graduationYear',
            'diversity',
            'expectedStartDate',
            'degree',
            'greScoreRange',
            'location',
          ])}>Expand All</button>
        </div>
        <Accordion preExpanded={preExpanded} allowMultipleExpanded allowZeroExpanded key={preExpanded}>
          <AccordionItem uuid="undergradMajor">
            <AccordionItemHeading>
              <AccordionItemButton>
                <span>Undergrad Major(s)</span>
                <img src={downCarat} alt="" />
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="grid">
                <Field>
                  <label htmlFor="major" className="sr-only">Major</label>
                  <ControlledMultiSelect
                    control={control}
                    options={cipFieldOptions}
                    name="major"
                    placeholder="Choose all that apply"
                    customClassNames="inst-filters-multiselect"
                  />
                </Field>
                <fieldset>
                  <legend className="sr-only">Major Importance</legend>
                  <div className="toggle-group">
                    <label>
                      <input type="radio" {...register('majorImportance')} value="less" />
                      <span>Less Important</span>
                    </label>
                    <label>
                      <input type="radio" {...register('majorImportance')} value="very" />
                      <span>Very Important</span>
                    </label>
                  </div>
                </fieldset>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem uuid="gpaRange">
            <AccordionItemHeading>
              <AccordionItemButton>
                <span>GPA</span>
                <img src={downCarat} alt="" />
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="grid">
                <Field>
                  <label htmlFor="gpaRange" className="sr-only">GPA</label>
                  <ControlledSlider
                    control={control}
                    name="gpaRange"
                    min={0}
                    max={4}
                    step={0.1}
                    displayThumb={value => value.toFixed(1)}
                    customClassNames="inst-filters-slider"
                  />
                </Field>
                <fieldset>
                  <legend className="sr-only">GPA Range Importance</legend>
                  <div className="toggle-group">
                    <label>
                      <input type="radio" {...register('gpaRangeImportance')} value="less" />
                      <span>Less Important</span>
                    </label>
                    <label>
                      <input type="radio" {...register('gpaRangeImportance')} value="very" />
                      <span>Very Important</span>
                    </label>
                  </div>
                </fieldset>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem uuid="graduationYear">
            <AccordionItemHeading>
              <AccordionItemButton>
                <span>Graduate Year</span>
                <img src={downCarat} alt="" />
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="grid">
                <Field>
                  <label htmlFor="graduationYear" className="sr-only">Graduation Year</label>
                  <ControlledMultiSelect
                    control={control}
                    name="graduationYear"
                    options={institutionGraduationYearOptions}
                    placeholder="Choose all that apply"
                    customClassNames="inst-filters-multiselect"
                  />
                </Field>
                <fieldset>
                  <legend className="sr-only">Graduation Year Importance</legend>
                  <div className="toggle-group">
                    <label>
                      <input type="radio" {...register('graduationYearImportance')} value="less" />
                      <span>Less Important</span>
                    </label>
                    <label>
                      <input type="radio" {...register('graduationYearImportance')} value="very" />
                      <span>Very Important</span>
                    </label>
                  </div>
                </fieldset>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem uuid="diversity">
            <AccordionItemHeading>
              <AccordionItemButton>
                <span>Diversity</span>
                <img src={downCarat} alt="" />
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <fieldset>
                <legend className="sr-only">Diversity</legend>

                <div className="grid">
                  <Field>
                    <label htmlFor="citizenshipCountry">Country of Citizenship</label>
                    <ControlledMultiSelect
                      control={control}
                      name="citizenshipCountry"
                      options={countryOptions.map(x => ({ value: x, label: x }))}
                      placeholder="Choose all that apply"
                      customClassNames="inst-filters-multiselect"
                    />
                  </Field>
                  <fieldset>
                    <legend className="sr-only">Country of Citizenship Importance</legend>
                    <div className="toggle-group">
                      <label>
                        <input type="radio" {...register('citizenshipCountryImportance')} value="less" />
                        <span>Less Important</span>
                      </label>
                      <label>
                        <input type="radio" {...register('citizenshipCountryImportance')} value="very" />
                        <span>Very Important</span>
                      </label>
                    </div>
                  </fieldset>
                </div>

                <div className="grid">
                  <div className="flex">
                    {institutionCitizenshipStatusOptions.map(opt => (
                      <label key={opt.value} className="radio">
                        <input type="radio" {...register('citizenshipStatus')} value={opt.value} id={opt.value} />
                        <span>{opt.label}</span>
                      </label>
                    ))}
                  </div>
                  <fieldset>
                    <legend className="sr-only">Citizenship Status Importance</legend>
                    <div className="toggle-group">
                      <label>
                        <input type="radio" {...register('citizenshipStatusImportance')} value="less" />
                        <span>Less Important</span>
                      </label>
                      <label>
                        <input type="radio" {...register('citizenshipStatusImportance')} value="very" />
                        <span>Very Important</span>
                      </label>
                    </div>
                  </fieldset>
                </div>

                <div className="grid">
                  <Field>
                    <label htmlFor="ethnicGroups">Ethnic Background</label>
                    <ControlledMultiSelect
                      control={control}
                      name="ethnicGroups"
                      options={ethnicGroupOptions}
                      placeholder="Choose all that apply"
                      customClassNames="inst-filters-multiselect"
                    />
                  </Field>
                  <fieldset>
                    <legend className="sr-only">Ethnic Background Importance</legend>
                    <div className="toggle-group">
                      <label>
                        <input type="radio" {...register('ethnicGroupsImportance')} value="less" />
                        <span>Less Important</span>
                      </label>
                      <label>
                        <input type="radio" {...register('ethnicGroupsImportance')} value="very" />
                        <span>Very Important</span>
                      </label>
                    </div>
                  </fieldset>
                </div>

                <div className="grid">
                  <Field>
                    <label htmlFor="gender">Gender</label>
                    <ControlledMultiSelect
                      control={control}
                      name="gender"
                      options={institutionGenderOptions}
                      placeholder="Choose all that apply"
                      customClassNames="inst-filters-multiselect"
                    />
                  </Field>
                  <fieldset>
                    <legend className="sr-only">Gender Importance</legend>
                    <div className="toggle-group">
                      <label>
                        <input type="radio" {...register('genderImportance')} value="less" />
                        <span>Less Important</span>
                      </label>
                      <label>
                        <input type="radio" {...register('genderImportance')} value="very" />
                        <span>Very Important</span>
                      </label>
                    </div>
                  </fieldset>
                </div>
              </fieldset>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem uuid="expectedStartDate">
            <AccordionItemHeading>
              <AccordionItemButton>
                <span>Expected Start Date</span>
                <img src={downCarat} alt="" />
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="grid">
                <fieldset>
                  <legend className="sr-only">Expected Start Date</legend>

                  <Field>
                    <label htmlFor="earliestStartDate">Earliest Start Date</label>
                    <ControlledMultiSelect
                      control={control}
                      name="earliestStartDate"
                      placeholder="Choose all that apply"
                      options={startDateOptions.filter(opt => opt.value !== 'Undecided')}
                      customClassNames="inst-filters-multiselect"
                    />
                  </Field>

                  <Field>
                    <label htmlFor="latestStartDate">Latest Start Date</label>
                    <ControlledMultiSelect
                      control={control}
                      name="latestStartDate"
                      placeholder="Choose all that apply"
                      options={startDateOptions.filter(opt => opt.value !== 'Undecided')}
                      customClassNames="inst-filters-multiselect"
                    />
                  </Field>
                </fieldset>
                <fieldset>
                  <legend className="sr-only">Expected Start Date Importance</legend>
                  <div className="toggle-group">
                    <label>
                      <input type="radio" {...register('expectedStartDateImportance')} value="less" />
                      <span>Less Important</span>
                    </label>
                    <label>
                      <input type="radio" {...register('expectedStartDateImportance')} value="very" />
                      <span>Very Important</span>
                    </label>
                  </div>
                </fieldset>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem uuid="degree">
            <AccordionItemHeading>
              <AccordionItemButton>
                <span>Degree Level Pursuing</span>
                <img src={downCarat} alt="" />
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="grid">
                <fieldset>
                  <legend className="sr-only">Degrees</legend>
                  {degreeOptions.filter(opt => opt.value !== 'Still Deciding').map(opt => (
                    <label key={opt.value} className="checkbox">
                      <input type="checkbox" {...register('degree')} value={opt.value} id={opt.value} />
                      <span>{opt.label}</span>
                    </label>
                  ))}
                </fieldset>
                <fieldset>
                  <legend className="sr-only">Degree Importance</legend>
                  <div className="toggle-group">
                    <label>
                      <input type="radio" {...register('degreeImportance')} value="less" />
                      <span>Less Important</span>
                    </label>
                    <label>
                      <input type="radio" {...register('degreeImportance')} value="very" />
                      <span>Very Important</span>
                    </label>
                  </div>
                </fieldset>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem uuid="greScoreRange">
            <AccordionItemHeading>
              <AccordionItemButton>
                <span>GRE Score Range</span>
                <img src={downCarat} alt="" />
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <fieldset>
                <legend className="sr-only">GRE Score Range</legend>
                
                <div className="grid">
                  <Field>
                    <label htmlFor="greVerbal">Verbal Reasoning</label>
                    <ControlledSlider
                      control={control}
                      name="greVerbal"
                      min={130}
                      max={170}
                      step={10}
                      displayThumb={value => value}
                      customClassNames="inst-filters-slider"
                    />
                  </Field>
                  <fieldset>
                    <legend className="sr-only">GRE Verbal Reasoning Importance</legend>
                    <div className="toggle-group">
                      <label>
                        <input type="radio" {...register('greVerbalImportance')} value="less" />
                        <span>Less Important</span>
                      </label>
                      <label>
                        <input type="radio" {...register('greVerbalImportance')} value="very" />
                        <span>Very Important</span>
                      </label>
                    </div>
                  </fieldset>
                </div>

                <div className="grid">
                  <Field>
                    <label htmlFor="greQuantitative">Quantitative Reasoning</label>
                    <ControlledSlider
                      control={control}
                      name="greQuantitative"
                      min={130}
                      max={170}
                      step={10}
                      displayThumb={value => value}
                      customClassNames="inst-filters-slider"
                    />
                  </Field>
                  <fieldset>
                    <legend className="sr-only">GRE Quantitative Reasoning Importance</legend>
                    <div className="toggle-group">
                      <label>
                        <input type="radio" {...register('greQuantitativeImportance')} value="less" />
                        <span>Less Important</span>
                      </label>
                      <label>
                        <input type="radio" {...register('greQuantitativeImportance')} value="very" />
                        <span>Very Important</span>
                      </label>
                    </div>
                  </fieldset>
                </div>

                <div className="grid">
                  <Field>
                    <label htmlFor="greWriting">Analytical Writing</label>
                    <ControlledSlider
                      control={control}
                      name="greWriting"
                      min={0}
                      max={6}
                      step={1}
                      displayThumb={value => value}
                      customClassNames="inst-filters-slider"
                    />
                  </Field>
                  <fieldset>
                    <legend className="sr-only">GRE Analytical Writing Importance</legend>
                    <div className="toggle-group">
                      <label>
                        <input type="radio" {...register('greWritingImportance')} value="less" />
                        <span>Less Important</span>
                      </label>
                      <label>
                        <input type="radio" {...register('greWritingImportance')} value="very" />
                        <span>Very Important</span>
                      </label>
                    </div>
                  </fieldset>
                </div>
              </fieldset>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem uuid="location">
            <AccordionItemHeading>
              <AccordionItemButton>
                <span>Location</span>
                <img src={downCarat} alt="" />
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <fieldset>
                <legend className="sr-only">Location</legend>

                <div className="grid">
                  <Field>
                    <label htmlFor="state">Search by State</label>
                    <ControlledMultiSelect
                      control={control}
                      name="state"
                      options={stateOptions}
                      isSearch
                      placeholder="Search"
                      customClassNames="inst-filters-multiselect"
                    />
                  </Field>
                  <fieldset>
                    <legend className="sr-only">State Importance</legend>
                    <div className="toggle-group">
                      <label>
                        <input type="radio" {...register('stateImportance')} value="less" />
                        <span>Less Important</span>
                      </label>
                      <label>
                        <input type="radio" {...register('stateImportance')} value="very" />
                        <span>Very Important</span>
                      </label>
                    </div>
                  </fieldset>
                </div>

                <div className="grid">
                  <div>
                    <Field>
                      <label htmlFor="zipCode">Search by Zip Code</label>
                      <ControlledMultiSelect
                        control={control}
                        name="zipCode"
                        options={[]}
                        isSearch
                        placeholder="This feature will be available soon"
                        customClassNames="inst-filters-multiselect"
                      />
                    </Field>
                    
                    <Field>
                      <label htmlFor="distance">Distance</label>
                      <ControlledSlider
                        control={control}
                        name="distance"
                        min={0}
                        max={150}
                        step={10}
                        displayThumb={(value) => `${value} miles`}
                        customClassNames="inst-filters-slider"
                      />
                    </Field>
                  </div>
                  <fieldset>
                    <legend className="sr-only">Distance Importance</legend>
                    <div className="toggle-group">
                      <label>
                        <input type="radio" {...register('distanceImportance')} value="less" />
                        <span>Less Important</span>
                      </label>
                      <label>
                        <input type="radio" {...register('distanceImportance')} value="very" />
                        <span>Very Important</span>
                      </label>
                    </div>
                  </fieldset>
                </div>
              </fieldset>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <div className="buttons">
        <button type="submit" className="inst-text-label search-button">Show Results</button>
        <button type="button" onClick={handleReset} className="inst-cta-link">Clear Filters</button>
      </div>
    </form>
  )

  return null;
}

CandidateBuilderForm.propTypes = {
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
}