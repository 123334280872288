import { Link } from 'react-router-dom';
import { useFirebaseStudent } from '../../context/FirebaseStudentContext';
import heartIllus from '../../assets/student/images/heart-darkpink.svg';
import './FavoritesLink.scss';

export default function FavoritesLink() {
  const { favoriteProgramsList } = useFirebaseStudent();

  return (
    <div className="student-favorites-link">
      <Link to="/students/favorites" className="student-cta-link">
        <img src={heartIllus} alt="" />
        Favorites ({favoriteProgramsList.filter(p => p.byStudent && !p.byProgram).length})
      </Link>
    </div>
  );
}