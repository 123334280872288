import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Field from '../../components/Field';
import ControlledSingleSelect from '../../components/ControlledSingleSelect';
import { testOptions } from '../../shared/formOptions';
import { studentGrePropTypes } from '../../shared/propTypes';
import './StudentProfileModalForms.scss';

const isNumber = value => !Number.isNaN(value) || 'Must be a number';

export default function StudentGREForm({ defaultValues, saveData }) {
  const { control, register, handleSubmit, formState: { isValid, errors } } = useForm({ defaultValues, mode: 'all' });

  return (
    <div className="student-profile-modal-form">
      <h3 className="student-text-h1">Test Scores</h3>
      <form onSubmit={handleSubmit(saveData)}>
        <Field>
          <label htmlFor="test">Test</label><br />
          <ControlledSingleSelect
            control={control}
            defaultValue="gre"
            name="test"
            options={testOptions}
            disabled
            customClassNames="student-profile-select"
            placeholder="Select one"
          />
        </Field>

        <Field>
          <label>
            Date Taken<br />
            <input type="date" {...register('dateTaken')} />
          </label>
        </Field>

        <Field error={errors?.verbal}>
          <label>
            Verbal Reasoning Score *<br />
            <input type="text" {...register('verbal', {
              valueAsNumber: true,
              required: 'Required',
              min: {
                value: 0,
                message: 'Must be between 0 and 170'
              },
              max: {
                value: 170,
                message: 'Must be between 0 and 170'
              },
              validate: { isNumber }
            })} />
          </label>
        </Field>

        <Field error={errors?.quantitative}>
          <label>
            Quantitative Reasoning Score *<br />
            <input type="text" {...register('quantitative', {
              valueAsNumber: true,
              required: 'Required',
              min: {
                value: 0,
                message: 'Must be between 0 and 170'
              },
              max: {
                value: 170,
                message: 'Must be between 0 and 170'
              },
              validate: { isNumber }
            })} />
          </label>
        </Field>

        <Field error={errors?.analyticalWriting}>
          <label>
            Analytical Writing Score *<br />
            <input type="text" {...register('analyticalWriting', {
              valueAsNumber: true,
              required: 'Required',
              min: {
                value: 0,
                message: 'Must be between 0 and 6'
              },
              max: {
                value: 6,
                message: 'Must be between 0 and 6'
              },
              validate: { isNumber }
            })} />
          </label>
        </Field>

        <Field center>
          <button type="submit" disabled={!isValid} className="profile-form-button">Save</button>
        </Field>

      </form>
    </div>
  );
}

StudentGREForm.propTypes = {
  defaultValues: PropTypes.shape(studentGrePropTypes),
  saveData: PropTypes.func.isRequired,
}