import PropTypes from 'prop-types';
import { ResponsiveFunnel } from '@nivo/funnel';
import './FunnelChart.scss';

export default function FunnelChart({ data, handleChartClick }) {
  return (
    <div className="inst-funnel-chart">
      <div className="inst-funnel-chart__header">
        {data.map(item => <button onClick={() => handleChartClick({ data: { id: item.id } })} key={`label=${item.id}`}>{item.label}</button>)}
      </div>
      <div className="inst-funnel-chart__chart" style={{ height: '387px' }}>
        <ResponsiveFunnel
          data={data}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          direction="horizontal"
          valueFormat=">-.0r"
          colors={['#C3E3CE', '#8FC0A0', '#59A473', '#0C642C', '#0C642C']}
          borderWidth={0}
          labelColor={{
            from: 'color',
            modifiers: [
              [ 'darker', 3 ]
            ]
          }}
          beforeSeparatorLength={100}
          beforeSeparatorOffset={20}
          afterSeparatorLength={100}
          afterSeparatorOffset={20}
          currentPartSizeExtension={10}
          currentBorderWidth={20}
          motionConfig="wobbly"
          onClick={handleChartClick}
        />
      </div>
    </div>
  )
}

FunnelChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.number,
    label: PropTypes.string,
  })).isRequired,
  handleChartClick: PropTypes.func,
}