import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import ControlledSingleSelect from '../../components/ControlledSingleSelect';
import Field from '../../components/Field';
import './StudentApplicationForms.scss';

export default function StudentApplicationProgramInfoForm({ defaultValues, program, saveUpdates }) {
  const { control, handleSubmit, formState: { errors, isValid } } = useForm({ defaultValues, mode: 'all'  });

  return (
    <form onSubmit={handleSubmit(saveUpdates)} className="student-app-form">
      <Field error={errors?.startDate}>
        <label htmlFor="startDate">Anticipated Start Term *</label><br />
        <ControlledSingleSelect
          control={control}
          name="startDate"
          defaultValue=""
          options={program?.programStartDate?.map(d => ({ label: d, value: d}))}
          rules={{ required: 'Required' }}
          customClassNames="student-app-select"
          placeholder="Select Start Term"
        />
      </Field>

      <Field error={errors?.degree}>
        <label htmlFor="degree">Intended Degree *</label><br />
        <ControlledSingleSelect
          control={control}
          name="degree"
          defaultValue=""
          options={program?.levels?.length ? program.levels.map(val => ({ value: val, label: val })) : []}
          rules={{ required: 'Required' }}
          customClassNames="student-app-select"
          placeholder="Select Degree"
        />
      </Field>

      <Field>
        <button type="submit" disabled={!isValid}>Continue</button>
      </Field>
    </form>
  )
}

StudentApplicationProgramInfoForm.propTypes = {
  defaultValues: PropTypes.shape({
    startDate: PropTypes.string,
    degree: PropTypes.string,
  }),
  program: PropTypes.shape({
    levels: PropTypes.arrayOf(PropTypes.string),
    programStartDate: PropTypes.arrayOf(PropTypes.string),
  }),
  saveUpdates: PropTypes.func.isRequired,
};