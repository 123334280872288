import { useFirebaseSchool } from '../../../context/FirebaseSchoolContext';
import StudentCard from '../../../components/InstitutionUI/StudentCard';
import ShadowCard from '../../../components/ShadowCard';
import SearchInput from '../../../components/InstitutionStudentSearch/SearchInput';
import Filters from '../../../components/InstitutionStudentSearch/Filters';
import './StudentSearch.scss';

export default function InstitutionSearchResultsPage() {
  const { studentsList } = useFirebaseSchool();

  return (
    <main className="inst-search">
      <ShadowCard customClassNames="top-box">
        <div>
          <p className="inst-text-h4">Want to See More? Refine Your Search Criteria for More Targeted Results!</p>
          <div className="search-box">
            <SearchInput placeholder="Coming Soon" />
            <Filters />
            <button className="btn">Search</button>
          </div>
        </div>
      </ShadowCard>
      <h2 className="inst-text-h2">{studentsList.length} Results</h2>
      <div className="grid">
        {studentsList.map(student => (
          <StudentCard student={student} key={student.studentId} />
        ))}
      </div>
    </main>
  );
}