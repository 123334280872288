import PropTypes from 'prop-types';
import './Button.scss';

export default function Button({ onClick, disabled, variant, children }) {
  return (
    <button onClick={onClick} disabled={disabled} className={`inst-btn inst-btn--${variant}`}>
      {children}
    </button>
  );
}

Button.defaultProps = {
  variant: 'primary'
}

Button.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['primary', 'outline']),
  children: PropTypes.node,
}