import PropTypes from 'prop-types';
import ShadowCard from '../ShadowCard';
import './SubmittedAppCard.scss';

export default function SubmittedAppCard({ application }) {
  return (
    <ShadowCard customClassNames="app-card--submitted">
      <h3 className="student-text-h3">{application.programTitle}</h3>
      <p className="student-text-p-small">{application.schoolName}</p>
      
      <p className="status student-text-p-small">Submitted</p>
    </ShadowCard>
  );
}

SubmittedAppCard.propTypes = {
  application: PropTypes.shape({
    programTitle: PropTypes.string,
    schoolName: PropTypes.string,
  })
}