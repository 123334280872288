import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ShadowCard from '../ShadowCard';
import WebLink from './WebLink';
import linkedin from '../../assets/student/images/linkedin-dark-pink.svg';
import facebook from '../../assets/student/images/facebook-dark-pink.svg';
import twitter from '../../assets/student/images/twitter-dark-pink.svg';
import { comingSoonShort } from '../../shared/appText';
import './ProfileMainImageCard.scss';

export default function ProfileMainImageCard({ imageUrl, schoolName, programTitle, website }) {
  return (
    <ShadowCard customClassNames="inst-profile-main-image-card">
      <div className="inst-profile-main-image-card__image" style={{ backgroundImage: `url(/programs/${imageUrl})`}}>
      </div>
      <div className="inst-profile-main-image-card__content">
        <div className="text">
          {programTitle ? <p className="inst-text-h1">{programTitle}</p> : null}
          {programTitle ? (
            <>
              {schoolName ? <p className="inst-text-h2"><Link to="/institutions/schoolprofile">{schoolName}</Link></p> : null}
            </>
          ) : (
            <>
              {schoolName ? <p className="inst-text-h2">{schoolName}</p> : null}
            </>
          )}

          <p><WebLink url={website} /></p>
        </div>
        <div className="social">
          <ul>
            <li><img src={facebook} alt="Facebook" /></li>
            <li><img src={linkedin} alt="LinkedIn" /></li>
            <li><img src={twitter} alt="Twitter" /></li>
            <li>{comingSoonShort}</li>
          </ul>
        </div>
      </div>
    </ShadowCard>
  )
}

ProfileMainImageCard.propTypes = {
  imageUrl: PropTypes.string,
  schoolName: PropTypes.string,
  programTitle: PropTypes.string,
  website: PropTypes.string,
}