import './PurchaseListConfirmation.scss';
import PropTypes from 'prop-types';
import ShadowCard from '../../../components/ShadowCard';
import arrow from '../../../assets/inst/images/arrow-left.svg';
import repeat from '../../../assets/inst/images/repeat.svg';
import { orderHistory } from './PurchaseListData';

export default function InstitutionPurchaseListConfirmationPage({changeView}) {
  return (
    <div className="purchase-list-confirmation">
      <button onClick={() => changeView('place-order')} className="back-button inst-text-p"><img src={arrow} alt="" /> Back</button>
      <div className="confirmation-details">
        <h3 className="inst-text-h3">Confirmation - Order Number 80033026</h3>
        <span className="inst-text-p">Your transaction was successful.</span><br/>
        <span className="inst-text-p">You will receive an email when your order is available to download.</span>
      </div>
      <div className="orders-heading">
        <h3 className="inst-text-h3">Your Orders</h3>
        <button onClick={() => changeView('search')} className="green-button inst-text-label">Create a New Order</button>
      </div>
      <div className="order-history-cards">
        {
          orderHistory.map((o, key) => (
            <ShadowCard key={key}>
              <div className="order-card">
                <div className="order-card-top inst-text-h4">
                  <div>Order #{o.orderNumber}</div>
                </div>
                <div className="order-card-bottom">
                  <div>
                    {o.recurringPurchase && <div className="recurring-purchase">
                      <img src={repeat} alt="" />
                      <span className="inst-text-label">Recurring Purchase</span>
                    </div>}
                    <div className="info">
                      <span className="inst-text-label">Total Registrants</span>
                      <span className="inst-text-p">{o.totalRegistrants}</span>
                    </div>
                    <div className="info">
                      <span className="inst-text-label">Date Purchased</span>
                      <span className="inst-text-p">{o.datePurchased}</span>
                    </div>
                  </div>
                  <div className="links">
                    <button className="details-button inst-text-p" onClick={() => changeView('review-order')}>View Order Details</button>
                  </div>
                </div>
              </div>
            </ShadowCard>
          ))
        }
      </div>
    </div>
  );
}

InstitutionPurchaseListConfirmationPage.propTypes = {
  changeView: PropTypes.func
};