import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Field from '../../components/Field';
import ControlledSingleSelect from '../../components/ControlledSingleSelect';
import { testOptions } from '../../shared/formOptions';
import { studentGrePropTypes } from '../../shared/propTypes';
import plus from '../../assets/student/images/plus.svg';
import './StudentApplicationForms.scss';

export default function StudentApplicationTestScoresForm({ defaultValues, saveUpdates }) {
  const { register, control, handleSubmit, formState: { isValid } } = useForm({ defaultValues, mode: 'all' });

  return (
    <form onSubmit={handleSubmit(saveUpdates)} className="student-app-form">
      <p>Input your test scores here. This is an important element of your qualifications that program advisors will be looking for.</p>
 
      <fieldset>
        <legend>GRE</legend>

        <Field>
          <label>
            Date taken<br />
            <input type="date" {...register('gre.dateTaken')} />
          </label>
        </Field>

        <Field>
          <label>
            Verbal Reasoning Score<br />
            <input type="text" {...register('gre.verbal')} />
          </label>
        </Field>

        <Field>
          <label>
            Quantitative Reasoning Score<br />
            <input type="text" {...register('gre.quantitative')} />
          </label>
        </Field>

        <Field>
          <label>
            Analytical Writing Score<br />
            <input type="text" {...register('gre.analyticalWriting')} />
          </label>
        </Field>
      </fieldset>

      <hr />

      <fieldset>
        <legend>Additional Exams</legend>
        <Field>
          <label htmlFor="additionalText">Exam *</label><br />
          <ControlledSingleSelect
            control={control}
            defaultValue="gre"
            name="additionalTest"
            options={testOptions}
            customClassNames="student-app-select"
            placeholder="Select one"
          />
        </Field>

        <Field>
          <button type="button" className="add-one" disabled>
            <img src={plus} alt="" />
            <span className="student-cta-link">Add Another Exam</span>
          </button>
        </Field>
      </fieldset>

      <Field>
        <button type="submit" disabled={!isValid}>Continue</button>
      </Field>
    </form>
  )
}

StudentApplicationTestScoresForm.propTypes = {
  defaultValues: PropTypes.shape({
    gre: PropTypes.shape(studentGrePropTypes)
  }),
  saveUpdates: PropTypes.func.isRequired,
};