import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFirebaseStudent } from '../../../context/FirebaseStudentContext';
import StudentOnboardingSchoolPrefsForm from '../../../forms/StudentOnboarding/StudentOnboardingSchoolPrefsForm';
import useKeypress from '../../../hooks/useKeypress';
import DashboardLink from '../../../components/StudentSurveys/DashboardLink';
import './SurveyPages.scss';

const prevSurveyPath = '/students/surveys/schools/priorities';
const nextSurveyPath = '/students/surveys/schools/size';

export default function StudentSchoolsSurveyPreferencesPage() {
  const navigate = useNavigate();
  const { student, addOrUpdateProfile } = useFirebaseStudent();

  const studentSchoolPrefs = useMemo(() => {
    if (student?.schoolPreferences) {
      return student.schoolPreferences;
    } else {
      return null;
    }
  }, [student?.schoolPreferences]);

  const prevScreen = () => {
    navigate(prevSurveyPath);
  }

  const nextScreen = () => {
    navigate(nextSurveyPath);
  }

  useKeypress('ArrowRight', nextScreen);
  useKeypress('ArrowLeft', prevScreen);  

  const savePreferenceData = (data) => {
    addOrUpdateProfile({ schoolPreferences: data }, 'Your preferences have been saved!');
  }

  return (
    <main className="student-onboarding-survey">
      <DashboardLink />
      <p className="student-text-p-large">Let&apos;s get to know your school preferences better.</p>
      <StudentOnboardingSchoolPrefsForm
        defaultValues={studentSchoolPrefs}
        saveData={(data) => {
          savePreferenceData(data);
          nextScreen();
        }}
        goBack={prevScreen}
      />
    </main>
  );
}