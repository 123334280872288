import PropTypes from 'prop-types';
import { comingSoonLong } from '../../shared/appText';
import '../../forms/StudentApplication/StudentApplicationForms.scss';
import Field from '../Field';

export default function ResumeFormPlaceholder({ onContinue }) {
  return (
    <div className="student-app-form">
      <p>Your .PDF, .DOC, .TXT, .XLS files must be 2 MB or smaller.</p>

      <div className="dotted-box">
        <p>Resume/CV</p>
        <p>{comingSoonLong}</p>
        <button disabled>Upload</button>
      </div>

      <hr />

      <fieldset>
        <legend className="no-margin">Additional Documents</legend>
        
        <div className="dotted-box">
          <p>Document</p>
          <p>{comingSoonLong}</p>
          <button disabled>Upload</button>
        </div>

      </fieldset>

      <Field>
        <button type="button" onClick={onContinue} className="submit">Continue</button>
      </Field>
    </div>
  );
}

ResumeFormPlaceholder.propTypes = {
  onContinue: PropTypes.func.isRequired,
}