import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import FavoriteButtons from '../StudentFavorites/FavoriteButtons';
import ShadowCard from '../ShadowCard';
import './SchoolProgramListCard.scss';

export default function SchoolProgramListCard({ program }) {
  return (
    <ShadowCard customClassNames="school-program-card">
      <div className="school-program-card__image" style={{ backgroundImage: `url(/programs/${program.programImageName})`}}>
        <FavoriteButtons programId={program.programId} />
      </div>
      <div className="school-program-card__content">
        <p className="student-text-h2"><Link to={`/students/explore/programs/${program.programId}`}>{program.programTitle}</Link></p>
      </div>
    </ShadowCard>
  )
}

SchoolProgramListCard.propTypes = {
  program: PropTypes.shape({
    programImageName: PropTypes.string,
    programTitle: PropTypes.string,
    programId: PropTypes.string,
  }),
};