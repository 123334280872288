import './PurchaseListSearch.scss';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { useForm } from 'react-hook-form';
import ControlledMultiSelect from '../../../components/ControlledMultiSelect';
import ControlledSlider from '../../../components/ControlledSlider';
import arrow from '../../../assets/inst/images/arrow-left.svg';
import carat from '../../../assets/inst/images/down-carat-green.svg';
import { stateOptions, countryOptions, citizenshipStatusOptions, ethnicGroupOptions } from '../../../shared/formOptions';
import { institutionGenderOptions } from '../../../shared/filterOptions';

export default function InstitutionPurchaseListSearchPage({changeView}) {
  const { control } = useForm();

  return (
    <div className="list-purchase-search">
      <button onClick={() => changeView('home')} className="back-button inst-text-p"><img src={arrow} alt="" /> Back</button>
      <h2 className="inst-text-h2">New Search</h2>
      <div className="section">
        <div className="section-header inst-text-h3">
          <span className="inst-text-p left">Step 1</span>Select a Search Criteria
        </div>
        <Accordion allowMultipleExpanded allowZeroExpanded>
          <AccordionItem uuid="personalInfo" activeClassName="accordion__item--expanded">
            <AccordionItemHeading>
              <AccordionItemButton>
                <span className="inst-text-h3">Personal Info</span>
                <div>
                  <img src={carat} alt="" className="carat" />
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="accordion-item-panel">
                <div className="search-form-select">
                  <label className="inst-text-label" htmlFor="state">State or Territory of Permanent Residence (US Citizens Only)</label>
                  <ControlledMultiSelect
                    options={stateOptions}
                    control={control}
                    defaultValue={[]}
                    name="state"
                    placeholder="Choose all that apply"
                    customClassNames="search-form-select-dropdown inst-text-p"
                  />
                </div>
                <div className="search-form-select">
                  <label className="inst-text-label" htmlFor="gender">Gender</label>
                  <ControlledMultiSelect
                    options={institutionGenderOptions}
                    control={control}
                    defaultValue={[]}
                    name="gender"
                    placeholder="Choose all that apply"
                    customClassNames="search-form-select-dropdown inst-text-p"
                  />
                </div>
                <div className="search-form-select">
                  <label className="inst-text-label" htmlFor="ethnicGroup">Racial/Ethnic Group (US Citizens Only)</label>
                  <ControlledMultiSelect
                    options={ethnicGroupOptions}
                    control={control}
                    defaultValue={[]}
                    name="ethnicGroup"
                    placeholder="Choose all that apply"
                    customClassNames="search-form-select-dropdown inst-text-p"
                  />
                </div>
                <div className="search-form-select">
                  <label className="inst-text-label" htmlFor="citizenship">US Citizenship Status</label>
                  <ControlledMultiSelect
                    options={citizenshipStatusOptions}
                    control={control}
                    defaultValue={[]}
                    name="citizenship"
                    placeholder="Choose all that apply"
                    customClassNames="search-form-select-dropdown inst-text-p"
                  />
                </div>
                <div className="search-form-select">
                  <label className="inst-text-label" htmlFor="country">Country of Citizenship</label>
                  <ControlledMultiSelect
                    options={countryOptions.map(c => ({ label: c, value: c }))}
                    control={control}
                    defaultValue={[]}
                    name="country"
                    placeholder="Choose all that apply"
                    customClassNames="search-form-select-dropdown inst-text-p"
                  />
                </div>
                <div className="search-form-select">
                  <label className="inst-text-label" htmlFor="language">Native Language</label>
                  <ControlledMultiSelect
                    disabled={true}
                    options={[]}
                    control={control}
                    defaultValue={[]}
                    name="language"
                    placeholder="Choose all that apply"
                    customClassNames="search-form-select-dropdown inst-text-p"
                  />
                </div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem uuid="geographicInfo" activeClassName="accordion__item--expanded">
            <AccordionItemHeading>
              <AccordionItemButton>
                <span className="inst-text-h3">Geographic Info</span>
                <div>
                  <img src={carat} alt="" className="carat" />
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="accordion-item-panel">
                <span className="available-soon">This feature will be available soon.</span>
                <br/><br/>
                <span className="inst-text-p underline">Geotarget Using Google Maps Mapping Service</span>
                <div className="search-form-select">
                  <label className="inst-text-label" htmlFor="country">Country</label>
                  <ControlledMultiSelect
                    disabled={true}
                    options={[]}
                    control={control}
                    defaultValue={[]}
                    name="country"
                    placeholder="Choose all that apply"
                    customClassNames="search-form-select-dropdown inst-text-p"
                  />
                </div>
                <div className="search-form-select">
                  <label className="inst-text-label" htmlFor="state">State or Province (U.S./Canada Only)</label>
                  <ControlledMultiSelect
                    disabled={true}
                    options={[]}
                    control={control}
                    defaultValue={[]}
                    isSearch
                    name="state"
                    placeholder="Search"
                    customClassNames="search-form-select-dropdown inst-text-p"
                  />
                </div>
                <div className="search-form-select">
                  <label className="inst-text-label" htmlFor="region">World Region</label>
                  <ControlledMultiSelect
                    disabled={true}
                    options={[]}
                    control={control}
                    defaultValue={[]}
                    name="region"
                    placeholder="Choose all that apply"
                    customClassNames="search-form-select-dropdown inst-text-p"
                  />
                </div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem uuid="history" activeClassName="accordion__item--expanded">
            <AccordionItemHeading>
              <AccordionItemButton>
                <span className="inst-text-h3">Educational/Employment History</span>
                <div>
                  <img src={carat} alt="" className="carat" />
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="accordion-item-panel">
                <span className="available-soon">This feature will be available soon.</span>
                <br/><br/>
                <div className="search-form-select">
                  <label className="inst-text-label" htmlFor="currentEducation">Current Educational Level</label>
                  <ControlledMultiSelect
                    disabled={true}
                    options={[]}
                    control={control}
                    defaultValue={[]}
                    name="currentEducation"
                    placeholder="Choose all that apply"
                    customClassNames="search-form-select-dropdown inst-text-p"
                  />
                </div>
                <div className="search-form-select">
                  <label className="inst-text-label" htmlFor="major">Undergraduate Major Field</label>
                  <ControlledMultiSelect
                    disabled={true}
                    options={[]}
                    control={control}
                    defaultValue={[]}
                    isSearch
                    name="major"
                    placeholder="Search"
                    customClassNames="search-form-select-dropdown inst-text-p"
                  />
                </div>
                <div className="search-form-select">
                  <label className="inst-text-label" htmlFor="broadMajor">Broad Undergraduate Major Field</label>
                  <ControlledMultiSelect
                    disabled={true}
                    options={[]}
                    control={control}
                    defaultValue={[]}
                    isSearch
                    name="broadMajor"
                    placeholder="Search"
                    customClassNames="search-form-select-dropdown inst-text-p"
                  />
                </div>
                <label htmlFor="yearsExperience" className="inst-text-label">Years of Full-Time Work Experience</label><br />
                <div className="horizontal-row">
                  <ControlledSlider
                    control={control}
                    defaultValue={[10]}
                    min={0}
                    max={20}
                    step={1}
                    name="yearsExperience"
                    displayThumb={(v) => `${v} year${v === 1 ? '' : 's'}`}
                    customClassNames="search-form-slider job-experience"
                  />
                </div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem uuid="gpa" activeClassName="accordion__item--expanded">
            <AccordionItemHeading>
              <AccordionItemButton>
                <span className="inst-text-h3">GPA With Score Bands</span>
                <div>
                  <img src={carat} alt="" className="carat" />
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="accordion-item-panel">
                <span className="available-soon">This feature will be available soon.</span><br/><br/>
                <label htmlFor="undergradGpa" className="inst-text-label">Overall Undergraduate GPA</label><br />
                <ControlledSlider
                  control={control}
                  defaultValue={[0.0, 4.0]}
                  min={0}
                  max={4}
                  step={0.1}
                  name="undergradGpa"
                  displayThumb={(v) => v.toFixed(1)}
                  customClassNames="search-form-slider"
                />
                <label htmlFor="undergradMajorGpa" className="inst-text-label">Undergraduate GPA in Major</label><br />
                <ControlledSlider
                  control={control}
                  defaultValue={[0.0, 4.0]}
                  min={0}
                  max={4}
                  step={0.1}
                  name="undergradMajorGpa"
                  displayThumb={(v) => v.toFixed(1)}
                  customClassNames="search-form-slider"
                />
              </div>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem uuid="objective" activeClassName="accordion__item--expanded">
            <AccordionItemHeading>
              <AccordionItemButton>
                <span className="inst-text-h3">Educational Objective/Intent</span>
                <div>
                  <img src={carat} alt="" className="carat" />
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="accordion-item-panel">
                <span className="available-soon">This feature will be available soon.</span><br/><br/>
                <div className="search-form-select">
                  <label className="inst-text-label" htmlFor="preferredRegion">Preferred Region of Graduate Study</label>
                  <ControlledMultiSelect
                    disabled={true}
                    options={[]}
                    control={control}
                    defaultValue={[]}
                    isSearch
                    name="preferredRegion"
                    placeholder="Search"
                    customClassNames="search-form-select-dropdown inst-text-p"
                  />
                </div>
                <div className="search-form-select">
                  <label className="inst-text-label" htmlFor="intendedMajor">Intended Graduate Major Field</label>
                  <ControlledMultiSelect
                    disabled={true}
                    options={[]}
                    control={control}
                    defaultValue={[]}
                    isSearch
                    name="intendedMajor"
                    placeholder="Search"
                    customClassNames="search-form-select-dropdown inst-text-p"
                  />
                </div>
                <div className="search-form-select">
                  <label className="inst-text-label" htmlFor="broadIntendedMajor">Broad Intended Graduate Major Field</label>
                  <ControlledMultiSelect
                    disabled={true}
                    options={[]}
                    control={control}
                    defaultValue={[]}
                    isSearch
                    name="broadMajor"
                    placeholder="Search"
                    customClassNames="search-form-select-dropdown inst-text-p"
                  />
                </div>
                <div className="search-form-select">
                  <label className="inst-text-label" htmlFor="plannedDate">Planned Date of Enrollment</label>
                  <ControlledMultiSelect
                    disabled={true}
                    options={[]}
                    control={control}
                    defaultValue={[]}
                    name="plannedDate"
                    placeholder="Choose all that apply"
                    customClassNames="search-form-select-dropdown inst-text-p"
                  />
                </div>
                <div className="search-form-select">
                  <label className="inst-text-label" htmlFor="degreeObjective">Graduate Degree Objective</label>
                  <ControlledMultiSelect
                    disabled={true}
                    options={[]}
                    control={control}
                    defaultValue={[]}
                    name="degreeObjective"
                    placeholder="Choose all that apply"
                    customClassNames="search-form-select-dropdown inst-text-p"
                  />
                </div>
                <div className="search-form-checkbox-wrapper">
                  <label className="inst-text-label" htmlFor="enrollmentPreference">Enrollment Preference</label><br/>
                  <div className="search-form-checkboxes">
                    <div className="search-form-checkbox">
                      <input type="checkbox"/>
                      <span className="inst-text-p"> Full-time</span>
                    </div>
                    <div className="search-form-checkbox">
                      <input className="checkbox" type="checkbox"/>
                      <span className="inst-text-p"> Part-time</span>
                    </div>
                  </div>
                </div>
                <div className="search-form-checkbox-wrapper">
                  <label className="inst-text-label" htmlFor="programFormat">Program Format</label><br/>
                  <div className="search-form-checkboxes">
                    <div className="search-form-checkbox">
                      <input type="checkbox"/>
                      <span className="inst-text-p"> Online</span>
                    </div>
                    <div className="search-form-checkbox">
                      <input type="checkbox"/>
                      <span className="inst-text-p"> On campus</span>
                    </div>
                    <div className="search-form-checkbox">
                      <input type="checkbox"/>
                      <span className="inst-text-p"> Hybrid</span>
                    </div>
                  </div>
                </div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem uuid="exclusion" activeClassName="accordion__item--expanded">
            <AccordionItemHeading>
              <AccordionItemButton>
                <span className="inst-text-h3">Exclusion Options</span>
                <div>
                  <img src={carat} alt="" className="carat" />
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="accordion-item-panel">
                <span className="available-soon">This feature will be available soon.</span><br/><br/>
                <input type="checkbox"/>
                <span className="inst-text-p"> Exclude test takers who have requested that their scores be sent to my institution/organization.</span><br/><br/>
                <input type="checkbox"/>
                <span className="inst-text-p"> Exclude search registrants who do not have an email address.</span>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <div className="section">
        <div className="section-header inst-text-h3">
          <span className="inst-text-p left">Step 2</span>Specify Test Date Range<span className="inst-text-p right">(Opt-in Date Included if Test Date Unavailable)</span>
        </div>
        <div className="accordion-item-panel">
          <span className="available-soon">This feature will be available soon.</span>
          <div className="date-range">
            <span className="inst-text-label">Between</span>
            <input className="inst-text-p" type="text" placeholder="MM/DD/YYYY"/>
            <span className="inst-text-label">- AND -</span>
            <input className="inst-text-p" type="text" placeholder="MM/DD/YYYY"/>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="section-header inst-text-h3">
          <span className="inst-text-p left">Step 3</span>Name and Save Your Search
        </div>
        <div className="accordion-item-panel">
          <span className="available-soon">This feature will be available soon.</span><br/>
          <div className="step-3-text">
            <span className="inst-text-label">Name</span><br/>
            <input className="inst-text-p" type="text" placeholder="Write here"/>
          </div>
          <div className="step-3-text">
            <span className="inst-text-label">Description</span><br/>
            <textarea className="description inst-text-p" type="text" placeholder="Write here"/>
          </div>
        </div>
      </div>
      <div className="nav-links">
        <button className="inst-text-label" onClick={() => changeView('review-order')}>Save and Continue to Order</button>
        <button className="inst-text-p" onClick={() => changeView('home')}>Save For Later</button>
      </div>
    </div>
  );
}

InstitutionPurchaseListSearchPage.propTypes = {
  changeView: PropTypes.func
};