export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});

export const generateLastXYears = (yearsBack) => {
  const max = new Date().getFullYear();
  const min = max - yearsBack;
  const years = [];

  for (let i = max; i >= min; i--) {
    years.push(i);
  }

  return years;
}