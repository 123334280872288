import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useStudentSystemTodos } from '../../context/FirebaseStudentContext';
import Modal from '../Modal';
import { formatDate } from '../../shared/dates';
import cal from '../../assets/student/images/notification-cal.svg';
import './StudentNotificationsDrawer.scss';

export default function StudentNotificationsDrawer({ customClassNames, children }) {
  const { profileTodos, programSearchTodos, applicationTodos } = useStudentSystemTodos();
  const [showNotifications, setShowNotifications] = useState(false);

  const todos = useMemo(() => {
    return [
      ...profileTodos,
      ...programSearchTodos,
      ...applicationTodos
    ].filter(t => !t.complete);
  }, [profileTodos, programSearchTodos, applicationTodos]);


  return (
    <>
      <button className={customClassNames} onClick={todos.length ? () => setShowNotifications(true) : null}>
        {children}
        {todos.length ? <span className="student-notification-count">{todos.length}</span> : null}
      </button>
      <Modal
        closeModal={() => setShowNotifications(false)}
        title="Notifications"
        isOpen={showNotifications}
        modalClassName="student-notification-panel"
      >
        <div className="student-notification-list">
          <h1 className="student-text-h2">Notifications</h1>
          {todos.map(todo => (
            <div key={todo.key} className="notification-list-item">
              <img src={cal} alt="" />
              <div>
                <p className="student-text-p-large">{todo.name}</p>
                <p className="student-text-p-small">Due {formatDate(todo.dueDate)}</p>
              </div>
            </div>
          ))}
        </div>
      </Modal>
    </>
  )
}

StudentNotificationsDrawer.propTypes = {
  customClassNames: PropTypes.string,
  children: PropTypes.node.isRequired,
};