import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useFirebasePrograms } from '../../context/FirebaseProgramDataContext';
import EditButton from '../../components/InstitutionProfile/EditButton';
import SectionHeader from '../../components/SectionHeader';
import ProfileMainImageCard from '../../components/InstitutionProfile/ProfileMainImageCard';
import TabNav from '../../components/InstitutionUI/TabNav';

export default function InstitutionSchoolProfileLayout() {
  const { schools, activeProgram } = useFirebasePrograms();

  const data = useMemo(() => {
    if (activeProgram?.schoolId && schools) return schools[activeProgram?.schoolId];
    return null;
  }, [activeProgram?.schoolId, schools]);

  if (data) return (
    <div className="student-school-profile">
      <SectionHeader title="Profile" />
      <TabNav tabs={[
        { label: 'School', to: '/institutions/schoolprofile' },
        { label: 'Program', to: `/institutions/programprofile/${activeProgram?.programId}` }
      ]} />
      <div className="student-school-profile__top">
        <div className="button">
          <EditButton />
        </div>
        <ProfileMainImageCard
          imageUrl={data.imageFilename}
          imageAlt={data.schoolName}
          schoolName={data.schoolName}
          website={data.website}
        />
      </div>
      <TabNav
        tabs={[
          { label: 'Overview', to: '', end: true },
          { label: 'Programs', to: 'programs' },
          { label: 'Graduate Student Demographics', to: 'students' }
        ]}
      />
      <div className="student-school-profile__content">
        <Outlet />
      </div>
    </div>
  );

  return null;
}