import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { studentPropTypes } from '../../shared/propTypes';
import {
  pronounOptions,
  ethnicityOptions,
  ethnicGroupOptions,
  degreeOptions,
  startDateOptions,
  citizenshipStatusOptions,
  stateOptions,
  countryOptions,
  cipFieldOptions
} from '../../shared/formOptions';
import ControlledMultiSelect from '../../components/ControlledMultiSelect';
import Field from '../../components/Field';
import ControlledSingleSelect from '../../components/ControlledSingleSelect';
import './StudentProfileModalForms.scss';

export default function StudentBasicInfoForm({ defaultValues, saveData }) {
  const { register, control, handleSubmit, formState: { isValid } } = useForm({ defaultValues });

  return (
    <div className="student-profile-modal-form">
      <h3 className="student-text-h1">General Information</h3>
      <form onSubmit={handleSubmit(saveData)}>
        <p className="student-text-p">This just helps us tailor your suggested program matches to best meet your criteria.</p>
        <Field>
          <label htmlFor="pronouns">Pronouns</label><br />
          <ControlledSingleSelect
            control={control}
            defaultValue=""
            name="pronouns"
            options={pronounOptions}
            customClassNames="student-profile-select"
            placeholder="Select one"
          />
        </Field>

        <fieldset className="field">
          <legend>Ethnic Background</legend>
          <ControlledMultiSelect
            control={control}
            defaultValue={[]}
            name="ethnicGroups"
            options={ethnicGroupOptions}
            customClassNames="student-profile-select"
            placeholder="Choose all that apply"
            singleOptionValue="Prefer Not To Respond"
          />
          <fieldset className="flex">
            {ethnicityOptions.map(eth => (
              <label key={eth.value} className="radio">
                <input type="radio" {...register('ethnicity')} value={eth.value} />
                {eth.label}<br />
              </label>
            ))}
          </fieldset>
        </fieldset>

        <Field>
          <label htmlFor="citizenshipStatus">Citizenship Status</label><br />
          <ControlledSingleSelect
            control={control}
            defaultValue=""
            name="citizenshipStatus"
            options={citizenshipStatusOptions}
            customClassNames="student-profile-select"
            placeholder="Select one"
          />
        </Field>

        <Field>
          <label htmlFor="citizenshipCountry">Country of Citizenship</label><br />
          <ControlledSingleSelect
            control={control}
            defaultValue=""
            name="citizenshipCountry"
            options={countryOptions.map(c => ({ value: c, label: c }))}
            customClassNames="student-profile-select"
            placeholder="Select one"
          />
        </Field>

        <Field>
          <label htmlFor="stateOfResidence">State of Residence</label><br />
          <ControlledSingleSelect
            control={control}
            defaultValue=""
            name="stateOfResidence"
            options={stateOptions}
            customClassNames="student-profile-select"
            placeholder="Select one"
          />
        </Field>

        <Field>
          <label htmlFor="seekingDegree">Degree or Education Level You&apos;re Seeking</label>
          <ControlledMultiSelect
            control={control}
            defaultValue={[]}
            name="seekingDegree"
            options={degreeOptions}
            customClassNames="student-profile-select"
            placeholder="Choose all that apply"
            singleOptionValue="Still Deciding"
          />
        </Field>

        <Field>
          <label htmlFor="fieldOfStudy">Anticipated Field of Study</label>
          <ControlledSingleSelect
            control={control}
            defaultValue={null}
            name="fieldOfStudy"
            options={cipFieldOptions}
            customClassNames="student-profile-select"
            placeholder="Select one"
          />
        </Field>

        <Field>
          <label htmlFor="expectedStartDate">Expected Start Date</label><br />
          <ControlledMultiSelect
            control={control}
            defaultValue={[]}
            name="expectedStartDate"
            options={startDateOptions}
            customClassNames="student-profile-select"
            placeholder="Choose all that apply"
            singleOptionValue="Undecided"
          />
        </Field>

        <Field>
          <label className="checkbox">
            <input type="checkbox" {...register('isInternational')} />
            I&apos;m an international student
          </label>
        </Field>

        <Field>
          <label className="checkbox">
            <input type="checkbox" {...register('isFirstGeneration')} />
            I&apos;m a first generation student
          </label>
        </Field>

        <Field center>
          <button type="submit" disabled={!isValid} className="profile-form-button">Save</button>
        </Field>
      </form>
    </div>
  )
}

StudentBasicInfoForm.propTypes = {
  defaultValues: PropTypes.shape({
    pronouns: studentPropTypes.pronouns,
    ethnicGroups: studentPropTypes.ethnicGroups,
    ethnicity: studentPropTypes.ethnicity,
    citizenshipStatus: studentPropTypes.citizenshipStatus,
    citizenshipCountry: studentPropTypes.citizenshipCountry,
    stateOfResidence: studentPropTypes.stateOfResidence,
    seekingDegree: studentPropTypes.seekingDegree,
    fieldOfStudy: studentPropTypes.fieldOfStudy,
    expectedStartDate: studentPropTypes.expectedStartDate,
    isInternational: studentPropTypes.isInternational,
    isFirstGeneration: studentPropTypes.isFirstGeneration,
  }),
  saveData: PropTypes.func.isRequired,
}