import PropTypes from 'prop-types';
import ShadowCard from '../ShadowCard';
import '../StudentPrograms/CampusSettingCard.scss';

export default function CampusSettingCard({ mapImage, setting, displayAddress }) {
  return (
    <ShadowCard customClassNames="campus-setting-card">
      <div className="campus-setting-card__content">
        <p className="inst-text-h2">{setting}</p>
        <p className="inst-text-label">Address</p>
        <p className="inst-text-p">{displayAddress}</p>
      </div>
      <div className="campus-setting-card__image" style={{ backgroundImage: `url(/programs/${mapImage})`}}>
      </div>
    </ShadowCard>
  );
}

CampusSettingCard.propTypes = {
  mapImage: PropTypes.string,
  setting: PropTypes.string,
  displayAddress: PropTypes.string,
}