import { useState, useMemo, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import DashboardLink from '../../../components/StudentSurveys/DashboardLink';
import { useFirebaseStudent, useStudentSurveyStatus } from '../../../context/FirebaseStudentContext';
import StudentOnboardingWorkExperienceForm from '../../../forms/StudentOnboarding/StudentOnboardingWorkExperienceForm';
import AddButton from '../../../components/StudentUI/AddButton';
import SurveyNavButtonWrapper from '../../../components/StudentSurveys/SurveyNavButtonWrapper';
import useKeypress from '../../../hooks/useKeypress';
import next from '../../../assets/student/images/right-carat-black.svg';
import applyIllus from '../../../assets/student/images/dashboard-apply.svg';
import './SurveyPages.scss';

const screens = {
  INIT: 'INIT',
  WORKING: 'WORKING',
  NOT_WORKING: 'NOT_WORKING',
  PRIOR_WORK: 'PRIOR_WORK',
  SUCCESS: 'SUCCESS'
};

const workingScreensList = [
  screens.INIT,
  screens.WORKING,
  screens.SUCCESS,
];

const notWorkingScreensList = [
  screens.INIT,
  screens.NOT_WORKING,
  screens.PRIOR_WORK,
  screens.SUCCESS,
];

const prevSurveyPath = '/students/surveys/education';

export default function StudentOnboardingWorkPage() {
  const navigate = useNavigate();
  const [screenView, setScreenView] = useState(screens.INIT);
  const [showNextJobForm, setShowNextJobForm] = useState(false);
  const { student, addOrUpdateListItem, removeListItem } = useFirebaseStudent();
  const { profilePercentComplete } = useStudentSurveyStatus();
  const studentJobs = useMemo(() => {
    if (student?.workExperience) {
      return Object.keys(student.workExperience).map(key => student.workExperience[key]);
    } else {
      return [];
    }
  }, [student?.workExperience]);

  useEffect(() => {
    setShowNextJobForm(false);
  }, [student?.workExperience]);

  const prevScreen = () => {
    if (notWorkingScreensList.includes(screenView)) {
      // use not working path
      const currentIndex = notWorkingScreensList.indexOf(screenView);
      if (currentIndex === 0) {
        navigate(prevSurveyPath);
      } else if (currentIndex > 0) {
        setScreenView(notWorkingScreensList[currentIndex - 1]);
      }
    } else {
      // use working path
      const currentIndex = workingScreensList.indexOf(screenView);
      if (currentIndex === 0) {
        navigate(prevSurveyPath);
      } else if (currentIndex > 0) {
        setScreenView(workingScreensList[currentIndex - 1]);
      }
    }
  }

  const nextScreen = () => {
    if (notWorkingScreensList.includes(screenView)) {
      // use not working path
      const currentIndex = notWorkingScreensList.indexOf(screenView);
      if (currentIndex > -1 && currentIndex < notWorkingScreensList.length - 1) {
        setScreenView(notWorkingScreensList[currentIndex + 1]);
      }
    } else {
      // use working path
      const currentIndex = workingScreensList.indexOf(screenView);
      if (currentIndex > -1 && currentIndex < workingScreensList.length - 1) {
        setScreenView(workingScreensList[currentIndex + 1]);
      }
    }
  }

  useKeypress('ArrowRight', nextScreen);
  useKeypress('ArrowLeft', prevScreen);

  const saveJob = (data) => addOrUpdateListItem('workExperience', data, 'Job saved!');

  const deleteJob = (id) => removeListItem('workExperience', id, 'Job deleted!');

  return (
    <main className="student-onboarding-survey">
      <DashboardLink />
      {screenView === screens.INIT ? (
        <>
          <p className="student-text-p-large">Let&apos;s start filling out your work experience.</p>
          <br />
          <br />
          <p className="student-text-h1">Are you currently working?</p>
          <div className="flex">
            <button onClick={() => setScreenView(screens.WORKING)} className="btn-gray">Yes!</button>
            <button onClick={() => setScreenView(screens.NOT_WORKING)} className="btn-gray">No</button>
          </div>
        </>
      ) : null}

      {screenView === screens.NOT_WORKING ? (
        <>
          <p className="student-text-p-large">Let&apos;s start filling out your work experience.</p>
          <br />
          <br />
          <p className="student-text-h1">No problem! Do you have any prior work experience?</p>
          <br />
          <p className="student-text-h3">Internships, part-time and volunteer work count!</p>
          <div className="flex">
            <button onClick={() => setScreenView(screens.PRIOR_WORK)} className="btn-gray">Yes!</button>
            <button onClick={() => navigate('/students')} className="btn-gray">I&apos;d like to skip this for now</button>
          </div>
        </>
      ) : null}

      {screenView === screens.WORKING || screenView === screens.PRIOR_WORK ? (
        <>
          <p className="student-text-p-large">Let&apos;s start filling out your work experience.</p>
          <StudentOnboardingWorkExperienceForm
            labelText={screenView === screens.WORKING ? 'I currently work as a' : 'I last worked as a'}
            defaultValues={studentJobs.length ? studentJobs[0] : null}
            saveData={saveJob}
            deleteItem={studentJobs.length ? () => deleteJob(studentJobs[0].id) : null}
            isCurrent={screenView === screens.WORKING || studentJobs?.[0]?.isCurrent}
          />
          {studentJobs.length > 1 ? studentJobs.slice(1).map(job => (
            <StudentOnboardingWorkExperienceForm
              key={job.id}
              labelText="I also worked as a"
              defaultValues={job}
              saveData={saveJob}
              deleteItem={() => deleteJob(job.id)}
            />
          )) : null}
          {showNextJobForm ? (
            <StudentOnboardingWorkExperienceForm
              labelText="I also worked as a"
              defaultValues={null}
              saveData={saveJob}
            />
          ) : null}
          {studentJobs?.length > 0 ? (
            <AddButton onClick={() => setShowNextJobForm(true)}>
              Add Another Work Experience
            </AddButton>
          ) : null}
          <br />
          <SurveyNavButtonWrapper>
            <button onClick={prevScreen} className="prev">
              <img src={next} alt="Go Back" />
            </button>
            <button className="next" onClick={() => setScreenView(screens.SUCCESS)}>
              <img src={next} alt="Next" />
            </button>
          </SurveyNavButtonWrapper>
        </>
      ) : null}

      {screenView === screens.SUCCESS ? (
        <div className="centered">
          <img src={applyIllus} alt="" width={313} />
          <p className="student-text-h2">You&apos;ve completed {profilePercentComplete}% of your profile! 👏</p>
          <p className="student-text-p-large">This information will be saved to your profile and can be imported into your applications when you&apos;re ready.</p>
          <Link to="/students/explore" className="btn">Go to Recommendations</Link>
        </div>
      ) : null}
    </main>
  )
}