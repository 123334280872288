import { useState, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFirebaseStudent } from '../../../context/FirebaseStudentContext';
import StudentOnboardingSchoolSizeForm from '../../../forms/StudentOnboarding/StudentOnboardingSchoolSizeForm';
import useKeypress from '../../../hooks/useKeypress';
import DashboardLink from '../../../components/StudentSurveys/DashboardLink';
import ProgramRecsCarousel from '../../../components/StudentPrograms/ProgramRecsCarousel';
import FavoritesLink from '../../../components/StudentFavorites/FavoritesLink';
import { responsive } from './SchoolsSurveyPrioritiesPage';
import './SurveyPages.scss';

const prevSurveyPath = '/students/surveys/schools/preferences';
const nextSurveyPath = '/students/surveys/education';

export default function StudentSchoolsSurveySizePage() {
  const navigate = useNavigate();
  const [showSuccess, setShowSuccess] = useState(false);
  const { student, addOrUpdateProfile } = useFirebaseStudent();

  const studentSchoolPrefs = useMemo(() => {
    if (student?.schoolPreferences) {
      return student.schoolPreferences;
    } else {
      return null;
    }
  }, [student?.schoolPreferences]);

  const prevScreen = () => {
    if (showSuccess) setShowSuccess(false);
    else navigate(prevSurveyPath);
  }

  const nextScreen = () => {
    if (showSuccess) navigate(nextSurveyPath);
    else setShowSuccess(true);
  }

  useKeypress('ArrowRight', nextScreen);
  useKeypress('ArrowLeft', prevScreen);  

  const savePreferenceData = (data) => {
    addOrUpdateProfile({ schoolPreferences: data }, 'Your preferences have been saved!');
  }

  return (
    <main className="student-onboarding-survey">
      <DashboardLink />
      {!showSuccess ? (
        <>
          <p className="student-text-p-large">Let&apos;s get to know your school preferences better.</p>
          <StudentOnboardingSchoolSizeForm
            defaultValues={studentSchoolPrefs}
            saveData={(data) => {
              savePreferenceData(data);
              setShowSuccess(true);
            }}
            goBack={prevScreen}
          />
        </>
      ) : (        
        <div className="centered">
          <div className="carousel-container">
            <div className="carousel-header">
              <p className="student-text-h2">Recommendations</p>
              <FavoritesLink />
            </div>
            <ProgramRecsCarousel customResponsive={responsive} />
          </div>
          <p className="student-text-h2">Keep Going! 👏</p>
          <p className="student-text-p-large">You can check out and save your first batch of recommendations here.</p>
          <p className="student-text-p-large">Continue completing your profile to receive more customized recommendations.</p>
          <Link to={nextSurveyPath} className="btn">Yes, Continue</Link>
          <Link to="/students/explore" className="student-cta-link">Take Me To Recommendations</Link>
        </div>
      )}
    </main>
  );
}