import { useState } from 'react';
import { useFirebaseSchool } from '../../../context/FirebaseSchoolContext';
import { useNavigate } from 'react-router-dom';
import SectionHeader from '../../../components/SectionHeader';
import ShadowCard from '../../../components/ShadowCard';
import Modal from '../../../components/Modal';
import CandidateSearchCard from '../../../components/InstitutionCandidateBuilder/CandidateSearchCard';
import CandidateBuilderForm, { filterDefaultValues } from '../../../forms/InstitutionCandidateBuilderForm/CandidateBuilderForm';
import candidateBuilder from '../../../assets/inst/images/candidatebuilder.svg';
import './CandidateBuilderPages.scss';

export default function InstitutionCandidateBuilderHomePage() {
  const navigate = useNavigate();
  const { savedSearches, savedSearchesList, addOrUpdateSavedSearch, removeSavedSearch } = useFirebaseSchool();
  const [activeForm, setActiveForm] = useState(null);

  const getDefaultValues = (activeForm, savedSearches) => {
    if (!activeForm || activeForm.key === null || !savedSearches[activeForm.key]) {
      return filterDefaultValues;
    } else {
      return savedSearches[activeForm.key];
    }
  }

  return (
    <main className="inst-candidate-builder">
      <SectionHeader title="Customized Candidate Builder" />
      <div className="inst-candidate-builder__content">
        <ShadowCard customClassNames="top-box">
          <img src={candidateBuilder} alt="" />
          <div>
            <p className="inst-text-h3">Create a comprehensive candidate profile to receive and view candidates with potential value to your program.</p>
            <p className="inst-text-p">Utilizing this feature will allow you to search for students with specific criteria that match your program needs. These are customized searches generated by you and can be saved and changed as often as you like. You will be able to engage with student leads generated by these results.</p>
            <div className="inst-builder-popup-container">
              <button className="btn" onClick={() => setActiveForm({ key: null })}>Customize Your Candidate Profile</button>
            </div>
          </div>
        </ShadowCard>

        {savedSearchesList?.length ? (
          <>
            <h2 className="inst-text-h2">Your Saved Candidate Profiles</h2>
            <div className="grid-2">
              {savedSearchesList.map(search => (
                <CandidateSearchCard
                  key={search.id}
                  search={search}
                  editSearch={() => setActiveForm({ key: search.id })}
                  deleteSearch={() => removeSavedSearch(search.id, 'Search deleted!')} />
              ))}
            </div>
          </>
        ) : null}

        <Modal
          closeModal={() => setActiveForm(null)}
          title="Create a customized candidate profile"
          isOpen={activeForm ? true : false}
          modalClassName="inst-builder-popup-modal"
        >
          <CandidateBuilderForm
            defaultValues={getDefaultValues(activeForm, savedSearches)}
            onSubmit={data => {
              addOrUpdateSavedSearch(data, 'Search saved!');
              navigate('results');
            }}
          />
        </Modal>
      </div>
    </main>
  );
}