import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Field from '../../components/Field';
import { comingSoonLong, comingSoonShort } from '../../shared/appText';
import { studentEducationPropTypes } from '../../shared/propTypes';
import './StudentProfileModalForms.scss';

export default function StudentEducationForm({ defaultValues, saveData }) {
  const { register, handleSubmit, formState: { errors, isValid } } = useForm({ defaultValues, mode: 'onBlur' });
  const registerOptions = {
    schoolName: { required: 'Required' },
    degree: { required: 'Required' },
    major: { required: 'Required' },
    startDate: { required: 'Required' },
    endDate: { required: 'Required' },
  };

  return (
    <div className="student-profile-modal-form">
      <h3 className="student-text-h1">Educational Background</h3>

      <form onSubmit={handleSubmit(saveData)}>
        <Field>
          <label className="checkbox">
            <input type="checkbox" {...register('isCurrent')} />
            I&apos;m currently a student here
          </label>
        </Field>

        <Field error={errors?.schoolName}>
          <label>
            School *<br />
            <input type="text" {...register('schoolName', registerOptions.schoolName)} placeholder="Write here" />
          </label>
        </Field>

        <Field>
          <label>
            City<br />
            <input type="text" disabled placeholder={comingSoonLong} />
          </label>
        </Field>

        <div className="flex">
          <Field>
            <label>
              State<br />
              <input type="text" disabled placeholder={comingSoonShort} />
            </label>
          </Field>

          <Field>
            <label>
              ZIP<br />
              <input type="text" disabled placeholder={comingSoonShort} />
            </label>
          </Field>
        </div>

        <Field>
          <label>
            Country<br />
            <input type="text" disabled placeholder={comingSoonLong} />
          </label>
        </Field>

        <Field error={errors?.degree}>
          <label>
            Degree or Intended Degree *<br />
            <input type="text" {...register('degree', registerOptions.degree)} placeholder="Write here" />
          </label>
        </Field>

        <div className="flex">
          <Field error={errors?.startDate}>
            <label>
              Start Date *<br />
              <input type="date" {...register('startDate', registerOptions.startDate)} />
            </label>
          </Field>

          <Field error={errors?.endDate}>
            <label>
              End Date or Anticipated *<br />
              <input type="date" {...register('endDate', registerOptions.endDate)} />
            </label>
          </Field>
        </div>

        <Field error={errors?.major}>
          <label>
            Major *<br />
            <input type="text" {...register('major', registerOptions.major)} placeholder="Write here" />
          </label>
        </Field>

        <div className="dotted-box student-text-p">
          <p className="student-text-label">Transcript</p>
          <p>{comingSoonLong}</p>
          <button disabled className="profile-form-button">Upload</button>
        </div>

        <Field error={errors?.grade}>
          <label>
            GPA<br />
            <input type="text" {...register('grade')} placeholder="Write here" />
          </label>
        </Field>

        <Field>
          <label>
            Description<br />
            <textarea {...register('description')} placeholder="Outcomes, courses, etc."></textarea>
          </label>
        </Field>

        <Field center>
          <button type="submit" disabled={!isValid} className="profile-form-button">Save</button>
        </Field>
      </form>
    </div>
  )
}

StudentEducationForm.defaultProps = {
  defaultValues: {
    isCurrent: false,
    schoolName: '',
    degree: '',
    major: '',
    startDate: null,
    endDate: null,
    grade: '',
    description: '',
  },
};

StudentEducationForm.propTypes = {
  defaultValues: PropTypes.shape(studentEducationPropTypes),
  saveData: PropTypes.func.isRequired,
};