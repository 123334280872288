import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import back from '../assets/icons/carat-left-black.svg';
import './BackLink.scss';

export default function BackLink({ label, to, inst }) {
  return (
    <Link to={to} className="back-link">
      <img src={back} alt="" />
      <span className={inst ? 'inst-cta-link' : 'student-cta-link'}>{label}</span>
    </Link>
  );
}

BackLink.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  inst: PropTypes.bool,
};