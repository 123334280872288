import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import './TabNav.scss';

export default function TabNav({ tabs, useHTML }) {
  return (
    <nav className="program-tab-nav">
      <ul>
        {tabs.map(tab => (
          <li key={tab.label}>
            {useHTML ? (
              <a href={tab.to} className={tab.isActive ? 'active' : ''}>
                {tab.label}
              </a>
            ) : (
              <NavLink to={tab.to} className={({ isActive }) => isActive ? 'active' : ''} end>
                {tab.label}
              </NavLink>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
}

TabNav.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
  })),
  useHTML: PropTypes.bool,
}
