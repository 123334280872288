import { useMemo } from 'react';
import { useFirebasePrograms } from '../../../context/FirebaseProgramDataContext';
import EditButton from '../../../components/InstitutionProfile/EditButton';
import DemoPieChart from '../../../components/StudentPrograms/DemoPieChart';
import PercentBar from '../../../components/PercentBar';
import ShadowCard from '../../../components/ShadowCard';
import { colors } from '../../../shared/colors';

export default function InstitutionSchoolProfileStudentsPage() {
  const { schools, activeProgram } = useFirebasePrograms();

  const data = useMemo(() => {
    if (activeProgram?.schoolId && schools) return schools[activeProgram.schoolId];
    return null;
  }, [activeProgram?.schoolId, schools]);

  if (data) return (
    <main className="school-profile-page">
      <div className="grid">
        <section className="section">
          <div className="section__header">
            <h2 className="inst-text-h2">Age</h2>
            <EditButton />
          </div>
          <ShadowCard customClassNames="padded">
            <DemoPieChart
              data={[
                { id: 'Age 25 and Under', value: data.under25Percent, label: 'Age 25 and Under', color: colors.lime },
                { id: 'Over Age 25', value: data.over25Percent, label: 'Over Age 25', color: colors.green },
              ]}
              size={200}
              inst
            />
          </ShadowCard>
        </section>

        <section className="section">
          <div className="section__header">
            <h2 className="inst-text-h2">Gender</h2>
            <EditButton />
          </div>
          <ShadowCard customClassNames="padded">
            <DemoPieChart
              data={[
                { id: 'Male', value: data.maleStudentPercent, label: 'Male', color: colors.lime },
                { id: 'Female', value: data.femaleStudentPercent, label: 'Female', color: colors.green },
                { id: 'Other', value: data.otherGenderStudentPercent, label: 'Other', color: colors.darkGreen }
              ]}
              size={200}
              inst
            />
          </ShadowCard>
        </section>
      </div>

      <section className="section">
        <div className="section__header">
          <h2 className="inst-text-h2">Diversity</h2>
          <EditButton />
        </div>
        <ShadowCard customClassNames="ethnic-group-chart ethnic-group-chart--inst padded">
          <div>
            <span>African American or Black</span>
            <PercentBar percentComplete={data.africanAmericanBlackPercent} bgColor={colors.instLightGreen} fgColor={colors.instDarkGreen} />
            <span>{data.africanAmericanBlackPercent}%</span>
          </div>
          <div>
            <span>Alaskan Native</span>
            <PercentBar percentComplete={data.alaskanNativePercent} bgColor={colors.instLightGreen} fgColor={colors.instDarkGreen} />
            <span>{data.alaskanNativePercent}%</span>
          </div>
          <div>
            <span>American Indian</span>
            <PercentBar percentComplete={data.americanIndianPercent} bgColor={colors.instLightGreen} fgColor={colors.instDarkGreen} />
            <span>{data.americanIndianPercent}%</span>
          </div>
          <div>
            <span>Asian</span>
            <PercentBar percentComplete={data.asianPercent} bgColor={colors.instLightGreen} fgColor={colors.instDarkGreen} />
            <span>{data.asianPercent}%</span>
          </div>
          <div>
            <span>Caucasian or White</span>
            <PercentBar percentComplete={data.caucasianWhitePercent} bgColor={colors.instLightGreen} fgColor={colors.instDarkGreen} />
            <span>{data.caucasianWhitePercent}%</span>
          </div>
          <div>
            <span>Native Hawaiian</span>
            <PercentBar percentComplete={data.nativeHawaiianPercent} bgColor={colors.instLightGreen} fgColor={colors.instDarkGreen} />
            <span>{data.nativeHawaiianPercent}%</span>
          </div>
          <div>
            <span>Other</span>
            <PercentBar percentComplete={data.otherEthnicGroupPercent} bgColor={colors.instLightGreen} fgColor={colors.instDarkGreen} />
            <span>{data.otherEthnicGroupPercent}%</span>
          </div>
          <div>
            <span>Other Pacific Islander</span>
            <PercentBar percentComplete={data.otherPacificIslanderPercent} bgColor={colors.instLightGreen} fgColor={colors.instDarkGreen} />
            <span>{data.otherPacificIslanderPercent}%</span>
          </div>
          <div>
            <span>Prefer Not to Respond</span>
            <PercentBar percentComplete={data.preferNotEthnicGroupPercent} bgColor={colors.instLightGreen} fgColor={colors.instDarkGreen} />
            <span>{data.preferNotEthnicGroupPercent}%</span>
          </div>
        </ShadowCard>
      </section>
    </main>
  );

  return null;
}