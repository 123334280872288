import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import './StudentAboutMeForm.scss';

export default function StudentAboutMeForm({ defaultValues, saveData }) {
  const { register, getValues } = useForm({ defaultValues });

  const saveOnBlur = () => {
    const data = getValues();
    saveData(data);
  }

  return (
    <form className="about-me">
      <label htmlFor="aboutMe" className="sr-only">About Me</label>
      <textarea
        {...register('aboutMe')}
        placeholder="Introduce yourself in your own words"
        onBlur={saveOnBlur}
      />
    </form>
  );
}

StudentAboutMeForm.propTypes = {
  defaultValues: PropTypes.shape({
    aboutMe: PropTypes.string,
  }),
  saveData: PropTypes.func.isRequired,
}