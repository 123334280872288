import { useState, useEffect, createContext, useContext, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ref, onValue, update, set, push, remove } from 'firebase/database';
import { useFirebaseAuth } from '../context/FirebaseAuthContext';
import { db } from '../firebase';
import toast from 'react-hot-toast';

const FirebaseStudentContext = createContext(undefined);

function FirebaseStudentProvider({ children }) {
  const { user } = useFirebaseAuth();
  const [student, setStudent] = useState(null);
  const [favoritePrograms, setFavoritePrograms] = useState(null);

  const favoriteProgramsList = useMemo(() => {
    if (!favoritePrograms) return [];
    return Object.keys(favoritePrograms).map(key => ({ ...favoritePrograms[key], programId: key }));
  }, [favoritePrograms]);

  const addOrUpdateListItem = (parentKey, data, successMessage) => {
    if (data.id) {
      updateListItem(parentKey, data.id, data, successMessage);
    } else {
      addListItem(parentKey, data, successMessage);
    }
  }

  const addListItem = async (parentKey, data, successMessage) => {
    try {
      const parentNodeRef = ref(db, `/students/${user.uid}/${parentKey}`);
      const newItemRef = push(parentNodeRef);
      set(newItemRef, {
        ...data,
        id: newItemRef.key
      });
      if (successMessage) toast.success(successMessage);
    } catch (e) {
      toast.error(e.code);
    }
  }

  const updateListItem = async (parentKey, key, data, successMessage) => {
    try {
      const listItemRef = ref(db, `/students/${user.uid}/${parentKey}/${key}`);
      await update(listItemRef, data);
      if (successMessage) toast.success(successMessage);
    } catch (e) {
      toast.error(e.code);
    }
  }

  const removeListItem = async (parentKey, key, successMessage) => {
    try {
      const listItemRef = ref(db, `/students/${user.uid}/${parentKey}/${key}`);
      await remove(listItemRef);
      if (successMessage) toast.success(successMessage);
    } catch (e) {
      toast.error(e.code);
    }
  }
  
  const addOrUpdateProfile = useCallback(async (data, successMessage) => {
    try {
      const studentProfileRef = ref(db, `/students/${user.uid}`);
      await update(studentProfileRef, data);
      if (successMessage) toast.success(successMessage);
    } catch (e) {
      toast.error(e.code);
    }
  }, [user]);

  const addOrUpdateApplication = useCallback(async (status, programId, data, successMessage) => {
    try {
      const sharedApplicationRef = ref(db, `/sharedApplications/${programId}/${user.uid}`);
      const studentApplicationRef = ref(db, `/students/${user.uid}/applications/${programId}`);

      if (status === 'created') {
        await update(studentApplicationRef, { ...data, status: 'in-progress' });
        await update(sharedApplicationRef, { ...data, status: 'in-progress' });
      } else if (status === 'submitted') {
        await update(studentApplicationRef, { ...data, status });
        await update(sharedApplicationRef, { ...data, status });
      } else {
        await update(studentApplicationRef, data);
      }

      if (successMessage) toast.success(successMessage);
    } catch (e) {
      toast.error(e.code);
    }
  }, [user]);

  const batchCreateApplications = async (dataList, successMessage) => {
    try {
      dataList.forEach(async data => {
        const sharedApplicationRef = ref(db, `/sharedApplications/${data.programId}/${user.uid}`);
        const studentApplicationRef = ref(db, `/students/${user.uid}/applications/${data.programId}`);
        await update(studentApplicationRef, { ...data, status: 'in-progress' });
        await update(sharedApplicationRef, { ...data, status: 'in-progress' });
      });

      if (successMessage) toast.success(successMessage);
    } catch (e) {
      toast.error(e.code);
    }
  }

  const removeApplication = async (programId, successMessage) => {
    try {
      const sharedApplicationRef = ref(db, `/sharedApplications/${programId}/${user.uid}`);
      const studentApplicationRef = ref(db, `/students/${user.uid}/applications/${programId}`);

      await remove(sharedApplicationRef);
      await remove(studentApplicationRef);

      if (successMessage) toast.success(successMessage);
    } catch (e) {
      toast.error(e.code);
    }
  }

  const addOrUpdateFavoriteProgram = async (programId, data, successMessage) => {
    try {
      const faveProgramRef = ref(db, `/studentFaves/${user.uid}/${programId}`);
      const programFaveStudentRef = ref(db, `/programFaves/${programId}/${user.uid}`);
      await update(faveProgramRef, data);
      await update(programFaveStudentRef, data);
      if (successMessage) toast.success(successMessage);
    } catch (e) {
      toast.error(e.code);
    }
  }

  useEffect(() => {
    if (user?.uid) {
      const studentFavesRef = ref(db, `/studentFaves/${user.uid}`);
      try {
        const unsubscribe = onValue(studentFavesRef, (snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            setFavoritePrograms(data);
          }
        });
    
        return () => unsubscribe;
      } catch (e) {
        toast.error(e.code);
      }
    }
  }, [user]);

  useEffect(() => {
    if (user?.uid) {
      const studentProfileRef = ref(db, `/students/${user.uid}`);
      try {
        const unsubscribe = onValue(studentProfileRef, (snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            setStudent(data);
          }
        });
    
        return () => unsubscribe;
      } catch (e) {
        toast.error(e.code);
      }
    }
  }, [user, addOrUpdateProfile]);

  return (
    <FirebaseStudentContext.Provider value={{
      student,
      favoritePrograms,
      favoriteProgramsList,
      addOrUpdateProfile,
      addOrUpdateListItem,
      addOrUpdateApplication,
      batchCreateApplications,
      addOrUpdateFavoriteProgram,
      removeApplication,
      removeListItem,
    }}>
      {children}
    </FirebaseStudentContext.Provider>
  );
}

FirebaseStudentProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function useFirebaseStudent() {
  const context = useContext(FirebaseStudentContext);

  if (context === undefined) {
    throw new Error('useFirebaseStudent must be used within a FirebaseStudentProvider');
  }

  return context;
}

function useStudentSystemTodos() {
  const { student, favoritePrograms } = useFirebaseStudent();
  const { applicationsList } = useStudentApplicationStatus();
  const profileTodos = useMemo(() => {
    const todos = [
      { key: 'aboutMe', name: 'Fill in About Me', dueDate: new Date().toLocaleDateString(), complete: Boolean(student?.aboutMe) },
      { key: 'basicInfo', name: 'Add General Information', dueDate: new Date().toLocaleDateString(), complete: Boolean(student?.basicInfo) },
      { key: 'workExperience', name: 'Add Work Experience', dueDate: new Date().toLocaleDateString(), complete: Boolean(student?.workExperience) },
      { key: 'education', name: 'Add Education', dueDate: new Date().toLocaleDateString(), complete: Boolean(student?.education) },
      { key: 'certificates', name: 'Add Certificates', dueDate: new Date().toLocaleDateString(), complete: Boolean(student?.certificates) }
    ];
    return todos;
  }, [student?.aboutMe, student?.basicInfo, student?.workExperience, student?.education, student?.certificates]);

  const programSearchTodos = useMemo(() => {
    return [
      { key: 'saveFavorites', name: 'Save Favorites', dueDate: new Date().toLocaleDateString(), complete: Boolean(favoritePrograms) },
    ]
  }, [favoritePrograms]);

  const applicationTodos = useMemo(() => {
    return applicationsList.map(app => ({
      key: `app-${app.programId}`,
      name: app.programTitle,
      dueDate: app.deadline,
      complete: app.status === 'submitted',
    }))
  }, [applicationsList]);

  const studentTodos = useMemo(() => {
    if (!student?.todos) return [];
    const tasks = Object.keys(student.todos).map(key => student.todos[key]);
    return tasks;
  }, [student?.todos]);

  return {
    profileTodos,
    programSearchTodos,
    applicationTodos,
    studentTodos,
  };
}

function useStudentApplicationStatus() {
  const { student } = useFirebaseStudent();

  const applicationsList = useMemo(() => {
    if (!student?.applications) return [];
    return Object.keys(student.applications).map(key => student.applications[key]);
  }, [student?.applications]);
  
  const inProgressApps = useMemo(() => applicationsList.filter(a => a.status === 'in-progress'), [applicationsList]);

  const submittedApps = useMemo(() => applicationsList.filter(a => a.status === 'submitted'), [applicationsList]);

  return {
    applicationsList,
    inProgressApps,
    submittedApps,
  }
}

const basicInfoFields = [
  'citizenshipCountry',
  'ethnicGroups',
  'ethnicity',
  'expectedStartDate',
  'fieldOfStudy',
  'pronouns',
  'stateOfResidence',
  'seekingDegree',
];

const studentFields = [
  'workExperience',
  'education',
];

const schoolPreferenceFields = [
  'programType',
  'schoolType',
  'settingType',
  'targetStates',
  'programSize'
];

const allStudentFields = [
  ...basicInfoFields,
  ...studentFields,
  ...schoolPreferenceFields
];

const isCompleteField = (parent, key) => {
  const isComplete = !!(
    parent?.[key] !== ''
    && parent?.[key] !== null
    && (parent?.[key]?.length || Object.keys(parent?.[key] || {})?.length || Number.isFinite(parent?.[key]))
  );
  return isComplete;
}

function useStudentSurveyStatus() {
  const { student } = useFirebaseStudent();
  const showBasicsSurvey = useMemo(() => {
    if (!student?.basicInfo) return true;
    return !!basicInfoFields.find(key => !isCompleteField(student?.basicInfo, key))?.length;
  }, [student?.basicInfo]);
  const showProgramsSurvey = useMemo(() => {
    if (!student?.schoolPreferences && !student?.applications) return true;
    const hasIncompleteSchoolPreferences = !!schoolPreferenceFields.find(key => !isCompleteField(student?.schoolPreferences, key))?.length;
    const hasApplications = !!student?.applications;
    return hasIncompleteSchoolPreferences && !hasApplications;
  }, [student?.schoolPreferences, student?.applications]);
  const showEducationSurvey = useMemo(() => Boolean(!student?.education), [student?.education]);
  const showWorkExperienceSurvey = useMemo(() => Boolean(!student?.workExperience), [student?.workExperience]);

  const profilePercentComplete = useMemo(() => {
    if (!student || !student.basicInfo) return 0;
    const completeBasicInfoFields = basicInfoFields.filter(key => isCompleteField(student?.basicInfo, key));
    const completeStudentFields = studentFields.filter(key => isCompleteField(student, key));
    const completeSchoolPrefFields = schoolPreferenceFields.filter(key => isCompleteField(student?.schoolPreferences, key));
    const completeFields = [...completeBasicInfoFields, ...completeStudentFields, ...completeSchoolPrefFields];
    return Math.ceil((completeFields.length / allStudentFields.length) * 100);
  }, [student]);
  
  return {
    showBasicsSurvey,
    showProgramsSurvey,
    showEducationSurvey,
    showWorkExperienceSurvey,
    profilePercentComplete,
  };
}

export { 
  FirebaseStudentProvider,
  useFirebaseStudent,
  useStudentSystemTodos,
  useStudentSurveyStatus,
  useStudentApplicationStatus
};