import { useState, useEffect, useMemo } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Modal from '../../../components/Modal';
import TodoBlock from '../../../components/StudentTodos/TodoBlock';
import { useFirebaseStudent, useStudentSystemTodos } from '../../../context/FirebaseStudentContext';
import StudentToDoForm from '../../../forms/StudentTodos/StudentToDoForm';
import AddButton from '../../../components/StudentUI/AddButton';
import down from '../../../assets/student/images/down-carat-green.svg';
import './TodoStyles.scss';

export default function StudentToDoByGroup() {
  const { student, addOrUpdateListItem, removeListItem } = useFirebaseStudent();
  const { profileTodos, programSearchTodos, applicationTodos, studentTodos } = useStudentSystemTodos();
  const [showForm, setShowForm] = useState(null);

  const tasksIncomplete = useMemo(() => {
    return studentTodos.filter(t => !t.complete);
  }, [studentTodos]);

  const profileTodosIncomplete = useMemo(() => {
    return profileTodos.filter(t => !t.complete);
  }, [profileTodos]);

  const programSearchTodosIncomplete = useMemo(() => {
    return programSearchTodos.filter(t => !t.complete);
  }, [programSearchTodos]);

  const applicationTodosIncomplete = useMemo(() => {
    return applicationTodos.filter(t => !t.complete);
  }, [applicationTodos]);

  const completeTodos = useMemo(() => {
    return [
      ...profileTodos.filter(t => t.complete),
      ...programSearchTodos.filter(t => t.complete),
      ...applicationTodos.filter(t => t.complete),
      ...studentTodos.filter(t => t.complete),
    ];
  }, [profileTodos, programSearchTodos, applicationTodos, studentTodos]);

  useEffect(() => {
    setShowForm(null);
  }, [student]);

  const getDefaultValues = (activeForm, studentData) => {
    if (!activeForm || activeForm.key === null || !studentData[activeForm.type]) {
      return null;
    } else {
      return studentData[activeForm.type][activeForm.key];
    }
  }

  const firstSection = useMemo(() => {
    if (tasksIncomplete?.length) {
      return 'tasks';
    } else if (profileTodosIncomplete?.length) {
      return 'profile';
    } else if (programSearchTodosIncomplete?.length) {
      return 'programSearch';
    } else if (applicationTodosIncomplete?.length) {
      return 'applications';
    } else {
      return '';
    }
  }, [tasksIncomplete, profileTodosIncomplete, applicationTodosIncomplete, programSearchTodosIncomplete]);

  return (
    <main className="student-todos-page">
      <div className="col">
        <div className="col__header">
          <h1>To - Do</h1>
          <AddButton onClick={() => setShowForm({ type: 'todos', index: null })}>
            add a task
          </AddButton>
        </div>

        {student && firstSection ? (
          <Accordion className="student-todo-accordion" preExpanded={[firstSection]} allowZeroExpanded>
            {tasksIncomplete?.length ? (
              <AccordionItem uuid="tasks" activeClassName="accordion__item--expanded">
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div>
                      Your Tasks
                      <span className="count">{tasksIncomplete.length} Task{tasksIncomplete.length === 1 ? '' : 's'}</span>
                    </div>
                    <img src={down} alt="" />
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  {tasksIncomplete.map((todo) => (
                    <TodoBlock
                      data={todo}
                      key={todo.id}
                      editData={() => setShowForm({ type: 'todos', key: todo.id })}
                    />
                  ))}
                </AccordionItemPanel>
              </AccordionItem>
            ) : null}

            {profileTodosIncomplete?.length ? (
              <AccordionItem uuid="profile" activeClassName="accordion__item--expanded">
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div>
                      Build My Profile
                      <span className="count">{profileTodosIncomplete.length} Task{profileTodosIncomplete.length === 1 ? '' : 's'}</span>
                    </div>
                    <img src={down} alt="" />
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  {profileTodosIncomplete.map((data) => <TodoBlock data={data} key={data.key} />)}
                </AccordionItemPanel>
              </AccordionItem>
            ) : null}

            {programSearchTodosIncomplete?.length ? (
              <AccordionItem uuid="programSearch" activeClassName="accordion__item--expanded">
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div>
                      Narrow Down My Search
                      <span className="count">{programSearchTodosIncomplete.length} Task{programSearchTodosIncomplete.length === 1 ? '' : 's'}</span>
                    </div>
                    <img src={down} alt="" />
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  {programSearchTodosIncomplete.map((data) => <TodoBlock data={data} key={data.key} />)}
                </AccordionItemPanel>
              </AccordionItem>
            ) : null}

            {applicationTodosIncomplete?.length ? (
              <AccordionItem uuid="applications" activeClassName="accordion__item--expanded">
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div>
                      Build My Application
                      <span className="count">{applicationTodosIncomplete.length} Task{applicationTodosIncomplete.length === 1 ? '' : 's'}</span>
                    </div>
                    <img src={down} alt="" />
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  {applicationTodosIncomplete.map((data) => <TodoBlock data={data} key={data.key} />)}
                </AccordionItemPanel>
              </AccordionItem>
            ) : null}
          </Accordion>
        ) : null}
      </div>


      <div className="col">
        <div className="col__header">
          <h1>Done 🎉</h1>
        </div>

        {completeTodos.map((data) => <TodoBlock data={data} key={data.key || data.id} done />)}
      </div>

      <Modal
        closeModal={() => setShowForm(null)}
        title="Add To Do"
        isOpen={showForm?.type === 'todos'}
        modalClassName="student-todo-panel"
      >
        <StudentToDoForm
          defaultValues={getDefaultValues(showForm, student)}
          saveData={(data) => addOrUpdateListItem('todos', data, 'To do saved!')}
          markComplete={showForm?.key ? () => {
            addOrUpdateListItem('todos', { ...getDefaultValues(showForm, student), complete: true }, 'Nice work! ✨ You\'ve finished this task.');
            setShowForm(null);
          } : null}
          deleteItem={showForm?.key ? () => {
            removeListItem('todos', showForm.key, 'To do removed!');
            setShowForm(null);
          } : null}
        />
      </Modal>
    </main>
  );
}