import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useFirebasePrograms } from '../../../context/FirebaseProgramDataContext';
import CampusSettingCard from '../../../components/StudentPrograms/CampusSettingCard';
import DataValueBox from '../../../components/StudentPrograms/DataValueBox';
import ProgressCircle from '../../../components/ProgressCircle';
import ShadowCard from '../../../components/ShadowCard';
import { colors } from '../../../shared/colors';
import './SchoolProfileStyles.scss';

export default function StudentSchoolProfileOverviewPage() {
  const { schoolId } = useParams();
  const { schools } = useFirebasePrograms();

  const data = useMemo(() => {
    if (schools && schoolId) return schools[schoolId];
    return null;
  }, [schools, schoolId]);

  if (data) return (
    <main className="school-profile-page">

      <section className="section">
        <div className="section__header">
          <h2 className="student-text-h2">Campus Setting</h2>
        </div>
        <CampusSettingCard
          mapImage={data.mapImageFilename}
          setting={data.campusSetting}
          displayAddress={data.displayAddress}
        />
      </section>

      <section className="section">
        <div className="section__header">
          <h2 className="student-text-h2">Graduate Students</h2>
        </div>
        <div className="grid">
          <ShadowCard customClassNames="grid padded">
            <DataValueBox value={data.gradStudentCount} label="Total" valueSize="large" />
            <DataValueBox value={data.intlStudentCount} label="International" valueSize="large" />
          </ShadowCard>
          <ShadowCard customClassNames="grid padded">
            <DataValueBox value={data.ptStudentCount} label="Part Time" valueSize="large" />
            <DataValueBox value={data.ftStudentCount} label="Full Time" valueSize="large" />
          </ShadowCard>
        </div>
      </section>

      <section className="section">
        <div className="section__header">
          <h2 className="student-text-h2">Program Format</h2>
        </div>
        <ShadowCard customClassNames="grid padded">
          <div className="center">
            <ProgressCircle
              percentComplete={data.hybridPercent}
              fgColor={colors.darkPink}
              bgColor={colors.mediumPeach}
              label="Hybrid"
              size={168}
            />
          </div>
          <div className="center">
            <ProgressCircle
              percentComplete={data.onlinePercent}
              fgColor={colors.darkPink}
              bgColor={colors.mediumPeach}
              label="Online"
              size={168}
            />
          </div>
          <div className="center">
            <ProgressCircle
              percentComplete={data.campusPercent}
              fgColor={colors.darkPink}
              bgColor={colors.mediumPeach}
              label="Campus"
              size={168}
            />
          </div>
        </ShadowCard>
      </section>
    </main>
  );

  return null;
}