import checkIcon from '../../../assets/inst/images/check.svg';
import xIcon from '../../../assets/inst/images/chart-x.svg';
import './PricingModels.scss';

export default function InstitutionPricingFeaturesPage() {
  return (
    <main className="inst-pricing-models">
      <div className="intro">
        <h2 className="inst-text-h2">Pricing Model 2: Feature-based Model</h2>
        <p className="inst-text-p gray">We offer 3 different pricing tiers to meet your need. If it&apos;s hard to make the decision, please take this survey.</p>
      </div>

      <div className="features-grid">
        <div className="heading"></div>
        <div className="heading">Basic</div>
        <div className="heading green">Pro</div>
        <div className="heading">Premium</div>

        <div className="col-1">
          <p>Monthly Subscription Fee per Program</p>
          <p className="or">Or</p>
        </div>
        <div className="flex-center">
          <p className="inst-text-title"><span>FREE</span></p>
        </div>
        <div className="flex-center green">
          <p>
            <span className="inst-text-title">$99</span>
            /month
          </p>
        </div>
        <div className="flex-center">
          <p>
            <span className="inst-text-title">$199</span>
            /month
          </p>
        </div>

        <div className="col-1">Yearly Subscription Fee per Program</div>
        <div className="flex-center">
          <p className="inst-text-h2">FREE</p>
        </div>
        <div className="flex-center green">
          <p>
            <span className="inst-text-h2">$1,188</span>
            /year
          </p>
        </div>
        <div className="flex-center">
          <p>
            <span className="inst-text-h2">$2,388</span>
            /year
          </p>
        </div>

        <div className="col-1">One time Integration fee with SLATE</div>
        <div className="flex-center">
          <p className="inst-text-h2">N/A</p>
        </div>
        <div className="flex-center green">
          <p className="inst-text-h2">N/A</p>
        </div>
        <div className="flex-center">
          <p className="inst-text-h2">$499</p>
        </div>

        <div className="col-1">List Purchase</div>
        <div className="flex-center">
          <p>
            <span className="inst-text-h2">$1.29</span>
            /student
          </p>
          <p className="inst-text-p-small gray">minimum 1000</p>
        </div>
        <div className="flex-center green">
          <p>
            <span className="inst-text-h2">$1.29</span>
            /student
          </p>
          <p className="inst-text-p-small gray">minimum 1000</p>
        </div>
        <div className="flex-center">
          <p>
            <span className="inst-text-h2">$1.29</span>
            /student
          </p>
          <p className="inst-text-p-small gray">minimum 1000</p>
        </div>
      </div>

      <div className="features-grid tight">
        <div className="col-1 inst-text-h4">Features</div>
        <div></div>
        <div className="green"></div>
        <div></div>

        <div className="col-1">- Basic Program Service</div>
        <div className="flex-center"><img src={checkIcon} alt="Yes" /></div>
        <div className="flex-center green"><img src={checkIcon} alt="Yes" /></div>
        <div className="flex-center"><img src={checkIcon} alt="Yes" /></div>

        <div className="col-1">- Personalized Recruitment</div>
        <div className="flex-center"><img src={xIcon} alt="No" /></div>
        <div className="flex-center green"><img src={checkIcon} alt="Yes" /></div>
        <div className="flex-center"><img src={checkIcon} alt="Yes" /></div>

        <div className="col-1">- Mass Recruitment</div>
        <div className="flex-center">upsell</div>
        <div className="flex-center green">upsell</div>
        <div className="flex-center">upsell</div>

        <div className="col-1">- Application</div>
        <div className="flex-center"><img src={xIcon} alt="No" /></div>
        <div className="flex-center green"><img src={xIcon} alt="No" /></div>
        <div className="flex-center"><img src={checkIcon} alt="Yes" /></div>

        <div className="col-1">- Advanced Data/Reporting</div>
        <div className="flex-center"><img src={xIcon} alt="No" /></div>
        <div className="flex-center green"><img src={xIcon} alt="No" /></div>
        <div className="flex-center"><img src={checkIcon} alt="Yes" /></div>

        <div className="col-1 last">- Promotion</div>
        <div className="flex-center last"><img src={xIcon} alt="No" /></div>
        <div className="flex-center green last"><img src={xIcon} alt="No" /></div>
        <div className="flex-center last"><img src={checkIcon} alt="Yes" /></div>
      </div>

      <hr />

      <h2 className="inst-text-h1 center space-y">Service Description</h2>
      <div className="bullets-grid">

        <p>Basic Program Service</p>
        <ul>
          <li>Customize program profile page.</li>
          <li>View profiles of prospective students on GradConnector.</li>
          <li>Search for prospective students who match specific criteria.</li>
          <li>Respond to student initiated inquiries.</li>
          <li>View number of daily, weekly, and monthly program profile visits.</li>
          <li>View number of times the program appears in student search results weekly.</li>
          <li>Preview the Customized Candidate Builder (paid feature).</li>
        </ul>

        <p>Personalized Recruitment</p>
        <ul>
          <li>Initiate 1:1 contact (messaging) with students within GradConnector platform.</li>
          <li>Favorite prospective students that match your program needs.</li>
          <li>Receive customized recommendations for prospective students that best fit your program.</li>
          <li>Match with prospective students that favorite you to communicate and share information about your program.</li>
          <li>Create customized candidate profiles to see matched students based on your criteria (paid feature).</li>
        </ul>

        <p>Mass recruitment</p>
        <ul>
          <li>Purchase list of prospective students using customized searches. Lists can be exported to be used in your CRM or other direct marketing tools.</li>
          <li>Option to include GRE test takers from a database of 500,000+ who have opted in to hear from programs.</li>
        </ul>

        <p>Application</p>
        <ul>
          <li>Accept applications from GradConnector through your dashboard to view number of applications received, pending reviews, reviewed applications.</li>
          <li>Have the ability to select custom fields to create a GradConnector application template for your program.</li>
          <li>Add low stakes assessments to application requirements to see more data on prospective applicants (paid feature).</li>
          <li>Integrate the GradConnector student application to feed directly into Slate or manage directly through the GradConnect portal.</li>
        </ul>

        <p>Advanced Data/Reporting</p>
        <ul>
          <li>Receive ROI data including conversion rates and recruitment savings.</li>
          <li>Receive ROI reporting including customized recommendations to improve recruitment ROI based on GHE products&apos; data.</li>
          <li>Observe student preference trends including where students want to go, goals, aspirations, fields of study, career objectives, etc.</li>
          <li>Receive recruitment reports with advice on how to expand your applicant pool and apply GHE products to their fullest potential.</li>
          <li>View peer institution trends including how many favorites and applications other programs in the same academic area or geographic location are receiving (no specific names given).</li>
        </ul>

        <p>Promotion</p>
        <ul>
          <li>Be featured as a Spotlight Institution in Resource Center.</li>
          <li>Promote an online open house or campus tour with GradConnector users.</li>
          <li>Participate in a virtual college fair hosted by GradConnector.</li>
        </ul>
      </div>
    </main>
  )
}