import { useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useFirebasePrograms } from '../../context/FirebaseProgramDataContext';
import ProgramMainImageCard from '../../components/StudentPrograms/ProgramMainImageCard';
import TabNav from '../../components/StudentUI/TabNav';
import BackLink from '../../components/BackLink';
import ContactUsButton from '../../components/StudentMessaging/ContactUsButton';
import { useFirebaseStudent } from '../../context/FirebaseStudentContext';
import './StudentSchoolProfileLayout.scss';

export default function StudentProgramProfileLayout() {
  const { programId } = useParams();
  const { programs } = useFirebasePrograms();
  const { student } = useFirebaseStudent();

  const data = useMemo(() => {
    if (programs && programId) return programs[programId];
    return null;
  }, [programId, programs]);

  if (student && data) return (
    <div className="student-school-profile">
      <div className="student-school-profile__top">
        <BackLink to="/students/explore" label="Back" />
        <ProgramMainImageCard
          programId={data.programId}
          schoolId={data.schoolId}
          imageUrl={data.programImageName}
          programTitle={data.programTitle}
          schoolName={data.schoolName}
          website={data.website}
          recommend={data.recommend}
        />
      </div>
      <TabNav
        tabs={[
          { label: 'Overview', to: '' },
          { label: 'Admission & Application', to: 'admissions' },
          { label: 'Faculty', to: 'faculty' },
          { label: 'Tuition & Costs', to: 'costs' },
          { label: 'FAQs', to: 'faq' }
        ]}
      />
      <div className="student-school-profile__content">
        <Outlet />
      </div>
      <ContactUsButton toIdentifier={`${data.schoolName} - ${data.programTitle}`} fromIdentifier={student.alias} />
    </div>
  );

  return null;
}