import PropTypes from 'prop-types';
import ShadowCard from '../ShadowCard';
import { cipFieldOptions } from '../../shared/formOptions';
import { studentPropTypes } from '../../shared/propTypes';
import profileIcon from '../../assets/student/images/profile-large.svg';
import './BasicInfoBlock.scss';
import ProfileIconButtonBlock from './ProfileIconButtonBlock';

const getPronouns = (pronouns) => {
  if (!pronouns || pronouns === 'Prefer not to say') return null;
  return pronouns;
}

const getDegrees = (degrees) => {
  if (!degrees || !degrees.length) return null;
  return degrees.join(', ');
}

const getStartDate = (startTerm) => {
  if (!startTerm || !startTerm.length) return null;
  return startTerm.join(' or ');
}

const getFieldOfStudy = (field) => {
  if (!field) return null;
  return cipFieldOptions.find(o => o.value === field)?.label;
}

export default function BasicInfoBlock({ data, name, editData }) {
  const pronounsDisplayLabel = getPronouns(data?.pronouns);
  const degreeDisplayLabel = getDegrees(data?.seekingDegree);
  const expectedStartDateDisplayLabel = getStartDate(data?.expectedStartDate);
  const fieldOfStudyDisplayLabel = getFieldOfStudy(data?.fieldOfStudy);
  return (
    <ShadowCard customClassNames="student-basic-info">
      <img src={profileIcon} alt="" />
      <div className="student-basic-info__left">
        <h2 className="student-text-h2">{name} {pronounsDisplayLabel ? <span className="student-text-p-italic">({pronounsDisplayLabel})</span> : null}</h2>
        {!data ? (
          <button onClick={editData} className="primary-button">Add Basic Information</button>
        ) : (
          <p className="student-text-p-small">
            From {data?.citizenshipCountry || '—'}<br />
            Interested in {degreeDisplayLabel || '—'} in {fieldOfStudyDisplayLabel || '—'}<br />
            Expecting to start in {expectedStartDateDisplayLabel || '—'}
          </p>
        )}
      </div>
      <div className="student-basic-info__right">
        <div>
          <ProfileIconButtonBlock
            showPrivacy
            onEdit={editData}
          />
        </div>

        <div className="bubbles student-text-p-small">
          {data?.isInternational ? <span className="bubble">International Student</span> : null}
          {data?.isFirstGeneration ? <span className="bubble">First Generation Student</span> : null}
        </div>
      </div>

    </ShadowCard>
  );
}

BasicInfoBlock.propTypes = {
  name: PropTypes.string,
  data: PropTypes.shape({
    isInternational: studentPropTypes.isInternational,
    isFirstGeneration: studentPropTypes.isFirstGeneration,
    pronouns: studentPropTypes.pronouns,
    citizenshipCountry: studentPropTypes.citizenshipCountry,
    seekingDegree: studentPropTypes.seekingDegree,
    fieldOfStudy: studentPropTypes.fieldOfStudy,
    expectedStartDate: studentPropTypes.expectedStartDate,
  }),
  editData: PropTypes.func.isRequired,
}