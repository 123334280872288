import linkedinIcon from '../../assets/student/images/linkedin.svg';
import { comingSoonShort } from '../../shared/appText';
import './ImportFromLinkedInButton.scss';

export default function ImportFromLinkedInButton() {
  return (
    <div className="import-button-group">
      <span className="coming-soon">{comingSoonShort}</span>
      <button disabled type="button" className="primary-button">
        <img src={linkedinIcon} alt="" />Import From LinkedIn
      </button>
    </div>
  );
}