import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { studentEducationPropTypes } from '../../shared/propTypes';
import Field from '../../components/Field';
import PopupCard from '../../components/StudentUI/PopupCard';
import deleteImg from '../../assets/student/images/delete.svg';
import './StudentOnboardingForm.scss';

export default function StudentOnboardingEducationForm({ labelText, defaultValues, saveData, deleteItem }) {
  const { register, getValues, reset } = useForm({ defaultValues });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const deleteForm = () => {
    deleteItem();
    reset({
      isCurrent: false,
      schoolName: '',
      degree: '',
      major: '',
      startDate: null,
      endDate: null,
      grade: '',
      description: '',
    });
  }

  const handleBlur = (e) => {
    if (!e.target.classList.contains('btn--delete')) {
      const data = getValues();
      saveData(data);
    }
  }

  return (
    <form className="student-onboarding-form" onBlur={handleBlur}>
      <Field>
        <label>
          {labelText}
          <input type="text" {...register('degree')} placeholder="Write degree type here" />
        </label>
        {deleteItem ? (
          <PopupCard
            triggerButton={<button type="button" className="btn--delete"><img src={deleteImg} alt="Delete" /></button>}
          >
            <div className="student-delete-warning">
              <p>Are you sure you want to delete this?</p>
              <button onClick={deleteForm}>Delete</button>
            </div>
          </PopupCard>
        ) : null}
      </Field>

      <Field>
        <label>
          from
          <input type="text" {...register('schoolName')} placeholder="Write school name here" />
        </label>
      </Field>

      <Field>
        <label>
          in
          <input type="text" {...register('major')} placeholder="Write major here" />
        </label>
      </Field>
    </form>
  );
}

StudentOnboardingEducationForm.defaultProps = {
  defaultValues: {
    isCurrent: false,
    schoolName: '',
    degree: '',
    major: '',
    startDate: null,
    endDate: null,
    grade: '',
    description: '',
  },
};

StudentOnboardingEducationForm.propTypes = {
  labelText: PropTypes.string.isRequired,
  defaultValues: PropTypes.shape(studentEducationPropTypes),
  saveData: PropTypes.func.isRequired,
  deleteItem: PropTypes.func,
};