import { Outlet } from 'react-router-dom';
import SectionHeader from '../../components/SectionHeader';
import MessagingPopup from '../../components/StudentMessaging/MessagingPopup';
import TabNav from '../../components/StudentUI/TabNav';

export default function StudentFavoriteProgramsLayout() {
  return (
    <div>
      <SectionHeader title="Saved Programs" />
      <TabNav tabs={[
        { label: 'Favorites', to: '' },
        { label: 'Favorited by', to: 'byprograms' },
        { label: 'Matches', to: 'matches' },
      ]} />
      <Outlet />
      <MessagingPopup />
    </div>
  );
}