import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './ResourceArticlesPage.scss';
import carat from '../../../assets/student/images/down-carat-dark-pink.svg';
import search from '../../../assets/icons/search-gray.svg';

export default function StudentResourceArticlesPage() {
  const [articles, setArticles] = useState([]);
  const [filterView, setFilterView] = useState('All');
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);

  const toggleFilter = () => {
    setFilterMenuOpen(!filterMenuOpen);
  }

  const fetchArticles = async (filter) => {
    const articles = await fetch(`${window.location.origin}/student-resources/index.json`);
    const json = await articles.json();

    if (filter === 'General Information') {
      const asArray = Object.entries(json);
      const filtered = asArray.filter(([, value]) => value['group'] === 'general');
      setArticles(Object.fromEntries(filtered));
    } else if (filter === 'Financial Resources') {
      const asArray = Object.entries(json);
      const filtered = asArray.filter(([, value]) => value['group'] === 'financial');
      setArticles(Object.fromEntries(filtered));
    } else {
      setArticles(json);
    }
  }

  const changeFilter = async (filter) => {
    setFilterView(filter);
    setFilterMenuOpen(false);
    fetchArticles(filter);
  }

  useEffect(() => {
    fetchArticles('All');
  }, []);

  return (
    <div className="page-container">
      <div className="search-bar-container">
        <input placeholder="Search ✨ Coming soon ✨" className="search-bar student-text-p" type="text" value="" disabled/>
        <img className="search-bar-icon" src={search} alt="" />
      </div>
      <div className="filter-container">
        <button onClick={toggleFilter} className="filter-button">
          <span className="student-text-h4">Show: {filterView}</span>
          <img className={filterMenuOpen ? 'reverse' : ''} src={carat} alt="" />
        </button>
        {filterMenuOpen && <div className="filter-menu">
          <button className="student-text-p" onClick={() => changeFilter('All')}>All</button>
          <button className="student-text-p" onClick={() => changeFilter('General Information')}>General Information</button>
          <button className="student-text-p" onClick={() => changeFilter('Financial Resources')}>Financial Resources</button>
        </div>}
      </div>
      <div className="articles-container">
        {Object.keys(articles).map(slug => (
          <Link className="articles-card" to={`/students/resources/${slug}`} key={slug}>
            <div>
              <img src={`/student-resources/${articles[slug].imagePath}`} alt="" />
              <div className="articles-card-content">
                <h2 className="student-text-h2">{articles[slug].title}</h2>
                <p className="student-text-p">{articles[slug].blurb}</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
