import SectionHeader from '../../../components/SectionHeader';
import womanRainIllus from '../../../assets/student/images/woman-rain.svg';
import './DocumentsPage.scss';

export default function DocumentsPage() {
  return (
    <div className='student-documents'>
      <SectionHeader title="Documents (Coming Soon)" />
      <main className='student-documents__content'>
        <img src={womanRainIllus} alt='' width={384} />
        <h1 className='student-text-h1'>Keep Your Documents Organized and Accessible</h1>
        <p className='student-text-p-large'>Keep all your documents in one place for easy reference and accessibility for use in graduate school and post-graduate career application submissions.</p>
      </main>
    </div>
  );
}