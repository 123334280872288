import SectionHeader from '../../../components/SectionHeader';
import ShadowCard from '../../../components/ShadowCard';
import ButtonLink from '../../../components/InstitutionUI/ButtonLink';
import { useFirebasePrograms } from '../../../context/FirebaseProgramDataContext';
import heart from '../../../assets/inst/images/heart-green.svg';
import plane from '../../../assets/inst/images/paper-plane.svg';
import folder from '../../../assets/inst/images/folder-green.svg';
import check from '../../../assets/inst/images/check-green.svg';
import magnifyingGlassImage from '../../../assets/inst/images/magnifying-glass-man.svg';
import './DashboardPage.scss';

export default function InstitutionDashboardPage() {
  const { activeProgram } = useFirebasePrograms();
  return (
    <div className="inst-dashboard">
      <SectionHeader title="Dashboard" />

      <div className="inst-dashboard__content">
        <section>
          <ShadowCard customClassNames="welcome">
            <h2 className="inst-text-title">Welcome!</h2>
            <p className="inst-text-h3">{activeProgram?.programTitle}</p>
            <p className="inst-text-h4">{activeProgram?.schoolName}</p>
            <ButtonLink to="/institutions/schoolprofile">
              See Profile
            </ButtonLink>
          </ShadowCard>
        </section>

        <section className="program-performance-section">
          <h2 className="inst-text-h2">Program Performance</h2>
          <div className="grid">
            <ShadowCard customClassNames="performance-cards">
              <p className="inst-text-label">Unique Search Appearances</p>
              <div className="stats-container">
                <span className="stats">
                  <p className="inst-text-h1">206</p>
                  <p className="inst-text-p">Last 7 Days</p>
                </span>
                <span className="stats">
                  <p className="inst-text-h1">1056</p>
                  <p className="inst-text-p">Last 30 Days</p>
                </span>
              </div>
            </ShadowCard>
            <ShadowCard customClassNames="performance-cards">
              <p className="inst-text-label">Unique Profile Visits</p>
              <div className="stats-container">
                <span className="stats">
                  <p className="inst-text-h1">103</p>
                  <p className="inst-text-p">Last 7 Days</p>
                </span>
                <span className="stats">
                  <p className="inst-text-h1">536</p>
                  <p className="inst-text-p">Last 30 Days</p>
                </span>
              </div>
            </ShadowCard>
            <ShadowCard customClassNames="performance-cards">
              <p className="inst-text-label">Favorited By</p>
              <div className="stats-container">
                <span className="stats">
                  <p className="inst-text-h1">89</p>
                  <p className="inst-text-p">Last 7 Days</p>
                </span>
                <span className="stats">
                  <p className="inst-text-h1">536</p>
                  <p className="inst-text-p">Last 30 Days</p>
                </span>
              </div>
            </ShadowCard>
          </div>

          <ShadowCard customClassNames="stats-grid performance-cards">
            <span className="stats-container">
              <p className="inst-text-label">Student Interactions</p>
              <h1 className="inst-text-title">563</h1>
            </span>
            <div className="stats-container">
              <span className="stats">
                <img src={heart} alt=""/>
                <p className="inst-text-p-small">Favorited By</p>
                <p className="inst-text-h2">428</p>
              </span>
            </div>
            <div className="stats-container">
              <span className="stats">
                <img src={plane} alt=""/>
                <p className="inst-text-p-small">Messages</p>
                <p className="inst-text-h2">135</p>
              </span>
            </div>
            <div className="stats-container">
              <span className="stats">
                <img src={folder} alt=""/>
                <p className="inst-text-p-small">Applicants</p>
                <p className="inst-text-h2">42</p>
              </span>
            </div>
            <div className="stats-container">
              <span className="stats">
                <img src={check} alt=""/>
                <p className="inst-text-p-small">Enrolled</p>
                <p className="inst-text-h2">12</p>
              </span>
            </div>
          </ShadowCard>
        </section>

        <section>
          <ShadowCard customClassNames="search-redirect">
            <div className="blurb">
              <img src={magnifyingGlassImage} alt=""/>
              <h2 className="inst-text-h2">GradConnector offers multiple ways to help you search and find potential candidates for your program.</h2>
            </div>
            <ButtonLink to="/institutions/recruiting/studentsearch">
              Start Your Search
            </ButtonLink>
          </ShadowCard>
        </section>
      </div>
    </div>
  )
}