import { useState, useMemo } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import SectionHeader from '../../../components/SectionHeader';
import InProgressAppCard from '../../../components/StudentApplications/InProgressAppCard';
import SubmittedAppCard from '../../../components/StudentApplications/SubmittedAppCard';
import ShadowCard from '../../../components/ShadowCard';
import MessagingPopup from '../../../components/StudentMessaging/MessagingPopup';
import { useFirebasePrograms } from '../../../context/FirebaseProgramDataContext';
import { useStudentApplicationStatus } from '../../../context/FirebaseStudentContext';
import { useFirebaseStudent } from '../../../context/FirebaseStudentContext';
import appsIllus from '../../../assets/student/images/apps-illustration.svg';
import './ApplicationsPage.scss';
import ProgressCircle from '../../../components/ProgressCircle';
import Modal from '../../../components/Modal';
import { colors } from '../../../shared/colors';
import { comingSoonShort } from '../../../shared/appText';
import carat from '../../../assets/student/images/down-carat-blue.svg';
import add from '../../../assets/student/images/add.svg';
import AddApplicationForm from '../../../forms/StudentApplication/AddApplicationForm';

export default function StudentApplicationsPage() {
  const { programsList, programs } = useFirebasePrograms();
  const { applicationsList, inProgressApps, submittedApps } = useStudentApplicationStatus();
  const { batchCreateApplications, student } = useFirebaseStudent();
  const [showAppModal, setShowAppModal] = useState(false);

  const addAppProgramOptions = useMemo(() => {
    if (!programsList || !programsList.length) return [];
    if (!student?.applications) return programsList;
    return programsList.filter(p => !student?.applications[p.programId]);
  }, [programsList, student?.applications]);

  const createAppsForPrograms = (targetPrograms) => {
    const appData = targetPrograms.map(p => ({
      programId: p.programId,
      programTitle: p.programTitle,
      schoolId: programs?.[p.programId]?.schoolId,
      schoolName: programs?.[p.programId]?.schoolName,
      deadline: '2022-06-01',
      alias: student?.alias,
    }));
    batchCreateApplications(appData, `${appData.length} applications created!`);
  }

  return (
    <>
      <div className="student-apps">
        <SectionHeader title="Applications" />
        <main className="student-apps__content">

          <section className="section">
            <h2 className="student-text-h2">Your Progress</h2>
            <div className="progress">
              <ShadowCard>
                <ProgressCircle
                  size={200}
                  percentComplete={applicationsList.length ? (submittedApps.length / applicationsList.length * 100) : 0}
                  bgColor={colors.lightBlue}
                  fgColor={colors.blue}
                >
                  <span className="percent" style={{ fontSize: '2rem' }}>{submittedApps.length} of {applicationsList.length}</span>
                  <span className="label">Complete</span>
                </ProgressCircle>
                <p className="student-text-p-large-bold">Nice work! 😎</p>
                <p className="student-text-label in-progress">
                  <span className="student-text-h2">{applicationsList.length - submittedApps.length}</span>
                  in progress
                </p>
                <p className="student-text-label submitted">
                  <span className="student-text-h2">{submittedApps.length}</span>
                  submitted
                </p>
                <button className="add-app" onClick={() => setShowAppModal(true)}>
                  <img src={add} alt="" />
                  Add Applications
                </button>
              </ShadowCard>

              <ShadowCard customClassNames="overview-content">
                <div className="overview-content__image">
                  <img src={appsIllus} alt="" />
                </div>
                <div className="student-text-p-large">
                  <p>The Graduate Unified Application, accepted by 950 programs across the US and beyond, is your opportunity to demonstrate why you&apos;re the best fit for graduate school.</p>
                  <p>We have consolidated the application process for you so that you can apply to your chosen programs right from this site. Remember to customize each application to show the best you have to offer to each program.</p>
                </div>
              </ShadowCard>
            </div>
          </section>

          <section className="section placeholder-link">
            <p className="student-text-p">Can&apos;t decide what school is the best fit for you? Try our school comparison tool <span className="accent">{comingSoonShort}</span></p>
          </section>

          <section className="section">
            <Accordion preExpanded={['in-progress']} allowMultipleExpanded allowZeroExpanded>
              <AccordionItem uuid="in-progress" activeClassName="accordion__item--expanded">
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div>
                      <span className="student-text-h2">In Progress</span>&nbsp;
                      <span className="student-text-p-small-italic accent">{inProgressApps.length} Applications</span>
                    </div>
                    <img src={carat} alt="" />
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="apps-list--in-progress">
                    {inProgressApps.map(app => (
                      <InProgressAppCard
                        application={app}
                        key={app.programId}
                      />
                    ))}
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem uuid="submitted" activeClassName="accordion__item--expanded">
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div>
                      <span className="student-text-h2">Completed</span>&nbsp;
                      <span className="student-text-p-small-italic accent">{submittedApps.length} Applications</span>
                    </div>
                    <img src={carat} alt="" />
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="apps-list--submitted">
                    {submittedApps.map(app => (
                      <SubmittedAppCard
                        application={app}
                        key={app.programId}
                      />
                    ))}
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </section>
          <Modal
            closeModal={() => setShowAppModal(false)}
            title="Add Applications"
            isOpen={showAppModal}
          >
            <div className="student-add-app-modal">
              <h1>Add Applications</h1>
              <AddApplicationForm
                programOptions={addAppProgramOptions}
                saveData={(data) => {
                  const { targetPrograms } = data;
                  if (targetPrograms?.length) {
                    createAppsForPrograms(targetPrograms);
                    setShowAppModal(false);
                  }
                }}
              />
            </div>
          </Modal>
        </main>
      </div>
      <MessagingPopup />
    </>
  );
}