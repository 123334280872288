import './CalculateROIGraph.scss';
import PropTypes from 'prop-types';
import { ResponsiveLine } from '@nivo/line';
import { currencyFormatter } from '../../../shared/numbers';
import info from '../../../assets/student/images/info-pink.svg';
import { useState } from 'react';

export default function StudentCalculateROIGraph({graphData, showForm}) {
  const {
    annualIncomeBeforeSchool,
    anticipatedYears,
    annualTuition,
    tuitionPaid,
    loanInterest,
    paybackYears,
    livingExpenses,
    annualIncome,
    postGraduationExpenses
  } = graphData;

  const [tooltipDisplay, setTooltipDisplay] = useState(null);

  const changeTooltip = tooltip => {
    setTooltipDisplay(tooltipDisplay ? null : tooltip);
  }

  const isLoanPaidOff = year => {
    return year >= paybackYears;
  }

  const totalCostOfSchooling = () => {
    return (anticipatedYears * annualTuition + livingExpenses * 12 - tuitionPaid) *
      Math.pow((1 + (loanInterest / 100) / 12), (12 * paybackYears)) + tuitionPaid;
  }

  const calculateWorthBeforePayoff = year => {
    return (annualIncome - postGraduationExpenses * 12) * year -
      ((anticipatedYears * (annualTuition + livingExpenses * 12) - tuitionPaid) *
        (Math.pow((1 + (loanInterest / 100) / 12), (12 * (year + anticipatedYears)))) + tuitionPaid);
  }

  const calculateWorthAfterPayoff = year => {
    return (annualIncome - postGraduationExpenses * 12) * year - totalCostOfSchooling();
  }

  const calculateWorth = year => {
    return isLoanPaidOff(year) ? calculateWorthAfterPayoff(year) : calculateWorthBeforePayoff(year);
  }

  const baselineData = {
    'id': 'No Graduate School Scenario',
    'color': 'hsl(170, 50%, 40%)',
    'data': [...Array(31).keys()].map(n => ({
      'x': n,
      'y': (annualIncomeBeforeSchool - (livingExpenses * 12)) * n
    }))
  };

  const backToSchoolData = {
    'id': 'Graduate School Scenario',
    'color': 'hsl(0, 100%, 50%)',
    'data': [...Array(31).keys()].map(n => ({
      'x': n,
      'y': calculateWorth(n)
    }))
  };

  const comparisonData = {
    'id': 'Grad School vs. No Grad School',
    'color': 'hsl(0, 100%, 0%)',
    'data': [...Array(31).keys()].map(n => ({
      'x': n,
      'y': backToSchoolData.data[n]['y'] - baselineData.data[n]['y']
    }))
  };

  const data = [
    baselineData,
    backToSchoolData,
    comparisonData
  ];

  const line = (<ResponsiveLine
    data={data}
    margin={{ top: 20, right: 20, bottom: 120, left: 100 }}
    xScale={{ type: 'point' }}
    yScale={{
      type: 'linear',
      min: 'auto',
      max: 'auto',
      reverse: false
    }}
    yFormat=" >-.2f"
    axisTop={null}
    axisRight={null}
    axisBottom={{
      orient: 'bottom',
      tickSize: 5,
      tickCount: 5,
      tickPadding: 5,
      tickRotation: 0,
      tickValues: [0, 5, 10, 15, 20, 25, 30],
      legend: '# of Years from Today',
      legendOffset: 36,
      legendPosition: 'middle'
    }}
    axisLeft={{
      format: l => `$${l.toLocaleString('en-us')}`,
      orient: 'left',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Net Worth',
      legendOffset: -85,
      legendPosition: 'middle'
    }}
    gridXValues={[0, 5, 10, 15, 20, 25, 30]}
    pointSize={10}
    pointBorderWidth={2}
    pointLabelYOffset={-12}
    colors={d => d.color}
  />);

  return (
    <div className="container">
      <div className="info-container">
        <div className="info-card">
          <div className="card-header">
            <span className="student-text-label">Total Cost of Graduate Program</span>
            <img onClick={() => changeTooltip('totalCost')} role="presentation" src={info} alt="" className="info" />
            {tooltipDisplay === 'totalCost' &&
              <div className="tooltipContainer">
                <div className="tooltip">
                  This is the total amount (before financial aid) it will cost a student to attend this program, including tuition, fees, materials, room and board, transportation and any other cost of living expenses a student may accrue.
                </div>
              </div>
            }
          </div>
          <h1 className="student-text-title">{currencyFormatter.format(totalCostOfSchooling())}</h1>
        </div>
        <div className="info-card">
          <div className="card-header">
            <span className="student-text-label">Post-Graduation Net Worth</span>
            <img onClick={() => changeTooltip('netWorth')} role="presentation" src={info} alt="" className="info" />
            {tooltipDisplay === 'netWorth' &&
              <div className="tooltipContainer">
                <div className="tooltip">
                  Your post-graduation net worth is the difference between your debt and your assets, including your post-graduation income. The difference between these numbers is your net worth.
                </div>
              </div>
            }
          </div>
          <h1 className="student-text-title">{currencyFormatter.format((annualIncome - postGraduationExpenses * 12))} yearly</h1>
        </div>
      </div>
      <div className="graph-container">
        <h3 className="student-text-h2">Net Worth Over Time</h3>
        <div className="legend">
          <div className="legend-item student-text-p"><span id='green'></span>No Graduate School Scenario</div>
          <div className="legend-item student-text-p"><span id='red'></span>Graduate School Scenario</div>
          <div className="legend-item student-text-p"><span id='black'></span>Grad School vs. No Grad School</div>
        </div>
        {line}
      </div>
      <button className="student-text-label" onClick={showForm}>Calculate Again</button>
    </div>
  );
}

StudentCalculateROIGraph.propTypes = {
  graphData: PropTypes.shape({
    annualIncomeBeforeSchool: PropTypes.number,
    anticipatedYears: PropTypes.number,
    annualTuition: PropTypes.number,
    tuitionPaid: PropTypes.number,
    loanInterest: PropTypes.number,
    paybackYears: PropTypes.number,
    livingExpenses: PropTypes.number,
    annualIncome: PropTypes.number,
    postGraduationExpenses: PropTypes.number
  }),
  showForm: PropTypes.func
};
