import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useFirebaseAuth } from '../../context/FirebaseAuthContext';
import ShadowCard from '../../components/ShadowCard';
import Field from '../../components/Field';
import loginIllus from '../../assets/login.svg';
import logo from '../../assets/student/images/grad-connector.svg';
import './Login.scss';

const required = 'This is a required field. Please fill out before continuing.'

export default function ForgotPasswordPage() {
  const [requestedEmail, setRequestedEmail] = useState('');
  const [requestState, setRequestState] = useState({});
  const { register, handleSubmit, watch, formState: { errors, isValid } } = useForm({ defaultValues: { email: '' }, mode: 'all' });
  const { requestPasswordReset } = useFirebaseAuth();

  const emailValue = watch('email');

  const attemptReset = ({ email }) => {
    setRequestedEmail(email);
    requestPasswordReset(
      email,
      (successMsg) => setRequestState({ mode: 'success', message: successMsg }),
      (errorMsg) => setRequestState({ mode: 'error', message: errorMsg }),
    );
  }

  return (
    <main className="login">
      <div className="login__header">
        <Link to="/">
          <img src={logo} alt="GradConnect" />
        </Link>
      </div>
      <div className="login__content">
        <ShadowCard customClassNames="form__wrapper">
          <h1 className="student-text-title">Welcome to GradConnector!</h1>
          <p className="student-text-h2">Forgot your password?</p>
          {requestState?.mode === 'error' ? <p className="error-msg">{requestState.message}</p> : null}
          {requestState?.mode === 'success' ? <p className="success-msg">{requestState.message}</p> : null}
          <form onSubmit={handleSubmit(attemptReset)}>
            <Field error={errors?.email}>
              <label>
                Email *
                <input type="email" {...register('email', { required })} placeholder="Write here" />
              </label>
            </Field>

            <Field center>
              <button type="submit" disabled={!isValid || requestedEmail === emailValue}>Send Request</button>
            </Field>
          </form>
        </ShadowCard>
        <div>
          <img src={loginIllus} alt="" height={435} />
        </div>
      </div>
    </main>
  );
}