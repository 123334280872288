import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useFirebasePrograms } from '../../../context/FirebaseProgramDataContext';
import { useFirebaseStudent } from '../../../context/FirebaseStudentContext';
import DataValueBox from '../../../components/StudentPrograms/DataValueBox';
import InfoTag from '../../../components/StudentUI/InfoTag';
import ShadowCard from '../../../components/ShadowCard';
import MailToETSLink from '../../../components/MailToETSLink';
import emailIllus from '../../../assets/student/images/program-profile-email.svg';
import { currencyFormatter } from '../../../shared/numbers';
import '../SchoolProfile/SchoolProfileStyles.scss';

export default function StudentProgramProfileAdmissionsPage() {
  const { student } = useFirebaseStudent();
  const { programId } = useParams();
  const { programs } = useFirebasePrograms();

  const data = useMemo(() => {
    if (programId && programs) return programs[programId];
    return null;
  }, [programId, programs]);

  if (data && student) return (
    <main className="school-profile-page">
      <section className="section">
        <div className="section__header">
          <div>
            <h2 className="student-text-h2">Acceptance Rate</h2>
            <InfoTag>{data.acceptanceRate}</InfoTag>
          </div>
        </div>
        <ShadowCard customClassNames="grid padded">
          <DataValueBox value={data.studentsAppliedCount} label="Applied" isCentered reverseLabel />
          <DataValueBox value={data.studentsAcceptedCount} label="Accepted" isCentered reverseLabel />
          <DataValueBox value={data.studentsEnrolledCount} label="Enrolled" isCentered reverseLabel />
        </ShadowCard>
      </section>

      <section className="section">
        <div className="section__header">
          <h2 className="student-text-h2">Application</h2>
        </div>
        <ShadowCard customClassNames="profile-illustration-box">
          <div>
            <div className="grid-1-2">
              <div>
                <p className="student-text-label">
                  Fee
                </p>
                <p className="student-text-h2">
                  {currencyFormatter.format(data.appFee)}
                </p>
              </div>
              <div>
                <p className="student-text-label">
                  Deadline
                </p>
                <p className="student-text-h2">
                  {data.appDeadline.map(d => (
                    <>
                      <span>{d}</span><br />
                    </>
                  ))}
                </p>
              </div>
            </div>
            <p className="link">
              <MailToETSLink
                subject={`From ${student.alias}: Waive app fee`}
                body={`I'm interested in waiving my application fee to ${data.schoolName}: ${data.programTitle}.`}
              >Ask how you can get your application fee waived</MailToETSLink>
            </p>

            <p className="student-text-label">Requirements</p>
            <div className="student-text-p">
              <ul>
                {data.appRequirements.map(r => <li key={r}>{r}</li>)}
              </ul>
            </div>
          </div>

          <img src={emailIllus} alt="" />
        </ShadowCard>
      </section>
    </main>
  );

  return null;
}