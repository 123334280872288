import { Controller } from 'react-hook-form';
import Select from 'react-select';
import PropTypes from 'prop-types';

export default function ControlledSingleSelect({
  control,
  defaultValue,
  name,
  options,
  rules,
  disabled,
  customClassNames,
  placeholder
}) {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      rules={rules}
      disabled={disabled}
      render={({ field: { value, ref, onChange, ...fieldProps }, fieldState: { invalid }}) => (
        <Select
          {...fieldProps}
          inputRef={ref}
          options={options}
          value={options?.find(o => o.value === value)}
          onChange={val => onChange(val.value)}
          className={`${customClassNames} ${invalid ? 'error' : ''}`}
          classNamePrefix="react-select"
          placeholder={placeholder}
          isDisabled={disabled}
        />
      )}
    />
  )
}

ControlledSingleSelect.propTypes = {
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  rules: PropTypes.object,
  disabled: PropTypes.bool,
  customClassNames: PropTypes.string,
  placeholder: PropTypes.string,
}