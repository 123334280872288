import { useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { useFirebasePrograms } from '../../context/FirebaseProgramDataContext';
import carat from '../../assets/student/images/down-carat-black.svg';
import profile from '../../assets/inst/images/profile.svg';
import smallProfileGray from '../../assets/inst/images/profile-gray-small.svg';
import smallProfilePeach from '../../assets/inst/images/profile-peach-small.svg';
import close from '../../assets/inst/images/close.svg';
import send from '../../assets/inst/images/send-gray.svg';
import './MessagingPopup.scss';

const messages = [
  {
    sender: 'Kumar Kleinfeld',
    message: 'Could you please tell me more about the types of research you do in the Biomedical Engineering master\'s program?',
    date: 'Jan 26',
    count: 1,
  }
];

export default function MessagingPopup() {
  const { activeProgram } = useFirebasePrograms();
  const [showMessage, setShowMessage] = useState(false);

  return (
    <>
      {showMessage ? (
        <div className="inst-messaging-popup inst-messaging-popup--message">
          <div className="message-box">
            <div className="message-box__heading">
              <span>
                <img src={profile} alt="" />
                {messages[0].sender} <span className="count">1</span>
              </span>
              <button onClick={() => setShowMessage(false)}>
                <img src={close} alt="Exit chat" />
              </button>
            </div>
            <div className="message-box__content">
              <div className="date">
                Today, {new Date().toLocaleDateString()}
              </div>
              <div className="messages">
                <div className="message message--left">
                  <div className="img">
                    <img src={smallProfileGray} alt="" width={24} />
                  </div>
                  <div>
                    <div className="name">
                      {messages[0].sender}
                    </div>
                    <div className="body">
                      {messages[0].message}
                    </div>
                  </div>
                </div>
                <div className="message message--right">
                  <div>
                    <div className="name">
                      {activeProgram?.schoolName}
                    </div>
                    <div className="body">
                      Hello! Yes, I would be happy to discuss the research options for our Master&apos;s in Biomedical Engineering with you.
                    </div>
                  </div>
                  <div className="img">
                    <img src={smallProfilePeach} alt="" width={24} />
                  </div>
                </div>
              </div>
              <div className="input">
                <input type="text" placeholder="Write here" disabled />
                <img src={send} alt="Send (coming soon)" />
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="inst-messaging-popup">
        <Accordion allowZeroExpanded>
          <AccordionItem uuid="messages" activeClassName="accordion__item--expanded">
            <AccordionItemHeading>
              <AccordionItemButton>
                <span>
                  Messaging <span className="count">1</span>
                </span>
                <img src={carat} alt="" />
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              {messages.map(m => (
                <button className="message" key={m.sender} onClick={() => setShowMessage(true)}>
                  <div>
                    <img src={profile} alt="" />
                  </div>
                  <div>
                    <p className="inst-text-h4">{m.sender}</p>
                    <p>{m.message}</p>
                  </div>
                  <div className="col">
                    <span className="gray">{m.date}</span>
                    {m.count ? <span className="count">{m.count}</span> : null}
                  </div>
                </button>
              ))}
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </>
  );
}