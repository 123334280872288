import PropTypes from 'prop-types';
import { ResponsivePie } from '@nivo/pie';
import './DemoPieChart.scss';

export default function DemoPieChart({ data, size, inst }) {
  return (
    <div className="demo-pie-chart-wrapper">
      <div style={{ height: size, width: size }} className="pie-chart">
        <ResponsivePie
          data={data}
          colors={{ datum: 'data.color' }}
          innerRadius={0}
          borderWidth={0}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          isInteractive={false}
        />
      </div>
      <div className={`legend ${inst ? 'legend--inst' : ''}`}>
        {data.map(i => (
          <div key={i.id} className="legend__item">
            <div className="legend__color" style={{ backgroundColor: i.color }}>&nbsp;</div>
            <div className="legend__label">
              {i.label}
              <br />
              {i.value}%
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

DemoPieChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.number,
    color: PropTypes.string,
  })),
  size: PropTypes.number,
  inst: PropTypes.bool,
}