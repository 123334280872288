export const colors = {
  lightGreen: '#E0ECC7',
  instLightGreen: '#E2EAD2',
  green: '#0C642C',
  darkGreen: '#0A260D',
  instDarkGreen: '#0B2F0F',
  blue: '#1718AF',
  lightBlue: '#D1D9EF',
  lime: '#CEE741',
  lightLime: '#EBF5B3',
  mediumPeach: '#FFECE0',
  peach: '#FBD0B5',
  darkPink: '#6C162B',
}