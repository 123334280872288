import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import AppCard from '../../../components/InstitutionApplications/AppCard';
import FunnelChart from '../../../components/InstitutionApplications/FunnelChart';
import SearchInput from '../../../components/InstitutionStudentSearch/SearchInput';
import Filters from '../../../components/InstitutionStudentSearch/Filters';
import { useFirebaseSchool } from '../../../context/FirebaseSchoolContext';
import './ApplicationsPage.scss';

export default function InstitutionApplicationsPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { applicationsList } = useFirebaseSchool();

  const filteredApps = useMemo(() => {
    const activeStatus = searchParams.get('status');

    if (activeStatus === 'received') {
      return applicationsList.filter(app => app.status === 'submitted' || app.status === 'reviewed');
    } else if (activeStatus) {
      return applicationsList.filter(app => app.status === activeStatus);
    } else {
      return applicationsList;
    }
  }, [applicationsList, searchParams]);

  const handleChartClick = (part) => {
    setSearchParams({ status: part?.data?.id });
  }

  return (
    <main className="inst-applications">
      <FunnelChart
        handleChartClick={handleChartClick}
        data={[
          {
            id: 'in-progress',
            value: applicationsList?.filter(app => app.status === 'in-progress')?.length || 0,
            label: 'Application Started',
          },
          {
            id: 'received',
            value: applicationsList?.filter(app => app.status === 'submitted' || app.status === 'reviewed')?.length || 0,
            label: 'Received',
          },
          {
            id: 'reviewed',
            value: applicationsList?.filter(app => app.status === 'reviewed')?.length || 0,
            label: 'Reviewed',
          },
          {
            id: 'submitted',
            value: applicationsList?.filter(app => app.status === 'submitted')?.length || 0,
            label: 'Pending Review',
          },
          {
            id: 'responded',
            value: 0,
            label: 'Responses Sent',
          }
        ]}
      />

      <div className="search">
        <SearchInput placeholder="Coming soon" />
        <Filters />
      </div>
      <div className="grid">
        {filteredApps.map(app => (
          <AppCard key={app.studentId} name={app.alias} status={app.status} studentId={app.studentId} />
        ))}
      </div>
    </main>
  )
}