import checkIcon from '../../../assets/inst/images/check.svg';
import xIcon from '../../../assets/inst/images/chart-x.svg';
import './PricingModels.scss';

export default function InstitutionPricingLeadsPage() {
  return (
    <main className="inst-pricing-models">
      <div className="intro">
        <h2 className="inst-text-h2">Pricing Model 3: Leads-based Model</h2>
        <p className="inst-text-p gray">We offer 4 different pricing tiers to meet your need.</p>
      </div>

      <div className="leads-grid">
        <div className="heading"></div>
        <div className="heading gray-col">Free</div>
        <div className="heading">Basic</div>
        <div className="heading green">Standard</div>
        <div className="heading">Premium</div>

        <div className="inst-text-label">Yearly Subscription Fee</div>
        <div className="gray-col flex-center inst-text-h1">FREE</div>
        <div className="flex-center inst-text-h1">$25,000</div>
        <div className="flex-center green inst-text-h1">$30,000</div>
        <div className="flex-center inst-text-h1">$35,000</div>
        
        <div className="inst-text-p space col-1">Qualified Leads (US, International, and Underrepresented minorities)</div>
        <div className="flex-center gray-col space"><img src={xIcon} alt="No" /></div>
        <div className="flex-center space">
          <p>
            <span className="inst-text-h4">1,000</span><br />
            Recommendation/year
          </p>
        </div>
        <div className="flex-center green space">
          <p>
            <span className="inst-text-h4">1,500</span><br />
            Recommendation/year
          </p>
        </div>
        <div className="flex-center space">
          <p>
            <span className="inst-text-h4">1,800</span><br />
            Recommendation/year
          </p>
        </div>

        <div className="inst-text-h4">Features</div>
        <div className="gray-col"></div>
        <div></div>
        <div className="green"></div>
        <div></div>

        <div className="col-1">
          <p>- Prospects Favoriting</p>
        </div>
        <div className="flex-center gray-col"><img src={xIcon} alt="No" /></div>
        <div className="flex-center">
          <p>
            <span className="inst-text-h4">1,000</span><br />
            Recommendation/year
          </p>
        </div>
        <div className="flex-center green">
          <p>
            <span className="inst-text-h4">1,500</span><br />
            Recommendation/year
          </p>
        </div>
        <div className="flex-center">
          <p>
            <span className="inst-text-h4">1,800</span><br />
            Recommendation/year
          </p>
        </div>

        <div className="col-1">
          <p>- Editable Institution Profile <span className="inst-text-p-small gray">(Text & Photo)</span></p>
        </div>
        <div className="flex-center gray-col"><img src={checkIcon} alt="Yes" /></div>
        <div className="flex-center"><img src={checkIcon} alt="Yes" /></div>
        <div className="flex-center green"><img src={checkIcon} alt="Yes" /></div>
        <div className="flex-center"><img src={checkIcon} alt="Yes" /></div>

        <div className="col-1">
          <p>- Editable Institution Profile <span className="inst-text-p-small gray">(Video)</span></p>
        </div>
        <div className="flex-center gray-col"><img src={xIcon} alt="No" /></div>
        <div className="flex-center"><img src={xIcon} alt="No" /></div>
        <div className="flex-center green"><img src={xIcon} alt="No" /></div>
        <div className="flex-center"><img src={checkIcon} alt="Yes" /></div>

        <div className="col-1">
          <p>- Personalized Emails to Prospects</p>
        </div>
        <div className="flex-center gray-col"><img src={xIcon} alt="No" /></div>
        <div className="flex-center"><img src={checkIcon} alt="Yes" /></div>
        <div className="flex-center green"><img src={checkIcon} alt="Yes" /></div>
        <div className="flex-center"><img src={checkIcon} alt="Yes" /></div>

        <div className="col-1">
          <p>- Event Hosting</p>
        </div>
        <div className="flex-center gray-col"><img src={xIcon} alt="No" /></div>
        <div className="flex-center"><img src={xIcon} alt="No" /></div>
        <div className="flex-center green"><img src={checkIcon} alt="Yes" /></div>
        <div className="flex-center"><img src={checkIcon} alt="Yes" /></div>

        <div className="col-1">
          <p>- Live Virtual Tours</p>
        </div>
        <div className="flex-center gray-col"><img src={xIcon} alt="No" /></div>
        <div className="flex-center"><img src={xIcon} alt="No" /></div>
        <div className="flex-center green"><img src={checkIcon} alt="Yes" /></div>
        <div className="flex-center"><img src={checkIcon} alt="Yes" /></div>

        <div className="col-1">
          <p>- Prospective Student Trends</p>
        </div>
        <div className="flex-center gray-col"><img src={xIcon} alt="No" /></div>
        <div className="flex-center"><img src={xIcon} alt="No" /></div>
        <div className="flex-center green"><img src={checkIcon} alt="Yes" /></div>
        <div className="flex-center"><img src={checkIcon} alt="Yes" /></div>

        <div className="col-1">
          <p>- Direct Messages to Prospects</p>
        </div>
        <div className="flex-center gray-col"><img src={xIcon} alt="No" /></div>
        <div className="flex-center"><img src={xIcon} alt="No" /></div>
        <div className="flex-center green"><img src={xIcon} alt="No" /></div>
        <div className="flex-center"><img src={checkIcon} alt="Yes" /></div>

        <div className="col-1 last">
          <p>- Web Analytics and Insights</p>
        </div>
        <div className="flex-center gray-col last"><img src={xIcon} alt="No" /></div>
        <div className="flex-center last"><img src={xIcon} alt="No" /></div>
        <div className="flex-center green last"><img src={xIcon} alt="No" /></div>
        <div className="flex-center last"><img src={checkIcon} alt="Yes" /></div>

      </div>
    </main>
  );
}