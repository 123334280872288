import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useFirebasePrograms } from '../../../context/FirebaseProgramDataContext';
import EditButton from '../../../components/InstitutionProfile/EditButton';
import DemoPieChart from '../../../components/StudentPrograms/DemoPieChart';
import DataValueBox from '../../../components/InstitutionProfile/DataValueBox';
import WebLink from '../../../components/InstitutionProfile/WebLink';
import ShadowCard from '../../../components/ShadowCard';
import { colors } from '../../../shared/colors';
import research from '../../../assets/inst/images/research.svg';

export default function InstitutionProgramProfileFacultyPage() {
  const { programId } = useParams();
  const { programs } = useFirebasePrograms();

  const data = useMemo(() => {
    if (programs && programId) return programs[programId];
    return null;
  }, [programs, programId]);

  if (data) return (
    <main className="school-profile-page">
      <div className="flex">
        <p className="inst-text-p">For more information about faculty&nbsp;&nbsp;&nbsp;</p>
        <WebLink url={data.website} />
      </div>

      <section className="section">
        <div className="grid">
          <div className="flex-end">
            <EditButton />
          </div>
          <div className="flex-end">
            <EditButton />
          </div>
        </div>
        <div className="grid">
          <ShadowCard customClassNames="padded">
            <DemoPieChart
              data={[
                { id: 'Part Time', label: 'Part Time', value: data.ptFacultyPercent, color: colors.lime },
                { id: 'Full Time', label: 'Full Time', value: data.ftFacultyPercent, color: colors.green },
                { id: 'Tenured', label: 'Tenured', value: data.tenuredFacultyPercent, color: colors.darkGreen }
              ]}
              size={200}
              inst
            />
          </ShadowCard>
          <ShadowCard customClassNames="grid padded">
            <DataValueBox value={`${data.maleFacultyPercent}%`} label="Male" isCentered />
            <DataValueBox value={`${data.femaleFacultyPercent}%`} label="Female" isCentered />
            <DataValueBox value={`${data.otherGenderFacultyPercent}%`} label="Other" isCentered />
          </ShadowCard>
        </div>
      </section>

      <section className="section">
        <div className="section__header">
          <h2 className="inst-text-h2">Research Areas</h2>
          <EditButton />
        </div>
        <ShadowCard customClassNames="profile-illustration-box profile-illustration-box--inst">
          <div>
            {data.facultyResearch1Name ? (
              <div className="faculty-research faculty-research--inst">
                <h3>{data.facultyResearch1Name}</h3>
                <p>{data.facultyResearch1Focus}</p>
                <p>{data.facultyResearch1Details}</p>
              </div>
            ) : null}
            {data.facultyResearch2Name ? (
              <div className="faculty-research faculty-research--inst">
                <h3>{data.facultyResearch2Name}</h3>
                <p>{data.facultyResearch2Focus}</p>
                <p>{data.facultyResearch2Details}</p>
              </div>
            ) : null}
          </div>
          <img src={research} alt="" />
        </ShadowCard>
      </section>
    </main>
  );

  return null;
}