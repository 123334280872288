import './PurchaseListReviewOrder.scss';
import PropTypes from 'prop-types';
import ControlledSingleSelect from '../../../components/ControlledSingleSelect';
import arrow from '../../../assets/inst/images/arrow-left.svg';
import { useForm } from 'react-hook-form';
import { useState } from 'react';

export default function InstitutionPurchaseListReviewOrderPage({changeView}) {
  const { control } = useForm({});
  const [termsAgree, setTermsAgree] = useState(false);

  return (
    <div className="list-purchase-review">
      <button onClick={() => changeView('home')} className="back-button inst-text-p"><img src={arrow} alt="" /> Back</button>
      <h2 className="inst-text-h2">Review Order</h2>
      <div className="section">
        <div className="section-header inst-text-h3">
          <span className="inst-text-p left">Step 1</span>Review Order
        </div>
        <div className="section-inner">
          <div className="price-details">
            <div className="price-detail">
              <label htmlFor="registrants" className="inst-text-label">
                <span className="form-label">Registrants</span>
                <input className="inst-text-p" disabled value="89" type="text" />
              </label>
            </div>
            <div className="price-detail">
              <span className="inst-text-label form-label">Price Per Registrant</span>
              <span className="inst-text-p">$0.75</span>
            </div>
            <div className="price-detail">
              <span className="inst-text-label form-label">Total Tax</span>
              <span className="inst-text-p">$0.00</span>
            </div>
            <div className="price-detail">
              <span className="inst-text-label form-label">Total (USD)</span>
              <span className="inst-text-p">$66.75</span>
            </div>
          </div>
          <div className="promo-code">
            <label htmlFor="promo-code" className="inst-text-label form-label">Promo Code</label>
            <div className="promo-code-apply">
              <input type="text" />
              <button className="green-button inst-text-label">Apply</button>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="section-header inst-text-h3">
          <span className="inst-text-p left">Step 2</span>Customize List Format
        </div>
        <div className="section-inner">
          <div className="dropdown-wrapper">
            <div className="dropdown">
              <label htmlFor="sort" className="inst-text-label">Sorting</label><br />
              <ControlledSingleSelect
                control={control}
                defaultValue=""
                name="sort"
                options={[]}
                disabled={true}
                customClassNames="inst-text-p student-survey-select"
                placeholder="Alphabetical"
              />
            </div>
            <div className="dropdown">
              <label htmlFor="case" className="inst-text-label">Case</label><br />
              <ControlledSingleSelect
                control={control}
                defaultValue=""
                name="case"
                options={[]}
                disabled={true}
                customClassNames="inst-text-p student-survey-select"
                placeholder="Proper Case"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="section-header inst-text-h3">
          <span className="inst-text-p left">Step 3</span>Payment Options
        </div>
        <div className="section-inner">
          <div className="radio-wrapper">
            <input defaultChecked type="radio" name="payment"/>
            <span className="inst-text-p">Electronic Payment (Credit Card, PayPal, ACH)</span><br/>
            <input type="radio" name="payment"/>
            <span className="inst-text-p">Saved Credit Card</span><br/>
            <input type="radio" name="payment"/>
            <span className="inst-text-p">Purchase Order</span><br/>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="section-header inst-text-h3">
          <span className="inst-text-p left">Step 4</span>Terms and Conditions
        </div>
        <div className="section-inner">
          <div className="checkbox-wrapper">
            <input type="checkbox" onChange={(v) => setTermsAgree(v.target.checked)}/>
            <span className="inst-text-p"> I hereby agree to all terms and conditions set forth in the policy/terms and conditions, specifically those concerning the Terms and Conditions of Use, Fees, Payment and Security and Privacy Policies.</span>
          </div>
        </div>
      </div>
      <button disabled={!termsAgree} onClick={() => changeView('place-order')} className="green-button inst-text-label">Continue</button>
    </div>
  );
}

InstitutionPurchaseListReviewOrderPage.propTypes = {
  changeView: PropTypes.func
};