import InfoTag from './InfoTag';
import PopupCard from './PopupCard';
import info from '../../assets/student/images/info-dark-green.svg';
import './MostCompatible.scss';

export default function MostCompatible() {
  return (
    <div className="student-most-compatible">
      <PopupCard
        triggerButton={
          <button>
            <InfoTag>Most Compatible <img src={info} alt="" /></InfoTag>
          </button>
        }
        position="bottom right"
        on={['hover', 'focus', 'click']}
      >
        <div className="student-most-compatible-popup">
          <p>Based on your qualifications and preferences, along with this program&apos;s admissions criteria, we think you may have a higher possibility of acceptance here.</p>
        </div>
      </PopupCard>
    </div>
  );
}