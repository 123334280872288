import { Link, useNavigate } from 'react-router-dom';
import { useFirebasePrograms } from '../../../context/FirebaseProgramDataContext';
import ShadowCard from '../../../components/ShadowCard';
import logo from '../../../assets/inst/images/logo.svg';
import favesIcon from '../../../assets/inst/images/favorites.svg';
import './InstitutionSwitchProgramPage.scss';

export default function InstitutionSwitchProgramPage() {
  const navigate = useNavigate();
  const { userPrograms, setActiveProgram } = useFirebasePrograms();

  const selectProgram = (program) => {
    setActiveProgram(program);
    navigate('/institutions');
  }

  return (
    <div className="inst-switch-program">
      <nav className="inst-switch-program__nav">
        <div className="nav-content">
          <Link to="/institutions">
            <img className="logo" src={logo} alt="GradConnector" />
          </Link>
        </div>
      </nav>
      <main className="inst-switch-program__main">
        <div>
          <h1 className="inst-text-title">Switch Program</h1>
          {userPrograms?.map(program => (
            <button onClick={() => selectProgram(program)} key={program.programId}>
              <ShadowCard customClassNames="program-card">
                <p>{program.programTitle}</p>
              </ShadowCard>
            </button>
          ))}
        </div>
        <div>
          <img src={favesIcon} alt="" width={521} />
        </div>
      </main>
    </div>
  )
}