import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import './Modal.scss';
import close from '../assets/student/images/close.svg';

export default function Modal({ closeModal, title, children, isOpen, modalClassName }) {
  const modalRef = useRef();

  // auto focus first element
  useEffect(() => {
    if (modalRef && modalRef.current && isOpen) {
      const focusableEls = modalRef.current.querySelectorAll(
        'a[href], button, textarea, input, select, [tabIndex]:not([tabIndex="-1"])',
      );
      const firstEl = focusableEls[0];

      if (firstEl) {
        firstEl.focus();
      } else {
        modalRef.current.focus();
      }
    }
  }, [modalRef, isOpen]);

  // focus trap
  const handleTab = e => {
    if (modalRef && modalRef.current) {
      let focusableEls = modalRef.current.querySelectorAll(
        'a[href], button, textarea, input, select, [tabIndex]:not([tabIndex="-1"])',
      );
      const firstEl = focusableEls[0];
      const lastEl = focusableEls[focusableEls.length - 1];
  
      if (!e.shiftKey && document.activeElement === lastEl) {
        firstEl.focus();
        return e.preventDefault();
      }
  
      if (e.shiftKey && document.activeElement === firstEl) {
        lastEl.focus();
        e.preventDefault();
      }
    }
  };

  const handleKeyDown = e => {
    switch (e.key) {
    case ('Escape'):
      return closeModal();
    case ('Tab'):
      return handleTab(e);
    default:
      break;
    }
  };

  const handleClick = e => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      closeModal();
    }
  };

  useEffect(() => {
    if (isOpen) {
      // handle keyboard and click events
      document.addEventListener('keydown', handleKeyDown);
      document.addEventListener('mousedown', handleClick);

      // prevent scroll behind
      document.body.style.overflow = 'hidden';

      // hide non-modal elements
      const root = document.getElementById('root');
      if (root) root.setAttribute('aria-hidden', true);

      return () => {
        // clean up keyboard and click event handlers
        document.removeEventListener('keydown', handleKeyDown);
        document.removeEventListener('mousedown', handleClick);

        // clean up scroll-behind freeze
        document.body.style.overflow = 'visible';

        // reveal hidden non-modal elements
        if (root) root.setAttribute('aria-hidden', false);
      };
    }
  }, [isOpen]); //eslint-disable-line

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className={`modal__bg ${modalClassName}`} role="dialog" aria-modal="true" aria-label={title}>
      <div ref={modalRef} className="modal__wrapper">
        <div className="modal__body">
          {children}
        </div>
        <div className="modal__close--wrapper">
          <button onClick={closeModal} className="modal__close" aria-label="Cancel and close modal">
            <img src={close} alt="" />
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
}

Modal.defaultProps = {
  modalClassName: 'modal__body',
};

Modal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  modalClassName: PropTypes.string,
};