import { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import edit from '../../assets/inst/images/edit.svg';
import './EditButton.scss';

export default function EditButton({ children }) {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <button className={`inst-edit-button ${children ? 'inst-edit-button--custom' : ''}`} onClick={() => setShowModal(true)}>
        {children ? (
          <>
            {children}
          </>
        ) : (
          <>
            Edit
            <img src={edit} alt="" />
          </>
        )}
      </button>
      <Modal
        closeModal={() => setShowModal(false)}
        title="Coming Soon"
        isOpen={showModal}
      >
        <div className="inst-coming-soon-modal">
          <h1 className="inst-text-h1">Coming Soon</h1>
          <p className="inst-text-p">This editing feature will be coming soon.</p>
        </div>
      </Modal>
    </>
  );
}

EditButton.propTypes = {
  children: PropTypes.node,
}