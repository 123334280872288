import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { comingSoonShort } from '../../shared/appText';
import './SurveyLink.scss';

export default function SurveyLink({ to, text, comingSoon }) {

  if (comingSoon) {
    return (
      <div className="survey-link">
        <div className="survey-link__left student-text-p">
          <span className="student-text-label">Survey <span className="student-text-p-small-italic">{comingSoonShort}</span></span>
          <p className="student-text-p">{text}</p>
        </div>
        <div className="survey-link__right">
          <span className="student-text-label">⏰ 10 min</span>
        </div>
      </div>
    )
  }

  return (
    <Link to={to} className="survey-link">
      <div className="survey-link__left student-text-p">
        <span className="student-text-label">Survey</span>
        <p className="student-text-p">{text}</p>
      </div>
      <div className="survey-link__right">
        <span className="student-text-label">⏰ 10 min</span>
      </div>
    </Link>
  )
}

SurveyLink.propTypes = {
  to: PropTypes.string,
  text: PropTypes.string.isRequired,
  comingSoon: PropTypes.bool,
};