import ShadowCard from '../../../components/ShadowCard';
import SectionHeader from '../../../components/SectionHeader';
import ProgressCircle from '../../../components/ProgressCircle';
import DemoPieChart from '../../../components/StudentPrograms/DemoPieChart';
import PercentBar from '../../../components/PercentBar';
import Filters from '../../../components/InstitutionStudentSearch/Filters';
import { colors } from '../../../shared/colors';
import insightsGraph from '../../../assets/inst/images/insights-graph.png';
import insightsMap from '../../../assets/inst/images/insights-map.png';
import calendar from '../../../assets/icons/calendar-dark-pink.svg';
import carat from '../../../assets/student/images/down-carat-dark-pink.svg';
import './StudentInsightsPage.scss';
import { useState } from 'react';

export default function InstitutionStudentInsightsPage() {
  const [filters, setFilters] = useState({
    softSkillsStudents: 'All Students',
    softSkillsTime: 'Last 365 Days',
    averageScoresStudents: 'All Students',
    averageScoresTime: 'Last 365 Days',
    diversityStudents: 'All Students',
    diversityTime: 'Last 365 Days'
  });
  
  const [showDropdowns, setShowDropdowns] = useState({
    softSkillsStudents: false,
    softSkillsTime: false,
    averageScoresStudents: false,
    averageScoresTime: false,
    diversityStudents: false,
    diversityTime: false
  });
  
  const changeDropdowns = (change) => {
    setShowDropdowns({ ...Object.assign(showDropdowns, change) });
  }
  
  const changeFilterText = (change) => {
    setFilters({ ...Object.assign(filters, change) });
    
    const dropdown = Object.keys(change)[0];
    changeDropdowns({ [dropdown]: false });
  }
  
  return (
    <div className="student-insights">
      <SectionHeader title="Student Insights" />
      <div className="student-insights-main">
        <p className="inst-text-p">There are many qualities that are factored into consideration when searching for ideal student applicants. Here we provide you with an overview of some of those qualities for the students who may be of interest to you.</p>
        <div className="filter-header">
          <h2 className="inst-text-h2">Soft Skills</h2>
          <div className="filters">
            <div className="student-filter-wrapper">
              <button onClick={() => changeDropdowns({ softSkillsStudents: !showDropdowns.softSkillsStudents })} className="inst-text-label">
                {`Show: ${filters.softSkillsStudents}`}
                <img className={`carat${showDropdowns.softSkillsStudents ? ' reverse' : ''}`} src={carat} alt="" />
              </button>
              {showDropdowns.softSkillsStudents && <div className="students-dropdown">
                <ShadowCard>
                  <button onClick={() => changeFilterText({ softSkillsStudents: 'All Students' })} className={`inst-text-p${filters.softSkillsStudents === 'All Students' ? ' bold' : ''}`}>All Students</button>
                  <button onClick={() => changeFilterText({ softSkillsStudents: 'Applicants' })} className={`inst-text-p${filters.softSkillsStudents === 'Applicants' ? ' bold' : ''}`}>Applicants</button>
                  <button onClick={() => changeFilterText({ softSkillsStudents: 'Matches' })} className={`inst-text-p${filters.softSkillsStudents === 'Matches' ? ' bold' : ''}`}>Matches</button>
                  <button onClick={() => changeFilterText({ softSkillsStudents: 'Students You Favorited' })} className={`inst-text-p${filters.softSkillsStudents === 'Students You Favorited' ? ' bold' : ''}`}>Students You Favorited</button>
                  <button onClick={() => changeFilterText({ softSkillsStudents: 'Recommended Students' })} className={`inst-text-p${filters.softSkillsStudents === 'Recommended Students' ? ' bold' : ''}`}>Recommended Students</button>
                </ShadowCard>
              </div>}
            </div>
            <div className="time-filter-wrapper">
              <button onClick={() => changeDropdowns({ softSkillsTime: !showDropdowns.softSkillsTime })} className="inst-text-p"><img src={calendar} alt="" />{filters.softSkillsTime}</button>
              {showDropdowns.softSkillsTime && <div className="time-dropdown">
                <ShadowCard>
                  <button onClick={() => changeFilterText({ softSkillsTime: 'Last Month' })} className={`inst-text-p${filters.softSkillsTime === 'Last Month' ? ' bold' : ''}`}>Last Month</button>
                  <button onClick={() => changeFilterText({ softSkillsTime: 'Last 3 Months' })} className={`inst-text-p${filters.softSkillsTime === 'Last 3 Months' ? ' bold' : ''}`}>Last 3 Months</button>
                  <button onClick={() => changeFilterText({ softSkillsTime: 'Last 365 Days' })} className={`inst-text-p${filters.softSkillsTime === 'Last 365 Days' ? ' bold' : ''}`}>Last 365 Days</button>
                </ShadowCard>
              </div>}
            </div>
            <Filters />
          </div>
        </div>
        <p className="inst-text-p">Soft skills are somewhat ambiguous qualities that some schools may take note of when considering potential applicants. These are less quantitatively measured than test scores and GPA, but through assessment, can be effectively gauged.</p>
        <div className="soft-skills">
          <ShadowCard>
            <img src={insightsGraph} alt="" />
          </ShadowCard>
        </div>
        <span className="inst-text-p-small dark-pink">Graph courtesy of PSQ+, an assessment of students&apos; soft skills that institutions will find insightful when evaluating potential program candidates.</span>
        <div className="filter-header">
          <h2 className="inst-text-h2">Average Scores</h2>
          <div className="filters">
            <div className="student-filter-wrapper">
              <button onClick={() => changeDropdowns({ averageScoresStudents: !showDropdowns.averageScoresStudents })} className="inst-text-label">
                {`Show: ${filters.averageScoresStudents}`}
                <img className={`carat${showDropdowns.averageScoresStudents ? ' reverse' : ''}`} src={carat} alt="" />
              </button>
              {showDropdowns.averageScoresStudents && <div className="students-dropdown">
                <ShadowCard>
                  <button onClick={() => changeFilterText({ averageScoresStudents: 'All Students' })} className={`inst-text-p${filters.averageScoresStudents === 'All Students' ? ' bold' : ''}`}>All Students</button>
                  <button onClick={() => changeFilterText({ averageScoresStudents: 'Applicants' })} className={`inst-text-p${filters.averageScoresStudents === 'Applicants' ? ' bold' : ''}`}>Applicants</button>
                  <button onClick={() => changeFilterText({ averageScoresStudents: 'Matches' })} className={`inst-text-p${filters.averageScoresStudents === 'Matches' ? ' bold' : ''}`}>Matches</button>
                  <button onClick={() => changeFilterText({ averageScoresStudents: 'Students You Favorited' })} className={`inst-text-p${filters.averageScoresStudents === 'Students You Favorited' ? ' bold' : ''}`}>Students You Favorited</button>
                  <button onClick={() => changeFilterText({ averageScoresStudents: 'Recommended Students' })} className={`inst-text-p${filters.averageScoresStudents === 'Recommended Students' ? ' bold' : ''}`}>Recommended Students</button>
                </ShadowCard>
              </div>}
            </div>
            <div className="time-filter-wrapper">
              <button onClick={() => changeDropdowns({ averageScoresTime: !showDropdowns.averageScoresTime })} className="inst-text-p"><img src={calendar} alt="" />{filters.averageScoresTime}</button>
              {showDropdowns.averageScoresTime && <div className="time-dropdown">
                <ShadowCard>
                  <button onClick={() => changeFilterText({ averageScoresTime: 'Last Month' })} className={`inst-text-p${filters.averageScoresTime === 'Last Month' ? ' bold' : ''}`}>Last Month</button>
                  <button onClick={() => changeFilterText({ averageScoresTime: 'Last 3 Months' })} className={`inst-text-p${filters.averageScoresTime === 'Last 3 Months' ? ' bold' : ''}`}>Last 3 Months</button>
                  <button onClick={() => changeFilterText({ averageScoresTime: 'Last 365 Days' })} className={`inst-text-p${filters.averageScoresTime === 'Last 365 Days' ? ' bold' : ''}`}>Last 365 Days</button>
                </ShadowCard>
              </div>}
            </div>
            <Filters hideSearch={true} stopRedirect={true}/>
          </div>
        </div>
        <div className="average-scores">
          <ShadowCard>
            <span className="inst-text-label">GRE</span>
            <div className="progress-circles">
              <div className="progress-circle">
                <ProgressCircle
                  percentComplete={(156 / 170) * 100}
                  fgColor={colors.green}
                  bgColor={colors.lightLime}
                  size={120}
                >
                  <span className="inst-text-h4">156/170</span>
                </ProgressCircle>
                <span className="inst-text-label">Verbal Reasoning</span>
              </div>
              <div className="progress-circle">
                <ProgressCircle
                  percentComplete={(159 / 170) * 100}
                  fgColor={colors.green}
                  bgColor={colors.lightLime}
                  size={120}
                >
                  <span className="inst-text-h4">159/170</span>
                </ProgressCircle>
                <span className="inst-text-label">Quantitative Reasoning</span>
              </div>
              <div className="progress-circle">
                <ProgressCircle
                  percentComplete={(4 / 6) * 100}
                  fgColor={colors.green}
                  bgColor={colors.lightLime}
                  size={120}
                >
                  <span className="inst-text-h4">4.0/6.0</span>
                </ProgressCircle>
                <span className="inst-text-label">Analytical Writing</span>
              </div>
            </div>
          </ShadowCard>
          <ShadowCard>
            <span className="inst-text-label">GMAT</span>
            <div className="progress-circles">
              <div className="progress-circle">
                <ProgressCircle
                  percentComplete={(41 / 51) * 100}
                  fgColor={colors.green}
                  bgColor={colors.lightLime}
                  size={120}
                >
                  <span className="inst-text-h4">41/51</span>
                </ProgressCircle>
                <span className="inst-text-label">Quantitative and Verbal</span>
              </div>
              <div className="progress-circle">
                <ProgressCircle
                  percentComplete={(4 / 8) * 100}
                  fgColor={colors.green}
                  bgColor={colors.lightLime}
                  size={120}
                >
                  <span className="inst-text-h4">4/8</span>
                </ProgressCircle>
                <span className="inst-text-label">Integrated Reasoning</span>
              </div>
              <div className="progress-circle">
                <ProgressCircle
                  percentComplete={(5 / 6) * 100}
                  fgColor={colors.green}
                  bgColor={colors.lightLime}
                  size={120}
                >
                  <span className="inst-text-h4">5/6</span>
                </ProgressCircle>
                <span className="inst-text-label">Analytical Writing Assessment</span>
              </div>
            </div>
          </ShadowCard>
          <ShadowCard>
            <span className="inst-text-label">TOEFL</span>
            <div className="progress-circles margin-top">
              <div className="progress-circle">
                <ProgressCircle
                  percentComplete={(20 / 30) * 100}
                  fgColor={colors.green}
                  bgColor={colors.lightLime}
                  size={120}
                >
                  <span className="inst-text-h4">20/30</span>
                </ProgressCircle>
                <span className="inst-text-label">Listening</span>
              </div>
              <div className="progress-circle">
                <ProgressCircle
                  percentComplete={(17 / 30) * 100}
                  fgColor={colors.green}
                  bgColor={colors.lightLime}
                  size={120}
                >
                  <span className="inst-text-h4">17/30</span>
                </ProgressCircle>
                <span className="inst-text-label">Reading</span>
              </div>
              <div className="progress-circle">
                <ProgressCircle
                  percentComplete={(21 / 30) * 100}
                  fgColor={colors.green}
                  bgColor={colors.lightLime}
                  size={120}
                >
                  <span className="inst-text-h4">21/30</span>
                </ProgressCircle>
                <span className="inst-text-label">Writing</span>
              </div>
              <div className="progress-circle">
                <ProgressCircle
                  percentComplete={(24 / 30) * 100}
                  fgColor={colors.green}
                  bgColor={colors.lightLime}
                  size={120}
                >
                  <span className="inst-text-h4">24/30</span>
                </ProgressCircle>
                <span className="inst-text-label">Speaking</span>
              </div>
            </div>
          </ShadowCard>
          <ShadowCard>
            <span className="inst-text-label">GPA</span>
            <div className="progress-circles center">
              <div className="progress-circle">
                <ProgressCircle
                  percentComplete={(3.23 / 4) * 100}
                  fgColor={colors.green}
                  bgColor={colors.lightLime}
                  size={200}
                >
                  <h1 className="inst-text-h1">3.23</h1>
                  <span className="inst-text-p">of 4.0</span>
                </ProgressCircle>
              </div>
            </div>
          </ShadowCard>
        </div>
        <div className="filter-header">
          <h2 className="inst-text-h2">Diversity</h2>
          <div className="filters">
            <div className="student-filter-wrapper">
              <button onClick={() => changeDropdowns({ diversityStudents: !showDropdowns.diversityStudents })} className="inst-text-label">
                {`Show: ${filters.diversityStudents}`}
                <img className={`carat${showDropdowns.diversityStudents ? ' reverse' : ''}`} src={carat} alt="" />
              </button>
              {showDropdowns.diversityStudents && <div className="students-dropdown">
                <ShadowCard>
                  <button onClick={() => changeFilterText({ diversityStudents: 'All Students' })} className={`inst-text-p${filters.diversityStudents === 'All Students' ? ' bold' : ''}`}>All Students</button>
                  <button onClick={() => changeFilterText({ diversityStudents: 'Applicants' })} className={`inst-text-p${filters.diversityStudents === 'Applicants' ? ' bold' : ''}`}>Applicants</button>
                  <button onClick={() => changeFilterText({ diversityStudents: 'Matches' })} className={`inst-text-p${filters.diversityStudents === 'Matches' ? ' bold' : ''}`}>Matches</button>
                  <button onClick={() => changeFilterText({ diversityStudents: 'Students You Favorited' })} className={`inst-text-p${filters.diversityStudents === 'Students You Favorited' ? ' bold' : ''}`}>Students You Favorited</button>
                  <button onClick={() => changeFilterText({ diversityStudents: 'Recommended Students' })} className={`inst-text-p${filters.diversityStudents === 'Recommended Students' ? ' bold' : ''}`}>Recommended Students</button>
                </ShadowCard>
              </div>}
            </div>
            <div className="time-filter-wrapper">
              <button onClick={() => changeDropdowns({ diversityTime: !showDropdowns.diversityTime })} className="inst-text-p"><img src={calendar} alt="" />{filters.diversityTime}</button>
              {showDropdowns.diversityTime && <div className="time-dropdown">
                <ShadowCard>
                  <button onClick={() => changeFilterText({ diversityTime: 'Last Month' })} className={`inst-text-p${filters.diversityTime === 'Last Month' ? ' bold' : ''}`}>Last Month</button>
                  <button onClick={() => changeFilterText({ diversityTime: 'Last 3 Months' })} className={`inst-text-p${filters.diversityTime === 'Last 3 Months' ? ' bold' : ''}`}>Last 3 Months</button>
                  <button onClick={() => changeFilterText({ diversityTime: 'Last 365 Days' })} className={`inst-text-p${filters.diversityTime === 'Last 365 Days' ? ' bold' : ''}`}>Last 365 Days</button>
                </ShadowCard>
              </div>}
            </div>
            <Filters hideSearch={true} stopRedirect={true}/>
          </div>
        </div>
        <div className="diversity">
          <ShadowCard>
            <div className="geographic">
              <img src={insightsMap} alt="" />
              <div className="geographic-bars">
                <div className="geographic-bars-header">
                  <span className="inst-text-label">Geographic</span>
                </div>
                <div className="geographic-bar">
                  <span className="inst-text-label">United States</span>
                  <div className="geographic-bar-stats">
                    <div className="bar-wrapper">
                      <PercentBar height="8" percentComplete={(75.16 / 100) * 100} bgColor={colors.lightGreen} fgColor={colors.darkGreen} />
                    </div>
                    <span className="inst-text-label lowercase">75.16%</span>
                  </div>
                </div>
                <div className="geographic-bar">
                  <span className="inst-text-label">South Africa</span>
                  <div className="geographic-bar-stats">
                    <div className="bar-wrapper">
                      <PercentBar height="8" percentComplete={(9.11 / 100) * 100} bgColor={colors.lightGreen} fgColor={colors.darkGreen} />
                    </div>
                    <span className="inst-text-label lowercase">9.11%</span>
                  </div>
                </div>
                <div className="geographic-bar">
                  <span className="inst-text-label">Australiia</span>
                  <div className="geographic-bar-stats">
                    <div className="bar-wrapper">
                      <PercentBar height="8" percentComplete={(4.86 / 100) * 100} bgColor={colors.lightGreen} fgColor={colors.darkGreen} />
                    </div>
                    <span className="inst-text-label lowercase">4.86%</span>
                  </div>
                </div>
                <div className="geographic-bar">
                  <span className="inst-text-label">Canada</span>
                  <div className="geographic-bar-stats">
                    <div className="bar-wrapper">
                      <PercentBar height="8" percentComplete={(2.84 / 100) * 100} bgColor={colors.lightGreen} fgColor={colors.darkGreen} />
                    </div>
                    <span className="inst-text-label lowercase">2.84%</span>
                  </div>
                </div>
                <div className="geographic-bar">
                  <span className="inst-text-label">India</span>
                  <div className="geographic-bar-stats">
                    <div className="bar-wrapper">
                      <PercentBar height="8" percentComplete={(1.64 / 100) * 100} bgColor={colors.lightGreen} fgColor={colors.darkGreen} />
                    </div>
                    <span className="inst-text-label lowercase">1.64%</span>
                  </div>
                </div>
                <div className="geographic-bar">
                  <span className="inst-text-label">New Zealand</span>
                  <div className="geographic-bar-stats">
                    <div className="bar-wrapper">
                      <PercentBar height="8" percentComplete={(0.46 / 100) * 100} bgColor={colors.lightGreen} fgColor={colors.darkGreen} />
                    </div>
                    <span className="inst-text-label lowercase">0.46%</span>
                  </div>
                </div>
                <div className="geographic-bar">
                  <span className="inst-text-label">Nigeria</span>
                  <div className="geographic-bar-stats">
                    <div className="bar-wrapper">
                      <PercentBar height="8" percentComplete={(0.43 / 100) * 100} bgColor={colors.lightGreen} fgColor={colors.darkGreen} />
                    </div>
                    <span className="inst-text-label lowercase">0.43%</span>
                  </div>
                </div>
                <div className="geographic-bar">
                  <span className="inst-text-label">China</span>
                  <div className="geographic-bar-stats">
                    <div className="bar-wrapper">
                      <PercentBar height="8" percentComplete={(0.43 / 100) * 100} bgColor={colors.lightGreen} fgColor={colors.darkGreen} />
                    </div>
                    <span className="inst-text-label lowercase">0.43%</span>
                  </div>
                </div>
                <div className="geographic-bar">
                  <span className="inst-text-label">Zimbabwe</span>
                  <div className="geographic-bar-stats">
                    <div className="bar-wrapper">
                      <PercentBar height="8" percentComplete={(0.26 / 100) * 100} bgColor={colors.lightGreen} fgColor={colors.darkGreen} />
                    </div>
                    <span className="inst-text-label lowercase">0.26%</span>
                  </div>
                </div>
                <div className="geographic-bar">
                  <span className="inst-text-label">Ghana</span>
                  <div className="geographic-bar-stats">
                    <div className="bar-wrapper">
                      <PercentBar height="8" percentComplete={(0.2 / 100) * 100} bgColor={colors.lightGreen} fgColor={colors.darkGreen} />
                    </div>
                    <span className="inst-text-label lowercase">0.20%</span>
                  </div>
                </div>
                <div className="geographic-bar">
                  <span className="inst-text-label">Bangladesh</span>
                  <div className="geographic-bar-stats">
                    <div className="bar-wrapper">
                      <PercentBar height="8" percentComplete={(0.18 / 100) * 100} bgColor={colors.lightGreen} fgColor={colors.darkGreen} />
                    </div>
                    <span className="inst-text-label lowercase">0.18%</span>
                  </div>
                </div>
              </div>
            </div>
          </ShadowCard>
          <div className="bottom-shadow-cards">
            <ShadowCard>
              <div className="ethnicity">
                <div className="ethnicity-bars">
                  <div className="ethnicity-bars-header">
                    <span className="inst-text-label">Ethnicity</span>
                  </div>
                  <div className="ethnicity-bar">
                    <span className="inst-text-label wider">African American or Black</span>
                    <div className="ethnicity-bar-stats">
                      <div className="bar-wrapper">
                        <PercentBar height="8" percentComplete={(20 / 100) * 100} bgColor={colors.lightGreen} fgColor={colors.darkGreen} />
                      </div>
                      <span className="inst-text-label lowercase">20%</span>
                    </div>
                  </div>
                  <div className="ethnicity-bar">
                    <span className="inst-text-label wider">Alaskan Native</span>
                    <div className="ethnicity-bar-stats">
                      <div className="bar-wrapper">
                        <PercentBar height="8" percentComplete={(20 / 100) * 100} bgColor={colors.lightGreen} fgColor={colors.darkGreen} />
                      </div>
                      <span className="inst-text-label lowercase">20%</span>
                    </div>
                  </div>
                  <div className="ethnicity-bar">
                    <span className="inst-text-label wider">American Indian</span>
                    <div className="ethnicity-bar-stats">
                      <div className="bar-wrapper">
                        <PercentBar height="8" percentComplete={(20 / 100) * 100} bgColor={colors.lightGreen} fgColor={colors.darkGreen} />
                      </div>
                      <span className="inst-text-label lowercase">20%</span>
                    </div>
                  </div>
                  <div className="ethnicity-bar">
                    <span className="inst-text-label wider">Asian</span>
                    <div className="ethnicity-bar-stats">
                      <div className="bar-wrapper">
                        <PercentBar height="8" percentComplete={(20 / 100) * 100} bgColor={colors.lightGreen} fgColor={colors.darkGreen} />
                      </div>
                      <span className="inst-text-label lowercase">20%</span>
                    </div>
                  </div>
                  <div className="ethnicity-bar">
                    <span className="inst-text-label wider">Caucasian or White</span>
                    <div className="ethnicity-bar-stats">
                      <div className="bar-wrapper">
                        <PercentBar height="8" percentComplete={(20 / 100) * 100} bgColor={colors.lightGreen} fgColor={colors.darkGreen} />
                      </div>
                      <span className="inst-text-label lowercase">20%</span>
                    </div>
                  </div>
                  <div className="ethnicity-bar">
                    <span className="inst-text-label wider">Native Hawaiian</span>
                    <div className="ethnicity-bar-stats">
                      <div className="bar-wrapper">
                        <PercentBar height="8" percentComplete={(20 / 100) * 100} bgColor={colors.lightGreen} fgColor={colors.darkGreen} />
                      </div>
                      <span className="inst-text-label lowercase">20%</span>
                    </div>
                  </div>
                  <div className="ethnicity-bar">
                    <span className="inst-text-label wider">Other</span>
                    <div className="ethnicity-bar-stats">
                      <div className="bar-wrapper">
                        <PercentBar height="8" percentComplete={(20 / 100) * 100} bgColor={colors.lightGreen} fgColor={colors.darkGreen} />
                      </div>
                      <span className="inst-text-label lowercase">20%</span>
                    </div>
                  </div>
                  <div className="ethnicity-bar">
                    <span className="inst-text-label wider">Other Pacific Islander</span>
                    <div className="ethnicity-bar-stats">
                      <div className="bar-wrapper">
                        <PercentBar height="8" percentComplete={(20 / 100) * 100} bgColor={colors.lightGreen} fgColor={colors.darkGreen} />
                      </div>
                      <span className="inst-text-label lowercase">20%</span>
                    </div>
                  </div>
                  <div className="ethnicity-bar">
                    <span className="inst-text-label wider">Prefer Not to Respond</span>
                    <div className="ethnicity-bar-stats">
                      <div className="bar-wrapper">
                        <PercentBar height="8" percentComplete={(20 / 100) * 100} bgColor={colors.lightGreen} fgColor={colors.darkGreen} />
                      </div>
                      <span className="inst-text-label lowercase">20%</span>
                    </div>
                  </div>
                  <span className="inst-text-p disclaimer">We care about the privacy of our students, and this field will be hidden if you have less than 1000 students.</span>
                </div>
              </div>
            </ShadowCard>
            <ShadowCard>
              <span className="inst-text-label margin-bottom">Gender</span>
              <div className="chart-wrapper">
                <DemoPieChart
                  data={[
                    { id: 'Male', value: 25.4, label: 'Male', color: colors.darkGreen },
                    { id: 'Female', value: 60.1, label: 'Female', color: colors.green },
                    { id: 'Other', value: 14.5, label: 'Other', color: colors.lime }
                  ]}
                  size={200}
                />
              </div>
            </ShadowCard>
            <ShadowCard>
              <span className="inst-text-label margin-bottom">First Generation Students</span>
              <div className="chart-wrapper">
                <DemoPieChart
                  data={[
                    { id: 'First Generation', value: 25.4, label: 'First Generation', color: colors.darkGreen },
                    { id: 'Not First Generation', value: 60.1, label: 'Not First Generation', color: colors.green },
                    { id: 'Not Reported', value: 14.5, label: 'Not Reported', color: colors.lime }
                  ]}
                  size={200}
                />
              </div>
            </ShadowCard>
            <ShadowCard>
              <span className="inst-text-label margin-bottom">Matched Applicants Seek Financial Aid</span>
              <div className="chart-wrapper">
                <ProgressCircle
                  percentComplete={(27 / 42) * 100}
                  fgColor={colors.green}
                  bgColor={colors.lightLime}
                  size={200}
                >
                  <h1 className="inst-text-h1">27</h1>
                  <span className="inst-text-p">of 42</span>
                </ProgressCircle>
              </div>
            </ShadowCard>
          </div>
        </div>
      </div>
    </div>
  );
}
