import { useState, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFirebaseStudent } from '../../../context/FirebaseStudentContext';
import StudentOnboardingSchoolPrioritiesForm from '../../../forms/StudentOnboarding/StudentOnboardingSchoolPrioritiesForm';
import useKeypress from '../../../hooks/useKeypress';
import DashboardLink from '../../../components/StudentSurveys/DashboardLink';
import ProgramRecsCarousel from '../../../components/StudentPrograms/ProgramRecsCarousel';
import FavoritesLink from '../../../components/StudentFavorites/FavoritesLink';
import './SurveyPages.scss';

export const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1000 },
    items: 2,
  }
}

const prevSurveyPath = '/students/surveys/schools';
const nextSurveyPath = '/students/surveys/schools/preferences';

export default function StudentSchoolsSurveyPrioritiesPage() {
  const navigate = useNavigate();
  const [showSuccess, setShowSuccess] = useState(false);
  const { student, addOrUpdateProfile } = useFirebaseStudent();

  const studentSchoolPrefs = useMemo(() => {
    if (student?.schoolPreferences) {
      return student.schoolPreferences;
    } else {
      return null;
    }
  }, [student?.schoolPreferences]);

  const prevScreen = () => {
    if (showSuccess) setShowSuccess(false);
    else navigate(prevSurveyPath);
  }

  const nextScreen = () => {
    if (showSuccess) navigate(nextSurveyPath);
    else setShowSuccess(true);
  }

  useKeypress('ArrowRight', nextScreen);
  useKeypress('ArrowLeft', prevScreen);  

  const savePreferenceData = (data) => {
    addOrUpdateProfile({ schoolPreferences: data }, 'Your preferences have been saved!');
  }

  return (
    <main className="student-onboarding-survey">
      <DashboardLink />

      {!showSuccess ? (
        <>
          <p className="student-text-p-large">Let&apos;s get to know your school preferences better.</p>
          <StudentOnboardingSchoolPrioritiesForm
            defaultValues={studentSchoolPrefs}
            saveData={(data) => {
              savePreferenceData(data);
              setShowSuccess(true);
            }}
            goBack={prevScreen}
          />
        </>
      ) : null}

      {showSuccess ? (
        <>
          <div className="centered">
            <div className="carousel-container">
              <div className="carousel-header">
                <p className="student-text-h2">Recommendations</p>
                <FavoritesLink />
              </div>
              <ProgramRecsCarousel customResponsive={responsive} />
            </div>
            <p className="student-text-h2">We are saving your preferences to give you more personal recommendations.</p>
            <p className="student-text-p-large">Would you like to find better matches?</p>
            <Link to={nextSurveyPath} className="btn">Yes, Continue</Link>
            <Link to="/students/explore" className="student-cta-link">Come Back To This Later, And Show More Recommendations</Link>
          </div>
        </>
      ) : null}
    </main>
  );
}