import PropTypes from 'prop-types';
import './AppStatus.scss';

const statusText = {
  'in-progress': 'Application Started',
  'submitted': 'Pending Review',
  'reviewed': 'Reviewed',
};

export default function AppStatus({ status }) {
  return (
    <span className={`inst-app-status ${status}`}>{statusText[status]}</span>
  );
}

AppStatus.propTypes = {
  status: PropTypes.oneOf(['in-progress', 'submitted', 'reviewed']).isRequired,
}