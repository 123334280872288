import { Controller } from 'react-hook-form';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import searchIcon from '../../assets/icons/search.svg';

const SearchSelect = ({ children, ...props }) => (
  <components.Control {...props}>
    <img src={searchIcon} alt="" className="icon" /> {children}
  </components.Control>
);

SearchSelect.propTypes = {
  children: PropTypes.node,
}

export default function ControlledProgramMultiSelect({ control, defaultValue, name, options, rules, customClassNames, placeholder }) {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      rules={rules}
      render={({ field: { onChange, value, ref, onBlur }, fieldState: { invalid }}) => (
        <Select
          inputRef={ref}
          isMulti
          options={options}
          value={options.filter(o => value.includes(o.value))}
          onChange={val => onChange(val.map(o => o.value))}
          onBlur={onBlur}
          className={`${customClassNames} ${invalid ? 'error' : ''}`}
          classNamePrefix="react-select"
          placeholder={placeholder}
          isSearchable
          components={{ Control: SearchSelect }}
          formatOptionLabel={(data) => (
            <>
              <span>{data.schoolName}</span><br />
              <span>{data.programTitle}</span>
            </>
          )}
        />
      )}
    />
  )
}

ControlledProgramMultiSelect.propTypes = {
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    programTitle: PropTypes.string,
    schoolName: PropTypes.string,
  })),
  rules: PropTypes.object,
  customClassNames: PropTypes.string,
  placeholder: PropTypes.string,
}