import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useFirebaseAuth } from '../context/FirebaseAuthContext';
import StudentNotificationsDrawer from './StudentTodos/StudentNotificationsDrawer';
import InstitutionNotificationsDrawer from './InstitutionDashboard/InstitutionNotificationsDrawer';
import profileIcon from '../assets/student/images/profile-green.svg';
import bellIcon from '../assets/icons/bell.svg';
import refreshIcon from '../assets/inst/images/refresh.svg';
import './SectionHeader.scss';

export default function SectionHeader({ title }) {
  const { userRole } = useFirebaseAuth();

  return (
    <header className="student-section-header">
      <h1 className={userRole === 'student' ? 'student-text-h1' : 'inst-text-h1'}>{title}</h1>
      <div>
        <ul className="student-text-p-small">
          {userRole === 'student' ? (
            <>
              <li>
                <Link to="/students/profile" className="link-item">
                  <img src={profileIcon} alt="" />
                  <span>Profile</span>
                </Link>
              </li>
              <li>
                <StudentNotificationsDrawer customClassNames="link-item">
                  <img src={bellIcon} alt="" />
                  <span>Notifications</span>
                </StudentNotificationsDrawer>
              </li>
            </>
          ) : (
            <>
              <li>
                <Link to="/institutions/switchprogram" className="link-item">
                  <img src={refreshIcon} alt="" />
                  <span>Switch Program</span>
                </Link>
              </li>
              <li>
                <InstitutionNotificationsDrawer customClassNames="link-item">
                  <img src={bellIcon} alt="" />
                  <span>Notifications</span>
                </InstitutionNotificationsDrawer>
              </li>
            </>
          )}
        </ul>
      </div>
    </header>
  );
}

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
}