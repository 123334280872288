import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ShadowCard from '../ShadowCard';
import './ShortcutCards.scss';

export default function ShortcutCards({ cards }) {
  const smallCards = cards?.length > 2;

  return (
    <div className="shortcut-cards">
      {cards.map(c => (
        <Link to={c.linkTo} key={c.linkTo}>
          <ShadowCard customClassNames="shortcut-card">
            <div className="shortcut-card__image">
              <img src={c.img} alt="" />
            </div>
            <div className="shortcut-card__content">
              <h3 className={smallCards ? 'student-text-h2' : 'student-text-h1'}>{c.title}</h3>
              <p className={smallCards ? 'student-text-p-small' : 'student-text-p'}>{c.text}</p>
            </div>
          </ShadowCard>
        </Link>
      ))}
    </div>
  )

}

ShortcutCards.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
    linkTo: PropTypes.string.isRequired,
  })),
}