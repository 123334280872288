import PropTypes from 'prop-types';
import './DataValueBox.scss';

export default function DataValueBox({ label, value, valueSize, isCentered, reverseLabel }) {
  return (
    <div className={`inst-data-value-box ${isCentered ? 'centered' : ''}`}>
      {!reverseLabel ? <p className="inst-text-label">{label}</p> : null}
      {typeof value === 'string' || typeof value === 'number' ? (
        <p className={valueSize === 'large' ? 'inst-text-title' : 'inst-text-h2'}>{value}</p>
      ) : (
        <>
          {value.map(v => (
            <span
              key={v}
              className={valueSize === 'large' ? 'inst-text-title' : 'inst-text-h2'}
            >
              {v}
            </span>
          ))}
        </>
      )}
      {reverseLabel ? <p className="inst-text-h2">{label}</p> : null}
    </div>
  );
}

DataValueBox.defaultProps = {
  valueSize: 'large',
  reverseLabel: false,
}

DataValueBox.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf([PropTypes.number, PropTypes.string])
  ]).isRequired,
  valueSize: PropTypes.oneOf(['small', 'large']),
  isCentered: PropTypes.bool,
  reverseLabel: PropTypes.bool,
}