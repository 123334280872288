import PropTypes from 'prop-types';

export default function MailToETSLink({ subject, body, customClassNames, children }) {
  const url = `mailto:${process.env.REACT_APP_ETS_EMAIL}`;
  let params = [];

  if (subject) {
    params.push(`subject=${subject}`);
  }

  if (body) {
    params.push(`body=${body}`);
  }

  return (
    <a
      href={encodeURI(params.length ? url + '?' + params.join('&') : url)}
      target="_blank"
      rel="noreferrer"
      className={customClassNames}
    >
      {children}
    </a>
  );
}

MailToETSLink.propTypes = {
  subject: PropTypes.string,
  body: PropTypes.string,
  customClassNames: PropTypes.string,
  children: PropTypes.node.isRequired,
}