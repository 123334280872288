import ShadowCard from '../../../components/ShadowCard';
import SectionHeader from '../../../components/SectionHeader';
import ProgressCircle from '../../../components/ProgressCircle';
import PercentBar from '../../../components/PercentBar';
import BackLink from '../../../components/BackLink';
import { colors } from '../../../shared/colors';
import trendsImage from '../../../assets/inst/images/trends-splash.png';
import './TipsAndBestPracticesTrends.scss';

export default function InstitutionTipsAndBestPracticesTrendsPage() {
  return (
    <div className="tips-and-best-practices-trends">
      <SectionHeader title="Tips and Best Practices" />
      <BackLink to="/institutions/tips" label="Back" inst />
      <img className="banner-image" src={trendsImage} alt="" />
      <div className="article-content">
        <h1 className="inst-text-h1">Trends in Student Profiles</h1>
        <span className="inst-text-label">March 10, 2022</span>
        <p className="inst-text-p">Overall student pool data (basic information on what kind of students we have):</p>
        <div className="info-banner">
          <span className="inst-text-label">Total Number of Students</span>
          <h1 className="inst-text-title">125.5k</h1>
        </div>
        <div className="student-averages">
          <ShadowCard>
            <span className="inst-text-label">Average GRE Score</span>
            <div className="progress-circles">
              <div className="progress-circle">
                <ProgressCircle
                  percentComplete={(145 / 170) * 100}
                  fgColor={colors.green}
                  bgColor={colors.lightLime}
                  size={170}
                >
                  <span className="inst-text-h2">145/170</span>
                </ProgressCircle>
                <span className="inst-text-label">Verbal Reasoning</span>
              </div>
              <div className="progress-circle">
                <ProgressCircle
                  percentComplete={(152 / 170) * 100}
                  fgColor={colors.green}
                  bgColor={colors.lightLime}
                  size={170}
                >
                  <span className="inst-text-h2">152/170</span>
                </ProgressCircle>
                <span className="inst-text-label">Quantitative Reasoning</span>
              </div>
            </div>
          </ShadowCard>
          <ShadowCard>
            <span className="inst-text-label">Average GPA</span>
            <div className="progress-circles">
              <div className="progress-circle">
                <ProgressCircle
                  percentComplete={(3 / 4) * 100}
                  fgColor={colors.green}
                  bgColor={colors.lightLime}
                  size={220}
                >
                  <h1 className="inst-text-h1">3.0</h1>
                  <span className="inst-text-p">of 4.0</span>
                </ProgressCircle>
              </div>
            </div>
          </ShadowCard>
        </div>
        <div className="academic-interests">
          <ShadowCard>
            <div className="academic-interests-header">
              <span className="inst-text-label">Top Academic Interest</span>
            </div>
            <div className="academic-interest">
              <span className="inst-text-label">Psychology</span>
              <div className="academic-interest-stats">
                <div className="bar-wrapper">
                  <PercentBar height="8" percentComplete={(3.97 / 3.97) * 100} bgColor={colors.lightGreen} fgColor={colors.darkGreen} />
                </div>
                <span className="inst-text-label lowercase">3.97%</span>
              </div>
            </div>
            <div className="academic-interest">
              <span className="inst-text-label">Business</span>
              <div className="academic-interest-stats">
                <div className="bar-wrapper">
                  <PercentBar height="8" percentComplete={(3.96 / 3.97) * 100} bgColor={colors.lightGreen} fgColor={colors.darkGreen} />
                </div>
                <span className="inst-text-label lowercase">3.96%</span>
              </div>
            </div>
            <div className="academic-interest">
              <span className="inst-text-label">Biological and Biomedical Services</span>
              <div className="academic-interest-stats">
                <div className="bar-wrapper">
                  <PercentBar height="8" percentComplete={(3.72 / 3.97) * 100} bgColor={colors.lightGreen} fgColor={colors.darkGreen} />
                </div>
                <span className="inst-text-label lowercase">3.72%</span>
              </div>
            </div>
            <div className="academic-interest">
              <span className="inst-text-label">Health Professions and Related Clinical Sciences</span>
              <div className="academic-interest-stats">
                <div className="bar-wrapper">
                  <PercentBar height="8" percentComplete={(3.61 / 3.97) * 100} bgColor={colors.lightGreen} fgColor={colors.darkGreen} />
                </div>
                <span className="inst-text-label lowercase">3.61%</span>
              </div>
            </div>
            <div className="academic-interest">
              <span className="inst-text-label">Education</span>
              <div className="academic-interest-stats">
                <div className="bar-wrapper">
                  <PercentBar height="8" percentComplete={(2.74 / 3.97) * 100} bgColor={colors.lightGreen} fgColor={colors.darkGreen} />
                </div>
                <span className="inst-text-label lowercase">2.74%</span>
              </div>
            </div>
            <div className="academic-interest">
              <span className="inst-text-label">Social Science</span>
              <div className="academic-interest-stats">
                <div className="bar-wrapper">
                  <PercentBar height="8" percentComplete={(2.46 / 3.97) * 100} bgColor={colors.lightGreen} fgColor={colors.darkGreen} />
                </div>
                <span className="inst-text-label lowercase">2.46%</span>
              </div>
            </div>
            <div className="academic-interest">
              <span className="inst-text-label">Visual and Performing Arts</span>
              <div className="academic-interest-stats">
                <div className="bar-wrapper">
                  <PercentBar height="8" percentComplete={(1.37 / 3.97) * 100} bgColor={colors.lightGreen} fgColor={colors.darkGreen} />
                </div>
                <span className="inst-text-label lowercase">1.37%</span>
              </div>
            </div>
            <div className="academic-interest">
              <span className="inst-text-label">Engineering</span>
              <div className="academic-interest-stats">
                <div className="bar-wrapper">
                  <PercentBar height="8" percentComplete={(1.35 / 3.97) * 100} bgColor={colors.lightGreen} fgColor={colors.darkGreen} />
                </div>
                <span className="inst-text-label lowercase">1.35%</span>
              </div>
            </div>
            <div className="academic-interest">
              <span className="inst-text-label">Multi/Interdisciplinary Studies</span>
              <div className="academic-interest-stats">
                <div className="bar-wrapper">
                  <PercentBar height="8" percentComplete={(1.33 / 3.97) * 100} bgColor={colors.lightGreen} fgColor={colors.darkGreen} />
                </div>
                <span className="inst-text-label lowercase">1.33%</span>
              </div>
            </div>
            <div className="academic-interest">
              <span className="inst-text-label">Public Administration and Social Service Professions</span>
              <div className="academic-interest-stats">
                <div className="bar-wrapper">
                  <PercentBar height="8" percentComplete={(1.26 / 3.97) * 100} bgColor={colors.lightGreen} fgColor={colors.darkGreen} />
                </div>
                <span className="inst-text-label lowercase">1.26%</span>
              </div>
            </div>
            <div className="academic-interest">
              <span className="inst-text-label">Computer and Information Sciences</span>
              <div className="academic-interest-stats">
                <div className="bar-wrapper">
                  <PercentBar height="8" percentComplete={(1.08 / 3.97) * 100} bgColor={colors.lightGreen} fgColor={colors.darkGreen} />
                </div>
                <span className="inst-text-label lowercase">1.08%</span>
              </div>
            </div>
            <div className="academic-interest">
              <span className="inst-text-label">Physical Sciences</span>
              <div className="academic-interest-stats">
                <div className="bar-wrapper">
                  <PercentBar height="8" percentComplete={(0.97 / 3.97) * 100} bgColor={colors.lightGreen} fgColor={colors.darkGreen} />
                </div>
                <span className="inst-text-label lowercase">0.97%</span>
              </div>
            </div>
            <div className="academic-interest">
              <span className="inst-text-label">Legal Professions and Studies</span>
              <div className="academic-interest-stats">
                <div className="bar-wrapper">
                  <PercentBar height="8" percentComplete={(0.96 / 3.97) * 100} bgColor={colors.lightGreen} fgColor={colors.darkGreen} />
                </div>
                <span className="inst-text-label lowercase">0.96%</span>
              </div>
            </div>
            <div className="academic-interest">
              <span className="inst-text-label">English Language</span>
              <div className="academic-interest-stats">
                <div className="bar-wrapper">
                  <PercentBar height="8" percentComplete={(0.73 / 3.97) * 100} bgColor={colors.lightGreen} fgColor={colors.darkGreen} />
                </div>
                <span className="inst-text-label lowercase">0.73%</span>
              </div>
            </div>
            <div className="academic-interest">
              <span className="inst-text-label">Mathematics and Statistics</span>
              <div className="academic-interest-stats">
                <div className="bar-wrapper">
                  <PercentBar height="8" percentComplete={(0.66 / 3.97) * 100} bgColor={colors.lightGreen} fgColor={colors.darkGreen} />
                </div>
                <span className="inst-text-label lowercase">0.66%</span>
              </div>
            </div>
          </ShadowCard>
        </div>
      </div>
    </div>
  );
}
