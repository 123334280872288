import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useFirebasePrograms } from '../../../context/FirebaseProgramDataContext';
import DemoPieChart from '../../../components/StudentPrograms/DemoPieChart';
import PercentBar from '../../../components/PercentBar';
import './SchoolProfileStyles.scss';
import ShadowCard from '../../../components/ShadowCard';
import { colors } from '../../../shared/colors';

export default function StudentSchoolProfileStudentsPage() {
  const { schoolId } = useParams();
  const { schools } = useFirebasePrograms();

  const data = useMemo(() => {
    if (schoolId && schools) return schools[schoolId];
    return null;
  }, [schoolId, schools]);

  if (data) return (
    <main className="school-profile-page">
      <div className="grid">
        <section className="section">
          <div className="section__header">
            <h2 className="student-text-h2">Age</h2>
          </div>
          <ShadowCard customClassNames="padded">
            <DemoPieChart
              data={[
                { id: 'Age 25 and Under', value: data.under25Percent, label: 'Age 25 and Under', color: colors.peach },
                { id: 'Over Age 25', value: data.over25Percent, label: 'Over Age 25', color: colors.darkPink },
              ]}
              size={200}
            />
          </ShadowCard>
        </section>

        <section className="section">
          <div className="section__header">
            <h2 className="student-text-h2">Gender</h2>
          </div>
          <ShadowCard customClassNames="padded">
            <DemoPieChart
              data={[
                { id: 'Male', value: data.maleStudentPercent, label: 'Male', color: colors.peach },
                { id: 'Female', value: data.femaleStudentPercent, label: 'Female', color: colors.darkPink },
                { id: 'Other', value: data.otherGenderStudentPercent, label: 'Other', color: colors.mediumPeach }
              ]}
              size={200}
            />
          </ShadowCard>
        </section>
      </div>

      <section className="section">
        <div className="section__header">
          <h2 className="student-text-h2">Diversity</h2>
        </div>
        <ShadowCard customClassNames="ethnic-group-chart padded">
          <div>
            <span>African American or Black</span>
            <PercentBar percentComplete={data.africanAmericanBlackPercent} bgColor={colors.mediumPeach} fgColor={colors.darkPink} />
            <span>{data.africanAmericanBlackPercent}%</span>
          </div>
          <div>
            <span>Alaskan Native</span>
            <PercentBar percentComplete={data.alaskanNativePercent} bgColor={colors.mediumPeach} fgColor={colors.darkPink} />
            <span>{data.alaskanNativePercent}%</span>
          </div>
          <div>
            <span>American Indian</span>
            <PercentBar percentComplete={data.americanIndianPercent} bgColor={colors.mediumPeach} fgColor={colors.darkPink} />
            <span>{data.americanIndianPercent}%</span>
          </div>
          <div>
            <span>Asian</span>
            <PercentBar percentComplete={data.asianPercent} bgColor={colors.mediumPeach} fgColor={colors.darkPink} />
            <span>{data.asianPercent}%</span>
          </div>
          <div>
            <span>Caucasian or White</span>
            <PercentBar percentComplete={data.caucasianWhitePercent} bgColor={colors.mediumPeach} fgColor={colors.darkPink} />
            <span>{data.caucasianWhitePercent}%</span>
          </div>
          <div>
            <span>Native Hawaiian</span>
            <PercentBar percentComplete={data.nativeHawaiianPercent} bgColor={colors.mediumPeach} fgColor={colors.darkPink} />
            <span>{data.nativeHawaiianPercent}%</span>
          </div>
          <div>
            <span>Other</span>
            <PercentBar percentComplete={data.otherEthnicGroupPercent} bgColor={colors.mediumPeach} fgColor={colors.darkPink} />
            <span>{data.otherEthnicGroupPercent}%</span>
          </div>
          <div>
            <span>Other Pacific Islander</span>
            <PercentBar percentComplete={data.otherPacificIslanderPercent} bgColor={colors.mediumPeach} fgColor={colors.darkPink} />
            <span>{data.otherPacificIslanderPercent}%</span>
          </div>
          <div>
            <span>Prefer Not to Respond</span>
            <PercentBar percentComplete={data.preferNotEthnicGroupPercent} bgColor={colors.mediumPeach} fgColor={colors.darkPink} />
            <span>{data.preferNotEthnicGroupPercent}%</span>
          </div>
        </ShadowCard>
      </section>
    </main>
  );

  return null;
}