import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../shared/colors';
import './ProgressSteps.scss';
function Step({ r, stepX, stepY, stepLabelX, stepLabel, labelWidth, isComplete }) {
  return (
    <g>
      <circle r={isComplete ? r : r-1} cx={stepX} cy={r} fill={isComplete ? colors.darkGreen : colors.lime } stroke={isComplete ? 'transparent' : colors.darkGreen} strokeWidth={2} />
      <foreignObject x={stepLabelX} y={stepY} height={stepY} width={labelWidth}>
        <p style={{ textAlign: 'center' }} className="step-label">{stepLabel}</p>
      </foreignObject>
    </g>
  );
}

Step.propTypes = {
  r: PropTypes.number.isRequired,
  stepX: PropTypes.number.isRequired,
  stepY: PropTypes.number.isRequired,
  stepLabelX: PropTypes.number.isRequired,
  stepLabel: PropTypes.string.isRequired,
  labelWidth: PropTypes.number.isRequired,
  isComplete: PropTypes.bool.isRequired,
}

export default function ProgressSteps({ steps }) {
  const height = 16;
  const r = height / 2;
  const paddingX = 50;
  const barWidth = 500;
  const svgWidth = barWidth + (paddingX * 2);

  const getPositionX = (index, stepCount, width, offsetX, r) => {
    if (index === 0) {
      return r + offsetX;
    } else if (index === stepCount - 1) {
      return width + (offsetX - r);
    } else {
      const sections = stepCount - 1;
      const sectionWidth = width / sections;
      return (sectionWidth * index) + offsetX;
    }
  }

  const getLabelPositionX = (index, stepCount, width) => {
    const sectionWidth = width / stepCount;
    return sectionWidth * index;
  }

  const lastCompleteIndex = useMemo(() => {
    if (!steps || !steps.length) return 0;

    for (let i = steps.length - 1; i >= 0; i--) {
      if (steps[i].isComplete) {
        return i;
      }
    }

    return null;
  }, [steps]);

  const percentComplete = Number.isFinite(lastCompleteIndex) && lastCompleteIndex > 0 ? lastCompleteIndex / (steps.length - 1) : 0;

  return (
    <svg width={svgWidth} height={80} className="progress-steps">
      <rect x={paddingX} y={0} height={height} width={barWidth} rx={r} fill={colors.lightLime} />
      <rect x={paddingX} y={0} height={height} width={percentComplete > 0 ? barWidth * percentComplete + r : 0} rx={r} fill={colors.green} />

      {steps.map((step, index) => (
        <Step
          key={step.label}
          r={r}
          stepX={getPositionX(index, steps.length, barWidth, paddingX, r)}
          stepY={height * 2}
          stepLabelX={getLabelPositionX(index, steps.length, svgWidth)}
          stepLabel={steps[index].label}
          labelWidth={svgWidth / steps.length}
          isComplete={Number.isFinite(lastCompleteIndex) ? index <= lastCompleteIndex : steps[index].isComplete}
        />
      ))}
    </svg>
  );
}

ProgressSteps.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    isComplete: PropTypes.bool,
  })),
}