import PropTypes from 'prop-types';
import PopupCard from '../StudentUI/PopupCard';
import { useFirebaseStudent } from '../../context/FirebaseStudentContext';
import kebab from '../../assets/student/images/kebab-dark-pink.svg';
import './ProgramPopupMenu.scss';

export default function ProgramPopupMenu({ program }) {
  const { student, addOrUpdateApplication } = useFirebaseStudent();

  const startApplication = (program) => {
    const newAppData = {
      programId: program.programId,
      programTitle: program.programTitle,
      schoolId: program.schoolId,
      schoolName: program.schoolName,
      deadline: '2022-06-01',
      alias: student?.alias,
    };
    addOrUpdateApplication('created', program.programId, newAppData, 'Application created!');
  }

  return (
    <PopupCard
      triggerButton={<button className="student-program-popup-menu__dots"><img src={kebab} alt="Menu" /></button>}
      position="bottom right"
    >
      <div className="student-program-popup-menu">
        <ul>
          {!student?.applications?.[program.programId] ? (
            <li>
              <button onClick={() => startApplication(program)}>Start Application</button>
            </li>
          ) : (
            <li className="student-text-p-italic">Application Created</li>
          )}
        </ul>
      </div>
    </PopupCard>
  )
}

ProgramPopupMenu.propTypes = {
  program: PropTypes.shape({
    programId: PropTypes.string,
    programTitle: PropTypes.string,
    schoolName: PropTypes.string,
    schoolId: PropTypes.string,
  }),
}