import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { Link as ScrollLink, Element } from 'react-scroll';
import { useFirebaseSchool } from '../../../context/FirebaseSchoolContext';
import Modal from '../../../components/Modal';
import Button from '../../../components/InstitutionUI/Button';
import AppStatus from '../../../components/InstitutionApplications/AppStatus';
import ContactStudentButton from '../../../components/InstitutionStudentSearch/ContactStudentButton';
import PopupCard from '../../../components/StudentUI/PopupCard';
import carat from '../../../assets/inst/images/down-carat-green.svg';
import caratBlack from '../../../assets/inst/images/down-carat-black.svg';
import doc from '../../../assets/inst/images/doc.svg';
import check from '../../../assets/student/images/check-green-circle.svg';
import checkGreen from '../../../assets/student/images/check-green.svg';
import './ApplicationPage.scss';

const comingSoonLong = 'This feature will be available soon';

const DocumentPlaceholder = ({ label }) => (
  <div className="inst-application__document">
    <img src={doc} alt="" />
    [{label}]
  </div>
);

DocumentPlaceholder.propTypes = {
  label: PropTypes.string.isRequired,
}

export default function InstitutionApplicationPage() {
  const [preExpanded, setPreExpanded] = useState(['basics']);
  const [showTestsModal, setShowTestsModal] = useState(false);
  const { studentId } = useParams();
  const { applications, updateApplication } = useFirebaseSchool();
  const scrollOffset = -1 * window.innerHeight / 2;

  const application = useMemo(() => {
    if (!studentId || !applications) return null;
    return applications[studentId];
  }, [applications, studentId]);

  if (application) return (
    <main className="inst-application">
      <div className="inst-application__sticky">
        <div className="inst-application__header">
          <div className="left">
            <h2><Link to={`/institutions/recruiting/studentsearch/${studentId}`}>{application.alias}</Link></h2>
            <AppStatus status={application.status} />
            <ContactStudentButton alias={application.alias} />
          </div>

          <div className="right">
            {application.status === 'submitted' ? (
              <button className="review" onClick={() => updateApplication(studentId, { status: 'reviewed' }, 'Application marked reviewed!')}>
                <img src={check} alt="" />
                Mark as Reviewed
              </button>
            ) : null}
            {application.status === 'reviewed' ? (
              <button className="review" onClick={() => updateApplication(studentId, { status: 'submitted' }, 'Application back in review!')}>
                <img src={checkGreen} alt="" />
                Move Back to Review
              </button>
            ) : null}
            <button className="tests" onClick={() => setShowTestsModal(true)}>
              Recommend Assessments
            </button>
            <PopupCard
              position="bottom right"
              triggerButton={<button className="decision">Decision <img src={caratBlack} alt="" /></button>}
            >
              <div className="inst-app-decision-popup">
                <p className="coming-soon">{comingSoonLong}</p>
                <p>
                  Accept<br />
                  Waitlist<br />
                  Deny
                </p>              
              </div>
            </PopupCard>
          </div>
        </div>

        <nav className="inst-tab-nav">
          <ul>
            <li>
              <ScrollLink activeClass="active" to="basics" spy={true} smooth={true} offset={scrollOffset} isDynamic>Basics</ScrollLink>
            </li>
            <li>
              <ScrollLink activeClass="active" to="student-profile" spy={true} smooth={true} offset={scrollOffset} isDynamic>Student Profile</ScrollLink>
            </li>
            <li>
              <ScrollLink activeClass="active" to="citizenship" spy={true} smooth={true} offset={scrollOffset} isDynamic>Citizenship</ScrollLink>
            </li>
            <li>
              <ScrollLink activeClass="active" to="education" spy={true} smooth={true} offset={scrollOffset} isDynamic>Education</ScrollLink>
            </li>
            <li>
              <ScrollLink activeClass="active" to="test-scores" spy={true} smooth={true} offset={scrollOffset} isDynamic>Test Scores</ScrollLink>
            </li>
            <li>
              <ScrollLink activeClass="active" to="personal-statement" spy={true} smooth={true} offset={scrollOffset} isDynamic>Personal Statement</ScrollLink>
            </li>
            <li>
              <ScrollLink activeClass="active" to="recommendations" spy={true} smooth={true} offset={scrollOffset} isDynamic>Letters of Recommendation</ScrollLink>
            </li>
            <li>
              <ScrollLink activeClass="active" to="resume" spy={true} smooth={true} offset={scrollOffset} isDynamic>Resume/CV</ScrollLink>
            </li>
          </ul>
        </nav>
      </div>

      <div className="inst-app-buttons">
        <button
          onClick={() => setPreExpanded([
            'basics',
            'student-profile',
            'citizenship',
            'education',
            'test-scores',
            'personal-statement',
            'recommendations',
            'resume'
          ])}
          disabled={preExpanded?.length > 1}
        >Expand All</button>
      </div>

      <div className="inst-app-accordion" key={preExpanded}>
        <Accordion preExpanded={preExpanded} allowMultipleExpanded>
          <Element name="basics">
            <AccordionItem uuid="basics" activeClassName="accordion__item--expanded">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <span className="inst-text-h2">The Basics</span>
                  <div>
                    <img src={carat} alt="" className="carat" />
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  <span>Anticipated Start Term</span><br />
                  {application?.programInfo?.startDate || '-'}
                </p>

                <p>
                  <span>Intended Degree</span><br />
                  {application?.programInfo?.degree || '-'}
                </p>
              </AccordionItemPanel>
            </AccordionItem>
          </Element>

          <Element name="student-profile">
            <AccordionItem uuid="student-profile" activeClassName="accordion__item--expanded">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <span className="inst-text-h2">Student Profile</span>
                  <div>
                    <img src={carat} alt="" className="carat" />
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <h2>Personal Information</h2>

                <p>
                  <span>Student Alias</span><br />
                  {application?.alias || '-'}
                </p>

                <p>
                  <span>Name</span><br />
                  <span className="coming-soon">{comingSoonLong}</span>
                </p>

                <p>
                  <span>Preferred First Name</span><br />
                  <span className="coming-soon">{comingSoonLong}</span>
                </p>

                <hr />

                <h2>Biographical Information</h2>

                <p>
                  <span>Gender</span><br />
                  {application?.studentProfile?.gender || '-'}
                </p>

                <p>
                  <span>Pronouns</span><br />
                  {application?.studentProfile?.pronouns || '-'}
                </p>

                <p>
                  <span>Year of Birth</span><br />
                  {application?.studentProfile?.birthYear || '-'}
                </p>

                <p>
                  <span>Country of Birth</span><br />
                  {application?.studentProfile?.birthCountry || '-'}
                </p>

                <hr />

                <h2>Permanent Address</h2>

                <p>
                  <span>Street</span><br />
                  <span className="coming-soon">{comingSoonLong}</span>
                </p>

                <p>
                  <span>City</span><br />
                  {application?.studentProfile?.permanentAddress?.city || '-'}
                </p>

                <div className="grid">
                  <p>
                    <span>State</span><br />
                    <span className="coming-soon">{comingSoonLong}</span>
                  </p>

                  <p>
                    <span>ZIP</span><br />
                    <span className="coming-soon">{comingSoonLong}</span>
                  </p>
                </div>

                <p>
                  <span>Country</span><br />
                  {application?.studentProfile?.permanentAddress?.country || '-'}
                </p>

                <hr />

                <h2>Mailing Address</h2>
                
                {application?.studentProfile?.mailingAddress?.sameAsPermanent ? (
                  <>
                    <p>My mailing address is the same as my permanent address.</p>

                    <p>
                      <span>Street</span><br />
                      <span className="coming-soon">{comingSoonLong}</span>
                    </p>

                    <p>
                      <span>City</span><br />
                      {application?.studentProfile?.permanentAddress?.city || '-'}
                    </p>

                    <div className="grid">
                      <p>
                        <span>State</span><br />
                        <span className="coming-soon">{comingSoonLong}</span>
                      </p>

                      <p>
                        <span>ZIP</span><br />
                        <span className="coming-soon">{comingSoonLong}</span>
                      </p>
                    </div>

                    <p>
                      <span>Country</span><br />
                      {application?.studentProfile?.permanentAddress?.country || '-'}
                    </p>
                  </>
                ) : (
                  <>
                    <p>
                      <span>Street</span><br />
                      <span className="coming-soon">{comingSoonLong}</span>
                    </p>

                    <p>
                      <span>City</span><br />
                      {application?.studentProfile?.mailingAddress?.city || '-'}
                    </p>

                    <p>
                      <span>State</span><br />
                      <span className="coming-soon">{comingSoonLong}</span>
                    </p>

                    <p>
                      <span>ZIP</span><br />
                      <span className="coming-soon">{comingSoonLong}</span>
                    </p>

                    <p>
                      <span>Country</span><br />
                      {application?.studentProfile?.mailingAddress?.country || '-'}
                    </p>
                  </>
                )}

                <hr />

                <h2>Contact Information</h2>

                <p>
                  <span>Email</span><br />
                  <span className="coming-soon">{comingSoonLong}</span>
                </p>

                <p>
                  <span>Phone</span><br />
                  <span className="coming-soon">{comingSoonLong}</span>
                </p>

                <p>
                  <span>Would you like more information about financial aid resources?</span><br />
                  {application?.studentProfile?.needFinancialAid === 'true' ? 'Yes' : 'No'}
                </p>
              </AccordionItemPanel>
            </AccordionItem>
          </Element>

          <Element name="citizenship">
            <AccordionItem uuid="citizenship" activeClassName="accordion__item--expanded">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <span className="inst-text-h2">Citizenship Information</span>
                  <div>
                    <img src={carat} alt="" className="carat" />
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <h2>Citizenship Information</h2>

                <p>
                  <span>Citizenship Status</span><br />
                  {application?.citizenship?.status || '-'}
                </p>

                <p>
                  <span>Country of Citizenship</span><br />
                  {application?.citizenship?.country || '-'}
                </p>

                <hr />

                <h2>Ethnic Background</h2>

                <p>
                  <span>What is your ethnicity?</span><br />
                  {application?.citizenship?.ethnicity || '-'}
                </p>

                <p>
                  <span>Please select one or more ethnic groups you belong to:</span><br />
                  {application?.citizenship?.ethnicGroups?.map(value => (<React.Fragment key={value}>{value}<br /></React.Fragment>))}
                </p>
              </AccordionItemPanel>
            </AccordionItem>
          </Element>

          <Element name="education">
            <AccordionItem uuid="education" activeClassName="accordion__item--expanded">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <span className="inst-text-h2">Educational Background</span>
                  <div>
                    <img src={carat} alt="" className="carat" />
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <h2>College</h2>
                {application?.education?.map(ed => (
                  <div className="education-block" key={ed.id}>
                    <p>
                      <span>School</span><br />
                      {ed.schoolName || '-'}
                    </p>

                    <p>
                      <span>City</span><br />
                      <span className="coming-soon">{comingSoonLong}</span>
                    </p>

                    <div className="grid">
                      <p>
                        <span>State</span><br />
                        <span className="coming-soon">{comingSoonLong}</span>
                      </p>

                      <p>
                        <span>ZIP</span><br />
                        <span className="coming-soon">{comingSoonLong}</span>
                      </p>
                    </div>

                    <p>
                      <span>Country</span><br />
                      <span className="coming-soon">{comingSoonLong}</span>
                    </p>

                    <p>
                      <span>Major</span><br />
                      {ed.major || '-'}
                    </p>

                    <p>
                      <span>Degree or Intended Degree</span><br />
                      {ed.degree || '-'}
                    </p>

                    <div className="grid">
                      <p>
                        <span>Start Date</span><br />
                        {ed.startDate}
                      </p>

                      <p>
                        <span>End Date or Anticipated</span><br />
                        {ed.endDate}
                      </p>
                    </div>

                    <p>
                      <span>GPA</span><br />
                      {ed.grade || '-'}
                    </p>

                    <div className="dotted-box">
                      <p>
                        <span>Transcript</span><br />
                        <span className="coming-soon">{comingSoonLong}</span>
                        <DocumentPlaceholder label="transcript.pdf" />
                      </p>
                    </div>

                    <hr />
                  </div>
                ))}

                <h2>High School <span className="coming-soon">(Coming Soon)</span></h2>
              </AccordionItemPanel>
            </AccordionItem>
          </Element>

          <Element name="test-scores">
            <AccordionItem uuid="test-scores" activeClassName="accordion__item--expanded">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <span className="inst-text-h2">Test Scores</span>
                  <div>
                    <img src={carat} alt="" className="carat" />
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <h2>GRE</h2>

                <p>
                  <span>Date Taken</span><br />
                  {application?.testScores?.gre?.dateTaken || '-'}
                </p>

                <p>
                  <span></span>
                </p>

                <div className="dotted-box">
                  <p>
                    <span>GRE Report</span><br />
                    <span className="coming-soon">{comingSoonLong}</span>
                    <DocumentPlaceholder label="report.pdf" />
                  </p>
                </div>

                <p>
                  <span>Verbal Reasoning Score</span><br />
                  {application?.testScores?.gre?.verbal || application?.testScores?.gre?.verbal === 0
                    ? application.testScores.gre.verbal : '-'}
                </p>

                <p>
                  <span>Quantitative Reasoning Score</span><br />
                  {application?.testScores?.gre?.quantitative || application?.testScores?.gre?.quantitative === 0
                    ? application.testScores.gre.quantitative : '-'}
                </p>

                <p>
                  <span>Analytical Writing Score</span><br />
                  {application?.testScores?.gre?.analyticalWriting || application?.testScores?.gre?.analyticalWriting === 0
                    ? application.testScores.gre.analyticalWriting : '-'}
                </p>

                <Button onClick={() => setShowTestsModal(true)}>Recommend Assessments</Button>
              </AccordionItemPanel>
            </AccordionItem>
          </Element>

          <Element name="personal-statement">
            <AccordionItem uuid="personal-statement" activeClassName="accordion__item--expanded">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <span className="inst-text-h2">
                    Personal Statement and/or Statement of Purpose
                    <span className="coming-soon">(Coming Soon)</span>
                  </span>
                  <div>
                    <img src={carat} alt="" className="carat" />
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="dotted-box">
                  <p>
                    <span>Personal Statement</span><br />
                    <span className="coming-soon">{comingSoonLong}</span>
                    <DocumentPlaceholder label="personalstatement.pdf" />
                  </p>
                </div>

                <div className="dotted-box">
                  <p>
                    <span>Statement of Purpose</span><br />
                    <span className="coming-soon">{comingSoonLong}</span>
                    <DocumentPlaceholder label="statementofpurpose.pdf" />
                  </p>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Element>

          <Element name="recommendations">
            <AccordionItem uuid="recommendations" activeClassName="accordion__item--expanded">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <span className="inst-text-h2">
                    Letters of Recommendation
                    <span className="coming-soon">(Coming Soon)</span>
                  </span>
                  <div>
                    <img src={carat} alt="" className="carat" />
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="rec">
                  <span className="inst-text-p-large">Dr. Jarvis Dupree</span><br />
                  <span className="inst-text-p-small">Professor of Microbiology</span>
                  <DocumentPlaceholder label="recommendation.pdf" />
                </div>

                <div className="rec">
                  <span className="inst-text-p-large">Mr. William McDuff</span><br />
                  <span className="inst-text-p-small">Engineering Supervisor</span>
                  <DocumentPlaceholder label="recommendation.pdf" />
                </div>

                <div className="rec">
                  <span className="inst-text-p-large">Dr. Sheila Humphries</span><br />
                  <span className="inst-text-p-small">Director of Exercise Science</span>
                  <DocumentPlaceholder label="recommendation.pdf" />
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Element>

          <Element name="resume">
            <AccordionItem uuid="resume" activeClassName="accordion__item--expanded">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <span className="inst-text-h2">
                    Resume/CV
                    <span className="coming-soon">(Coming Soon)</span>
                  </span>
                  <div>
                    <img src={carat} alt="" className="carat" />
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="dotted-box">
                  <p>
                    <span>Resume/CV</span><br />
                    <span className="coming-soon">{comingSoonLong}</span>
                    <DocumentPlaceholder label="resume.pdf" />
                  </p>
                </div>

                <hr />

                <h2>Additional Documents</h2>

                <div className="dotted-box">
                  <p>
                    <span>Document</span><br />
                    <span className="coming-soon">{comingSoonLong}</span>
                    <DocumentPlaceholder label="document.pdf" />
                  </p>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Element>
        </Accordion>

        <Modal
          closeModal={() => setShowTestsModal(false)}
          title="Recommended Assessments"
          isOpen={showTestsModal}
        >
          <div className="inst-app-recommended-tests">
            <h1>Recommend Assessments</h1>

            <h2>Kira Talent</h2>
            <p>Kira Talent is a holistic admissions solution that supports applicant-first, equity-based assessments to identify best-fit students. It consists of on-demand, timed video and written assessments that can support your existing admissions requirements.</p>
            <div className="flex">
              <Button variant="outline">Recommend</Button>
              <span className="coming-soon">{comingSoonLong}</span>
            </div>

            <hr />

            <h2>Critical Thinking</h2>
            <p>This is an on-demand tool that helps admissions professionals assess a candidate&apos;s relevant skills and dispositions. It mainly focuses on critical thinking, quantitative reasoning, communication and readiness skills.</p>
            <div className="flex">
              <Button variant="outline">Recommend</Button>
              <span className="coming-soon">{comingSoonLong}</span>
            </div>

            <hr />

            <h2>PSQ+</h2>
            <p>This is an on-demand tool that helps admissions professionals assess a candidate&apos;s relevant skills and dispositions. It mainly focuses on critical thinking, quantitative reasoning, communication and readiness skills.</p>
            <div className="flex">
              <Button variant="outline">Recommend</Button>
              <span className="coming-soon">{comingSoonLong}</span>
            </div>
          </div>
        </Modal>
      </div>
    </main>
  );

  return null;
}