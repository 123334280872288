import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import FavoriteButtons from '../StudentFavorites/FavoriteButtons';
import ProgramPopupMenu from '../StudentPrograms/ProgramPopupMenu';
import ShadowCard from '../ShadowCard';
import MostCompatible from '../StudentUI/MostCompatible';
import { currencyFormatter } from '../../shared/numbers';
import './ProgramCard.scss';

export default function ProgramCard({ program }) {

  return (
    <ShadowCard customClassNames="student-program-search-card">
      <div className="student-program-search-card__image" style={{ backgroundImage: `url(/programs/${program.programImageName})` }}>
        {program.recommend ? <MostCompatible /> : null}
      </div>
      <div className="student-program-search-card__content">
        <div className="student-program-search-card__actions">
          <FavoriteButtons programId={program.programId} />
          <ProgramPopupMenu program={program} />
        </div>
        <h2 className="student-text-h2"><Link to={`/students/explore/programs/${program.programId}`}>{program.programTitle}</Link></h2>
        <p className="student-text-h3"><Link to={`/students/explore/schools/${program.schoolId}`}>{program.schoolName}</Link></p>
        <p>{program.schoolType} &middot; {program.campusSetting} &middot; {program.state} </p>
        <p>{currencyFormatter.format(program.tuitionInState)} per year in-state</p>
        <p>{currencyFormatter.format(program.tuitionOutOfState)} per year out of state</p>
      </div>
    </ShadowCard>
  );
}

ProgramCard.propTypes = {
  program: PropTypes.shape({
    programId: PropTypes.string,
    programTitle: PropTypes.string,
    schoolName: PropTypes.string,
    schoolId: PropTypes.string,
    recommend: PropTypes.bool,
    programImageName: PropTypes.string,
    tuitionInState: PropTypes.number,
    tuitionOutOfState: PropTypes.number,
    schoolType: PropTypes.string,
    campusSetting: PropTypes.string,
    appDeadline: PropTypes.string,
    state: PropTypes.string,
  }),
}