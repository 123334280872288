import PropTypes from 'prop-types';
import PopupCard from '../StudentUI/PopupCard';
import pencilIcon from '../../assets/student/images/pencil.svg';
import deleteIcon from '../../assets/student/images/delete.svg';
import privacyIcon from '../../assets/student/images/privacy.svg';
import PrivacyPopup from '../StudentUI/PrivacyPopup';
import './ProfileIconButtonBlock.scss';

export default function ProfileIconButtonBlock({ showPrivacy, onEdit, onDelete }) {
  return (
    <div className="profile-icon-button-block">
      {showPrivacy ? (
        <PrivacyPopup
          triggerButton={<button><img src={privacyIcon} alt="View privacy options" /></button>}
        />
      ) : null}
      {onDelete ? (
        <PopupCard
          triggerButton={<button><img src={deleteIcon} alt="Delete" /></button>}
        >
          <div className="student-delete-warning">
            <p>Are you sure you want to delete this?</p>
            <button onClick={onDelete}>Delete</button>
          </div>
        </PopupCard>
      ) : null}
      {onEdit ? (
        <button onClick={onEdit}><img src={pencilIcon} alt="Edit" /></button>
      ) : null}
    </div>
  )
}

ProfileIconButtonBlock.propTypes = {
  showPrivacy: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
}