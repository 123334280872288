import PropTypes from 'prop-types';
import { formatDate } from '../../shared/dates';
import check from '../../assets/student/images/check-green.svg';
import './TodoBlock.scss';

export default function TodoBlock({ data, editData, done }) {
  const formattedDate = formatDate(data.dueDate);

  if (editData) return (
    <button className="student-todo-block" onClick={editData}>
      <div>
        <span className="student-text-p-large">{data.name}</span>
        <span className="student-text-p-small">{formattedDate}</span>
      </div>
    </button>
  );

  return (
    <div className={`student-todo-block ${done ? 'done' : ''}`}>
      <div>
        <span className="student-text-p-large">{data.name}</span>
        <span className="student-text-p-small">{formattedDate}</span>
      </div>
      {done ? <img src={check} alt="Done" /> : null}
    </div>
  )
}

TodoBlock.defaultProps = {
  isGenerated: false,
  editData: null,
  deleteItem: null,
}

TodoBlock.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    dueDate: PropTypes.string,
    dueTime: PropTypes.string,
    value: PropTypes.string,
    notes: PropTypes.string,
  }),
  editData: PropTypes.func,
  done: PropTypes.bool,
};