import { useState, useMemo, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import DashboardLink from '../../../components/StudentSurveys/DashboardLink';
import { useFirebaseStudent } from '../../../context/FirebaseStudentContext';
import StudentOnboardingEducationForm from '../../../forms/StudentOnboarding/StudentOnboardingEducationForm';
import StudentFutureOnboardingEducationForm from '../../../forms/StudentOnboarding/StudentOnboardingFutureEducationForm';
import useKeypress from '../../../hooks/useKeypress';
import { comingSoonShort } from '../../../shared/appText';
import next from '../../../assets/student/images/right-carat-black.svg';
import SurveyNavButtonWrapper from '../../../components/StudentSurveys/SurveyNavButtonWrapper';
import AddButton from '../../../components/StudentUI/AddButton';
import applyIllus from '../../../assets/student/images/dashboard-apply.svg';
import './SurveyPages.scss';

const screens = {
  INIT: 'INIT',
  GRADUATED: 'GRADUATED',
  IN_SCHOOL: 'IN_SCHOOL',
  SUCCESS: 'SUCCESS',
};

const inSchoolScreensList = [
  screens.INIT,
  screens.IN_SCHOOL,
  screens.SUCCESS,
];

const graduatedScreensList = [
  screens.INIT,
  screens.GRADUATED,
  screens.SUCCESS,
];

const prevSurveyPath = '/students/surveys/schools/size';
const nextSurveyPath = '/students/surveys/workexperience';

export default function StudentOnboardingEducationPage() {
  const navigate = useNavigate();
  const [screenView, setScreenView] = useState(screens.INIT);
  const [showNextDegreeForm, setShowNextDegreeForm] = useState(false);
  const { student, addOrUpdateListItem, removeListItem } = useFirebaseStudent();

  const studentDegrees = useMemo(() => {
    if (student?.education) {
      return Object.keys(student.education).map(key => student.education[key]);
    } else {
      return [];
    }
  }, [student?.education]);

  useEffect(() => {
    setShowNextDegreeForm(false);
  }, [student?.education]);

  const prevScreen = () => {
    if (inSchoolScreensList.includes(screenView)) {
      // use in school path
      const currentIndex = inSchoolScreensList.indexOf(screenView);
      if (currentIndex === 0) {
        navigate(prevSurveyPath);
      } else if (currentIndex > 0) {
        setScreenView(inSchoolScreensList[currentIndex - 1]);
      }
    } else {
      // use graduated path
      const currentIndex = graduatedScreensList.indexOf(screenView);
      if (currentIndex === 0) {
        navigate(prevSurveyPath);
      } else if (currentIndex > 0) {
        setScreenView(graduatedScreensList[currentIndex - 1]);
      }
    }
  }

  const nextScreen = () => {
    if (inSchoolScreensList.includes(screenView)) {
      // use in school path
      const currentIndex = inSchoolScreensList.indexOf(screenView);
      if (currentIndex > -1 && currentIndex < inSchoolScreensList.length - 1) {
        setScreenView(inSchoolScreensList[currentIndex + 1]);
      } else if (currentIndex === inSchoolScreensList.length - 1) {
        navigate(nextSurveyPath);
      }
    } else {
      // use graduated path
      const currentIndex = graduatedScreensList.indexOf(screenView);
      if (currentIndex > -1 && currentIndex < graduatedScreensList.length - 1) {
        setScreenView(graduatedScreensList[currentIndex + 1]);
      } else if (currentIndex === graduatedScreensList.length - 1) {
        navigate(nextSurveyPath);
      }
    }
  }

  useKeypress('ArrowRight', nextScreen);
  useKeypress('ArrowLeft', prevScreen);

  const saveDegree = (data) => addOrUpdateListItem('education', data, 'Degree saved!');

  const deleteDegree = (id) => removeListItem('education', id, 'Degree deleted!');

  return (
    <main className="student-onboarding-survey">
      <DashboardLink />
      {screenView === screens.INIT ? (
        <>
          <p className="student-text-p-large">Let&apos;s start filling out your academic background.</p>
          <br />
          <br />
          <p className="student-text-h1">Have you already graduated?</p>
          <div className="flex">
            <button onClick={() => setScreenView(screens.GRADUATED)} className="btn-gray">Yes!</button>
            <button onClick={() => setScreenView(screens.IN_SCHOOL)} className="btn-gray">No, I&apos;m still in school</button>
          </div>
        </>
      ) : null}

      {screenView === screens.IN_SCHOOL ? (
        <>
          <p className="student-text-p-large">Let&apos;s start filling out your academic background.</p>
          <StudentFutureOnboardingEducationForm
            defaultValues={studentDegrees.length ? studentDegrees[0] : null}
            saveData={saveDegree}
            deleteItem={studentDegrees.length ? () => deleteDegree(studentDegrees[0].id) : null}
          />
          
          <br />
          <div className="flex">
            <AddButton disabled>Add A Certificate</AddButton>
            <span className="coming-soon">{comingSoonShort}</span>
          </div>
          
          <SurveyNavButtonWrapper>
            <button onClick={prevScreen} className="prev">
              <img src={next} alt="Go Back" />
            </button>
            <Link to={nextSurveyPath} className="next">
              <img src={next} alt="Next" />
            </Link>
          </SurveyNavButtonWrapper>
        </>
      ) : null}

      {screenView === screens.GRADUATED ? (
        <>
          <p className="student-text-p-large">Let&apos;s start filling out your academic background.</p>
          <StudentOnboardingEducationForm
            labelText="My highest level degree is a"
            defaultValues={studentDegrees.length ? studentDegrees[0] : null}
            saveData={saveDegree}
            deleteItem={studentDegrees.length ? () => deleteDegree(studentDegrees[0].id) : null}
          />
          {studentDegrees.length > 1 ? studentDegrees.slice(1).map(degree => (
            <StudentOnboardingEducationForm
              key={degree.id}
              labelText="I also have a"
              defaultValues={degree}
              saveData={saveDegree}
              deleteItem={() => deleteDegree(degree.id)}
            />
          )) : null}
          {showNextDegreeForm ? (
            <StudentOnboardingEducationForm
              labelText="I also have a"
              defaultValues={null}
              saveData={saveDegree}
            />
          ) : null}
          {studentDegrees?.length > 0 ? (   
            <AddButton onClick={() => setShowNextDegreeForm(true)}>
              Add Another Degree
            </AddButton>
          ) : null}
          <br />
          <div className="flex">
            <AddButton disabled>
              Add A Certificate
            </AddButton>
            <span className="coming-soon">{comingSoonShort}</span>
          </div>
          <br />

          <SurveyNavButtonWrapper>
            <button onClick={prevScreen} className="prev">
              <img src={next} alt="Save & Back" />
            </button>
            <button className="next" onClick={() => setScreenView(screens.SUCCESS)}>
              <img src={next} alt="Save & Next" />
            </button>
          </SurveyNavButtonWrapper>
        </>
      ) : null}

      {screenView === screens.SUCCESS ? (
        <div className="centered">
          <img src={applyIllus} alt="" width={313} />
          <p className="student-text-h2">This information will be saved to your profile and can be imported into your applications when you&apos;re ready.</p>
          <p className="student-text-p-large">Would you like to complete your profile?</p>
          <Link to={nextSurveyPath} className="btn">Yes, Continue</Link>
          <Link to="/students" className="student-cta-link">Skip For Now</Link>
        </div>
      ) : null}
    </main>
  )
}