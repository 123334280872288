import { generateLastXYears } from './numbers';

export const studentLevelFilters = [
  { value: 'Master\'s', label: 'Master\'s' },
  { value: 'Doctorate', label: 'Doctorate' },
  { value: 'Certificate', label: 'Certificate or Non-degree' },
];

export const studentCampusSettingFilters = [
  { value: 'City', label: 'Urban' },
  { value: 'Suburban', label: 'Suburban' },
  { value: 'Small Town', label: 'Small Town' },
  { value: 'Rural', label: 'Rural' },
];

export const studentSchoolTypeFilters = [
  { value: 'Public', label: 'Public' },
  { value: 'Private', label: 'Private' }
];

export const studentProgramFormatFilters = [
  { value: 'Campus', label: 'On Campus' },
  { value: 'Online', label: 'Online' },
  { value: 'Hybrid', label: 'Hybrid' },
  { value: 'FT', label: 'Full - Time' },
  { value: 'PT', label: 'Part - Time' },
];

export const institutionCitizenshipStatusValues = [
  'Domestic',
  'International',
  'Both'
];

export const institutionCitizenshipStatusOptions = institutionCitizenshipStatusValues.map(x => ({ value: x, label: x }));

export const institutionGraduationYearOptions = generateLastXYears(20).map(x => ({ value: x.toString(), label: x.toString() }));

export const institutionGenderValues = ['Female', 'Male', 'Other'];

export const institutionGenderOptions = institutionGenderValues.map(x => ({ value: x, label: x }));