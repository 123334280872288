import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useFirebaseSchool } from '../../../context/FirebaseSchoolContext';
import StudentCard from '../../../components/InstitutionUI/StudentCard';
import Filters from '../../../components/InstitutionStudentSearch/Filters';
import down from '../../../assets/inst/images/down.svg';
import searchIcon from '../../../assets/icons/search-gray.svg';
import candidate from '../../../assets/inst/images/candidatebuilder.svg';
import './InstFavorites.scss';

export const EmptyPlaceholder = ({ text }) => (
  <div className="empty-placeholder">
    <img src={candidate} alt="" width={420} />
    <p className="inst-text-h4">{text}</p>
    <Link to="/institutions/recruiting" className="btn--outline">Recruiting Tools</Link>
  </div>
);

EmptyPlaceholder.propTypes = {
  text: PropTypes.string,
}

export const TopSection = ({ totalText }) => (
  <div className="inst-favorites__top">
    <p className="inst-text-h4">{totalText}</p>
    <div className="inst-favorites__right">
      <div className="search">
        <input type="text" placeholder="Coming soon" disabled style={{ backgroundImage: `url(${searchIcon})` }} />
        <Filters />
      </div>

      <span className="sort">
        <span className="coming-soon">coming soon</span>
        Sort by
        <img src={down} alt="" />
      </span>
    </div>
  </div>
);

TopSection.propTypes = {
  totalText: PropTypes.string.isRequired,
}

export default function InstitutionFavoriteStudentsPage() {
  const { favoriteStudentsList, students } = useFirebaseSchool();

  const favorites = useMemo(() => {
    if (!favoriteStudentsList || !favoriteStudentsList.length || !students) return [];
    return favoriteStudentsList.filter(s => !s.byStudent && s.byProgram).map(s => ({ ...students[s.studentId], studentId: s.studentId }));
  }, [favoriteStudentsList, students]);

  return (
    <main className="inst-favorites">
      {favorites.length ? (
        <>
          <TopSection totalText={`You favorited ${favorites.length} profile${favorites.length === 1 ? '' : 's'}`} />
          <ul className="grid">
            {favorites.map(s => (
              <li key={s.studentId}>
                <StudentCard student={s} />
              </li>
            ))}
          </ul>
        </>
      ) : (
        <EmptyPlaceholder text="You haven't Favorited any prospective students yet! Use the Customized Candidate Builder and the Recommendations features to find and Favorite students who fit your Program Profile." />
      )}
    </main>
  );
}