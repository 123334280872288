import PropTypes from 'prop-types';
import './ShadowCard.scss';

export default function ShadowCard({ customClassNames, children }) {
  return (
    <div className={`shadow-card ${customClassNames}`}>
      {children}
    </div>
  );
}

ShadowCard.propTypes = {
  customClassNames: PropTypes.string,
  children: PropTypes.node,
};