import { Link } from 'react-router-dom';
import ShadowCard from '../../../components/ShadowCard';
import SectionHeader from '../../../components/SectionHeader';
import roiImage from '../../../assets/inst/images/roi.png';
import trendsImage from '../../../assets/inst/images/trends.png';
import './TipsAndBestPractices.scss';

export default function InstitutionTipsAndBestPracticesPage() {
  return (
    <div className="tips-and-best-practices">
      <SectionHeader title="Tips and Best Practices" />
      <div className="tips-body">
        <Link to="improve-roi">
          <ShadowCard>
            <img src={roiImage} alt="" />
            <div className="card-right">
              <h1 className="inst-text-h1">How to Improve Your ROI</h1>
              <span className="inst-text-label">March 10, 2022</span>
              <p className="student-text-p">Make sure your program’s website is up to date and properly linked in your program profile. Students should have easy access to research your program on their own.</p>
            </div>
          </ShadowCard>
        </Link>
        <Link to="profile-trends">
          <ShadowCard>
            <img src={trendsImage} alt="" />
            <div className="card-right">
              <h1 className="inst-text-h1">Trends in Student Profiles</h1>
              <span className="inst-text-label">March 10, 2022</span>
              <p className="student-text-p">As programs become more selective, students want to have access to as many details about a program as possible so they can apply to ones that not only meet their criteria, but where they have the best chance of acceptance.</p>
            </div>
          </ShadowCard>
        </Link>
      </div>
    </div>
  );
}
