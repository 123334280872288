import { Link, useNavigate } from 'react-router-dom';
import useKeypress from '../../../hooks/useKeypress';
import DashboardLink from '../../../components/StudentSurveys/DashboardLink';
import './SurveyPages.scss';

const prevSurveyPath = '/students/surveys/goals';
const addSchoolsSurveyPath = '/students/surveys/schools/add';
const schoolPrefsSurveyPath = '/students/surveys/schools/priorities';

export default function StudentSchoolsSurveyIntroPage() {
  const navigate = useNavigate();

  const prevScreen = () => {
    navigate(prevSurveyPath);
  }

  useKeypress('ArrowLeft', prevScreen);

  return (
    <main className="student-onboarding-survey">
      <DashboardLink />
      <p className="student-text-p-large">Let&apos;s get to know your school preferences better.</p>
      <br /><br />
      <p className="student-text-h1">Do you have any schools in mind already?</p>
      <div className="flex">
        <Link to={addSchoolsSurveyPath} className="btn-gray">Yes!</Link>
        <Link to={schoolPrefsSurveyPath} className="btn-gray">No, I need help</Link>
      </div>
    </main>
  );
}