import SectionHeader from '../../../components/SectionHeader';
import womanRainIllus from '../../../assets/student/images/woman-rain.svg';
import './CareersPage.scss';

export default function CareersPage() {
  return (
    <div className='student-careers'>
      <SectionHeader title='Career Resources (Coming Soon)' />
      <main className='student-careers__content'>
        <img src={womanRainIllus} alt='' width={384} />
        <h1 className='student-text-h1'>It&apos;s Time to Put that Graduate Degree to Work and Think About Your Career</h1>
        <p className='student-text-p-large'>Explore career pathways and potential employment opportunities based on your profile and your career objectives.</p>
      </main>
    </div>
  );
}