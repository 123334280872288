import { useForm, useFieldArray } from 'react-hook-form';
import PropTypes from 'prop-types';
import Field from '../../components/Field';
import { comingSoonLong, comingSoonShort } from '../../shared/appText';
import { studentEducationPropTypes } from '../../shared/propTypes';
import plus from '../../assets/student/images/plus.svg';
import './StudentApplicationForms.scss';

export const educationDefault = {
  schoolName: '',
  major: '',
  degree: '',
  startDate: '',
  endDate: '',
  grade: ''
};

function EducationFormArray({ control, register, errors }) {
  const { fields, append } = useFieldArray({
    control,
    name: 'education'
  });

  return (
    <>
      {fields.map((edFormItem, index) => (
        <div key={edFormItem.id || edFormItem.schoolName}>
          {index > 0 ? <hr /> : null}
          <fieldset>
            <Field error={errors?.education?.[index]?.schoolName}>
              <label>
                School *<br />
                <input
                  type="text"
                  className={errors?.education?.[index]?.schoolName ? 'error' : ''}
                  {...register(`education[${index}].schoolName`, { required: 'Required' })}
                />
              </label>
            </Field>

            <Field>
              <label>
                City<br />
                <input type="text" disabled placeholder={comingSoonLong} />
              </label>
            </Field>

            <div className="flex">
              <Field>
                <label>
                  State<br />
                  <input type="text" disabled placeholder={comingSoonShort} />
                </label>
              </Field>

              <Field>
                <label>
                  ZIP<br />
                  <input type="text" disabled placeholder={comingSoonShort} />
                </label>
              </Field>
            </div>

            <Field>
              <label>
                Country<br />
                <input type="text" disabled placeholder={comingSoonLong} />
              </label>
            </Field>

            <Field error={errors?.education?.[index]?.major}>
              <label>
                Major *<br />
                <input
                  type="text"
                  className={errors?.education?.[index]?.major ? 'error' : ''}
                  {...register(`education.[${index}].major`, { required: 'Required' })}
                  placeholder="Write here"
                />
              </label>
            </Field>

            <Field error={errors?.education?.[index]?.degree}>
              <label>
                Degree or Intended Degree *<br />
                <input
                  type="text"
                  className={errors?.education?.[index]?.degree ? 'error' : ''}
                  {...register(`education.[${index}].degree`, { required: 'Required' })}
                  placeholder="Write here"
                />
              </label>
            </Field>

            <Field error={errors?.education?.[index]?.startDate}>
              <label>
                Start Date *<br />
                <input
                  type="date"
                  className={errors?.education?.[index]?.startDate ? 'error' : ''}
                  {...register(`education.[${index}].startDate`, { required: 'Required' })}
                />
              </label>
            </Field>

            <Field error={errors?.education?.[index]?.endDate}>
              <label>
                End Date or Anticipated *<br />
                <input
                  type="date"
                  className={errors?.education?.[index]?.endDate ? 'error' : ''}
                  {...register(`education.[${index}].endDate`, { required: 'Required' })}
                />
              </label>
            </Field>

            <div className="dotted-box">
              <p>Transcript</p>
              <p>{comingSoonLong}</p>
              <button disabled>Upload</button>
            </div>

            <Field error={errors?.education?.[index]?.grade}>
              <label>
                GPA *<br />
                <input
                  type="text"
                  className={errors?.education?.[index]?.grade ? 'error' : ''}
                  {...register(`education.[${index}].grade`, { required: 'Required' })}
                  placeholder="Write here"
                />
              </label>
            </Field>
          </fieldset>
        </div>
      ))}
      <Field>
        <button onClick={() => append(educationDefault)} className="add-one">
          <img src={plus} alt="" />
          <span className="student-cta-link">Add Another College</span>
        </button>
      </Field>
    </>
  )
}

EducationFormArray.propTypes = {
  control: PropTypes.object,
  register: PropTypes.func,
  errors: PropTypes.object,
}

export default function StudentApplicationEducationForm({ defaultValues, saveUpdates }) {
  const { control, register, handleSubmit, formState: { errors, isValid } } = useForm({ defaultValues, mode: 'all' });

  return (
    <form onSubmit={handleSubmit(saveUpdates)} className="student-app-form">
      <h2 className="student-text-h2">College</h2>

      <EducationFormArray {...{ control, register, errors }} />

      <Field>
        <button type="submit" disabled={!isValid}>Continue</button>
      </Field>
    </form>
  );
}

StudentApplicationEducationForm.propTypes = {
  defaultValues: PropTypes.shape({
    education: PropTypes.arrayOf(PropTypes.shape(studentEducationPropTypes)),
  }),
  saveUpdates: PropTypes.func.isRequired,
};