import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { responsive } from '../StudentApplications/DesktopAppsCarousel';
import ShadowCard from '../ShadowCard';
import FavoriteButtons from '../StudentFavorites/FavoriteButtons';
import MostCompatible from '../StudentUI/MostCompatible';
import { useFirebasePrograms } from '../../context/FirebaseProgramDataContext';
import './ProgramRecsCarousel.scss';

function ProgramRecCarouselCard({ program }) {
  return (
    <ShadowCard customClassNames="program-rec-card">
      <div className="program-rec-card__image" style={{ backgroundImage: `url('/programs/${program.programImageName}')`}}>
        <FavoriteButtons programId={program.programId} />
      </div>
      <div className="program-rec-card__content">
        <div>
          <h3 className="student-text-h2"><Link to={`/students/explore/programs/${program.programId}`}>{program.programTitle}</Link></h3>
          <p className="student-text-h3"><Link to={`/students/explore/schools/${program.schoolId}`}>{program.schoolName}</Link></p>
        </div>
        <div>
          <MostCompatible />
        </div>
      </div>
    </ShadowCard>
  );
}

ProgramRecCarouselCard.propTypes = {
  program: PropTypes.shape({
    programId: PropTypes.string,
    programTitle: PropTypes.string,
    schoolId: PropTypes.string,
    schoolName: PropTypes.string,
    programImageName: PropTypes.string,
  })
}

export default function ProgramRecsCarousel({ customResponsive }) {
  const { programsList } = useFirebasePrograms();

  const recommendedPrograms = useMemo(() => {
    if (!programsList || !programsList.length) return [];
    return programsList.filter(p => p.recommend);
  }, [programsList]);

  return (
    <div className="program-recs-carousel student-carousel">
      <Carousel responsive={customResponsive || responsive} autoPlay={false}>
        {recommendedPrograms.map(program => <ProgramRecCarouselCard program={program} key={program.programId} />)}
      </Carousel>
    </div>
  )
}

ProgramRecsCarousel.propTypes = {
  customResponsive: PropTypes.object,
}