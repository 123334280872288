import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFirebasePrograms } from '../../../context/FirebaseProgramDataContext';
import SchoolProgramListCard from '../../../components/StudentPrograms/SchoolProgramListCard';
import Modal from '../../../components/Modal';
import { FiltersForm } from '../../../components/StudentSearch/Filters';
import searchIcon from '../../../assets/icons/search.svg';
import { comingSoonShort } from '../../../shared/appText';
import './SchoolProfileStyles.scss';

export default function StudentSchoolProfileProgramsListPage() {
  const [showFilters, setShowFilters] = useState(false);
  const { schoolId } = useParams();
  const { programsList } = useFirebasePrograms();

  const schoolPrograms = useMemo(() => {
    if (schoolId && programsList) return programsList.filter(p => p.schoolId === schoolId);
    return [];
  }, [schoolId, programsList]);

  return (
    <main className="school-profile-page">
      <section className="programs-search">
        <div className="search-input">
          <img src={searchIcon} alt="" />
          <input
            className="student-text-p"
            type="text"
            disabled
            placeholder={`Search ${comingSoonShort}`}
          />
          <button aria-label="Clear" type="button" disabled>&times;</button>
        </div>
        <button className="student-cta-link" onClick={() => setShowFilters(true)}>Filters</button>

        <Modal
          closeModal={() => setShowFilters(false)}
          title="Program Filters (coming soon)"
          isOpen={showFilters}
        >
          <FiltersForm
            defaultValues={null}
            updateFilters={() => {}}
            closeModal={() => setShowFilters(false)}
            readOnly
          />
        </Modal>
      </section>

      <section className="section">
        <div className="section__header">
          <h2 className="student-text-h2">Programs</h2>
        </div>
        <div className="programs-list">
          {schoolPrograms.map(p => (
            <SchoolProgramListCard program={p} key={p.programId} />
          ))}
        </div>
      </section>
    </main>
  );
}