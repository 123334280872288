import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import SectionHeader from '../../../components/SectionHeader';
import MessagingPopup from '../../../components/StudentMessaging/MessagingPopup';
import arrow from '../../../assets/student/images/arrow-left.svg';
import './ResourcesPage.scss';
import { useEffect } from 'react';

export default function StudentResourcesPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const useBackButton = !['articles', 'calculateroi'].includes(pathname.split('/').slice(-1)[0]);

  useEffect(() => {
    if (pathname.split('/').slice(-1)[0] === 'resources') {
      return navigate('articles');
    }
  });

  return (
    <div className="student-resources-page">
      <SectionHeader title="Resources" showProfile/>
      {!useBackButton && <nav className="student-resources-tabs">
        <ul className="student-text-h3">
          <li><NavLink to="articles">Articles</NavLink></li>
          <li><NavLink to="calculateroi">Calculate ROI</NavLink></li>
          <li>Communities <span>✨ coming soon ✨</span></li>
        </ul>
      </nav>}
      {useBackButton && <div className="back-button-container">
        <NavLink to="/students/resources/articles">
          <img src={arrow} alt=""/> Back
        </NavLink>
      </div>}
      <Outlet />
      <MessagingPopup />
    </div>
  );
}
