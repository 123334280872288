import { Link } from 'react-router-dom';
import SectionHeader from '../../../components/SectionHeader';
import ShadowCard from '../../../components/ShadowCard';
import studentSearch from '../../../assets/inst/images/studentsearch.svg';
import candidateBuilder from '../../../assets/inst/images/candidatebuilder.svg';
import purchaselist from '../../../assets/inst/images/purchaselist.svg';
import favorites from '../../../assets/inst/images/favorites.svg';
import recommendations from '../../../assets/inst/images/recommendations.svg';
import './RecruitingLandingPage.scss';

export default function InstitutionRecruitingLandingPage() {
  return (
    <main className="inst-recruiting-landing">
      <SectionHeader title="Recruiting Tools" />
      <div className="inst-recruiting-landing__content">
        <p className="inst-text-p-large">GradConnector offers multiple ways to help you search and find potential candidates for your program. You can utilize some or all of these features in order to maximize your ROI and receive your desired number of viable applicants.</p>
        <div className="grid">
          <ShadowCard customClassNames="link-card">
            <div>
              <img src={studentSearch} alt="" />
              <h2>Student Search</h2>
              <p>Search from thousands of student users or target your search with our customized filters.</p>
            </div>
            <Link to="studentsearch" className="btn">Start</Link>
          </ShadowCard>
          <ShadowCard customClassNames="link-card">
            <div>
              <img src={candidateBuilder} alt="" />
              <h2>Customized Candidate Builder</h2>
              <p>Utilizing our comprehensive list of filters, customize what your ideal potential program candidate looks like and receive student profiles based on that criteria.</p>
            </div>
            <Link to="candidatebuilder" className="btn">Start</Link>
          </ShadowCard>
          <ShadowCard customClassNames="link-card">
            <div>
              <img src={purchaselist} alt="" />
              <h2>Purchase a List</h2>
              <p>You can purchase a customized list of students from our extensive database based on your individual set of criteria.</p>
            </div>
            <Link to="purchaselist" className="btn">Start</Link>
          </ShadowCard>
          <ShadowCard customClassNames="link-card">
            <div>
              <img src={favorites} alt="" />
              <h2>Favorites</h2>
              <p>Here you will see the students that you Favorited, the students who Favorited your program, and if you both Favorited each other, it&apos;s a Match!</p>
            </div>
            <Link to="favorites" className="btn">Start</Link>
          </ShadowCard>
          <ShadowCard customClassNames="link-card">
            <div>
              <img src={recommendations} alt="" />
              <h2>Recommendations</h2>
              <p>You receive student recommendations based on your Program Profile. The more comprehensive your profile, the accurate your recommendations will be.</p>
            </div>
            <Link to="recommendations" className="btn">Start</Link>
          </ShadowCard>
          <ShadowCard customClassNames="tip-card">
            <div>
              <h2>Recruiting Tip</h2>
              <p>Your profile may be the most important because it&apos;s the first thing that students will see when searching for programs they are interested in. It&apos;s also where your recommendations from GradConnector will be generated. The more detail you can provide, the more appealing your program will be to prospective students and the more accurate your recommendations will be.</p>
            </div>
            <Link to="/institutions/schoolprofile" className="btn--outline">Complete Your Profile</Link>
          </ShadowCard>
        </div>
      </div>
    </main>
  );
}