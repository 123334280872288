import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { programTypeOptions, schoolTypeOptions, campusSettingOptions } from '../../shared/formOptions';
import ControlledMultiSelect from '../../components/ControlledMultiSelect';
import ControlledSlider from '../../components/ControlledSlider';
import Field from '../../components/Field';
import { studentPropTypes } from '../../shared/propTypes';
import { currencyFormatter } from '../../shared/numbers';
import next from '../../assets/student/images/right-carat-black.svg';
import './StudentOnboardingForm.scss';
import SurveyNavButtonWrapper from '../../components/StudentSurveys/SurveyNavButtonWrapper';

export default function StudentOnboardingSchoolPrefsForm({ defaultValues, saveData, goBack }) {
  const { control, handleSubmit } = useForm({ defaultValues });

  return (
    <form onSubmit={handleSubmit(saveData)} className="student-onboarding-form">
      <Field>
        <label htmlFor="programType">I prefer programs that are</label>&nbsp;
        <ControlledMultiSelect
          control={control}
          defaultValue={[]}
          name="programType"
          options={programTypeOptions}
          singleOptionValue="No preference"
          customClassNames="student-survey-select"
          placeholder="Select all that apply"
        />
      </Field>

      <Field>
        <label htmlFor="schoolType">I prefer schools that are</label>&nbsp;
        <ControlledMultiSelect
          control={control}
          defaultValue={[]}
          name="schoolType"
          options={schoolTypeOptions}
          singleOptionValue="No preference"
          customClassNames="student-survey-select"
          placeholder="Select all that apply"
        />
      </Field>

      <Field>
        <label htmlFor="settingType">I prefer settings that are</label>&nbsp;
        <ControlledMultiSelect
          control={control}
          defaultValue={[]}
          name="settingType"
          options={campusSettingOptions}
          singleOptionValue="No preference"
          customClassNames="student-survey-select"
          placeholder="Select all that apply"
        />
      </Field>

      <Field>
        <p className="student-text-h1">Yearly, I can afford paying</p>
        <br />
        <label htmlFor="costRange" className="slider">Annual Tuition Range</label><br />
        <ControlledSlider
          control={control}
          defaultValue={[0, 50000]}
          min={0}
          max={100000}
          step={5000}
          name="costRange"
          displayThumb={value => currencyFormatter.format(value)}
          customClassNames="student-survey-slider"
        />
      </Field>

      <Field>
        <SurveyNavButtonWrapper>
          <button onClick={goBack} className="prev">
            <img src={next} alt="Go Back" />
          </button>
          <button type="submit" className="next">
            <img src={next} alt="Save & Next" />
          </button>
        </SurveyNavButtonWrapper>
      </Field>
    </form>
  )
}

StudentOnboardingSchoolPrefsForm.defaultProps = {
  defaultValues: {
    programType: [],
    schoolType: [],
    settingType: [],
    costRange: [0, 50000],
  },
}

StudentOnboardingSchoolPrefsForm.propTypes = {
  defaultValues: PropTypes.shape({
    programType: studentPropTypes.programType,
    schoolType: studentPropTypes.schoolType,
    settingType: studentPropTypes.settingType,
    costRange: studentPropTypes.costRange,
  }),
  saveData: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
}