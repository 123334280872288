import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import './PopupCard.scss';

export default function PopupCard({ triggerButton, position, on, children }) {
  return (
    <Popup trigger={triggerButton} position={position} on={on} className="student-popup" repositionOnResize closeOnDocumentClick>
      {children}
    </Popup>
  );
}

PopupCard.defaultProps = {
  position: 'bottom center',
  on: ['click'],
};

PopupCard.propTypes = {
  triggerButton: PropTypes.node.isRequired,
  position: PropTypes.string,
  children: PropTypes.node.isRequired,
  on: PropTypes.arrayOf(PropTypes.oneOf(['hover', 'focus', 'click'])),
}
