import PropTypes from 'prop-types';
import arrow from '../../assets/student/images/arrow-right-dark-pink.svg';
import './WebLink.scss';

export default function WebLink({ url }) {
  return (
    <a href={url} target="_blank" rel="noreferrer" className="student-program-profile-web-link">
      Go To Website <img src={arrow} alt="" />
    </a>
  )
}

WebLink.propTypes = {
  url: PropTypes.string.isRequired,
}