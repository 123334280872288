import SectionHeader from '../../../components/SectionHeader';
import BackLink from '../../../components/BackLink';
import roiImage from '../../../assets/inst/images/roi-splash.png';
import './TipsAndBestPracticesROI.scss';

export default function InstitutionTipsAndBestPracticesRoiPage() {
  return (
    <div className="tips-and-best-practices-roi">
      <SectionHeader title="Tips and Best Practices" />
      <BackLink to="/institutions/tips" label="Back" inst />
      <img className="banner-image" src={roiImage} alt="" />
      <div className="article-content">
        <h1 className="inst-text-h1">How to Improve Your ROI</h1>
        <span className="inst-text-label">March 10, 2022</span>
        <ul className="inst-text-p">
          <li>Make sure your program’s website is up to date and properly linked in your program profile. Students should have easy access to research your program on their own.</li><br/>
          <li>Your faculty and alumni are some of your best weapons for promoting your program. Post testimonials on your program page and give prospective students access to communicate with faculty, current and alumni students.</li><br/>
          <li>Partner with local businesses to promote your programs that may be of interest to their employees and/or create internship opportunities for current students. Highlight any of these partnerships on your Program Profile.</li><br/>
          <li>Post a video highlighting the uniqueness and benefits of your program. Show students and faculty engaged in your curriculum, campus life or online learning options.</li><br/>
          <li>Keep your communications and messaging with prospective students personal and individualized, rather than generic or formulaic. Prospective students are more apt to apply to a program that makes them feel acknowledged personally.</li><br/> 
          <li>Follow up with every lead and every student who ‘Favorites’ your program. Even the slightest interest from “browsing” students can lead to an application.</li><br/>
          <li>If you’re a smaller school, consider targeting your search for prospective students outside your institution’s geographical area. Many students are willing to relocate, travel or take advantage of online learning options.</li><br/>  
          <li>Utilize social media and make sure you post all appropriate links on your program profile, giving students another avenue to engage and interact with your program.</li><br/>
        </ul>
      </div>
    </div>
  );
}
