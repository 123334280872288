import { useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useFirebasePrograms } from '../../context/FirebaseProgramDataContext';
import EditButton from '../../components/InstitutionProfile/EditButton';
import ProfileMainImageCard from '../../components/InstitutionProfile/ProfileMainImageCard';
import TabNav from '../../components/InstitutionUI/TabNav';
import SectionHeader from '../../components/SectionHeader';

export default function InstitutionProgramProfileLayout() {
  const { programId } = useParams();
  const { programs, activeProgram } = useFirebasePrograms();

  const data = useMemo(() => {
    if (programs && programId) return programs[programId];
    return null;
  }, [programId, programs]);

  if (data) return (
    <div className="student-school-profile">
      <SectionHeader title="Profile" />
      <TabNav tabs={[
        { label: 'School', to: '/institutions/schoolprofile' },
        { label: 'Program', to: `/institutions/programprofile/${activeProgram?.programId}` }
      ]} />
      <div className="student-school-profile__top">
        <div className="button">
          <EditButton />
        </div>
        <ProfileMainImageCard
          programId={data.programId}
          imageUrl={data.programImageName}
          programTitle={data.programTitle}
          schoolName={data.schoolName}
          website={data.website}
          recommend={data.recommend}
        />
      </div>
      <TabNav
        tabs={[
          { label: 'Overview', to: '', end: true },
          { label: 'Admission & Application', to: 'admissions' },
          { label: 'Faculty', to: 'faculty' },
          { label: 'Tuition & Costs', to: 'costs' },
          { label: 'FAQs', to: 'faq' }
        ]}
      />
      <div className="student-school-profile__content">
        <Outlet />
      </div>
    </div>
  );

  return null;
}