import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useFirebasePrograms } from '../../../context/FirebaseProgramDataContext';
import DataValueBox from '../../../components/StudentPrograms/DataValueBox';
import ShadowCard from '../../../components/ShadowCard';
import womanWaves from '../../../assets/student/images/woman-wave-illus.svg';
import '../SchoolProfile/SchoolProfileStyles.scss';

export default function StudentProgramProfileOverviewPage() {
  const { programId } = useParams();
  const { programs } = useFirebasePrograms();

  const data = useMemo(() => {
    if (programs && programId) return programs[programId];
    return null;
  }, [programs, programId]);

  if (data) return (
    <main className="school-profile-page">
      <section className="section">
        <div className="section__header">
          <h2 className="student-text-h2">Summary</h2>
        </div>
        <ShadowCard customClassNames="profile-illustration-box">
          <div>
            <h3 className="student-text-h3">{data.summaryHeadline}</h3>
            <p className="student-text-p">{data.summaryText}</p>
          </div>
          <img src={womanWaves} alt="" />
        </ShadowCard>
      </section>

      <section className="section">
        <div className="section__header">
          <h2 className="student-text-h2">Program Details</h2>
        </div>
        <div className="grid">
          <ShadowCard customClassNames="grid-1-2 padded">
            <DataValueBox label="Credits" value={data.credits} />
            <DataValueBox label="Program Start" value={data.programStartDate} valueSize="small" />
          </ShadowCard>
          <ShadowCard customClassNames="grid padded">
            <DataValueBox label="Degrees Offered" value={data.levels} valueSize="small" />
          </ShadowCard>
        </div>
      </section>
    </main>
  );

  return null;
}