import SectionHeader from '../../../components/SectionHeader';
import './AssessmentPage.scss';
import ShadowCard from '../../../components/ShadowCard';

export default function AssessmentPage() {
  const cards = [
    {
      title: 'TOEFL', 
      text: 'There are two versions of this English language proficiency test: iBT and Essentials. These assessments are accepted by more than 11,500 universities and other institutions in over 160 countries.'
    },
    {
      title: 'GRE',
      text: ' The GRE is the most widely accepted exam for graduate school admissions and can be submitted to almost any grad school program including business and law schools.... It consists of three sections divided into Analytical Writing, Verbal Reasoning and Quantitative Reasoning.'
    },
    {
      title: 'Dimensions',
      text: 'This is an on-demand tool that helps admissions professionals assess a candidate\'s relevant skills and dispositions. It mainly focuses on critical thinking, quantitative reasoning, communication and readiness skills'
    },
    {
      title: 'PSQ+',
      text: 'Assess your potential success in a particular program as well as employability after graduation on a personalized level by measuring your dynamic insights into the categories of leadership, innovative thinking, communication and perseverance.'
    }
  ]
  return (
    <div className='student-assessments'>
      <SectionHeader title='Assessments (Coming Soon)' />
      <main className='student-assessment__content'>
        <div className='placeholder-description'>
          <h1 className='student-text-h1'>Tap Into Your True Skills and Abilities by Taking These Added Assessments</h1>
          <p className='student-text-p-large'>Show graduate schools and potential employers the unique set of skills that set you apart by taking one or more of these specialized assessment exams. </p>
        </div>
        <div className='cards-container'>
          {cards.map((card, index) => {
            return <ShadowCard key={index} customClassNames={['assessment-card']}>
              <h1 className='student-text-title'>{card.title}</h1>
              <p className='inst-text-p'>{card.text}</p>
            </ShadowCard>
          })}
        </div>
      </main>
    </div>
  );
}