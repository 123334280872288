import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import ControlledSingleSelect from '../../components/ControlledSingleSelect';
import Field from '../../components/Field';
import { ethnicityOptions, ethnicGroupOptions, citizenshipStatusOptions } from '../../shared/formOptions';
import { countryOptions } from '../../shared/formOptions';
import './StudentApplicationForms.scss';

export default function StudentApplicationCitizenshipForm({ defaultValues, saveUpdates }) {
  const { control, register, handleSubmit, formState: { errors, isValid } } = useForm({ defaultValues, mode: 'all' });

  return (
    <form onSubmit={handleSubmit(saveUpdates)} className="student-app-form">
      <Field error={errors?.status}>
        <label htmlFor="status">Citizenship Status *</label><br />
        <ControlledSingleSelect
          control={control}
          defaultValue=""
          options={citizenshipStatusOptions.filter(o => o.value !== 'prefer-not')}
          name="status"
          rules={{ required: 'Required' }}
          customClassNames="student-app-select"
          placeholder="Select one"
        />
      </Field>

      <Field error={errors?.country}>
        <label htmlFor="country">Country of Citizenship *</label><br />
        <ControlledSingleSelect
          control={control}
          defaultValue=""
          options={countryOptions.map(c => ({ value: c, label: c }))}
          name="country"
          rules={{ required: 'Required' }}
          customClassNames="student-app-select"
          placeholder="Select one"
        />
      </Field>

      <hr />

      <fieldset>
        <legend>Ethnic Background</legend>

        <fieldset className="no-margin">
          <legend>What is your ethnicity?</legend>
          {ethnicityOptions.map(eth => (
            <label key={eth.value} className="radio">
              <input type="radio" {...register('ethnicity')} value={eth.value} />
              {eth.label}<br />
            </label>
          ))}
        </fieldset>

        <fieldset>
          <legend>Please select one or more ethnic group(s) you belong to:</legend>
          {ethnicGroupOptions.map(grp => (
            <label key={grp.value} className="checkbox">
              <input type="checkbox" {...register('ethnicGroups')} value={grp.value} />
              {grp.label}<br />
            </label>
          ))}
        </fieldset>
      </fieldset>

      <Field>
        <button type="submit" disabled={!isValid}>Continue</button>
      </Field>
    </form>
  )
}

StudentApplicationCitizenshipForm.propTypes = {
  defaultValues: PropTypes.shape({
    status: PropTypes.string,
    country: PropTypes.string,
    ethnicity: PropTypes.string,
    ethnicGroups: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.bool]),
  }),
  saveUpdates: PropTypes.func.isRequired,
};