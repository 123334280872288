import { Outlet } from 'react-router-dom';
import SectionHeader from '../../components/SectionHeader';
import TabNav from '../../components/StudentUI/TabNav';
import MessagingPopup from '../../components/StudentMessaging/MessagingPopup';

export default function StudentToDoLayout() {
  return (
    <div className="student-todo">
      <SectionHeader title="To-Do" />
      <TabNav
        tabs={[
          { label: 'To - Do', to: '' },
          { label: 'Planner', to: 'planner' },
        ]}
      />
      <div className="student-todo__content">
        <Outlet />
      </div>
      <MessagingPopup />
    </div>
  );
}