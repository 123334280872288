import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { studentPropTypes } from '../../shared/propTypes';
import {
  pronounOptions,
  ethnicityOptions,
  ethnicGroupOptions,
  countryOptions,
  stateOptions,
} from '../../shared/formOptions';
import ControlledMultiSelect from '../../components/ControlledMultiSelect';
import ControlledSingleSelect from '../../components/ControlledSingleSelect';
import Field from '../../components/Field';
import next from '../../assets/student/images/right-carat-black.svg';
import './StudentOnboardingForm.scss';
import SurveyNavButtonWrapper from '../../components/StudentSurveys/SurveyNavButtonWrapper';
export default function StudentOnboardingBasicInfoForm({ defaultValues, saveData }) {
  const { register, control, handleSubmit } = useForm({ defaultValues });

  return (
    <form onSubmit={handleSubmit(saveData)} className="student-onboarding-form">
      <Field>
        <label>
          My name is
          <input type="text" value={defaultValues.alias} disabled placeholder="Your Alias" />
        </label>
      </Field>

      <Field>
        <label htmlFor="pronouns">My pronouns are</label>&nbsp;
        <ControlledSingleSelect
          control={control}
          defaultValue=""
          name="pronouns"
          options={pronounOptions}
          customClassNames="student-survey-select"
          placeholder="Select one"
        />
      </Field>

      <Field>
        <label htmlFor="ethnicGroups">My ethnic background is</label>
        &nbsp;
        <ControlledMultiSelect
          control={control}
          defaultValue={[]}
          name="ethnicGroups"
          options={ethnicGroupOptions}
          singleOptionValue="Prefer Not To Respond"
          customClassNames="student-survey-select"
          placeholder="Select all that apply"
        />
      </Field>

      <fieldset>
        <legend className="sr-only">Ethnicity</legend>
        <div className="flex">
          {ethnicityOptions.map(eth => (
            <label key={eth.value} className="radio">
              <input type="radio" {...register('ethnicity')} value={eth.value} />
              <span>{eth.altLabel}</span>
            </label>
          ))}
        </div>
      </fieldset>

      <Field>
        <label htmlFor="citizenshipCountry">and I&apos;m a citizen of</label>
        <ControlledSingleSelect
          control={control}
          defaultValue=""
          options={countryOptions.map(c => ({ label: c, value: c }))}
          name="citizenshipCountry"
          customClassNames="student-survey-select"
          placeholder="Select one"
        />
      </Field>

      <Field>
        <label htmlFor="stateOfResidence">My state of residence is</label>&nbsp;
        <ControlledSingleSelect
          control={control}
          defaultValue=""
          options={stateOptions}
          name="stateOfResidence"
          customClassNames="student-survey-select"
          placeholder="Select one"
        />
      </Field>

      <Field>
        <div className="flex">
          <label className="checkbox">
            <input type="checkbox" {...register('isInternational')} />
            <span>I&apos;m an international student</span>
          </label>
          <label className="checkbox">
            <input type="checkbox" {...register('isFirstGeneration')} />
            <span>I&apos;m a first generation student</span>
          </label>
        </div>
      </Field>

      <Field>
        <SurveyNavButtonWrapper>
          <button disabled className="prev">
            <img src={next} alt="Save & Back" />
          </button>
          <button type="submit" className="next">
            <img src={next} alt="Save & Next" />
          </button>
        </SurveyNavButtonWrapper>
      </Field>
    </form>
  )
}

StudentOnboardingBasicInfoForm.propTypes = {
  defaultValues: PropTypes.shape({
    alias: studentPropTypes.alias,
    isInternational: studentPropTypes.isInternational,
    isFirstGeneration: studentPropTypes.isFirstGeneration,
    pronouns: studentPropTypes.pronouns,
    ethnicity: studentPropTypes.ethnicity,
    ethnicGroups: studentPropTypes.ethnicGroups,
    citizenshipCountry: studentPropTypes.citizenshipCountry,
    stateOfResidence: studentPropTypes.stateOfResidence,
  }),
  saveData: PropTypes.func.isRequired,
}