import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import FavoriteButtons from '../StudentFavorites/FavoriteButtons';
import ProgramPopupMenu from './ProgramPopupMenu';
import ShadowCard from '../ShadowCard';
import MostCompatible from '../StudentUI/MostCompatible';
import WebLink from './WebLink';
import Modal from '../Modal';
import linkedin from '../../assets/student/images/linkedin-dark-pink.svg';
import facebook from '../../assets/student/images/facebook-dark-pink.svg';
import twitter from '../../assets/student/images/twitter-dark-pink.svg';
import send from '../../assets/student/images/survey-handing-email.svg';
import { comingSoonShort } from '../../shared/appText';
import './ProgramMainImageCard.scss';
import { useFirebaseStudent } from '../../context/FirebaseStudentContext';
import { useFirebasePrograms } from '../../context/FirebaseProgramDataContext';
export default function ProgramMainImageCard({ programId, schoolId, imageUrl, schoolName, programTitle, website, recommend }) {
  const [showAppModal, setShowAppModal] = useState(false);
  const { student, addOrUpdateApplication } = useFirebaseStudent();
  const { programs } = useFirebasePrograms();

  const afterFavoriteUpdate = (isFavorite) => {
    if (isFavorite && !student?.applications?.[programId]) setShowAppModal(true);
  }

  const startApplication = (programId) => {
    const program = programs[programId];
    const newAppData = {
      programId: program.programId,
      programTitle: program.programTitle,
      schoolId: program.schoolId,
      schoolName: program.schoolName,
      deadline: '2022-06-01',
      alias: student?.alias,
    };
    addOrUpdateApplication('created', program.programId, newAppData, 'Application created!');
  }

  return (
    <ShadowCard customClassNames="program-main-image-card">
      <div className="program-main-image-card__image" style={{ backgroundImage: `url(/programs/${imageUrl})`}}>
        {recommend ? <MostCompatible /> : null}
      </div>
      <div className="program-main-image-card__content">
        <div className="text">
          {programTitle ? (
            <div className="program-main-image-card__actions">
              <FavoriteButtons programId={programId} onClickCallback={afterFavoriteUpdate} />
              <ProgramPopupMenu program={programs[programId]} />
            </div>
          ) : null}
          {programTitle ? <p className="student-text-h1">{programTitle}</p> : null}
          {schoolName ? (
            <>
              {schoolId ? (
                <Link to={`/students/explore/schools/${schoolId}`} className="student-text-h2">{schoolName}</Link>
              ) : (
                <p className="student-text-h2">{schoolName}</p>
              )}
            </>
          ) : null}

          <p><WebLink url={website} /></p>
        </div>
        <div className="social">
          <ul>
            <li><img src={facebook} alt="Facebook" /></li>
            <li><img src={linkedin} alt="LinkedIn" /></li>
            <li><img src={twitter} alt="Twitter" /></li>
            <li>{comingSoonShort}</li>
          </ul>
        </div>
      </div>
      <Modal
        closeModal={() => setShowAppModal(false)}
        title="Start Application?"
        isOpen={showAppModal}
      >
        <div className="student-create-app-modal">
          <h1 className="student-text-h1">
            Hi there! 👋<br/>
            We noticed you liked this program!
          </h1>
          <img src={send} alt="" width={246} />
          <p className="student-text-h2">Would you like to start an application for this program now?</p>
          <button onClick={() => {
            startApplication(programId);
            setShowAppModal(false);
          }} className="btn">Yes, let&apos;s start!</button>
          <button onClick={() => setShowAppModal(false)} className="cancel">No Thanks, Take Me Back</button>
        </div>
      </Modal>
    </ShadowCard>
  )
}

ProgramMainImageCard.propTypes = {
  programId: PropTypes.string,
  schoolId: PropTypes.string,
  imageUrl: PropTypes.string,
  schoolName: PropTypes.string,
  programTitle: PropTypes.string,
  website: PropTypes.string,
  recommend: PropTypes.bool,
}