export const formatDate = (dateString) => {
  return new Date(dateString).toLocaleDateString('en-US', { timeZone: 'UTC', month: 'short', day: 'numeric' });
}

export const formatAppDate = (dateString) => {
  return new Date(dateString).toLocaleDateString('en-US', { timeZone: 'UTC' });
}

export const formatProfileDate = (dateString) => {
  if (!dateString) return 'N/A';
  return new Date(dateString).toLocaleDateString('en-US', { timeZone: 'UTC', month: 'short', year: 'numeric' });
}