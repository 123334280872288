import { useState } from 'react';
import PropTypes from 'prop-types';
import SectionHeader from '../../../components/SectionHeader';
import ShadowCard from '../../../components/ShadowCard';
import carat from '../../../assets/student/images/down-carat-dark-pink.svg';
import calendar from '../../../assets/icons/calendar-dark-pink.svg';
import eye from '../../../assets/inst/images/eye.svg';
import heart from '../../../assets/inst/images/heart.svg';
import folder from '../../../assets/icons/folder.svg';
import send from '../../../assets/inst/images/send.svg';
import './PeerProgramsInsightsPage.scss';

const FavoritedLabel = ({ color }) => (
  <span className={`inst-favorited-by ${color}`}>
    Favorited by +10 Students
  </span>
);

FavoritedLabel.propTypes = {
  color: PropTypes.oneOf(['gray', 'green']).isRequired,
}

export default function InstitutionPeerProgramsInsightsPage() {
  const [filters, setFilters] = useState({
    topProgramStudents: 'Students You Favorited',
    topKeywordStudents: 'Students You Favorited',
    profilePerformance: 'Last 3 Months',
  });
  
  const [showDropdowns, setShowDropdowns] = useState({
    topProgramStudents: false,
    topKeywordStudents: false,
    profilePerformance: false,
  });
  
  const changeDropdowns = (change) => {
    setShowDropdowns({ ...Object.assign(showDropdowns, change) });
  }
  
  const changeFilterText = (change) => {
    setFilters({ ...Object.assign(filters, change) });
    
    const dropdown = Object.keys(change)[0];
    changeDropdowns({ [dropdown]: false });
  }

  return (
    <div className="inst-peer-programs">
      <SectionHeader title="Peer Programs" />

      <div className="inst-peer-programs__content">
        <p>Curious about what kind of attention your peer programs are getting? You can compare statistics here and make adjustments to your searches or program profile to achieve the results you want.</p>

        <section className="section">
          <div className="section__header">
            <h2>Profile Performance</h2>
            <div className="filters">
              <div className="time-filter-wrapper">
                <button onClick={() => changeDropdowns({ profilePerformance: !showDropdowns.profilePerformance })} className="inst-text-p"><img src={calendar} alt="" />{filters.profilePerformance}</button>
                {showDropdowns.profilePerformance && <div className="time-dropdown">
                  <ShadowCard>
                    <button onClick={() => changeFilterText({ profilePerformance: 'Last Month' })} className={`inst-text-p${filters.profilePerformance === 'Last Month' ? ' bold' : ''}`}>Last Month</button>
                    <button onClick={() => changeFilterText({ profilePerformance: 'Last 3 Months' })} className={`inst-text-p${filters.profilePerformance === 'Last 3 Months' ? ' bold' : ''}`}>Last 3 Months</button>
                    <button onClick={() => changeFilterText({ profilePerformance: 'Last 365 Days' })} className={`inst-text-p${filters.profilePerformance === 'Last 365 Days' ? ' bold' : ''}`}>Last 365 Days</button>
                  </ShadowCard>
                </div>}
              </div>
            </div>
          </div>
          <div className="stats-grid row1">
            <div className="header col1">Metrics</div>
            <div className="header">Your Program</div>
            <div className="header">Peer Programs</div>
          </div>
          <ShadowCard customClassNames="stats-grid">
            <div className="col1"><img src={eye} alt="" /> Profile Visits</div>
            <div>
              <span className="green-stat">821</span>
            </div>
            <div>
              <span className="green-stat">1346</span>
            </div>
          </ShadowCard>
          <ShadowCard customClassNames="stats-grid">
            <div className="col1"><img src={heart} alt="" /> Favorites</div>
            <div>
              <span className="green-stat">115</span>
            </div>
            <div>
              <span className="green-stat">224</span>
            </div>
          </ShadowCard>
          <ShadowCard customClassNames="stats-grid">
            <div className="col1"><img src={folder} alt="" /> Applications</div>
            <div>
              <span className="green-stat">33</span>
            </div>
            <div>
              <span className="green-stat">64</span>
            </div>
          </ShadowCard>
          <ShadowCard customClassNames="stats-grid">
            <div className="col1"><img src={send} alt="" /> Messages</div>
            <div>
              <span className="green-stat">59</span>
            </div>
            <div>
              <span className="green-stat">64</span>
            </div>
          </ShadowCard>
        </section>

        <section className="section">
          <div className="section__header">
            <h2>Top Filters Searched</h2>
          </div>
          <div className="stats-grid-2 row1">
            <div className="header col1">Filter</div>
            <div className="header">Preferred Date Range</div>
          </div>
          <ShadowCard customClassNames="stats-grid-2">
            <div className="col1">Location</div>
            <div>
              <span className="green-stat">Within 25 mi. radius</span>
            </div>
          </ShadowCard>
          <ShadowCard customClassNames="stats-grid-2">
            <div className="col1">Undergrad GPA</div>
            <div>
              <span className="green-stat">3.2-4.0</span>
            </div>
          </ShadowCard>
          <ShadowCard customClassNames="stats-grid-2">
            <div className="col1">Top 3 Majors Searched</div>
            <div>
              <ul>
                <li><span className="green-stat">Computer Science</span></li>
                <li><span className="green-stat">Biomedical Engineering</span></li>
                <li><span className="green-stat">STEM MBA</span></li>
              </ul>
            </div>
          </ShadowCard>
          <ShadowCard customClassNames="stats-grid-2">
            <div className="col1">GRE Score</div>
            <div>
              <ul>
                <li>
                  <span className="green-stat">
                    <span className="inst-text-label-small">Verbal Reasoning</span>
                    Range 140-150
                  </span>
                </li>
                <li>
                  <span className="green-stat">
                    <span className="inst-text-label-small">Quantitative Reasoning</span>
                    Range 140-150
                  </span>
                </li>
                <li>
                  <span className="green-stat">
                    <span className="inst-text-label-small">Analytical Writing</span>
                    Range 4-6
                  </span>
                </li>
              </ul>
            </div>
          </ShadowCard>
        </section>

        <section className="section">
          <div className="section__header">
            <h2>Top Keywords Searched by Students</h2>
            <div className="filters">
              <div className="student-filter-wrapper">
                <button onClick={() => changeDropdowns({ topKeywordStudents: !showDropdowns.topKeywordStudents })} className="inst-text-label">
                  {`Show: ${filters.topKeywordStudents}`}
                  <img className={`carat${showDropdowns.topKeywordStudents ? ' reverse' : ''}`} src={carat} alt="" />
                </button>
                {showDropdowns.topKeywordStudents && <div className="students-dropdown">
                  <ShadowCard>
                    <button onClick={() => changeFilterText({ topKeywordStudents: 'All Students' })} className={`inst-text-p${filters.topKeywordStudents === 'All Students' ? ' bold' : ''}`}>All Students</button>
                    <button onClick={() => changeFilterText({ topKeywordStudents: 'Applicants' })} className={`inst-text-p${filters.topKeywordStudents === 'Applicants' ? ' bold' : ''}`}>Applicants</button>
                    <button onClick={() => changeFilterText({ topKeywordStudents: 'Matches' })} className={`inst-text-p${filters.topKeywordStudents === 'Matches' ? ' bold' : ''}`}>Matches</button>
                    <button onClick={() => changeFilterText({ topKeywordStudents: 'Students You Favorited' })} className={`inst-text-p${filters.topKeywordStudents === 'Students You Favorited' ? ' bold' : ''}`}>Students You Favorited</button>
                    <button onClick={() => changeFilterText({ topKeywordStudents: 'Recommended Students' })} className={`inst-text-p${filters.topKeywordStudents === 'Recommended Students' ? ' bold' : ''}`}>Recommended Students</button>
                  </ShadowCard>
                </div>}
              </div>
            </div>
          </div>
          <div className="underline-grid-container">
            <div className="underline-grid row1">
              <div className="header col1">Keyword</div>
              <div className="header">Searches</div>
              <div className="header">Profile Visits</div>
            </div>
          </div>
          <ShadowCard customClassNames="underline-grid-container">
            <div className="underline-grid">
            
              <div className="col1">Diversity</div>
              <div>
                <span className="green-stat">10</span>
              </div>
              <div>
                <span className="green-stat">9</span>
              </div>
              
              <div className="col1">Application Fee Waiver</div>
              <div>
                <span className="green-stat">4</span>
              </div>
              <div>
                <span className="green-stat">2</span>
              </div>
              
              <div className="col1">GRE Waiver</div>
              <div>
                <span className="green-stat">3</span>
              </div>
              <div>
                <span className="green-stat">1</span>
              </div>
              
              <div className="col1">Scholarship</div>
              <div>
                <span className="green-stat">3</span>
              </div>
              <div>
                <span className="green-stat">1</span>
              </div>

              <div className="col1">TA</div>
              <div>
                <span className="green-stat">1</span>
              </div>
              <div>
                <span className="green-stat">1</span>
              </div>

              <div className="col1 last-row">RA</div>
              <div className="last-row">
                <span className="green-stat">1</span>
              </div>
              <div className="last-row">
                <span className="green-stat">0</span>
              </div>

            </div>
          </ShadowCard>
        </section>

        <section className="section">
          <div className="section__header">
            <h2>Top 10 Programs Favorited by Students</h2>
            <div className="filters">
              <div className="student-filter-wrapper">
                <button onClick={() => changeDropdowns({ topProgramStudents: !showDropdowns.topProgramStudents })} className="inst-text-label">
                  {`Show: ${filters.topProgramStudents}`}
                  <img className={`carat${showDropdowns.topProgramStudents ? ' reverse' : ''}`} src={carat} alt="" />
                </button>
                {showDropdowns.topProgramStudents && <div className="students-dropdown">
                  <ShadowCard>
                    <button onClick={() => changeFilterText({ topProgramStudents: 'All Students' })} className={`inst-text-p${filters.topProgramStudents === 'All Students' ? ' bold' : ''}`}>All Students</button>
                    <button onClick={() => changeFilterText({ topProgramStudents: 'Applicants' })} className={`inst-text-p${filters.topProgramStudents === 'Applicants' ? ' bold' : ''}`}>Applicants</button>
                    <button onClick={() => changeFilterText({ topProgramStudents: 'Matches' })} className={`inst-text-p${filters.topProgramStudents === 'Matches' ? ' bold' : ''}`}>Matches</button>
                    <button onClick={() => changeFilterText({ topProgramStudents: 'Students You Favorited' })} className={`inst-text-p${filters.topProgramStudents === 'Students You Favorited' ? ' bold' : ''}`}>Students You Favorited</button>
                    <button onClick={() => changeFilterText({ topProgramStudents: 'Recommended Students' })} className={`inst-text-p${filters.topProgramStudents === 'Recommended Students' ? ' bold' : ''}`}>Recommended Students</button>
                  </ShadowCard>
                </div>}
              </div>
            </div>
          </div>
          <div className="grid-2">
            <ShadowCard customClassNames="green-box">
              <FavoritedLabel color="green" />
              <h3>Computer Science</h3>
              <p>Georgia State University</p>
            </ShadowCard>
            <ShadowCard customClassNames="green-box">
              <FavoritedLabel color="green" />
              <h3>Master of Arts in Bioethics</h3>
              <p>Emory University</p>
            </ShadowCard>
            <ShadowCard customClassNames="gray-box">
              <FavoritedLabel color="gray" />
              <p className="coming-soon">Coming soon</p>
            </ShadowCard>
            <ShadowCard customClassNames="gray-box">
              <FavoritedLabel color="gray" />
              <p className="coming-soon">Coming soon</p>
            </ShadowCard>
          </div>
          <div className="grid-3">
            <ShadowCard customClassNames="gray-box">
              <FavoritedLabel color="gray" />
              <p className="coming-soon">Coming soon</p>
            </ShadowCard>
            <ShadowCard customClassNames="gray-box">
              <FavoritedLabel color="gray" />
              <p className="coming-soon">Coming soon</p>
            </ShadowCard>
            <ShadowCard customClassNames="gray-box">
              <FavoritedLabel color="gray" />
              <p className="coming-soon">Coming soon</p>
            </ShadowCard>

            <ShadowCard customClassNames="gray-box">
              <FavoritedLabel color="gray" />
              <p className="coming-soon">Coming soon</p>
            </ShadowCard>
            <ShadowCard customClassNames="gray-box">
              <FavoritedLabel color="gray" />
              <p className="coming-soon">Coming soon</p>
            </ShadowCard>
            <ShadowCard customClassNames="gray-box">
              <FavoritedLabel color="gray" />
              <p className="coming-soon">Coming soon</p>
            </ShadowCard>
          </div>
        </section>
      </div>
    </div>
  );
}