import PropTypes from 'prop-types';
import ProfileIconButtonBlock from './ProfileIconButtonBlock';
import './ProfileListItemBlock.scss';

export default function ProfileListItemBlock({ editItem, deleteItem, children }) {
  return (
    <div className="profile-list-item">
      <div className="profile-list-item__content">
        {children}
      </div>
      <div className="profile-list-item__buttons">
        <ProfileIconButtonBlock
          showPrivacy
          onEdit={editItem}
          onDelete={deleteItem}
        />
      </div>
    </div>
  );
}

ProfileListItemBlock.propTypes = {
  editItem: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};