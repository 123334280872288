import { Outlet } from 'react-router-dom';
import SectionHeader from '../../components/SectionHeader';

export default function InstitutionStudentSearchLayout() {
  return (
    <div>
      <SectionHeader title="Student Search" />
      <Outlet />
    </div>
  );
}