import { Link } from 'react-router-dom';
import { useFirebaseSchool } from '../../../context/FirebaseSchoolContext';
import StudentCard from '../../../components/InstitutionUI/StudentCard';
import ShadowCard from '../../../components/ShadowCard';
import SearchInput from '../../../components/InstitutionStudentSearch/SearchInput';
import Filters from '../../../components/InstitutionStudentSearch/Filters';
import studentSearch from '../../../assets/inst/images/studentsearch.svg';
import './StudentSearch.scss';

export default function InstitutionStudentSearchHomePage() {
  const { studentsList } = useFirebaseSchool();

  return (
    <main className="inst-search">
      <ShadowCard customClassNames="top-box">
        <img src={studentSearch} alt="" />
        <div>
          <p className="inst-text-h1">Instantly find potential student candidates.</p>
          <div className="search-box">
            <SearchInput placeholder="Coming Soon" />
            <Filters goTo="/institutions/recruiting/studentsearch/results" />
            <Link to="results" className="btn">Search</Link>
          </div>
        </div>
      </ShadowCard>
      <h2 className="inst-text-h2">Results</h2>
      <div className="grid">
        {studentsList.map(student => (
          <StudentCard student={student} key={student.studentId} />
        ))}
      </div>
    </main>
  );
}