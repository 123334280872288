import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useFirebaseStudent } from '../../../context/FirebaseStudentContext';
import { useFirebasePrograms } from '../../../context/FirebaseProgramDataContext';
import ProgramCard from '../../../components/StudentSearch/ProgramCard';
import BackLink from '../../../components/BackLink';
import placeholderImg from '../../../assets/student/images/favorites-empty.svg';
import down from '../../../assets/student/images/down-carat-dark-pink.svg';
import { comingSoonShort } from '../../../shared/appText';
import './StudentFavorites.scss';

export const EmptyPlaceholder = ({ text }) => (
  <div className="empty-placeholder">
    <p>{text}</p>
    <Link to="/students/explore">Explore Programs</Link>
    <img src={placeholderImg} alt="" />
  </div>
);

export const TopSection = () => (
  <>
    <div className="student-favorites__link">
      <BackLink to="/students/explore" label="Back to Explore" />
    </div>
    <div className="student-favorites__sort">
      <span className="coming-soon">{comingSoonShort}</span>
      <span className="sort">
        Sort by
        <img src={down} alt="" />
      </span>
    </div>
  </>
)

EmptyPlaceholder.propTypes = {
  text: PropTypes.string,
}

export default function StudentFavoriteProgramsPage() {
  const { favoriteProgramsList } = useFirebaseStudent();
  const { programs } = useFirebasePrograms();

  const studentFavePrograms = useMemo(() => {
    if (!favoriteProgramsList || !favoriteProgramsList.length || !programs) return [];
    return favoriteProgramsList.filter(p => p.byStudent && !p.byProgram).map(p => programs[p.programId]);
  }, [favoriteProgramsList, programs]);

  return (
    <main className="student-favorites">
      {studentFavePrograms.length ? (
        <>
          <TopSection />
          <ul>
            {studentFavePrograms.map(p => (
              <li key={p.programId}>
                <ProgramCard program={p} />
              </li>
            ))}
          </ul>
        </>
      ) : (
        <EmptyPlaceholder text="You haven&apos;t saved any programs yet! Make sure you set your preferences and let&apos;s find a program that best matches you!" />
      )}
    </main>
  );
}