import { comingSoonShort } from './appText';

export const testOptions = [
  { value: 'gre', label: 'GRE' },
  { value: 'toefl', label: `TOEFL ${comingSoonShort}`, isDisabled: true },
  { value: 'ielts', label: `iELTS ${comingSoonShort}`, isDisabled: true },
  { value: 'mcat', label: `MCAT ${comingSoonShort}`, isDisabled: true },
  { value: 'dat', label: `DAT ${comingSoonShort}`, isDisabled: true },
  { value: 'gmat', label: `GMAT ${comingSoonShort}`, isDisabled: true },
  { value: 'lsat', label: `LSAT ${comingSoonShort}`, isDisabled: true },
];

export const countryOptions = [
  'Afghanistan',
  'Aland Islands',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bonaire, Sint Eustatius and Saba',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cape Verde',
  'Cayman Islands',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands',
  'Colombia',
  'Comoros',
  'Congo',
  'Congo, Democratic Republic of the Congo',
  'Cook Islands',
  'Costa Rica',
  'Cote D\'Ivoire',
  'Croatia',
  'Cuba',
  'Curacao',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Falkland Islands (Malvinas)',
  'Faroe Islands',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard Island and Mcdonald Islands',
  'Holy See (Vatican City State)',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran, Islamic Republic of',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Korea, Democratic People\'s Republic of',
  'Korea, Republic of',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Lao People\'s Democratic Republic',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libyan Arab Jamahiriya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Macedonia, the Former Yugoslav Republic of',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia, Federated States of',
  'Moldova, Republic of',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'Netherlands Antilles',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestinian Territory, Occupied',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Reunion',
  'Romania',
  'Russian Federation',
  'Rwanda',
  'Saint Barthelemy',
  'Saint Helena',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Martin',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Serbia and Montenegro',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan, Province of China',
  'Tajikistan',
  'Tanzania, United Republic of',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'United States Minor Outlying Islands',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela',
  'Viet Nam',
  'Virgin Islands, British',
  'Virgin Islands, U.s',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe'
];

export const stateOptions = [
  {
    label: 'Alabama',
    value: 'AL'
  },
  {
    label: 'Alaska',
    value: 'AK'
  },
  {
    label: 'American Samoa',
    value: 'AS'
  },
  {
    label: 'Arizona',
    value: 'AZ'
  },
  {
    label: 'Arkansas',
    value: 'AR'
  },
  {
    label: 'California',
    value: 'CA'
  },
  {
    label: 'Colorado',
    value: 'CO'
  },
  {
    label: 'Connecticut',
    value: 'CT'
  },
  {
    label: 'Delaware',
    value: 'DE'
  },
  {
    label: 'District Of Columbia',
    value: 'DC'
  },
  {
    label: 'Federated States Of Micronesia',
    value: 'FM'
  },
  {
    label: 'Florida',
    value: 'FL'
  },
  {
    label: 'Georgia',
    value: 'GA'
  },
  {
    label: 'Guam',
    value: 'GU'
  },
  {
    label: 'Hawaii',
    value: 'HI'
  },
  {
    label: 'Idaho',
    value: 'ID'
  },
  {
    label: 'Illinois',
    value: 'IL'
  },
  {
    label: 'Indiana',
    value: 'IN'
  },
  {
    label: 'Iowa',
    value: 'IA'
  },
  {
    label: 'Kansas',
    value: 'KS'
  },
  {
    label: 'Kentucky',
    value: 'KY'
  },
  {
    label: 'Louisiana',
    value: 'LA'
  },
  {
    label: 'Maine',
    value: 'ME'
  },
  {
    label: 'Marshall Islands',
    value: 'MH'
  },
  {
    label: 'Maryland',
    value: 'MD'
  },
  {
    label: 'Massachusetts',
    value: 'MA'
  },
  {
    label: 'Michigan',
    value: 'MI'
  },
  {
    label: 'Minnesota',
    value: 'MN'
  },
  {
    label: 'Mississippi',
    value: 'MS'
  },
  {
    label: 'Missouri',
    value: 'MO'
  },
  {
    label: 'Montana',
    value: 'MT'
  },
  {
    label: 'Nebraska',
    value: 'NE'
  },
  {
    label: 'Nevada',
    value: 'NV'
  },
  {
    label: 'New Hampshire',
    value: 'NH'
  },
  {
    label: 'New Jersey',
    value: 'NJ'
  },
  {
    label: 'New Mexico',
    value: 'NM'
  },
  {
    label: 'New York',
    value: 'NY'
  },
  {
    label: 'North Carolina',
    value: 'NC'
  },
  {
    label: 'North Dakota',
    value: 'ND'
  },
  {
    label: 'Northern Mariana Islands',
    value: 'MP'
  },
  {
    label: 'Ohio',
    value: 'OH'
  },
  {
    label: 'Oklahoma',
    value: 'OK'
  },
  {
    label: 'Oregon',
    value: 'OR'
  },
  {
    label: 'Palau',
    value: 'PW'
  },
  {
    label: 'Pennsylvania',
    value: 'PA'
  },
  {
    label: 'Puerto Rico',
    value: 'PR'
  },
  {
    label: 'Rhode Island',
    value: 'RI'
  },
  {
    label: 'South Carolina',
    value: 'SC'
  },
  {
    label: 'South Dakota',
    value: 'SD'
  },
  {
    label: 'Tennessee',
    value: 'TN'
  },
  {
    label: 'Texas',
    value: 'TX'
  },
  {
    label: 'Utah',
    value: 'UT'
  },
  {
    label: 'Vermont',
    value: 'VT'
  },
  {
    label: 'Virgin Islands',
    value: 'VI'
  },
  {
    label: 'Virginia',
    value: 'VA'
  },
  {
    label: 'Washington',
    value: 'WA'
  },
  {
    label: 'West Virginia',
    value: 'WV'
  },
  {
    label: 'Wisconsin',
    value: 'WI'
  },
  {
    label: 'Wyoming',
    value: 'WY'
  }
]

export const pronounOptions = [
  { value: 'She/Her', label: 'She/Her' },
  { value: 'He/Him', label: 'He/Him' },
  { value: 'They/Them', label: 'They/Them' },
  { value: 'Prefer not to say', label: 'Prefer not to say' },
];

export const ethnicityOptions = [
  { value: 'Hispanic or Latino', label: 'Hispanic or Latino', altLabel: 'I\'m Hispanic or Latino' },
  { value: 'Not Hispanic or Latino', label: 'Not Hispanic or Latino', altLabel: 'I\'m NOT Hispanic or Latino' }
];

export const ethnicGroupOptions = [
  { value: 'African American or Black', label: 'African American or Black' },
  { value: 'Alaskan Native', label: 'Alaskan Native' },
  { value: 'American Indian', label: 'American Indian' },
  { value: 'Asian', label: 'Asian' },
  { value: 'Caucasian or White', label: 'Caucasian or White' },
  { value: 'Native Hawaiian', label: 'Native Hawaiian' },
  { value: 'Other', label: 'Other' },
  { value: 'Other Pacific Islander', label: 'Other Pacific Islander' },
  { value: 'Prefer Not To Respond', label: 'Prefer Not to Respond' },
];

export const citizenshipStatusOptions = [
  { value: 'Domestic', label: 'Domestic Student' },
  { value: 'International', label: 'International Student'},
  { value: 'Prefer not to say', label: 'Prefer not to say' },
];

export const degreeOptions = [
  { value: 'Master\'s Degree', label: 'Master\'s Degree' },
  { value: 'Master\'s Degree with Thesis', label: 'Master\'s Degree with Thesis' },
  { value: 'MBA', label: 'MBA' },
  { value: 'Doctorate', label: 'Doctorate' },
  { value: 'Certificate', label: 'Certificate' },
  { value: 'Still Deciding', label: 'Still Deciding' },
];

export const startDateOptions = [
  { value: 'Fall 2022', label: 'Fall 2022' },
  { value: 'Spring 2023', label: 'Spring 2023' },
  { value: 'Undecided', label: 'I haven\'t decided yet' },
];

export const programTypeOptions = [
  { value: 'In person', label: 'In person' },
  { value: 'Online', label: 'Online' },
  { value: 'Hybrid', label: 'Hybrid' },
  { value: 'No preference', label: 'I don\'t have a preference' },
];

export const schoolTypeOptions = [
  { value: 'Private', label: 'Private' },
  { value: 'Public', label: 'Public' },
  { value: 'No preference', label: 'I don\'t have a preference' },
];

export const campusSettingOptions = [
  { value: 'City', label: 'City' },
  { value: 'Suburban', label: 'Suburban' },
  { value: 'Small Town', label: 'Small Town' },
  { value: 'Rural', label: 'Rural' },
  { value: 'No preference', label: 'I don\'t have a preference' },
];

export const programSizeOptions = [
  { value: 'Small', label: 'Small Programs (<5,000 students)' },
  { value: 'Medium', label: 'Medium Programs (5,000 - 15,000 students)' },
  { value: 'Large', label: 'Large Programs (15,000+ students)' },
  { value: 'No preference', label: 'I don\'t have a preference' }
];

export const cipFieldOptions = [
  {
    'value': 1,
    'label': 'Agriculture, General'
  },
  {
    'value': 1.01,
    'label': 'Agricultural Business and Management'
  },
  {
    'value': 1.02,
    'label': 'Agricultural Mechanization'
  },
  {
    'value': 1.03,
    'label': 'Agricultural Production Operations'
  },
  {
    'value': 1.031,
    'label': 'Apiculture'
  },
  {
    'value': 1.04,
    'label': 'Agricultural and Food Products Processing'
  },
  {
    'value': 1.05,
    'label': 'Agricultural and Domestic Animal Services'
  },
  {
    'value': 1.06,
    'label': 'Applied Horticulture and Horticultural Business Services'
  },
  {
    'value': 1.061,
    'label': 'Urban and Community Horticulture'
  },
  {
    'value': 1.07,
    'label': 'International Agriculture'
  },
  {
    'value': 1.08,
    'label': 'Agricultural Public Services'
  },
  {
    'value': 1.09,
    'label': 'Animal Sciences'
  },
  {
    'value': 1.1,
    'label': 'Food Science and Technology'
  },
  {
    'value': 1.11,
    'label': 'Plant Sciences'
  },
  {
    'value': 1.12,
    'label': 'Soil Sciences'
  },
  {
    'value': 1.13,
    'label': 'Agriculture/Veterinary Preparatory Programs'
  },
  {
    'value': 1.8,
    'label': 'Veterinary Medicine'
  },
  {
    'value': 1.81,
    'label': 'Veterinary Biomedical and Clinical Sciences'
  },
  {
    'value': 1.811,
    'label': 'Veterinary Preventive Medicine, Epidemiology, and Public Health'
  },
  {
    'value': 1.82,
    'label': 'Veterinary Administrative Services'
  },
  {
    'value': 1.83,
    'label': 'Veterinary/Animal Health Technologies/Technicians'
  },
  {
    'value': 1.99,
    'label': 'Agricultural/Animal/Plant/Veterinary Science and Related Fields, Other'
  },
  {
    'value': 3.01,
    'label': 'Natural Resources Conservation and Research'
  },
  {
    'value': 3.02,
    'label': 'Environmental/Natural Resources Management and Policy'
  },
  {
    'value': 3.021,
    'label': 'Bioenergy'
  },
  {
    'value': 3.03,
    'label': 'Fishing and Fisheries Sciences and Management'
  },
  {
    'value': 3.05,
    'label': 'Forestry'
  },
  {
    'value': 3.051,
    'label': 'Forest Resources Production and Management'
  },
  {
    'value': 3.06,
    'label': 'Wildlife and Wildlands Science and Management'
  },
  {
    'value': 3.99,
    'label': 'Natural Resources and Conservation, Other'
  },
  {
    'value': 4.02,
    'label': 'Architecture'
  },
  {
    'value': 4.02,
    'label': 'Pre-Architecture Studies'
  },
  {
    'value': 4.03,
    'label': 'City/Urban, Community, and Regional Planning'
  },
  {
    'value': 4.04,
    'label': 'Environmental Design'
  },
  {
    'value': 4.05,
    'label': 'Interior Architecture'
  },
  {
    'value': 4.06,
    'label': 'Landscape Architecture'
  },
  {
    'value': 4.08,
    'label': 'Architectural History, Criticism, and Conservation'
  },
  {
    'value': 4.09,
    'label': 'Architectural Sciences and Technology'
  },
  {
    'value': 4.1,
    'label': 'Real Estate Development'
  },
  {
    'value': 4.99,
    'label': 'Architecture and Related Services, Other'
  },
  {
    'value': 5.01,
    'label': 'Area Studies'
  },
  {
    'value': 5.011,
    'label': 'Russian Studies'
  },
  {
    'value': 5.012,
    'label': 'Ural-Altaic and Central Asian Studies'
  },
  {
    'value': 5.013,
    'label': 'Spanish and Iberian Studies'
  },
  {
    'value': 5.02,
    'label': 'Ethnic, Cultural Minority, Gender, and Group Studies'
  },
  {
    'value': 5.02,
    'label': 'Ethnic Studies'
  },
  {
    'value': 5.021,
    'label': 'Disability Studies'
  },
  {
    'value': 5.99,
    'label': 'Area, Ethnic, Cultural, Gender, and Group Studies, Other'
  },
  {
    'value': 9.01,
    'label': 'Communication and Media Studies'
  },
  {
    'value': 9.01,
    'label': 'Communication, General'
  },
  {
    'value': 9.04,
    'label': 'Journalism'
  },
  {
    'value': 9.07,
    'label': 'Radio, Television, and Digital Communication'
  },
  {
    'value': 9.09,
    'label': 'Public Relations, Advertising, and Applied Communication'
  },
  {
    'value': 9.09,
    'label': 'Public Relations, Advertising, and Applied Communication'
  },
  {
    'value': 9.1,
    'label': 'Publishing'
  },
  {
    'value': 9.99,
    'label': 'Communication, Journalism, and Related Programs, Other'
  },
  {
    'value': 10.01,
    'label': 'Communications Technologies/Technicians'
  },
  {
    'value': 10.02,
    'label': 'Audiovisual Communications Technologies/Technicians'
  },
  {
    'value': 10.03,
    'label': 'Graphic Communications'
  },
  {
    'value': 10.99,
    'label': 'Communications Technologies/Technicians and Support Services, Other'
  },
  {
    'value': 11,
    'label': 'COMPUTER AND INFORMATION SCIENCES AND SUPPORT SERVICES'
  },
  {
    'value': 11.01,
    'label': 'Computer and Information Sciences, General'
  },
  {
    'value': 11.02,
    'label': 'Computer Programming'
  },
  {
    'value': 11.03,
    'label': 'Data Processing'
  },
  {
    'value': 11.04,
    'label': 'Information Science/Studies'
  },
  {
    'value': 11.05,
    'label': 'Computer Systems Analysis'
  },
  {
    'value': 11.06,
    'label': 'Data Entry/Microcomputer Applications'
  },
  {
    'value': 11.07,
    'label': 'Computer Science'
  },
  {
    'value': 11.08,
    'label': 'Computer Software and Media Applications'
  },
  {
    'value': 11.09,
    'label': 'Computer Systems Networking and Telecommunications'
  },
  {
    'value': 11.1,
    'label': 'Computer/Information Technology Administration and Management'
  },
  {
    'value': 11.99,
    'label': 'Computer and Information Sciences and Support Services, Other'
  },
  {
    'value': 12,
    'label': 'CULINARY, ENTERTAINMENT, AND PERSONAL SERVICES'
  },
  {
    'value': 12.03,
    'label': 'Funeral Service and Mortuary Science'
  },
  {
    'value': 12.04,
    'label': 'Cosmetology and Related Personal Grooming Services'
  },
  {
    'value': 12.05,
    'label': 'Culinary Arts and Related Services'
  },
  {
    'value': 12.05,
    'label': 'Cooking and Related Culinary Arts, General'
  },
  {
    'value': 12.06,
    'label': 'Casino Operations and Services'
  },
  {
    'value': 12.99,
    'label': 'Culinary, Entertainment, and Personal Services, Other'
  },
  {
    'value': 13.01,
    'label': 'Education, General'
  },
  {
    'value': 13.02,
    'label': 'Bilingual, Multilingual, and Multicultural Education'
  },
  {
    'value': 13.03,
    'label': 'Curriculum and Instruction'
  },
  {
    'value': 13.04,
    'label': 'Educational Administration and Supervision'
  },
  {
    'value': 13.05,
    'label': 'Educational/Instructional Media Design'
  },
  {
    'value': 13.06,
    'label': 'Educational Assessment, Evaluation, and Research'
  },
  {
    'value': 13.07,
    'label': 'International and Comparative Education'
  },
  {
    'value': 13.09,
    'label': 'Social and Philosophical Foundations of Education'
  },
  {
    'value': 13.1,
    'label': 'Special Education and Teaching'
  },
  {
    'value': 13.11,
    'label': 'Student Counseling and Personnel Services'
  },
  {
    'value': 13.12,
    'label': 'Teacher Education and Professional Development, Specific Levels and Methods'
  },
  {
    'value': 13.13,
    'label': 'Teacher Education and Professional Development, Specific Subject Areas'
  },
  {
    'value': 13.14,
    'label': 'Teaching English or French as a Second or Foreign Language'
  },
  {
    'value': 13.15,
    'label': 'Teaching Assistants/Aides'
  },
  {
    'value': 13.99,
    'label': 'Education, Other'
  },
  {
    'value': 14.01,
    'label': 'Engineering, General'
  },
  {
    'value': 14.02,
    'label': 'Aerospace, Aeronautical, and Astronautical/Space Engineering'
  },
  {
    'value': 14.03,
    'label': 'Agricultural Engineering'
  },
  {
    'value': 14.04,
    'label': 'Architectural Engineering'
  },
  {
    'value': 14.05,
    'label': 'Biomedical/Medical Engineering'
  },
  {
    'value': 14.06,
    'label': 'Ceramic Sciences and Engineering'
  },
  {
    'value': 14.07,
    'label': 'Chemical Engineering'
  },
  {
    'value': 14.08,
    'label': 'Civil Engineering'
  },
  {
    'value': 14.09,
    'label': 'Computer Engineering'
  },
  {
    'value': 14.1,
    'label': 'Electrical, Electronics, and Communications Engineering'
  },
  {
    'value': 14.11,
    'label': 'Engineering Mechanics'
  },
  {
    'value': 14.12,
    'label': 'Engineering Physics'
  },
  {
    'value': 14.13,
    'label': 'Engineering Science'
  },
  {
    'value': 14.14,
    'label': 'Environmental/Environmental Health Engineering'
  },
  {
    'value': 14.18,
    'label': 'Materials Engineering'
  },
  {
    'value': 14.19,
    'label': 'Mechanical Engineering'
  },
  {
    'value': 14.2,
    'label': 'Metallurgical Engineering'
  },
  {
    'value': 14.21,
    'label': 'Mining and Mineral Engineering'
  },
  {
    'value': 14.22,
    'label': 'Naval Architecture and Marine Engineering'
  },
  {
    'value': 14.23,
    'label': 'Nuclear Engineering'
  },
  {
    'value': 14.24,
    'label': 'Ocean Engineering'
  },
  {
    'value': 14.25,
    'label': 'Petroleum Engineering'
  },
  {
    'value': 14.27,
    'label': 'Systems Engineering'
  },
  {
    'value': 14.28,
    'label': 'Textile Sciences and Engineering'
  },
  {
    'value': 14.32,
    'label': 'Polymer/Plastics Engineering'
  },
  {
    'value': 14.33,
    'label': 'Construction Engineering'
  },
  {
    'value': 14.34,
    'label': 'Forest Engineering'
  },
  {
    'value': 14.35,
    'label': 'Industrial Engineering'
  },
  {
    'value': 14.36,
    'label': 'Manufacturing Engineering'
  },
  {
    'value': 14.37,
    'label': 'Operations Research'
  },
  {
    'value': 14.38,
    'label': 'Surveying Engineering'
  },
  {
    'value': 14.39,
    'label': 'Geological/Geophysical Engineering'
  },
  {
    'value': 14.4,
    'label': 'Paper Science and Engineering'
  },
  {
    'value': 14.41,
    'label': 'Electromechanical Engineering'
  },
  {
    'value': 14.42,
    'label': 'Mechatronics, Robotics, and Automation Engineering'
  },
  {
    'value': 14.43,
    'label': 'Biochemical Engineering'
  },
  {
    'value': 14.44,
    'label': 'Engineering Chemistry'
  },
  {
    'value': 14.45,
    'label': 'Biological/Biosystems Engineering'
  },
  {
    'value': 14.47,
    'label': 'Electrical and Computer Engineering'
  },
  {
    'value': 14.48,
    'label': 'Energy Systems Engineering'
  },
  {
    'value': 14.99,
    'label': 'Engineering, Other'
  },
  {
    'value': 16.01,
    'label': 'Linguistic, Comparative, and Related Language Studies and Services'
  },
  {
    'value': 16.02,
    'label': 'African Languages, Literatures, and Linguistics'
  },
  {
    'value': 16.03,
    'label': 'East Asian Languages, Literatures, and Linguistics'
  },
  {
    'value': 16.03,
    'label': 'East Asian Languages, Literatures, and Linguistics, General'
  },
  {
    'value': 16.04,
    'label': 'Slavic, Baltic and Albanian Languages, Literatures, and Linguistics'
  },
  {
    'value': 16.04,
    'label': 'Slavic Languages, Literatures, and Linguistics, General'
  },
  {
    'value': 16.05,
    'label': 'Germanic Languages, Literatures, and Linguistics'
  },
  {
    'value': 16.05,
    'label': 'Germanic Languages, Literatures, and Linguistics, General'
  },
  {
    'value': 16.06,
    'label': 'Modern Greek Language and Literature'
  },
  {
    'value': 16.07,
    'label': 'South Asian Languages, Literatures, and Linguistics'
  },
  {
    'value': 16.07,
    'label': 'South Asian Languages, Literatures, and Linguistics, General'
  },
  {
    'value': 16.08,
    'label': 'Iranian/Persian Languages, Literatures, and Linguistics'
  },
  {
    'value': 16.09,
    'label': 'Romance Languages, Literatures, and Linguistics'
  },
  {
    'value': 16.09,
    'label': 'Romance Languages, Literatures, and Linguistics, General'
  },
  {
    'value': 16.1,
    'label': 'American Indian/Native American Languages, Literatures, and Linguistics'
  },
  {
    'value': 16.11,
    'label': 'Middle/Near Eastern and Semitic Languages, Literatures, and Linguistics'
  },
  {
    'value': 16.11,
    'label': 'Middle/Near Eastern and Semitic Languages, Literatures, and Linguistics, General'
  },
  {
    'value': 16.12,
    'label': 'Classics and Classical Languages, Literatures, and Linguistics'
  },
  {
    'value': 16.12,
    'label': 'Classics and Classical Languages, Literatures, and Linguistics, General'
  },
  {
    'value': 16.13,
    'label': 'Celtic Languages, Literatures, and Linguistics'
  },
  {
    'value': 16.14,
    'label': 'Southeast Asian and Australasian/Pacific Languages, Literatures, and Linguistics'
  },
  {
    'value': 16.14,
    'label': 'Southeast Asian Languages, Literatures, and Linguistics, General'
  },
  {
    'value': 16.15,
    'label': 'Turkic, Uralic-Altaic, Caucasian, and Central Asian Languages, Literatures, and Linguistics'
  },
  {
    'value': 16.16,
    'label': 'American Sign Language'
  },
  {
    'value': 16.17,
    'label': 'Second Language Learning'
  },
  {
    'value': 16.18,
    'label': 'Armenian Languages, Literatures, and Linguistics'
  },
  {
    'value': 16.99,
    'label': 'Foreign Languages, Literatures, and Linguistics, Other'
  },
  {
    'value': 19,
    'label': 'Work and Family Studies'
  },
  {
    'value': 19.01,
    'label': 'Family and Consumer Sciences/Human Sciences, General'
  },
  {
    'value': 19.02,
    'label': 'Family and Consumer Sciences/Human Sciences Business Services'
  },
  {
    'value': 19.04,
    'label': 'Family and Consumer Economics and Related Studies'
  },
  {
    'value': 19.05,
    'label': 'Foods, Nutrition, and Related Services'
  },
  {
    'value': 19.06,
    'label': 'Housing and Human Environments'
  },
  {
    'value': 19.07,
    'label': 'Human Development, Family Studies, and Related Services'
  },
  {
    'value': 19.09,
    'label': 'Apparel and Textiles'
  },
  {
    'value': 19.1,
    'label': 'Work and Family Studies'
  },
  {
    'value': 19.99,
    'label': 'Family and Consumer Sciences/Human Sciences, Other'
  },
  {
    'value': 22,
    'label': 'Non-Professional Legal Studies'
  },
  {
    'value': 22,
    'label': 'Legal Studies'
  },
  {
    'value': 22.01,
    'label': 'Law'
  },
  {
    'value': 22.02,
    'label': 'Legal Research and Advanced Professional Studies'
  },
  {
    'value': 22.03,
    'label': 'Legal Support Services'
  },
  {
    'value': 22.99,
    'label': 'Legal Professions and Studies, Other'
  },
  {
    'value': 23.01,
    'label': 'English Language and Literature, General'
  },
  {
    'value': 23.13,
    'label': 'Rhetoric and Composition/Writing Studies'
  },
  {
    'value': 23.14,
    'label': 'Literature'
  },
  {
    'value': 23.99,
    'label': 'English Language and Literature/Letters, Other'
  },
  {
    'value': 24,
    'label': 'LIBERAL ARTS AND SCIENCES, GENERAL STUDIES AND HUMANITIES'
  },
  {
    'value': 24.01,
    'label': 'Liberal Arts and Sciences, General Studies and Humanities'
  },
  {
    'value': 25.01,
    'label': 'Library Science and Administration'
  },
  {
    'value': 25.03,
    'label': 'Library and Archives Assisting'
  },
  {
    'value': 25.99,
    'label': 'Library Science, Other'
  },
  {
    'value': 26.01,
    'label': 'Biology, General'
  },
  {
    'value': 26.02,
    'label': 'Biochemistry, Biophysics and Molecular Biology'
  },
  {
    'value': 26.03,
    'label': 'Botany/Plant Biology'
  },
  {
    'value': 26.04,
    'label': 'Cell/Cellular Biology and Anatomical Sciences'
  },
  {
    'value': 26.05,
    'label': 'Microbiological Sciences and Immunology'
  },
  {
    'value': 26.07,
    'label': 'Zoology/Animal Biology'
  },
  {
    'value': 26.08,
    'label': 'Genetics'
  },
  {
    'value': 26.09,
    'label': 'Physiology, Pathology and Related Sciences'
  },
  {
    'value': 26.1,
    'label': 'Pharmacology and Toxicology'
  },
  {
    'value': 26.11,
    'label': 'Biomathematics, Bioinformatics, and Computational Biology'
  },
  {
    'value': 26.12,
    'label': 'Biotechnology'
  },
  {
    'value': 26.13,
    'label': 'Ecology, Evolution, Systematics, and Population Biology'
  },
  {
    'value': 26.14,
    'label': 'Molecular Medicine'
  },
  {
    'value': 26.15,
    'label': 'Neurobiology and Neurosciences'
  },
  {
    'value': 26.99,
    'label': 'Biological and Biomedical Sciences, Other'
  },
  {
    'value': 27.01,
    'label': 'Mathematics'
  },
  {
    'value': 27.03,
    'label': 'Applied Mathematics'
  },
  {
    'value': 27.05,
    'label': 'Statistics'
  },
  {
    'value': 27.06,
    'label': 'Applied Statistics'
  },
  {
    'value': 27.99,
    'label': 'Mathematics and Statistics, Other'
  },
  {
    'value': 30,
    'label': 'Multi-/Interdisciplinary Studies, General'
  },
  {
    'value': 30,
    'label': 'Multi-/Interdisciplinary Studies, General'
  },
  {
    'value': 30.01,
    'label': 'Biological and Physical Sciences'
  },
  {
    'value': 30.05,
    'label': 'Peace Studies and Conflict Resolution'
  },
  {
    'value': 30.06,
    'label': 'Systems Science and Theory'
  },
  {
    'value': 30.08,
    'label': 'Mathematics and Computer Science'
  },
  {
    'value': 30.1,
    'label': 'Biopsychology'
  },
  {
    'value': 30.11,
    'label': 'Gerontology'
  },
  {
    'value': 30.12,
    'label': 'Historic Preservation and Conservation'
  },
  {
    'value': 30.13,
    'label': 'Medieval and Renaissance Studies'
  },
  {
    'value': 30.14,
    'label': 'Museology/Museum Studies'
  },
  {
    'value': 30.15,
    'label': 'Science, Technology and Society'
  },
  {
    'value': 30.16,
    'label': 'Accounting and Computer Science'
  },
  {
    'value': 30.17,
    'label': 'Behavioral Sciences'
  },
  {
    'value': 30.18,
    'label': 'Natural Sciences'
  },
  {
    'value': 30.19,
    'label': 'Nutrition Sciences'
  },
  {
    'value': 30.2,
    'label': 'International/Globalization Studies'
  },
  {
    'value': 30.21,
    'label': 'Holocaust and Related Studies'
  },
  {
    'value': 30.22,
    'label': 'Classical and Ancient Studies'
  },
  {
    'value': 30.23,
    'label': 'Intercultural/Multicultural and Diversity Studies'
  },
  {
    'value': 30.25,
    'label': 'Cognitive Science'
  },
  {
    'value': 30.26,
    'label': 'Cultural Studies/Critical Theory and Analysis'
  },
  {
    'value': 30.27,
    'label': 'Human Biology'
  },
  {
    'value': 30.28,
    'label': 'Dispute Resolution'
  },
  {
    'value': 30.29,
    'label': 'Maritime Studies'
  },
  {
    'value': 30.3,
    'label': 'Computational Science'
  },
  {
    'value': 30.31,
    'label': 'Human Computer Interaction'
  },
  {
    'value': 30.32,
    'label': 'Marine Sciences'
  },
  {
    'value': 30.33,
    'label': 'Sustainability Studies'
  },
  {
    'value': 30.34,
    'label': 'Anthrozoology'
  },
  {
    'value': 30.35,
    'label': 'Climate Science'
  },
  {
    'value': 30.36,
    'label': 'Cultural Studies and Comparative Literature'
  },
  {
    'value': 30.37,
    'label': 'Design for Human Health'
  },
  {
    'value': 30.38,
    'label': 'Earth Systems Science'
  },
  {
    'value': 30.39,
    'label': 'Economics and Computer Science'
  },
  {
    'value': 30.4,
    'label': 'Economics and Foreign Language/Literature'
  },
  {
    'value': 30.41,
    'label': 'Environmental Geosciences'
  },
  {
    'value': 30.42,
    'label': 'Geoarcheaology'
  },
  {
    'value': 30.43,
    'label': 'Geobiology'
  },
  {
    'value': 30.44,
    'label': 'Geography and Environmental Studies'
  },
  {
    'value': 30.45,
    'label': 'History and Language/Literature'
  },
  {
    'value': 30.46,
    'label': 'History and Political Science'
  },
  {
    'value': 30.47,
    'label': 'Linguistics and Anthropology'
  },
  {
    'value': 30.48,
    'label': 'Linguistics and Computer Science'
  },
  {
    'value': 30.49,
    'label': 'Mathematical Economics'
  },
  {
    'value': 30.5,
    'label': 'Mathematics and Atmospheric/Oceanic Science'
  },
  {
    'value': 30.51,
    'label': 'Philosophy, Politics, and Economics'
  },
  {
    'value': 30.52,
    'label': 'Digital Humanities and Textual Studies'
  },
  {
    'value': 30.53,
    'label': 'Thanatology'
  },
  {
    'value': 30.7,
    'label': 'Data Science'
  },
  {
    'value': 30.71,
    'label': 'Data Analytics'
  },
  {
    'value': 30.99,
    'label': 'Multi/Interdisciplinary Studies, Other'
  },
  {
    'value': 31.01,
    'label': 'Parks, Recreation, and Leisure Studies'
  },
  {
    'value': 31.03,
    'label': 'Parks, Recreation, and Leisure Facilities Management'
  },
  {
    'value': 31.05,
    'label': 'Sports, Kinesiology, and Physical Education/Fitness'
  },
  {
    'value': 31.06,
    'label': 'Outdoor Education'
  },
  {
    'value': 31.99,
    'label': 'Parks, Recreation, Leisure, Fitness, and Kinesiology, Other'
  },
  {
    'value': 34.01,
    'label': 'Health-Related Knowledge and Skills'
  },
  {
    'value': 35.01,
    'label': 'Interpersonal and Social Skills'
  },
  {
    'value': 36.01,
    'label': 'Leisure and Recreational Activities'
  },
  {
    'value': 36.02,
    'label': 'Noncommercial Vehicle Operation'
  },
  {
    'value': 37.01,
    'label': 'Personal Awareness and Self-Improvement'
  },
  {
    'value': 38,
    'label': 'Philosophy and Religious Studies, General'
  },
  {
    'value': 38.01,
    'label': 'Philosophy'
  },
  {
    'value': 38.02,
    'label': 'Religion/Religious Studies'
  },
  {
    'value': 38.99,
    'label': 'Philosophy and Religious Studies, Other'
  },
  {
    'value': 39,
    'label': 'THEOLOGY AND RELIGIOUS VOCATIONS'
  },
  {
    'value': 39.02,
    'label': 'Bible/Biblical Studies'
  },
  {
    'value': 39.03,
    'label': 'Missions/Missionary Studies and Missiology'
  },
  {
    'value': 39.04,
    'label': 'Religious Education'
  },
  {
    'value': 39.05,
    'label': 'Religious Music and Worship'
  },
  {
    'value': 39.06,
    'label': 'Theological and Ministerial Studies'
  },
  {
    'value': 39.07,
    'label': 'Pastoral Counseling and Specialized Ministries'
  },
  {
    'value': 39.08,
    'label': 'Religious Institution Administration and Law'
  },
  {
    'value': 39.99,
    'label': 'Theology and Religious Vocations, Other'
  },
  {
    'value': 40.01,
    'label': 'Physical Sciences, General'
  },
  {
    'value': 40.02,
    'label': 'Astronomy and Astrophysics'
  },
  {
    'value': 40.04,
    'label': 'Atmospheric Sciences and Meteorology'
  },
  {
    'value': 40.05,
    'label': 'Chemistry'
  },
  {
    'value': 40.06,
    'label': 'Geological and Earth Sciences/Geosciences'
  },
  {
    'value': 40.08,
    'label': 'Physics'
  },
  {
    'value': 40.1,
    'label': 'Materials Sciences'
  },
  {
    'value': 40.11,
    'label': 'Physics and Astronomy'
  },
  {
    'value': 40.99,
    'label': 'Physical Sciences, Other'
  },
  {
    'value': 41,
    'label': 'Science Technologies/Technicians, General'
  },
  {
    'value': 41,
    'label': 'Science Technologies/Technicians, General'
  },
  {
    'value': 41.01,
    'label': 'Biology/Biotechnology Technologies/Technicians'
  },
  {
    'value': 41.02,
    'label': 'Nuclear and Industrial Radiologic Technologies/Technicians'
  },
  {
    'value': 41.03,
    'label': 'Physical Science Technologies/Technicians'
  },
  {
    'value': 41.99,
    'label': 'Science Technologies/Technicians, Other'
  },
  {
    'value': 42.01,
    'label': 'Psychology, General'
  },
  {
    'value': 42.27,
    'label': 'Research and Experimental Psychology'
  },
  {
    'value': 42.28,
    'label': 'Clinical, Counseling and Applied Psychology'
  },
  {
    'value': 42.99,
    'label': 'Psychology, Other'
  },
  {
    'value': 44,
    'label': 'Human Services, General'
  },
  {
    'value': 44,
    'label': 'Human Services, General'
  },
  {
    'value': 44.02,
    'label': 'Community Organization and Advocacy'
  },
  {
    'value': 44.04,
    'label': 'Public Administration'
  },
  {
    'value': 44.05,
    'label': 'Public Policy Analysis'
  },
  {
    'value': 44.07,
    'label': 'Social Work'
  },
  {
    'value': 44.99,
    'label': 'Public Administration and Social Service Professions, Other'
  },
  {
    'value': 45.01,
    'label': 'Social Sciences, General'
  },
  {
    'value': 45.02,
    'label': 'Anthropology'
  },
  {
    'value': 45.03,
    'label': 'Archeology'
  },
  {
    'value': 45.04,
    'label': 'Criminology'
  },
  {
    'value': 45.05,
    'label': 'Demography'
  },
  {
    'value': 45.06,
    'label': 'Economics'
  },
  {
    'value': 45.07,
    'label': 'Geography and Cartography'
  },
  {
    'value': 45.09,
    'label': 'International Relations and National Security Studies'
  },
  {
    'value': 45.1,
    'label': 'Political Science and Government'
  },
  {
    'value': 45.11,
    'label': 'Sociology'
  },
  {
    'value': 45.12,
    'label': 'Urban Studies/Affairs'
  },
  {
    'value': 45.13,
    'label': 'Sociology and Anthropology'
  },
  {
    'value': 45.14,
    'label': 'Rural Sociology'
  },
  {
    'value': 45.15,
    'label': 'Geography and Anthropology'
  },
  {
    'value': 45.99,
    'label': 'Social Sciences, Other'
  },
  {
    'value': 50.01,
    'label': 'Visual and Performing Arts, General'
  },
  {
    'value': 50.02,
    'label': 'Crafts/Craft Design, Folk Art and Artisanry'
  },
  {
    'value': 50.03,
    'label': 'Dance'
  },
  {
    'value': 50.04,
    'label': 'Design and Applied Arts'
  },
  {
    'value': 50.05,
    'label': 'Drama/Theatre Arts and Stagecraft'
  },
  {
    'value': 50.06,
    'label': 'Film/Video and Photographic Arts'
  },
  {
    'value': 50.07,
    'label': 'Fine and Studio Arts'
  },
  {
    'value': 50.09,
    'label': 'Music'
  },
  {
    'value': 50.1,
    'label': 'Arts, Entertainment, and Media Management'
  },
  {
    'value': 50.11,
    'label': 'Community/Environmental/Socially-Engaged Art'
  },
  {
    'value': 50.99,
    'label': 'Visual and Performing Arts, Other'
  },
  {
    'value': 51,
    'label': 'Health Services/Allied Health/Health Sciences, General'
  },
  {
    'value': 51,
    'label': 'Health Services/Allied Health/Health Sciences, General'
  },
  {
    'value': 51.01,
    'label': 'Chiropractic'
  },
  {
    'value': 51.02,
    'label': 'Communication Disorders Sciences and Services'
  },
  {
    'value': 51.04,
    'label': 'Dentistry'
  },
  {
    'value': 51.05,
    'label': 'Advanced/Graduate Dentistry and Oral Sciences'
  },
  {
    'value': 51.06,
    'label': 'Dental Support Services and Allied Professions'
  },
  {
    'value': 51.07,
    'label': 'Health and Medical Administrative Services'
  },
  {
    'value': 51.08,
    'label': 'Allied Health and Medical Assisting Services'
  },
  {
    'value': 51.09,
    'label': 'Allied Health Diagnostic, Intervention, and Treatment Professions'
  },
  {
    'value': 51.1,
    'label': 'Clinical/Medical Laboratory Science/Research and Allied Professions'
  },
  {
    'value': 51.11,
    'label': 'Health/Medical Preparatory Programs'
  },
  {
    'value': 51.12,
    'label': 'Medicine'
  },
  {
    'value': 51.14,
    'label': 'Medical Clinical Sciences/Graduate Medical Studies'
  },
  {
    'value': 51.15,
    'label': 'Mental and Social Health Services and Allied Professions'
  },
  {
    'value': 51.17,
    'label': 'Optometry'
  },
  {
    'value': 51.18,
    'label': 'Ophthalmic and Optometric Support Services and Allied Professions'
  },
  {
    'value': 51.19,
    'label': 'Osteopathic Medicine/Osteopathy'
  },
  {
    'value': 51.2,
    'label': 'Pharmacy, Pharmaceutical Sciences, and Administration'
  },
  {
    'value': 51.21,
    'label': 'Podiatric Medicine/Podiatry'
  },
  {
    'value': 51.22,
    'label': 'Public Health'
  },
  {
    'value': 51.23,
    'label': 'Rehabilitation and Therapeutic Professions'
  },
  {
    'value': 51.23,
    'label': 'Rehabilitation and Therapeutic Professions, General'
  },
  {
    'value': 51.24,
    'label': 'Veterinary Medicine'
  },
  {
    'value': 51.25,
    'label': 'Veterinary Biomedical and Clinical Sciences'
  },
  {
    'value': 51.26,
    'label': 'Health Aides/Attendants/Orderlies'
  },
  {
    'value': 51.27,
    'label': 'Medical Illustration and Informatics'
  },
  {
    'value': 51.31,
    'label': 'Dietetics and Clinical Nutrition Services'
  },
  {
    'value': 51.32,
    'label': 'Health Professions Education, Ethics, and Humanities'
  },
  {
    'value': 51.33,
    'label': 'Alternative and Complementary Medicine and Medical Systems'
  },
  {
    'value': 51.33,
    'label': 'Alternative and Complementary Medicine and Medical Systems, General'
  },
  {
    'value': 51.34,
    'label': 'Alternative and Complementary Medical Support Services'
  },
  {
    'value': 51.35,
    'label': 'Somatic Bodywork and Related Therapeutic Services'
  },
  {
    'value': 51.36,
    'label': 'Movement and Mind-Body Therapies and Education'
  },
  {
    'value': 51.37,
    'label': 'Energy and Biologically Based Therapies'
  },
  {
    'value': 51.38,
    'label': 'Registered Nursing, Nursing Administration, Nursing Research and Clinical Nursing'
  },
  {
    'value': 51.39,
    'label': 'Practical Nursing, Vocational Nursing and Nursing Assistants'
  },
  {
    'value': 51.99,
    'label': 'Health Professions and Related Clinical Sciences, Other'
  },
  {
    'value': 52,
    'label': 'BUSINESS, MANAGEMENT, MARKETING, AND RELATED SUPPORT SERVICES'
  },
  {
    'value': 52.01,
    'label': 'Business/Commerce, General'
  },
  {
    'value': 52.02,
    'label': 'Business Administration, Management and Operations'
  },
  {
    'value': 52.03,
    'label': 'Accounting and Related Services'
  },
  {
    'value': 52.04,
    'label': 'Business Operations Support and Assistant Services'
  },
  {
    'value': 52.05,
    'label': 'Business/Corporate Communications'
  },
  {
    'value': 52.06,
    'label': 'Business/Managerial Economics'
  },
  {
    'value': 52.07,
    'label': 'Entrepreneurial and Small Business Operations'
  },
  {
    'value': 52.08,
    'label': 'Finance and Financial Management Services'
  },
  {
    'value': 52.09,
    'label': 'Hospitality Administration/Management'
  },
  {
    'value': 52.1,
    'label': 'Human Resources Management and Services'
  },
  {
    'value': 52.11,
    'label': 'International Business'
  },
  {
    'value': 52.12,
    'label': 'Management Information Systems and Services'
  },
  {
    'value': 52.13,
    'label': 'Management Sciences and Quantitative Methods'
  },
  {
    'value': 52.14,
    'label': 'Marketing'
  },
  {
    'value': 52.15,
    'label': 'Real Estate'
  },
  {
    'value': 52.16,
    'label': 'Taxation'
  },
  {
    'value': 52.17,
    'label': 'Insurance'
  },
  {
    'value': 52.18,
    'label': 'General Sales, Merchandising and Related Marketing Operations'
  },
  {
    'value': 52.19,
    'label': 'Specialized Sales, Merchandising and  Marketing Operations'
  },
  {
    'value': 52.2,
    'label': 'Construction Management'
  },
  {
    'value': 52.21,
    'label': 'Telecommunications Management'
  },
  {
    'value': 52.99,
    'label': 'Business, Management, Marketing, and Related Support Services, Other'
  },
  {
    'value': 54.01,
    'label': 'History'
  },
  {
    'value': 60,
    'label': 'HEALTH PROFESSIONS RESIDENCY/FELLOWSHIP PROGRAMS'
  },
  {
    'value': 60.01,
    'label': 'Dental Residency/Fellowship Programs'
  },
  {
    'value': 60.03,
    'label': 'Veterinary Residency/Fellowship Programs'
  },
  {
    'value': 60.04,
    'label': 'Medical Residency Programs - General Certificates'
  },
  {
    'value': 60.05,
    'label': 'Medical Residency Programs - Subspecialty Certificates'
  },
  {
    'value': 60.06,
    'label': 'Podiatric Medicine Residency Programs'
  },
  {
    'value': 60.07,
    'label': 'Nurse Practitioner Residency/Fellowship Programs'
  },
  {
    'value': 60.08,
    'label': 'Pharmacy Residency/Fellowship Programs'
  },
  {
    'value': 60.09,
    'label': 'Physician Assistant Residency/Fellowship Programs'
  },
  {
    'value': 60.99,
    'label': 'Health Professions Residency/Fellowship Programs, Other'
  },
  {
    'value': 61,
    'label': 'MEDICAL RESIDENCY/FELLOWSHIP PROGRAMS'
  },
  {
    'value': 61.01,
    'label': 'Combined Medical Residency/Fellowship Programs'
  },
  {
    'value': 61.02,
    'label': 'Multiple-Pathway Medical Fellowship Programs'
  },
  {
    'value': 61.03,
    'label': 'Allergy and Immunology Residency/Fellowship Programs'
  },
  {
    'value': 61.04,
    'label': 'Anesthesiology Residency/Fellowship Programs'
  },
  {
    'value': 61.05,
    'label': 'Dermatology Residency/Fellowship Programs'
  },
  {
    'value': 61.06,
    'label': 'Emergency Medicine Residency/Fellowship Programs'
  },
  {
    'value': 61.07,
    'label': 'Family Medicine Residency/Fellowship Programs'
  },
  {
    'value': 61.08,
    'label': 'Internal Medicine Residency/Fellowship Programs'
  },
  {
    'value': 61.09,
    'label': 'Medical Genetics and Genomics Residency/Fellowship Programs'
  },
  {
    'value': 61.1,
    'label': 'Neurological Surgery Residency/Fellowship Programs'
  },
  {
    'value': 61.11,
    'label': 'Neurology Residency/Fellowship Programs'
  },
  {
    'value': 61.12,
    'label': 'Nuclear Medicine Residency/Fellowship Programs'
  },
  {
    'value': 61.13,
    'label': 'Obstetrics and Gynecology Residency/Fellowship Programs'
  },
  {
    'value': 61.14,
    'label': 'Ophthalmology Residency/Fellowship Programs'
  },
  {
    'value': 61.15,
    'label': 'Orthopedic Surgery Residency/Fellowship Programs'
  },
  {
    'value': 61.16,
    'label': 'Osteopathic Medicine Residency/Fellowship Programs'
  },
  {
    'value': 61.17,
    'label': 'Otolaryngology Residency/Fellowship Programs'
  },
  {
    'value': 61.18,
    'label': 'Pathology Residency/Fellowship Programs'
  },
  {
    'value': 61.19,
    'label': 'Pediatrics Residency/Fellowship Programs'
  },
  {
    'value': 61.2,
    'label': 'Physical Medicine and Rehabilitation Residency/Fellowship Programs'
  },
  {
    'value': 61.21,
    'label': 'Plastic Surgery Residency/Fellowship Programs'
  },
  {
    'value': 61.22,
    'label': 'Podiatric Medicine Residency/Fellowship Programs'
  },
  {
    'value': 61.23,
    'label': 'Preventive Medicine Residency/Fellowship Programs'
  },
  {
    'value': 61.24,
    'label': 'Psychiatry Residency/Fellowship Programs'
  },
  {
    'value': 61.25,
    'label': 'Radiation Oncology Residency/Fellowship Programs'
  },
  {
    'value': 61.26,
    'label': 'Radiology Residency/Fellowship Programs'
  },
  {
    'value': 61.27,
    'label': 'Surgery Residency/Fellowship Programs'
  },
  {
    'value': 61.28,
    'label': 'Urology Residency/Fellowship Programs'
  },
  {
    'value': 61.99,
    'label': 'Medical Residency/Fellowship Programs, Other'
  }
];