import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { studentCertificatePropTypes } from '../../shared/propTypes';
import Field from '../../components/Field';
import './StudentProfileModalForms.scss';

export default function StudentCertificateForm({ defaultValues, saveData }) {
  const { register, handleSubmit, formState: { errors, isValid } } = useForm({ defaultValues, mode: 'onBlur' });
  const registerOptions = {
    title: { required: 'Required' },
    issuingOrgName: { required: 'Required' },
    issueDate: { required: 'Required' },
    expireDate: {}
  }

  return (
    <div className="student-profile-modal-form">
      <h3 className="student-text-h1">Certificates</h3>

      <form onSubmit={handleSubmit(saveData)}>
        <Field error={errors?.title}>
          <label>
            Title *<br />
            <input type="text" {...register('title', registerOptions.title)} />
          </label>
        </Field>

        <Field error={errors?.issuingOrgName}>
          <label>
            Issuing Organization *<br />
            <input type="text" {...register('issuingOrgName', registerOptions.issuingOrgName)} />
          </label>
        </Field>

        <div className="flex">
          <Field error={errors?.issueDate}>
            <label>
              Date Issued *<br />
              <input type="date" {...register('issueDate', registerOptions.issueDate)} />
            </label>
          </Field>

          <Field>
            <label>
              Date Expired<br />
              <input type="date" {...register('expireDate', registerOptions.expireDate)} />
            </label>
          </Field>
        </div>

        <Field>
          <label>
            Description<br />
            <textarea {...register('description')}></textarea>
          </label>
        </Field>

        <Field center>
          <button type="submit" disabled={!isValid} className="profile-form-button">Save</button>
        </Field>
      </form>
    </div>
  )
}

StudentCertificateForm.defaultProps = {
  defaultValues: {
    title: '',
    issuingOrgName: '',
    issueDate: null,
    expireDate: null,
    description: '',
  },
};

StudentCertificateForm.propTypes = {
  defaultValues: PropTypes.shape(studentCertificatePropTypes),
  saveData: PropTypes.func.isRequired,
};