import { Controller } from 'react-hook-form';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import searchIcon from '../assets/icons/search.svg';

const SearchSelect = ({ children, ...props }) => (
  <components.Control {...props}>
    <img src={searchIcon} alt="" className="icon" /> {children}
  </components.Control>
);

SearchSelect.propTypes = {
  children: PropTypes.node,
}

export default function ControlledMultiSelect({
  control,
  defaultValue,
  disabled,
  name,
  options,
  rules,
  customClassNames,
  placeholder,
  isSearch,
  singleOptionValue
}) {

  const customOnChange = (val, onChange) => {
    let selectionValues = val?.map(o => o.value);
    if (singleOptionValue && selectionValues.includes(singleOptionValue)) {
      selectionValues = selectionValues.filter(o => o === singleOptionValue);
    }
    onChange(selectionValues);
  }

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      rules={rules}
      render={({ field: { onChange, value, ref, onBlur }, fieldState: { invalid }}) => (
        <Select
          inputRef={ref}
          isMulti
          isDisabled={disabled}
          options={options}
          value={options?.filter(o => value?.includes(o.value))}
          onChange={val => customOnChange(val, onChange)}
          onBlur={onBlur}
          className={`${customClassNames} ${invalid ? 'error' : ''}`}
          classNamePrefix="react-select"
          placeholder={placeholder}
          components={isSearch ? { Control: SearchSelect } : undefined}
        />
      )}
    />
  )
}

ControlledMultiSelect.propTypes = {
  control: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  })),
  rules: PropTypes.object,
  customClassNames: PropTypes.string,
  placeholder: PropTypes.string,
  isSearch: PropTypes.bool,
  singleOptionValue: PropTypes.string,
}