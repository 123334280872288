import { useMemo } from 'react';
import { useFirebaseStudent } from '../../../context/FirebaseStudentContext';
import { useFirebasePrograms } from '../../../context/FirebaseProgramDataContext';
import ProgramCard from '../../../components/StudentSearch/ProgramCard';
import { EmptyPlaceholder, TopSection } from './FavoriteProgramsPage';
import './StudentFavorites.scss';

export default function StudentFavoriteByProgramsPage() {
  const { favoriteProgramsList } = useFirebaseStudent();
  const { programs } = useFirebasePrograms();

  const favedByPrograms = useMemo(() => {
    if (!favoriteProgramsList || !favoriteProgramsList.length || !programs) return [];
    return favoriteProgramsList.filter(p => p.byProgram && !p.byStudent).map(p => programs[p.programId]);
  }, [favoriteProgramsList, programs]);

  return (
    <main className="student-favorites">
      {favedByPrograms.length ? (
        <>
          <TopSection />
          <ul>
            {favedByPrograms.map(p => (
              <li key={p.programId}>
                <ProgramCard program={p} />
              </li>
            ))}
          </ul>
        </>
      ) : (
        <EmptyPlaceholder text="You haven&apos;t been saved by any programs yet! Make sure you set your preferences and let&apos;s find a program that best matches you!" />
      )}
    </main>
  );
}