import PropTypes from 'prop-types';
import ProgressCircle from '../ProgressCircle';
import { studentGrePropTypes } from '../../shared/propTypes';
import { colors } from '../../shared/colors';
import './GREScoreBlock.scss';

export default function GREScoreBlock({ data }) {
  return (
    <div className="inst-profile-gre-score">
      <div className="profile-gre-score__title">
        <h3 className="inst-text-h4">
          GRE Score<br />
          (Self reported)
        </h3>
      </div>
      <div className="profile-gre-score__scores">
        <div className="score">
          <ProgressCircle
            percentComplete={(data.verbal / 170) * 100}
            fgColor={colors.green}
            bgColor={colors.lightGreen}
            size={104}
          >
            <span className="inst-text-h2">{data.verbal}</span>
          </ProgressCircle>
          <p className="inst-text-label">Verbal Reasoning</p>
        </div>
        <div className="score">
          <ProgressCircle
            percentComplete={(data.quantitative / 170) * 100}
            fgColor={colors.green}
            bgColor={colors.lightGreen}
            size={104}
          >
            <span className="inst-text-h2">{data.quantitative}</span>
          </ProgressCircle>
          <p className="inst-text-label">Quantitative Reasoning</p>
        </div>
        <div className="score">
          <ProgressCircle
            percentComplete={(data.analyticalWriting / 6) * 100}
            fgColor={colors.green}
            bgColor={colors.lightGreen}
            size={104}
          >
            <span className="inst-text-h2">{data.analyticalWriting}</span>
          </ProgressCircle>
          <p className="inst-text-label">Analytical Writing</p>
        </div>
      </div>
    </div>   
  )
}

GREScoreBlock.propTypes = {
  data: PropTypes.shape(studentGrePropTypes),
};