import { useState, useEffect, createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ref, onValue } from 'firebase/database';
import { useFirebaseAuth } from '../context/FirebaseAuthContext';
import { db } from '../firebase';
import toast from 'react-hot-toast';

const defaultFilters = {
  levels: [],
  setting: '',
  schoolType: '',
  programType: [],
  disabilitySupport: false,
  minorityServingInstitution: false,
  title: '',
  schoolName: '',
  state: [],
};

const FirebaseProgramDataContext = createContext(undefined);

function FirebaseProgramDataProvider({ children }) {
  const [filters, setFilters] = useState(defaultFilters);
  const { user, userPrivileges } = useFirebaseAuth();
  const [programs, setPrograms] = useState(null);
  const [schools, setSchools] = useState(null);
  const [activeProgram, setActiveProgram] = useState(null);

  const userSchool = useMemo(() => {
    if (!schools || !userPrivileges?.school) return null;
    return schools[userPrivileges.school];
  }, [schools, userPrivileges?.school]);

  const userPrograms = useMemo(() => {
    if (!programs || !userPrivileges?.programs?.length) return null;
    return userPrivileges.programs.map(id => programs[id]);
  }, [programs, userPrivileges?.programs]);

  const programsList = useMemo(() => {
    if (!programs) return [];
    return Object.keys(programs).map(key => programs[key])
  }, [programs]);
  
  // Returns any program that meets any specified condition
  const filteredProgramsList = useMemo(() => {
    if (!programsList?.length) return [];
    if (!filters) return programsList;
    
    const activeFilterKeys = Object.keys(filters).filter(key => {
      if (Array.isArray(filters[key])) {
        return Boolean(filters[key].length);
      } else {
        return Boolean(filters[key]);
      }
    });

    if (!activeFilterKeys.length) return programsList;

    const filteredPrograms = programsList.filter(program => {
      return activeFilterKeys.some(key => {
        if (Array.isArray(filters[key])) {
          return filters[key].some(val => program[key]?.includes(val));
        } else if (key === 'title') {
          const lowercaseSearchString = filters.title.toLowerCase();
          return program.programTitle.toLowerCase().includes(lowercaseSearchString) || program.schoolName.toLowerCase().includes(lowercaseSearchString);
        } else {
          return program[key] === filters[key];
        }
      });
    });

    return filteredPrograms;
  }, [programsList, filters]);

  useEffect(() => {
    if (!activeProgram?.programId && userPrograms?.length) {
      setActiveProgram(userPrograms[0]);
    }
  }, [userPrograms, activeProgram?.programId]);

  useEffect(() => {
    if (user?.uid && !programs) {
      const programsRef = ref(db, '/programs');
      try {
        const unsubscribe = onValue(programsRef, (snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            setPrograms(data);
          } else {
            throw new Error({ code: 'No programs exist!' });
          }
        });

        return () => unsubscribe;
      } catch (e) {
        toast.error(e.code);
      }
    } else if (!user && programs) {
      setPrograms(null);
    }
  }, [user, programs]);

  useEffect(() => {
    if (user?.uid && !schools) {
      const schoolsRef = ref(db, '/schools');
      try {
        const unsubscribe = onValue(schoolsRef, (snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            setSchools(data);
          } else {
            throw new Error({ code: 'No schools exist!' });
          }
        });

        return () => unsubscribe;
      } catch (e) {
        toast.error(e.code);
      }
    } else if (!user && schools) {
      setSchools(null);
    }
  }, [user, schools]);

  return (
    <FirebaseProgramDataContext.Provider value={{
      programs,
      programsList,
      filteredProgramsList,
      filters,
      setFilters,
      schools,
      userSchool,
      userPrograms,
      activeProgram,
      activeSchool: userSchool,
      setActiveProgram,
    }}>
      {children}
    </FirebaseProgramDataContext.Provider>
  );
}

FirebaseProgramDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function useFirebasePrograms() {
  const context = useContext(FirebaseProgramDataContext);

  if (context === undefined) {
    throw new Error('useFirebasePrograms must be used with FirebaseProgramDataProvider');
  }

  return context;
}

export { FirebaseProgramDataProvider, useFirebasePrograms, defaultFilters };