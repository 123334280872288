import { useMemo } from 'react';
import { useFirebaseSchool } from '../../../context/FirebaseSchoolContext';
import { TopSection, EmptyPlaceholder } from './FavoriteStudentsPage';
import StudentCard from '../../../components/InstitutionUI/StudentCard';
import './InstFavorites.scss';

export default function InstitutionFavoriteByStudentsPage() {
  const { favoriteStudentsList, students } = useFirebaseSchool();

  const favedByStudents = useMemo(() => {
    if (!favoriteStudentsList || !favoriteStudentsList.length || !students) return [];
    return favoriteStudentsList.filter(s => s.byStudent && !s.byProgram).map(s => ({ ...students[s.studentId], studentId: s.studentId }));
  }, [favoriteStudentsList, students]);

  return (
    <main className="inst-favorites">
      {favedByStudents.length ? (
        <>
          <TopSection totalText={`You've been favorited by ${favedByStudents.length} ${favedByStudents.length === 1 ? 'person' : 'people'}`} />
          <ul className="grid">
            {favedByStudents.map(s => (
              <li key={s.studentId}>
                <StudentCard student={s} />
              </li>
            ))}
          </ul>
        </>
      ) : (
        <EmptyPlaceholder text="You haven't been Favorited by any students yet. Make sure your Program Profile is filled out completely with as much information about your program as possible to make it stand out to prospective students. This will help them find you more easily!" />
      )}
    </main>
  );
}