import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useFirebaseStudent } from '../../context/FirebaseStudentContext';
import ShadowCard from '../ShadowCard';
import ProgressCircle from '../ProgressCircle';
import { colors } from '../../shared/colors';
import greenCheck from '../../assets/student/images/check-green.svg';
import deleteIcon from '../../assets/student/images/delete.svg';
import './InProgressAppCard.scss';
import { formatAppDate } from '../../shared/dates';

export const appSections = [
  { key: 'programInfo', label: 'The Basics' },
  { key: 'studentProfile', label: 'Student Profile' },
  { key: 'citizenship', label: 'Citizenship Status' },
  { key: 'education', label: 'Education History' },
  { key: 'testScores', label: 'Test Results' },
  { key: 'resume', label: 'Resume/CV' },
  { key: 'personalStatement', label: 'Personal Statement and/ or Statement of Purpose' },
  { key: 'recommendation', label: 'Letters of Recommendation' },
];

export default function InProgressAppCard({ application }) {  
  const { removeApplication } = useFirebaseStudent();
  const completeSections = appSections.map(s => s.key).filter(key => application?.[key]);
  const percentComplete = completeSections?.length ? Math.round((completeSections.length / appSections.length) * 100) : 0;

  return (
    <Link to={`/students/applications/${application.programId}`} className="app-in-progress">
      <ShadowCard customClassNames="app-card">
        <div className="app-card__top">
          <div>
            <ProgressCircle
              percentComplete={percentComplete}
              fgColor={colors.blue}
              bgColor="white"
              size={104}
            />
          </div>
          <div>
            <p className="student-text-h3">{application.programTitle}</p>
            <p className="student-text-p-small">{application.schoolName}</p>
            <p className="student-text-p-small">⏳ Deadline: {formatAppDate(application.deadline)}</p>
          </div>
          <div className="delete__wrapper">
            <button onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              removeApplication(application.programId, 'Application deleted!')
            }} className="delete">
              <img src={deleteIcon} alt="Delete application" />
            </button>
          </div>
        </div>
        <div className="app-card__bottom">
          <ul>
            {appSections.map(s => (
              <li key={s.key} className="student-text-p">
                <img
                  src={greenCheck} alt={application[s.key] ? 'Complete' : 'Not Complete'}
                  style={{ opacity: application[s.key] ? '1' : '0.4' }}
                />
                {s.label}
              </li>
            ))}
          </ul>
        </div>
      </ShadowCard>
    </Link>
  )
}

InProgressAppCard.propTypes = {
  application: PropTypes.shape({
    programId: PropTypes.string,
    programTitle: PropTypes.string,
    schoolName: PropTypes.string,
    deadline: PropTypes.string,
    programInfo: PropTypes.object,
    studentProfile: PropTypes.object,
    citizenship: PropTypes.object,
    education: PropTypes.arrayOf(PropTypes.object),
    testScores: PropTypes.object,
    resume: PropTypes.bool,
    personalStatement: PropTypes.bool,
  })
}