import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { stateOptions } from '../../shared/formOptions';
import ControlledMultiSelect from '../../components/ControlledMultiSelect';
import ControlledSlider from '../../components/ControlledSlider';
import Field from '../../components/Field';
import { comingSoonShort } from '../../shared/appText';
import { studentPropTypes } from '../../shared/propTypes';
import next from '../../assets/student/images/right-carat-black.svg';
import './StudentOnboardingForm.scss';
import SurveyNavButtonWrapper from '../../components/StudentSurveys/SurveyNavButtonWrapper';
import AddButton from '../../components/StudentUI/AddButton';

export default function StudentOnboardingSchoolPrioritiesForm({ defaultValues, saveData, goBack }) {
  const { control, handleSubmit } = useForm({ defaultValues });

  return (
    <form onSubmit={handleSubmit(saveData)} className="student-onboarding-form">
      <Field>
        <label htmlFor="targetStates">I prefer to go to schools in</label>&nbsp;
        <ControlledMultiSelect
          control={control}
          defaultValue={[]}
          name="targetStates"
          options={stateOptions}
          customClassNames="student-survey-select"
          placeholder="Select all that apply"
        />
      </Field>
      
      <fieldset>
        <legend>My priorities are</legend>

        <Field>
          <label htmlFor="prestigePriority" className="slider">School Prestige and Reputation</label><br />
          <ControlledSlider
            control={control}
            defaultValue={[5]}
            min={0}
            max={10}
            step={1}
            name="prestigePriority"
            displayThumb={() => {}}
            customClassNames="student-survey-slider"
            rangeLabels={['Not Important', 'Very Important']}
          />
        </Field>

        <Field>
          <label htmlFor="costPriority" className="slider">Cost</label><br />
          <ControlledSlider
            control={control}
            defaultValue={[5]}
            min={0}
            max={10}
            step={1}
            name="costPriority"
            displayThumb={() => {}}
            customClassNames="student-survey-slider"
            rangeLabels={['Not Important', 'Very Important']}
          />
        </Field>

        <Field>
          <label htmlFor="locationPriority" className="slider">Location</label><br />
          <ControlledSlider
            control={control}
            defaultValue={[5]}
            min={0}
            max={10}
            step={1}
            name="locationPriority"
            displayThumb={() => {}}
            customClassNames="student-survey-slider"
            rangeLabels={['Not Important', 'Very Important']}
          />
        </Field>

        <Field>
          <label htmlFor="programFormatPriority" className="slider">Program Format (online, on-campus, hybrid</label><br />
          <ControlledSlider
            control={control}
            defaultValue={[5]}
            min={0}
            max={10}
            step={1}
            name="programFormatPriority"
            displayThumb={() => {}}
            customClassNames="student-survey-slider"
            rangeLabels={['Not Important', 'Very Important']}
          />
        </Field>

        <div className="flex">
          <AddButton disabled>Add Another Priority</AddButton>
          <span className="coming-soon">{comingSoonShort}</span>
        </div>
      </fieldset>

      <Field>
        <SurveyNavButtonWrapper>
          <button type="button" onClick={goBack} className="prev">
            <img src={next} alt="Go Back" />
          </button>
          <button type="submit" className="next">
            <img src={next} alt="Save & Next" />
          </button>
        </SurveyNavButtonWrapper>
      </Field>
    </form>
  )
}

StudentOnboardingSchoolPrioritiesForm.defaultProps = {
  defaultValues: {
    targetStates: [],
    prestigePriority: [5],
    costPriority: [5],
    locationPriority: [5],
    programFormatPriority: [5],
  },
}

StudentOnboardingSchoolPrioritiesForm.propTypes = {
  defaultValues: PropTypes.shape({
    targetStates: studentPropTypes.targetStates,
    prestigePriority: studentPropTypes.prestigePriority,
    costPriority: studentPropTypes.costPriority,
    locationPriority: studentPropTypes.locationPriority,
    programFormatPriority: studentPropTypes.programFormatPriority,
  }),
  saveData: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
}