import { useMemo, useState } from 'react';
import { useFirebasePrograms } from '../../../context/FirebaseProgramDataContext';
import SchoolProgramListCard from '../../../components/InstitutionProfile/SchoolProgramListCard';
import Modal from '../../../components/Modal';
import searchIcon from '../../../assets/icons/search.svg';
import add from '../../../assets/student/images/add.svg';
import { comingSoonShort } from '../../../shared/appText';

export default function InstitutionSchoolProfileProgramsListPage() {
  const [showFilters, setShowFilters] = useState(false);
  const { programsList, activeProgram } = useFirebasePrograms();

  const schoolPrograms = useMemo(() => {
    if (activeProgram?.schoolId && programsList) return programsList.filter(p => p.schoolId === activeProgram.schoolId);
    return [];
  }, [activeProgram?.schoolId, programsList]);

  return (
    <main className="school-profile-page">
      <section className="programs-search programs-search--inst">
        <div className="inst-search-input">
          <img src={searchIcon} alt="" />
          <input
            className="inst-text-p"
            type="text"
            disabled
            placeholder={`Search ${comingSoonShort}`}
          />
          <button aria-label="Clear" type="button" disabled>&times;</button>
        </div>
        <button className="inst-cta-link" onClick={() => setShowFilters(true)}>Filters</button>

        <Modal
          closeModal={() => setShowFilters(false)}
          title="Program Filters (coming soon)"
          isOpen={showFilters}
        >
          <div className="inst-coming-soon-modal">
            <h1 className="inst-text-h1">Coming Soon</h1>
            <p className="inst-text-p">This feature will be coming soon.</p>
          </div>
        </Modal>
      </section>

      <section className="section">
        <div className="section__header">
          <h2 className="inst-text-h2">Programs</h2>
          <button disabled className="inst-coming-soon-button">
            Add Another Program (coming soon)
            <img src={add} alt="" />
          </button>
        </div>
        <div className="programs-list">
          {schoolPrograms.map(p => (
            <SchoolProgramListCard program={p} key={p.programId} />
          ))}
        </div>
      </section>
    </main>
  );
}