import PropTypes from 'prop-types';

export default function PercentBar({ height, fgColor, bgColor, percentComplete }) {
  return (
    <svg width="100%" height={height}>
      <rect x={0} y={0} height={height} width="100%" rx={height / 2} fill={bgColor} />
      <rect x={0} y={0} height={height} width={`${percentComplete}%`} rx={height / 2} fill={fgColor} />
    </svg>
  );
}

PercentBar.defaultProps = {
  height: 16,
  fgColor: 'black',
  bgColor: 'lightgray',
};

PercentBar.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fgColor: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  percentComplete: PropTypes.number.isRequired,
};