import { useState, useEffect, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { ref, onValue, update, set, push, remove } from 'firebase/database';
import { useFirebasePrograms } from './FirebaseProgramDataContext';
import { db } from '../firebase';
import toast from 'react-hot-toast';
import { useFirebaseAuth } from './FirebaseAuthContext';

const FirebaseSchoolContext = createContext(undefined);

function FirebaseSchoolProvider({ children }) {
  const { userRole } = useFirebaseAuth();
  const { activeProgram } = useFirebasePrograms();
  const [favoriteStudents, setFavoriteStudents] = useState(null);
  const [applications, setApplications] = useState(null);
  const [applicationsList, setApplicationsList] = useState([]);
  const [students, setStudents] = useState(null);
  const [studentsList, setStudentsList] = useState([]);
  const [recommendedStudentsList, setRecommendedStudentsList] = useState([]);
  const [favoriteStudentsList, setFavoriteStudentsList] = useState([]);
  const [savedSearches, setSavedSearches] = useState(null);
  const [savedSearchesList, setSavedSearchesList] = useState([]);

  const addOrUpdateFavoriteStudent = async (studentId, data, successMessage) => {
    try {
      const faveStudentRef = ref(db, `/programFaves/${activeProgram.programId}/${studentId}`);
      const studentFaveProgramRef = ref(db, `/studentFaves/${studentId}/${activeProgram.programId}`);
      await update(faveStudentRef, data);
      await update(studentFaveProgramRef, data);
      if (successMessage) toast.success(successMessage);
    } catch (e) {
      toast.error(e.code);
    }
  }

  const addOrUpdateSavedSearch = (data, successMessage) => {
    if (data.id) {
      updateSavedSearch(data.id, data, successMessage);
    } else {
      addSavedSearch(data, successMessage);
    }
  }

  const addSavedSearch = async (data, successMessage) => {
    try {
      const parentNodeRef = ref(db, `/savedSearches/${activeProgram.programId}`);
      const newItemRef = push(parentNodeRef);
      set(newItemRef, {
        ...data,
        id: newItemRef.key,
      });
      if (successMessage) toast.success(successMessage);
    } catch (e) {
      toast.error(e.code);
    }
  }

  const updateSavedSearch = async (key, data, successMessage) => {
    try {
      const listItemRef = ref(db, `/savedSearches/${activeProgram.programId}/${key}`);
      await update(listItemRef, data);
      if (successMessage) toast.success(successMessage);
    } catch (e) {
      toast.error(e.code);
    }
  }

  const removeSavedSearch = async (key, successMessage) => {
    try {
      const listItemRef = ref(db, `/savedSearches/${activeProgram.programId}/${key}`);
      await remove(listItemRef);
      if (successMessage) toast.success(successMessage);
    } catch (e) {
      toast.error(e.code);
    }
  }

  const updateApplication = async (studentId, data, successMessage) => {
    try {
      const appRef = ref(db, `/sharedApplications/${activeProgram.programId}/${studentId}`);
      await update(appRef, data);
      if (successMessage) toast.success(successMessage);
    } catch (e) {
      toast.error(e.code);
    }
  }

  useEffect(() => {
    if (activeProgram?.programId) {
      const savedSearchesRef = ref(db, `/savedSearches/${activeProgram.programId}`);
      try {
        const unsubscribe = onValue(savedSearchesRef, (snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            const list = Object.keys(data)?.map(key => data[key])
            setSavedSearches(data);
            setSavedSearchesList(list);
          } else {
            setSavedSearches(null);
            setSavedSearchesList([]);
          }
        });

        return () => unsubscribe;
      } catch (e) {
        toast.error(e.code);
      }
    }
  }, [activeProgram?.programId]);

  useEffect(() => {
    if (activeProgram?.programId) {
      const applicationsRef = ref(db, `/sharedApplications/${activeProgram.programId}`);
      try {
        const unsubscribe = onValue(applicationsRef, (snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            setApplications(data);
            setApplicationsList(Object.keys(data)?.map(key => ({ ...data[key], studentId: key })));
          } else {
            setApplications(null);
            setApplicationsList([]);
          }
        });

        return () => unsubscribe;
      } catch (e) {
        toast.error(e.code);
      }
    }
  }, [activeProgram?.programId]);

  useEffect(() => {
    if (activeProgram?.programId) {
      const programFavesRef = ref(db, `/programFaves/${activeProgram.programId}`);
      try {
        const unsubscribe = onValue(programFavesRef, (snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            const list = Object.keys(data)?.map(key => ({ ...data[key], studentId: key }));
            setFavoriteStudents(data);
            setFavoriteStudentsList(list);
          } else {
            setFavoriteStudents(null);
            setFavoriteStudentsList([]);
          }
        });

        return () => unsubscribe;
      } catch (e) {
        toast.error(e.code);
      }
    }
  }, [activeProgram?.programId]);

  useEffect(() => {
    if (userRole) {
      const studentsRef = ref(db, '/students');
      try {
        const unsubscribe = onValue(studentsRef, (snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            const list = Object.keys(data)?.map(key => ({ ...data[key], studentId: key }));
            const recommended = list?.filter(student => student.recommend);
            setStudents(data);
            setStudentsList(list);
            setRecommendedStudentsList(recommended);
          } else {
            setStudents(null);
            setStudentsList([]);
            setRecommendedStudentsList([]);
          }
        });

        return () => unsubscribe;
      } catch (e) {
        toast.error(e.code);
      }
    }
  }, [userRole]);

  return (
    <FirebaseSchoolContext.Provider value={{
      applications,
      applicationsList,
      students,
      studentsList,
      recommendedStudentsList,
      favoriteStudents,
      favoriteStudentsList,
      savedSearches,
      savedSearchesList,
      addOrUpdateFavoriteStudent,
      addOrUpdateSavedSearch,
      removeSavedSearch,
      updateApplication,
    }}>
      {children}
    </FirebaseSchoolContext.Provider>
  );
}

FirebaseSchoolProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function useFirebaseSchool() {
  const context = useContext(FirebaseSchoolContext);

  if (context === undefined) {
    throw new Error('useFirebaseSchool must be used within a FirebaseSchoolProvider');
  }

  return context;
}

export {
  FirebaseSchoolProvider,
  useFirebaseSchool,
};