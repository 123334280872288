import { Outlet } from 'react-router-dom';
import SectionHeader from '../../components/SectionHeader';

export default function StudentExploreLayout() {
  return (
    <div>
      <SectionHeader title="Explore" />
      <Outlet />
    </div>
  );
}