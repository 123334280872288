import PropTypes from 'prop-types';
import './InfoTag.scss';

export default function InfoTag({ children }) {
  return (
    <span className="inst-info-tag">
      {children}
    </span>
  );
}

InfoTag.propTypes = {
  children: PropTypes.node,
}