import { Outlet } from 'react-router-dom';
import SectionHeader from '../../components/SectionHeader';
import TabNav from '../../components/InstitutionUI/TabNav';

export default function InstitutionFavoriteStudentsLayout() {
  return (
    <div>
      <SectionHeader title="Favorites" />
      <TabNav tabs={[
        { label: 'Favorites', to: '', end: true },        
        { label: 'Favorited By', to: 'bystudents' },
        { label: 'Matches', to: 'matches' },
      ]} />
      <Outlet />
    </div>
  );
}