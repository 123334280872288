import { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState
} from 'react-accessible-accordion';
import { institutionCitizenshipStatusValues, institutionGenderValues } from '../../shared/filterOptions';
import { cipFieldOptions, countryOptions, ethnicGroupOptions, startDateOptions, stateOptions } from '../../shared/formOptions';
import { filterDefaultValues } from '../../forms/InstitutionCandidateBuilderForm/CandidateBuilderForm';
import ShadowCard from '../ShadowCard';
import ButtonLink from '../InstitutionUI/ButtonLink';
import collapse from '../../assets/inst/images/collapse.svg';
import add from '../../assets/student/images/add.svg';
import deleteIcon from '../../assets/student/images/delete.svg';
import editIcon from '../../assets/student/images/pencil.svg';
import './CandidateSearchCard.scss';

const ImportanceTag = ({ value }) => (
  <span className={`${value}-important`}>
    {value} important
  </span>
);

ImportanceTag.propTypes = {
  value: PropTypes.string,
};

export default function CandidateSearchCard({ search, editSearch, deleteSearch }) {

  const fieldSections = useMemo(() => {
    return {
      major: search?.major?.length,
      gpaRange: !!search?.gpaRange.find((val, i) => val !== filterDefaultValues?.gpaRange[i]),
      graduationYear: search?.graduationYear?.length,
      diversity: search?.citizenshipCountry?.length || search?.citizenshipStatus || search?.ethnicGroups?.length || search?.gender?.length,
      expectedStartDate: search?.earliestStartDate?.length || search?.latestStartDate?.length,
      degree: search?.degree?.length,
      greScoreRange:
        search?.greVerbal?.find((val, i) => val !== filterDefaultValues?.greVerbal[i])
        || search?.greQuantitative?.find((val, i) => val !== filterDefaultValues?.greQuantitative[i])
        || search?.greWriting?.find((val, i) => val !== filterDefaultValues?.greWriting[i]),
      location: search?.state?.length,
    }
  }, [search]);

  const activeFieldSectionList = useMemo(() => {
    if (fieldSections) {
      return Object.keys(fieldSections).filter(key => fieldSections[key]);
    }
  }, [fieldSections]);

  return (
    <ShadowCard customClassNames="inst-candidate-search-card">
      <div className="inst-candidate-search-card__top">
        <span>{search.title}</span>
        <div>
          <button onClick={deleteSearch}><img src={deleteIcon} alt="Delete" /></button>
          <button onClick={editSearch}><img src={editIcon} alt="Edit" /></button>
        </div>
      </div>
      <div className="inst-candidate-search-card__content">
        <Accordion allowZeroExpanded>
          <AccordionItem uuid="filters" activeClassName="accordion__item--expanded">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="filter-count">
                  {activeFieldSectionList?.length} Filters Selected
                </div>
                <div className="accordion__indicator">
                  <AccordionItemState>
                    {({ expanded }) => (
                      <>
                        {expanded ? 'Collapse' : 'Expand'}
                        <img src={add} alt="" className="open" />
                        <img src={collapse} alt="" className="close" />
                      </>
                    )}
                  </AccordionItemState>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              {fieldSections?.major ? (
                <div className="filter-group grid">          
                  <div>
                    <h3>Undergraduate Major(s)</h3>
                    <p>{search?.major?.map(x => cipFieldOptions.find(o => o.value === x).label).join(', ')}</p>
                  </div>
                  <div>
                    <ImportanceTag value={search?.majorImportance} />
                  </div>
                </div>
              ) : null}

              {fieldSections?.gpaRange ? (
                <div className="filter-group grid">          
                  <div>
                    <h3>GPA</h3>
                    <p>{search?.gpaRange?.join(' - ')}</p>
                  </div>
                  <div>
                    <ImportanceTag value={search?.gpaRangeImportance} />
                  </div>
                </div>
              ) : null}

              {fieldSections?.graduationYear ? (
                <div className="filter-group grid">        
                  <div>
                    <h3>Graduate Year</h3>
                    <p>{search?.graduationYear?.length ? search?.graduationYear?.join(', ') : '-'}</p>
                  </div>
                  <div>
                    <ImportanceTag value={search?.graduationYearImportance} />
                  </div>
                </div>
              ) : null}

              {fieldSections?.diversity ? (
                <div className="filter-group">
                  <h3>Diversity</h3>

                  <div className="grid">
                    <p>
                      <span className="label">Country of Citizenship</span><br />
                      {search?.citizenshipCountry?.length ? search?.citizenshipCountry?.join(', ') : '-'}
                    </p>
                    <div>
                      <ImportanceTag value={search?.citizenshipCountryImportance} />
                    </div>
                  </div>

                  <div className="grid">
                    <p>
                      <span className="label">Domestic/International</span><br />
                      {search?.citizenshipStatus || '-'}
                    </p>
                    <div>
                      <ImportanceTag value={search?.citizenshipStatusImportance} />
                    </div>
                  </div>

                  <div className="grid">
                    <p>
                      <span className="label">Ethnic Background</span><br />
                      {search?.ethnicGroups?.length ? search?.ethnicGroups?.join(', ') : '-'}
                    </p>
                    <div>
                      <ImportanceTag value={search?.ethnicGroupsImportance} />
                    </div>
                  </div>

                  <div className="grid">
                    <p>
                      <span className="label">Genders</span><br />
                      {search?.gender?.length ? search?.gender?.join(', ') : '-'}
                    </p>
                    <div>
                      <ImportanceTag value={search?.genderImportance} />
                    </div>
                  </div>
                </div>
              ) : null}

              {fieldSections?.expectedStartDate ? (
                <div className="filter-group grid">
                  <div>
                    <h3>Expected Start Date</h3>
                    <p>{search?.earliestStartDate?.length ? search?.earliestStartDate?.join(', ') : ''} - {search?.latestStartDate?.length ? search?.latestStartDate?.join(', ') : ''}</p>
                  </div>
                  <div>
                    <ImportanceTag value={search?.expectedStartDateImportance} />
                  </div>
                </div>
              ) : null}

              {fieldSections?.degree ? (
                <div className="filter-group grid">
                  <div>
                    <h3>Degree Level Seeking</h3>
                    <p>{search?.degree?.length ? search?.degree?.join(', ') : '-'}</p>
                  </div>
                  <div>
                    <ImportanceTag value={search?.degreeImportance} />
                  </div>
                </div>
              ) : null}

              {fieldSections?.greScoreRange ? (
                <div className="filter-group">
                  <h3>GRE Score Range</h3>
                  
                  <div className="grid">
                    <p>
                      <span className="label">Verbal</span><br />
                      {search?.greVerbal?.length ? search?.greVerbal?.join(' - ') : ''}
                    </p>
                    <div>
                      <ImportanceTag value={search?.greVerbalImportance} />
                    </div>
                  </div>

                  <div className="grid">
                    <p>
                      <span className="label">Quantitative</span><br />
                      {search?.greQuantitative?.length ? search?.greQuantitative?.join(' - ') : ''}
                    </p>
                    <div>
                      <ImportanceTag value={search?.greQuantitativeImportance} />
                    </div>
                  </div>

                  <div className="grid">
                    <p>
                      <span className="label">Analytical Writing</span><br />
                      {search?.greWriting?.length ? search?.greWriting?.join(' - ') : ''}
                    </p>
                    <div>
                      <ImportanceTag value={search?.greWritingImportance} />
                    </div>
                  </div>
                </div>
              ) : null}

              {fieldSections?.location ? (
                <div className="filter-group grid">
                  <div>
                    <h3>Location</h3>
                    <p>{search?.state?.length ? search?.state?.join(', ') : '-'}</p>
                  </div>
                  <div>
                    <ImportanceTag value={search?.stateImportance} />
                  </div>
                </div>
              ) : null}
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <div className="inst-candidate-search-card__bottom">
        <ButtonLink to="results" variant="outline">Show Results</ButtonLink>
      </div>
    </ShadowCard>
  )
}

CandidateSearchCard.propTypes = {
  search: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    major: PropTypes.arrayOf(PropTypes.oneOf([...cipFieldOptions.map(o => o.value), ''])),
    majorImportance: PropTypes.oneOf(['less', 'very']),
    gpaRange: PropTypes.arrayOf(PropTypes.number),
    gpaRangeImportance: PropTypes.oneOf(['less', 'very']),
    graduationYear: PropTypes.arrayOf(PropTypes.string),
    graduationYearImportance: PropTypes.oneOf(['less', 'very']),
    citizenshipCountry: PropTypes.arrayOf(PropTypes.oneOf([...countryOptions, ''])),
    citizenshipCountryImportance: PropTypes.oneOf(['less', 'very']),
    citizenshipStatus: PropTypes.oneOf([...institutionCitizenshipStatusValues, '']),
    citizenshipStatusImportance: PropTypes.oneOf(['less', 'very']),
    ethnicGroups: PropTypes.arrayOf(PropTypes.oneOf([...ethnicGroupOptions.map(o => o.value), ''])),
    ethnicGroupsImportance: PropTypes.oneOf(['less', 'very']),
    gender: PropTypes.arrayOf(PropTypes.oneOf([ ...institutionGenderValues, ''])),
    genderImportance: PropTypes.oneOf(['less', 'very']),
    earliestStartDate: PropTypes.arrayOf(PropTypes.oneOf([...startDateOptions.map(o => o.value), ''])),
    latestStartDate: PropTypes.arrayOf(PropTypes.oneOf([...startDateOptions.map(o => o.value), ''])),
    expectedStartDateImportance: PropTypes.oneOf(['less', 'very']),
    degree: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    degreeImportance: PropTypes.oneOf(['less', 'very']),
    greVerbal: PropTypes.arrayOf(PropTypes.number),
    greVerbalImportance: PropTypes.oneOf(['less', 'very']),
    greQuantitative: PropTypes.arrayOf(PropTypes.number),
    greQuantitativeImportance: PropTypes.oneOf(['less', 'very']),
    greWriting: PropTypes.arrayOf(PropTypes.number),
    greWritingImportance: PropTypes.oneOf(['less', 'very']),
    state: PropTypes.arrayOf(PropTypes.oneOf([...stateOptions.map(o => o.value), ''])),
    stateImportance: PropTypes.oneOf(['less', 'very']),
    zipCode: PropTypes.string,
    distance: PropTypes.arrayOf(PropTypes.number),
    distanceImportance: PropTypes.oneOf(['less', 'very']),
  }),
  editSearch: PropTypes.func.isRequired,
  deleteSearch: PropTypes.func.isRequired,
}