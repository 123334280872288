import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { studentEducationPropTypes } from '../../shared/propTypes';
import Field from '../../components/Field';
import deleteImg from '../../assets/student/images/delete.svg';
import './StudentOnboardingForm.scss';

export default function StudentFutureOnboardingEducationForm({ defaultValues, saveData, deleteItem }) {
  const { register, handleSubmit, reset } = useForm({ defaultValues });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <form className="student-onboarding-form" onBlur={handleSubmit(saveData)}>
      <Field>
        <label>
          I will graduate on
          <input type="date" {...register('endDate')} />
        </label>
      </Field>

      <Field>
        <label>
          My anticipated degree will be a
          <input type="text" {...register('degree')} placeholder="Write here" />
        </label>
        {deleteItem ? (
          <button onClick={deleteItem} className="btn--delete"><img src={deleteImg} alt="" /></button>
        ) : null}
      </Field>

      <Field>
        <label>
          from
          <input type="text" {...register('schoolName')} placeholder="Write school name here" />
        </label>
      </Field>

      <Field>
        <label>
          in
          <input type="text" {...register('major')} placeholder="Write major here" />
        </label>
      </Field>
    </form>
  );
}

StudentFutureOnboardingEducationForm.defaultProps = {
  defaultValues: {
    isCurrent: false,
    schoolName: '',
    degree: '',
    major: '',
    startDate: null,
    endDate: null,
    grade: '',
    description: '',
  },
};

StudentFutureOnboardingEducationForm.propTypes = {
  defaultValues: PropTypes.shape(studentEducationPropTypes),
  saveData: PropTypes.func.isRequired,
  deleteItem: PropTypes.func,
};