import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useFirebaseSchool } from '../../../context/FirebaseSchoolContext';
import BackLink from '../../../components/BackLink';
import StudentBasicInfoBlock from '../../../components/InstitutionStudentSearch/StudentBasicInfoBlock';
import ShadowCard from '../../../components/ShadowCard';
import GREScoreBlock from '../../../components/InstitutionStudentSearch/GREScoreBlock';
import { formatProfileDate } from '../../../shared/dates';
import './StudentProfilePage.scss';

export default function InstitutionStudentProfilePage() {
  const { studentId } = useParams();
  const { students } = useFirebaseSchool();

  const student = useMemo(() => {
    if (!students || !studentId) return null;
    return students[studentId];
  }, [students, studentId]);

  if (student) return (
    <main className="inst-student-profile">
      <BackLink label="Back" to="/institutions/recruiting/studentsearch" inst />

      <section className="section">
        <StudentBasicInfoBlock data={student?.basicInfo} name={student?.alias} recommend={student?.recommend} studentId={studentId} />
      </section>

      <section className="section">
        <ShadowCard customClassNames="profile-block">
          <h2>About</h2>
          <div className="block-item">
            <p className="inst-text-p">{student?.aboutMe}</p>
          </div>

          <hr />

          <h2>Experience</h2>

          {student?.workExperience ? (
            <>
              {Object.keys(student.workExperience).map((key) => (
                <div key={key} className="block-item">
                  <h3 className="inst-text-h4">{student.workExperience[key]?.title}</h3>
                  <p className="inst-text-p-small">
                    {student.workExperience[key]?.company}<br />
                    {formatProfileDate(student.workExperience[key]?.startDate)} - {formatProfileDate(student.workExperience[key]?.endDate)}
                  </p>
                  <p className="inst-text-p-small">{student.workExperience[key]?.description}</p>
                </div>
              ))} 
            </>
          ) : null}

          <hr />

          <h2>Educational Background</h2>

          {student?.education ? (
            <>
              {Object.keys(student.education).map((key) => (
                <div key={key} className="block-item">
                  <h3 className="inst-text-h4">{student.education[key]?.schoolName}</h3>
                  <p className="inst-text-p-small">
                    {student.education[key]?.degree}, {student.education[key]?.major}<br />
                    {formatProfileDate(student.education[key]?.startDate)} - {formatProfileDate(student.education[key]?.endDate)}<br />
                    {student.education[key]?.grade}
                  </p>
                  <p className="inst-text-p-small">{student.education[key]?.description}</p>
                </div>
              ))}
            </>
          ) : null}
          
          <hr />

          <h2>Test Scores</h2>

          {student?.assessments?.gre ? <GREScoreBlock data={student.assessments.gre} /> : null}
          
          <hr />

          <h2>Certificates</h2>

          {student?.certificates ? (
            <>
              {Object.keys(student.certificates).map((key) => (
                <div key={key} className="block-item">
                  <h3 className="inst-text-h4">{student.certificates[key]?.title}</h3>
                  <p className="inst-text-p-small">
                    {student.certificates[key]?.issuingOrgName}<br />
                    {formatProfileDate(student.certificates[key]?.issueDate)}
                  </p>
                  <p className="inst-text-p-small">{student.certificates[key]?.description}</p>
                </div>
              ))}
            </>
          ) : null}
        </ShadowCard>
      </section>
    </main>
  );

  return null;
}