import PropTypes from 'prop-types';
import Field from '../Field';
import { comingSoonShort } from '../../shared/appText';
import plus from '../../assets/student/images/plus.svg';
import '../../forms/StudentApplication/StudentApplicationForms.scss';

export default function RecommendationsPlaceholder({ children }) {
  return (
    <div className="student-app-form">
      <p>
        These are a very important part of your application. If you need help attaining them,&nbsp;
        <span className="student-cta-link">Click Here For Advice.</span>&nbsp;
        <span className="coming-soon">{comingSoonShort}</span>
      </p>

      <fieldset>
        <label className="checkbox rec">
          <input type="checkbox" checked disabled />
          <div>
            <span>Recommender Name</span><br />
            <span>Position Title</span>
          </div>
        </label>

        <label className="checkbox rec">
          <input type="checkbox" checked disabled />
          <div>
            <span>Recommender Name</span><br />
            <span>Position Title</span>
          </div>
        </label>

        <label className="checkbox rec">
          <input type="checkbox" checked disabled />
          <div>
            <span>Recommender Name</span><br />
            <span>Position Title</span>
          </div>
        </label>

        <Field>
          <button disabled className="add-one">
            <img src={plus} alt="" />
            <span className="student-cta-link">Send Another Request</span>
            <span className="coming-soon">{comingSoonShort}</span>
          </button>
        </Field>
      </fieldset>

      <Field>
        {children}
      </Field>
    </div>
  );
}

RecommendationsPlaceholder.propTypes = {
  children: PropTypes.node.isRequired,
}