import { useFirebaseSchool } from '../../../context/FirebaseSchoolContext';
import SectionHeader from '../../../components/SectionHeader';
import StudentCard from '../../../components/InstitutionUI/StudentCard';
import BackLink from '../../../components/BackLink';
import carat from '../../../assets/inst/images/down-carat-black.svg';
import './CandidateBuilderPages.scss';

export default function InstitutionCandidateBuilderResultsPage() {
  const { studentsList } = useFirebaseSchool();

  return (
    <main className="inst-candidate-builder">
      <SectionHeader title="Customized Candidate Builder" />
      <div className="inst-candidate-builder__content">
        <p><BackLink to="/institutions/recruiting/candidatebuilder" inst label="Back" /></p>

        <section className="section">
          <div className="section__header">
            <span className="coming-soon">coming soon</span>
            <button disabled>Sort By <img src={carat} alt="" /></button>
          </div>
          <div className="grid-3">
            {studentsList.map(student => (
              <StudentCard key={student.id} student={student} />
            ))}
          </div>
        </section>
      </div>
    </main>
  );
}