import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { useForm } from 'react-hook-form';
import Field from '../../components/Field';
import carat from '../../assets/student/images/down-carat-dark-pink.svg';
import info from '../../assets/student/images/info-black.svg';
import './CalculateROIForm.scss';
import PropTypes from 'prop-types';
import { useState } from 'react';

export default function StudentCalculateROIForm({ showGraph }) {
  const defaultValues = {};
  const [tooltipDisplay, setTooltipDisplay] = useState(null);
  const { register, handleSubmit, formState: { errors, isValid } } = useForm({ defaultValues, mode: 'all' });

  const changeTooltip = tooltip => {
    setTooltipDisplay(tooltipDisplay ? null : tooltip);
  }

  return (
    <form className="student-app-form student-roi-form" onSubmit={handleSubmit(showGraph)}>
      <Accordion preExpanded={['tuition', 'livingExpenses', 'postGraduate']} allowMultipleExpanded allowZeroExpanded>
        <AccordionItem uuid="tuition" activeClassName="accordion__item--expanded">
          <AccordionItemHeading>
            <AccordionItemButton>
              <span className="student-text-h2">Tuition, Student Loans and Other Forms of Financial Aid</span>
              <div>
                <img src={carat} alt="" className="carat" />
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <Field error={errors?.annualIncomeBeforeSchool}>
              <label htmlFor="annualIncomeBeforeSchool">Annual Income Before Returning to School</label><br />
              <input
                {...register('annualIncomeBeforeSchool', { required: 'Required: Must be a number.', valueAsNumber: true })}
                className={errors?.annualIncomeBeforeSchool ? 'error money-field' : 'money-field'}
                placeholder="00000"
                type="number"
              />
              <p className="placeholder-symbol">$</p>
            </Field>
            <Field error={errors?.anticipatedYears}>
              <label htmlFor="anticipatedYears">Anticipated # of Years in Graduate Program</label><br />
              <input
                {...register('anticipatedYears', { required: 'Required: Must be a number.', valueAsNumber: true })}
                className={errors?.anticipatedYears ? 'error' : ''}
                placeholder="4 Years"
                type="number"
              />
            </Field>
            <Field error={errors?.annualTuition}>
              <div className="form-label">
                <label htmlFor="annualTuition">Annual Tuition Costs</label><br />
                <img role="presentation" src={info} alt="" className="info" onClick={() => changeTooltip('annualTuition')}/>
                {tooltipDisplay === 'annualTuition' &&
                  <div className="tooltipContainer">
                    <div className="tooltip">
                      Calculating annual tuition costs can involve a number of factors. You can find information on the individual school&rsquo;s website or for a more detailed breakdown, go to <a href="https://nces.ed.gov/collegenavigator/" target="_blank" rel="noreferrer">https://nces.ed.gov/collegenavigator/</a> and input each school you are interested in.
                    </div>
                  </div>
                }
              </div>
              <input
                {...register('annualTuition', { required: 'Required: Must be a number.', valueAsNumber: true })}
                className={errors?.annualTuition ? 'error money-field' : 'money-field'}
                placeholder="00000"
                type="number"
              />
              <p className="placeholder-symbol">$</p>
            </Field>
            <Field error={errors?.tuitionPaid}>
              <div className="form-label">
                <label htmlFor="tuitionPaid">Tuition Paid With Student Loan</label><br />
                <img role="presentation" src={info} alt="" className="info" onClick={() => changeTooltip('tuitionPaid')}/>
                {tooltipDisplay === 'tuitionPaid' &&
                  <div className="tooltipContainer">
                    <div className="tooltip">
                      Deciding how much of your savings to use for your education is a personal decision. If you decide to supplement  your tuition with loans and financial aid, input the total tuition cost minus what you want to pay personally. This will give you the amount you need for your loan. The loan company will be able to help you determine your monthly costs once you graduate since most school loans are deferred until graduation. Make sure to also optimize your non-loan financial aid options such as scholarships, FAFSA and employer reimbursements if applicable.
                    </div>
                  </div>
                }
              </div>
              <input
                {...register('tuitionPaid', { required: 'Required: must be a number.', valueAsNumber: true })}
                className={errors?.tuitionPaid ? 'error money-field' : 'money-field'}
                placeholder="00000"
                type="number"
              />
              <p className="placeholder-symbol">$</p>
            </Field>
            <Field error={errors?.loanInterest}>
              <div className="form-label">
                <label htmlFor="loanInterest">Loan Interest</label><br />
                <img role="presentation" src={info} alt="" className="info" onClick={() => changeTooltip('loanInterest')}/>
                {tooltipDisplay === 'loanInterest' &&
                  <div className="tooltipContainer">
                    <div className="tooltip">
                      Interest rates on loans can vary month to month, company to company, and can be either fixed or variable. It is best to research different companies and pay attention to reviews and reputation. Refinancing options are also always available if a more favorable rate becomes available down the road.
                    </div>
                  </div>
                }
              </div>
              <input
                {...register('loanInterest', { required: 'Required: Must be a number.', valueAsNumber: true })}
                className={errors?.loanInterest ? 'error' : ''}
                placeholder="4"
                type="number"
                step="0.01"
              />
              <p className="placeholder-symbol percent">%</p>
            </Field>
            <Field error={errors?.paybackYears}>
              <div className="form-label">
                <label htmlFor="paybackYears">Number of Years to Pay Back Loan</label><br />
                <img role="presentation" src={info} alt="" className="info" onClick={() => changeTooltip('paybackYears')}/>
                {tooltipDisplay === 'paybackYears' &&
                  <div className="tooltipContainer">
                    <div className="tooltip">
                      The most common length of time to repay a student loan is 10 years, however some companies may offer repayment terms of up to 25 years. Just be aware that the longer you take to pay off the loan, the more interest you will pay meaning your total out-of-pocket amount will increase significantly as well.
                    </div>
                  </div>
                }
              </div>
              <input
                {...register('paybackYears', { required: 'Required: Must be a number.', valueAsNumber: true })}
                className={errors?.paybackYears ? 'error' : ''}
                placeholder="10 Years"
                type="number"
              />
            </Field>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="livingExpenses" activeClassName="accordion__item--expanded">
          <AccordionItemHeading>
            <AccordionItemButton>
              <span className="student-text-h2">Living Expenses</span>
              <div>
                <img src={carat} alt="" className="carat" />
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <Field error={errors?.livingExpenses}>
              <div className="form-label">
                <label htmlFor="livingExpenses">Total Monthly Living Expenses</label><br />
                <img role="presentation" src={info} alt="" className="info" onClick={() => changeTooltip('livingExpenses')}/>
                {tooltipDisplay === 'livingExpenses' &&
                  <div className="tooltipContainer">
                    <div className="tooltip">
                      Ex: Living expenses include your housing and food expenses; transportation costs including gas, bus fare, parking fees, etc.; clothing expenses such as lab uniforms; entertainment expenses; and general expenses such as phone, internet and cable.
                    </div>
                  </div>
                }
              </div>
              <input
                {...register('livingExpenses', { required: 'Required: Must be a number.', valueAsNumber: true })}
                className={errors?.livingExpenses ? 'error money-field' : 'money-field'}
                placeholder="00000"
                type="number"
              />
              <p className="placeholder-symbol">$</p>
            </Field>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="postGraduate" activeClassName="accordion__item--expanded">
          <AccordionItemHeading>
            <AccordionItemButton>
              <span className="student-text-h2">Post-Graduate School Finances</span>
              <div>
                <img src={carat} alt="" className="carat" />
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <Field error={errors?.annualIncome}>
              <div className="form-label">
                <label htmlFor="annualIncome">Annual Income After Graduate Program Completion</label><br />
                <img role="presentation" src={info} alt="" className="info" onClick={() => changeTooltip('annualIncome')}/>
                {tooltipDisplay === 'annualIncome' &&
                  <div className="tooltipContainer">
                    <div className="tooltip">
                      You can estimate what you will make per year in your chosen profession by going to <a href="https://www.bls.gov/" target="_blank" rel="noreferrer">https://www.bls.gov/</a> to see average salaries in your field of study. (In a future state of this product we may have a the ability to also provide that information here.)
                    </div>
                  </div>
                }
              </div>
              <input
                {...register('annualIncome', { required: 'Required: Must be a number.', valueAsNumber: true })}
                className={errors?.annualIncome ? 'error money-field' : 'money-field'}
                placeholder="00000"
                type="number"
              />
              <p className="placeholder-symbol">$</p>
            </Field>
            <Field error={errors?.postGraduationExpenses}>
              <div className="form-label">
                <label htmlFor="postGraduationExpenses">Monthly Living Expenses Post-Graduation</label><br />
                <img role="presentation" src={info} alt="" className="info" onClick={() => changeTooltip('postGraduationExpenses')}/>
                {tooltipDisplay === 'postGraduationExpenses' &&
                  <div className="tooltipContainer">
                    <div className="tooltip">
                      Your living expense factors while you were studying for your degree will translate to your post-graduate living expenses as well, however you will also need to factor in your school loan payment here.
                    </div>
                  </div>
                }
              </div>
              <input
                {...register('postGraduationExpenses', { required: 'Required: Must be a number.', valueAsNumber: true })}
                className={errors?.postGraduationExpenses ? 'error money-field' : 'money-field'}
                placeholder="00000"
                type="number"
              />
              <p className="placeholder-symbol">$</p>
            </Field>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>

      <Field>
        <button disabled={!isValid} type="submit">Calculate</button>
      </Field>
    </form>
  );
}

StudentCalculateROIForm.propTypes = {
  showGraph: PropTypes.func,
  formData: PropTypes.shape({
    annualIncomeBeforeSchool: PropTypes.number,
    anticipatedYears: PropTypes.number,
    annualTuition: PropTypes.number,
    tuitionPaid: PropTypes.number,
    loanInterest: PropTypes.number,
    paybackYears: PropTypes.number,
    livingExpenses: PropTypes.number,
    annualIncome: PropTypes.number,
    postGraduationExpenses: PropTypes.number
  })
};
