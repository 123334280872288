import { useMemo, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import SectionHeader from '../../../components/SectionHeader';
import Modal from '../../../components/Modal';
import { useFirebaseStudent } from '../../../context/FirebaseStudentContext';
import { useFirebasePrograms } from '../../../context/FirebaseProgramDataContext';
import StudentApplicationProgramInfoForm from '../../../forms/StudentApplication/StudentApplicationProgramInfoForm';
import StudentApplicationStudentProfileForm from '../../../forms/StudentApplication/StudentApplicationStudentProfileForm';
import StudentApplicationCitizenshipForm from '../../../forms/StudentApplication/StudentApplicationCitizenshipForm';
import StudentApplicationEducationForm, { educationDefault } from '../../../forms/StudentApplication/StudentApplicationEducationForm';
import StudentApplicationTestScoresForm from '../../../forms/StudentApplication/StudentApplicationTestScoresForm';
import ProgressCircle from '../../../components/ProgressCircle';
import ResumeFormPlaceholder from '../../../components/StudentApplications/ResumeFormPlaceholder';
import PersonalStatementFormPlaceholder from '../../../components/StudentApplications/PersonalStatementFormPlaceholder';
import RecommendationsPlaceholder from '../../../components/StudentApplications/RecommendationsPlaceholder';
import BackLink from '../../../components/BackLink';
import carat from '../../../assets/student/images/down-carat-blue.svg';
import greenCheck from '../../../assets/student/images/check-green.svg';
import appSuccessIllustration from '../../../assets/student/images/app-success-illustration.svg';
import { colors } from '../../../shared/colors';
import { appSections } from '../../../components/StudentApplications/InProgressAppCard';
import './ApplicationPage.scss';

const appsLink = '/students/applications';

export default function StudentApplicationPage() {
  const navigate = useNavigate();
  const { programId } = useParams();
  const { student, addOrUpdateApplication } = useFirebaseStudent();
  const { programsList } = useFirebasePrograms();

  const application = useMemo(() => {
    if (!programId || !student?.applications) return null;
    return student.applications?.[programId];
  }, [programId, student?.applications]);
  
  const program = useMemo(() => {
    if (!programsList?.length || !programId) return null;
    return programsList?.find(p => p.programId === programId);
  }, [programsList, programId]);

  const completeSections = useMemo(() => {
    return appSections.map(s => s.key).filter(key => application?.[key]);
  }, [application]);

  const percentComplete = useMemo(() => {
    return completeSections?.length ? Math.round((completeSections.length / appSections.length) * 100) : 0;
  }, [completeSections]);

  const programInfoDefaultValues = useMemo(() => ({
    degree: '',
    startDate: '',
    ...application?.programInfo,
  }), [application?.programInfo]);

  const studentProfileDefaultValues = useMemo(() => ({
    alias: student?.alias,
    birthCountry: '',
    birthYear: '',
    gender: '',
    mailingAddress: {
      city: '',
      state: student?.basicInfo?.stateOfResidence || '',
      country: '',
      sameAsPermanent: false,
    },
    permanentAddress: {
      city: '',
      state: student?.basicInfo?.stateOfResidence || '',
      country: '',
    },
    needFinancialAid: '',
    pronouns: student?.basicInfo?.pronouns || '',
    ...application?.studentProfile,
  }), [application?.studentProfile, student?.basicInfo?.pronouns, student?.basicInfo?.stateOfResidence, student?.alias]);

  const citizenshipDefaultValues = useMemo(() => ({
    status: student?.basicInfo?.citizenshipStatus || '',
    country: student?.basicInfo?.citizenshipCountry || '',
    ethnicity: student?.basicInfo?.ethnicity || '',
    ethnicGroups: student?.basicInfo?.ethnicGroups || [],
    ...application?.citizenship,
  }), [application?.citizenship, student?.basicInfo]);

  const testScoresDefaultValues = useMemo(() => ({
    gre: {
      analyticalWriting: null,
      dateTaken: '',
      quantitative: null,
      verbal: null,
      ...student?.assessments?.gre,
    },
    ...application?.testScores,
  }), [application?.testScores, student?.assessments?.gre]);

  const educationListDefaultValues = useMemo(() => {
    if (application?.education) {
      return application.education;
    } else if (student?.education) {
      return Object.keys(student.education).map(key => student.education[key]);
    } else {
      return [educationDefault];
    }
  }, [application?.education, student?.education]);

  const showSubmittedModal = useMemo(() => {
    return application?.status === 'submitted';
  }, [application?.status]);

  const openNextSection = (nextSectionKey) => {
    const nextSection = document.getElementById(`accordion__heading-${nextSectionKey}`);
    nextSection?.click();
    nextSection?.scrollIntoView(true);
  };

  const saveAndNext = useCallback((data, nextSectionKey, successMessage) => {
    addOrUpdateApplication('in-progress', programId, data, successMessage);
    openNextSection(nextSectionKey);
  }, [programId, addOrUpdateApplication]);

  const submitApplication = useCallback(() => {
    addOrUpdateApplication('submitted', programId, application);
  }, [programId, addOrUpdateApplication, application]);

  if (student && application) return (
    <div className="student-app-page">
      <SectionHeader title="Applications" />
      <main className="student-app-page__content">
        <BackLink to={appsLink} label="Back" />
        <div className="student-app-page__header">
          <div>
            <h2 className="student-text-h1">{application.programTitle}</h2>
            <p className="student-text-h2">{application.schoolName}</p>
          </div>
          <div>
            <ProgressCircle
              size={96}
              fgColor={colors.blue}
              bgColor={colors.lightBlue}
              percentComplete={percentComplete}
            >
              <span className="student-text-h3">{completeSections.length} of 8</span>
            </ProgressCircle>
          </div>
        </div>
        <div className="student-app-accordion">
          <Accordion preExpanded={['programInfo']}>
            <AccordionItem uuid="programInfo" activeClassName="accordion__item--expanded">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <span className="student-text-h2">The Basics</span>
                  <div>
                    {application?.programInfo ? <img src={greenCheck} alt="Complete" /> : null}
                    <img src={carat} alt="" className="carat" />
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                {programInfoDefaultValues ? (
                  <StudentApplicationProgramInfoForm
                    defaultValues={programInfoDefaultValues}
                    program={program}
                    saveUpdates={(data) => saveAndNext({ programInfo: data }, 'studentProfile', 'Program Information has successfully been saved')}
                  />
                ) : null}
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem uuid="studentProfile" activeClassName="accordion__item--expanded">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <span className="student-text-h2">Student Profile</span>
                  <div>
                    {application?.studentProfile ? <img src={greenCheck} alt="Complete" /> : null}
                    <img src={carat} alt="" className="carat" />
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                {studentProfileDefaultValues ? (
                  <StudentApplicationStudentProfileForm
                    defaultValues={studentProfileDefaultValues}
                    saveUpdates={(data) => saveAndNext({ studentProfile: data }, 'citizenship', 'Student Profile information has successfully been saved')}
                  />
                ) : null}
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem uuid="citizenship" activeClassName="accordion__item--expanded">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <span className="student-text-h2">Citizenship Status</span>
                  <div>
                    {application?.citizenship ? <img src={greenCheck} alt="Complete" /> : null}
                    <img src={carat} alt="" className="carat" />
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                {citizenshipDefaultValues ? (
                  <StudentApplicationCitizenshipForm
                    defaultValues={citizenshipDefaultValues}
                    saveUpdates={(data) => saveAndNext({ citizenship: data }, 'education', 'Citizenship Status has successfully been saved')}
                  />
                ) : null}
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem uuid="education" activeClassName="accordion__item--expanded">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <span className="student-text-h2">Education History</span>
                  <div>
                    {application?.education ? <img src={greenCheck} alt="Complete" /> : null}
                    <img src={carat} alt="" className="carat" />
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                {educationListDefaultValues ? (
                  <StudentApplicationEducationForm
                    defaultValues={{ education: educationListDefaultValues }}
                    saveUpdates={(data) => saveAndNext(data, 'testScores', 'Education History has successfully been saved')}
                  />
                ) : null}
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem uuid="testScores" activeClassName="accordion__item--expanded">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <span className="student-text-h2">Test Scores</span>
                  <div>
                    {application?.testScores ? <img src={greenCheck} alt="Complete" /> : null}
                    <img src={carat} alt="" className="carat" />
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                {testScoresDefaultValues ? (
                  <StudentApplicationTestScoresForm
                    defaultValues={testScoresDefaultValues}
                    saveUpdates={(data) => saveAndNext({ testScores: data }, 'resume', 'Test Scores have successfully been saved')}
                  />
                ) : null}
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem uuid="resume" activeClassName="accordion__item--expanded">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <span className="student-text-h2">Resume/CV</span>
                  <div>
                    {application?.resume ? <img src={greenCheck} alt="Complete" /> : null}
                    <img src={carat} alt="" className="carat" />
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <ResumeFormPlaceholder
                  onContinue={() => saveAndNext({ resume: true }, 'personalStatement', 'Resume/CV has successfully been saved')}
                />
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem uuid="personalStatement" activeClassName="accordion__item--expanded">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <span className="student-text-h2">Personal Statement and/or Statement of Purpose</span>
                  <div>
                    {application?.personalStatement ? <img src={greenCheck} alt="Complete" /> : null}
                    <img src={carat} alt="" className="carat" />
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <PersonalStatementFormPlaceholder
                  onContinue={() => saveAndNext({ personalStatement: true }, 'recommendations', 'Personal Documents have successfully been saved')}
                />
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem uuid="recommendations" activeClassName="accordion__item--expanded">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <span className="student-text-h2">Letters of Recommendation</span>
                  <div>
                    <img src={carat} alt="" className="carat" />
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <RecommendationsPlaceholder>
                  <button
                    type="button"
                    className="submit"
                    disabled={!(
                      application?.programInfo
                      && application?.studentProfile
                      && application?.citizenship
                      && application?.education
                      && application?.testScores
                      && application?.resume
                      && application?.personalStatement
                    )}
                    onClick={submitApplication}
                  >Submit</button>
                </RecommendationsPlaceholder>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
          <Modal
            closeModal={() => navigate(appsLink)}
            title="You submitted your application!"
            isOpen={showSubmittedModal}
          >
            <div className="student-app-success-modal">
              <h1 className="student-text-h1">Wow! What an accomplishment! 👏</h1>
              <img src={appSuccessIllustration} alt="" />
              <p className="student-text-h2">You&apos;ve successfully submitted this application!</p>
              <p className="student-text-p">Try to take some time to relax and care for yourself while you await a response.</p>
            </div>
          </Modal>
        </div>
      </main>
    </div>
  );

  return null;
}