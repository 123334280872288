import { Outlet, NavLink, Link } from 'react-router-dom';
import { useFirebaseAuth } from '../../context/FirebaseAuthContext';
import ShadowCard from '../../components/ShadowCard';
import homeIcon from '../../assets/icons/home.svg';
import profileIcon from '../../assets/icons/profile.svg';
import searchIcon from '../../assets/icons/search.svg';
import folderIcon from '../../assets/icons/folder.svg';
import calendarIcon from '../../assets/icons/calendar.svg';
import questionIcon from '../../assets/icons/question.svg';
import documentsIcon from '../../assets/icons/documents.svg';
import checkIcon from '../../assets/icons/check.svg';
import briefcaseIcon from '../../assets/icons/briefcase.svg';
import logoutIcon from '../../assets/icons/logout.svg';
import logo from '../../assets/student/images/grad-connector.svg';
import contact from '../../assets/student/images/contact.svg';
import './StudentLayout.scss';

export default function StudentLayout() {
  const { signOut } = useFirebaseAuth();

  return (
    <div className="student-layout-container">
      <aside className="student-layout-container__sidebar">
        <Link to="/students">
          <img className="logo" src={logo} alt="Grad Connector" />
        </Link>

        <nav className="student-text-h3">
          <ul>
            <li>
              <NavLink to="" end>
                <img src={homeIcon} alt="" /> Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink to="profile">
                <img src={profileIcon} alt="" /> Profile
              </NavLink>
            </li>
            <li>
              <NavLink to="explore">
                <img src={searchIcon} alt="" /> Explore
              </NavLink>
            </li>
            <li>
              <NavLink to="applications">
                <img src={folderIcon} alt="" /> Applications
              </NavLink>
            </li>
            <li>
              <NavLink to="todos">
                <img src={calendarIcon} alt="" /> To-Do
              </NavLink>
            </li>
            <li>
              <NavLink to="resources">
                <img src={questionIcon} alt="" /> Resources
              </NavLink>
            </li>
            <li><NavLink to="documents"><img src={documentsIcon} alt="" /> Documents</NavLink></li>
            <li><NavLink to="assessments"><img src={checkIcon} alt="" /> Assessments</NavLink></li>
            <li><NavLink to="careers"><img src={briefcaseIcon} alt="" /> Career Resources</NavLink></li>
          </ul>

          <hr />

          <button className="logout" onClick={signOut}><img src={logoutIcon} alt="" /> Sign Out</button>
        </nav>
        <ShadowCard customClassNames="contact-us">
          <img src={contact} alt="" />
          <p className="student-text-p-large">How are we doing?</p>
          <p className="student-text-p-small">We want to know what you think about this website</p>
          <a href={`mailto:${process.env.REACT_APP_ETS_EMAIL}`} target="_blank" rel="noreferrer">Start Here</a>
        </ShadowCard>
      </aside>
      <div className="student-layout-container__main">
        <Outlet />
      </div>
    </div>
  )
}