import { useEffect } from 'react';
import './PurchaseList.scss';
import SectionHeader from '../../../components/SectionHeader';
import InstitutionPurchaseListHomePage from './PurchaseListHome';
import InstitutionPurchaseListSearchPage from './PurchaseListSearch';
import InstitutionPurchaseListReviewOrderPage from './PurchaseListReviewOrder';
import InstitutionPurchaseListPlaceOrderPage from './PurchaseListPlaceOrder';
import InstitutionPurchaseListConfirmationPage from './PurchaseListConfirmation';
import { useState } from 'react';

export default function InstitutionPurchaseListPage() {
  const [view, setView] = useState('home');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [view]);

  const changeView = (view) => {
    setView(view);
  }

  return (
    <div className="list-purchase">
      <SectionHeader title="Purchase a List" />
      <div className="list-purchase-content">
        {view === 'home' && <InstitutionPurchaseListHomePage changeView={changeView}/>}
        {view === 'search' && <InstitutionPurchaseListSearchPage changeView={changeView}/>}
        {view === 'review-order' && <InstitutionPurchaseListReviewOrderPage changeView={changeView}/>}
        {view === 'place-order' && <InstitutionPurchaseListPlaceOrderPage changeView={changeView}/>}
        {view === 'confirmation' && <InstitutionPurchaseListConfirmationPage changeView={changeView}/>}
      </div>
    </div>
  );
}