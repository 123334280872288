import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import ControlledProgramMultiSelect from '../../components/StudentSurveys/ControlledProgramMultiSelect';
import Field from '../../components/Field';
import './AddApplicationForm.scss';

const defaultValues = {
  targetPrograms: '',
};

export default function AddApplicationForm({ programOptions, saveData }) {
  const { control, handleSubmit, formState: { errors, isValid }} = useForm({ defaultValues, mode: 'all' });

  const structureAndSaveData = (data) => {
    const { targetPrograms } = data;
    const structuredData = targetPrograms.map(val => {
      const values = val.split('-');
      return {
        programId: values[0],
        programTitle: values[1],
        schoolName: values[2],
      };
    });
    saveData({ targetPrograms: structuredData });
  }

  return (
    <form onSubmit={handleSubmit(structureAndSaveData)} className="student-app-form student-add-app-form">
      <Field error={errors?.targetPrograms}>
        <label htmlFor="targetPrograms">Programs</label><br />
        <ControlledProgramMultiSelect
          control={control}
          defaultValue={[]}
          name="targetPrograms"
          options={programOptions.map(({ programTitle, schoolName, programId }) => ({
            // serialized value allows text search by either field
            value: `${programId}-${programTitle}-${schoolName}`,
            programTitle,
            schoolName,
          }))}
          customClassNames="student-app-select student-programs-select"
          placeholder="Choose programs"
        />
      </Field>

      <Field center>
        <button type="submit" disabled={!isValid}>Done</button>
      </Field>
    </form>
  );
}

AddApplicationForm.propTypes = {
  programOptions: PropTypes.arrayOf(PropTypes.shape({
    programTitle: PropTypes.string.isRequired,
    schoolName: PropTypes.string.isRequired,
    programId: PropTypes.string.isRequired,
  })),
  saveData: PropTypes.func.isRequired,
}