import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { colors } from '../../shared/colors';
import './SurveySteps.scss';

function Step({ r, stepX, stepY, stepLabelY, label, isComplete, labelHeight, url }) {
  return (
    <g>
      <circle
        r={isComplete ? r : r-1}
        cx={stepX + r}
        cy={stepY}
        fill={isComplete ? colors.darkGreen : colors.lime}
        stroke={isComplete ? 'transparent' : colors.darkGreen}
        strokeWidth={2}
      />
      <foreignObject x={0} y={stepLabelY} height={labelHeight} width={170}>
        {url ? (
          <p className="step-label student-text-h4">
            <Link to={url}>
              {label} &nbsp; {isComplete ? '🎉' : null}
            </Link>
          </p>
        ) : (
          <p className="step-label student-text-h4">
            {label} &nbsp; {isComplete ? '🎉' : null}
          </p>
        )}
      </foreignObject>
    </g>
  );
}

Step.propTypes = {
  r: PropTypes.number.isRequired,
  stepX: PropTypes.number.isRequired,
  stepY: PropTypes.number.isRequired,
  stepLabelY: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  isComplete: PropTypes.bool.isRequired,
  labelHeight: PropTypes.number.isRequired,
  url: PropTypes.string,
}

export default function SurveySteps({ steps, lastCompleteIndex }) {
  const barWidth = 16;
  const labelWidth = 180;
  const svgHeight = 650;
  const svgWidth = barWidth + labelWidth;
  const r = barWidth / 2;

  const percentComplete = (lastCompleteIndex + 1.5) / (steps.length - 1);

  const getPositionY = (index, stepCount, height, r) => {
    if (index === 0) {
      return r;
    } else if (index === stepCount - 1) {
      return height - r;
    } else {
      const sections = stepCount - 1;
      const sectionHeight = height / sections;
      return sectionHeight * index;
    }
  }

  const getLabelPositionY = (index, stepCount, height) => {
    const sectionHeight = height / (stepCount - 1);
    if (index === 0) {
      return 0;
    } else if (index === stepCount - 1) {
      return (sectionHeight * index) - barWidth;
    } else {
      return (sectionHeight * index) - r;
    }
  }

  return (
    <svg width={svgWidth} height={svgHeight + 5} className="student-survey-steps">
      <rect x={labelWidth} y={0} height={svgHeight} width={barWidth} rx={r} fill={colors.lightLime} />
      <rect x={labelWidth} y={0} height={percentComplete > 0 ? svgHeight * percentComplete + r : 0} width={barWidth} rx={r} fill={colors.green} />

      {steps.map(({ label, url, isComplete }, index) => (
        <Step
          key={label}
          r={r}
          stepX={labelWidth}
          stepY={getPositionY(index, steps.length, svgHeight, r)}
          stepLabelY={getLabelPositionY(index, steps.length, svgHeight)}
          label={label}
          isComplete={isComplete || false}
          labelHeight={(svgHeight / steps.length) - barWidth}
          url={url}
        />
      ))}
    </svg>
  );
}

SurveySteps.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    url: PropTypes.string,
    isComplete: PropTypes.bool,
  })),
  lastCompleteIndex: PropTypes.number,
}