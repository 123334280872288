import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import EditButton from '../../../components/InstitutionProfile/EditButton';
import { useFirebasePrograms } from '../../../context/FirebaseProgramDataContext';
import close from '../../../assets/student/images/collapse.svg';
import open from '../../../assets/student/images/add.svg';
import pencil from '../../../assets/inst/images/pencil.svg';

const FAQEditButton = () => (
  <EditButton>
    <img src={pencil} alt="" /> Edit Answer
  </EditButton>
);

export default function InstitutionProgramProfileFAQPage() {
  const { programId } = useParams();
  const { programs } = useFirebasePrograms();

  const data = useMemo(() => {
    if (programs && programId) return programs[programId];
    return null;
  }, [programs, programId]);

  if (data) return (
    <main className="school-profile-page">
      <section className="section">
        <div className="faq-header faq-header--inst">
          <h2 className="inst-text-h2">General Admissions</h2>
        </div>
        <Accordion allowMultipleExpanded allowZeroExpanded className="faq-accordion faq-accordion--inst">
          <AccordionItem activeClassName="accordion__item--expanded">
            <AccordionItemHeading>
              <AccordionItemButton>
                Q: How do I apply to {data.schoolName}?
                <img src={open} alt="" className="open" />
                <img src={close} alt="" className="close" />
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>A: {data.faq1}</p>
              <FAQEditButton />
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem activeClassName="accordion__item--expanded">
            <AccordionItemHeading>
              <AccordionItemButton>
                Q: What are the admission requirements?
                <img src={open} alt="" className="open" />
                <img src={close} alt="" className="close" />
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>A: {data.faq2}</p>
              <FAQEditButton />
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </section>
      
      <section className="section">
        <div className="faq-header faq-header--inst">
          <h2 className="inst-text-h2">Applications</h2>
        </div>
        <Accordion allowMultipleExpanded allowZeroExpanded className="faq-accordion faq-accordion--inst">
          <AccordionItem activeClassName="accordion__item--expanded">
            <AccordionItemHeading>
              <AccordionItemButton>
                Q: If I have previously submitted an application, must I resubmit application material?
                <img src={open} alt="" className="open" />
                <img src={close} alt="" className="close" />
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>A: {data.faq3}</p>
              <FAQEditButton />
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem activeClassName="accordion__item--expanded">
            <AccordionItemHeading>
              <AccordionItemButton>
                Q: Is it okay to have my official standardized test scores and electronic letters of recommendation sent before I&apos;ve submitted my application?
                <img src={open} alt="" className="open" />
                <img src={close} alt="" className="close" />
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>A: {data.faq4}</p>
              <FAQEditButton />
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem activeClassName="accordion__item--expanded">
            <AccordionItemHeading>
              <AccordionItemButton>
                Q: How will I be notified of the school&apos;s decision?
                <img src={open} alt="" className="open" />
                <img src={close} alt="" className="close" />
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>A: {data.faq5}</p>
              <FAQEditButton />
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </section>
      
      <section className="section">
        <div className="faq-header faq-header--inst">
          <h2 className="inst-text-h2">Test Scores</h2>
        </div>
        <Accordion allowMultipleExpanded allowZeroExpanded className="faq-accordion faq-accordion--inst">
          <AccordionItem activeClassName="accordion__item--expanded">
            <AccordionItemHeading>
              <AccordionItemButton>
                Q: Does {data.schoolName} accept the at-home GRE and/or the TOEFL iBT?
                <img src={open} alt="" className="open" />
                <img src={close} alt="" className="close" />
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>A: {data.faq6}</p>
              <FAQEditButton />
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem activeClassName="accordion__item--expanded">
            <AccordionItemHeading>
              <AccordionItemButton>
                Q: If my speaking score is below the minimum, should I apply, and will my application be reviewed?
                <img src={open} alt="" className="open" />
                <img src={close} alt="" className="close" />
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>A: {data.faq7}</p>
              <FAQEditButton />
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </section>      
    </main>
  );

  return null;
}