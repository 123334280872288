import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import searchIcon from '../../assets/icons/search.svg';
import './ControlledSearchInput.scss';

export default function ControlledSearchInput({ control, defaultValue, name, placeholder, reset }) {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field }) => (
        <div className="search-input">
          <img src={searchIcon} alt="" />
          <input
            className="student-text-p"
            {...field}
            type="text"
            placeholder={placeholder}
          />
          <button aria-label="Clear" type="button" onClick={reset}>&times;</button>
        </div>
      )}

    />
  )
}

ControlledSearchInput.propTypes = {
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  reset: PropTypes.func,
};