import Filters from '../../../components/StudentSearch/Filters';
import { useFirebasePrograms } from '../../../context/FirebaseProgramDataContext';
import ProgramCard from '../../../components/StudentSearch/ProgramCard';
import BackLink from '../../../components/BackLink';
import FavoritesLink from '../../../components/StudentFavorites/FavoritesLink';
import MessagingPopup from '../../../components/StudentMessaging/MessagingPopup';
import down from '../../../assets/student/images/down-carat-dark-pink.svg';
import { comingSoonShort } from '../../../shared/appText';
import './SearchResultsPage.scss';

export default function StudentProgramResultsPage() {
  const { filteredProgramsList } = useFirebasePrograms();

  return (
    <>
      <main className="student-search-results">
        <div className="links">
          <BackLink to="/students/explore" label="Back to Explore" />
          <FavoritesLink />
        </div>
        <div className="filter-container">
          <Filters />
        </div>
        <section className="section results-container">
          <div className="section__header">
            <h2 className="student-text-h2">{filteredProgramsList.length} Search Results</h2>
            <div className="search-results__sort">
              <span className="coming-soon">{comingSoonShort}</span>
              <span className="sort">
                Sort by
                <img src={down} alt="" />
              </span>
            </div>
          </div>
          {filteredProgramsList.map(p => (
            <ProgramCard
              key={p.programId}
              program={p}
            />
          ))}
        </section>
      </main>
      <MessagingPopup />
    </>
  );
}