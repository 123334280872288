import PropTypes from 'prop-types';
import ShadowCard from '../ShadowCard';
import './FinancialAidCard.scss';

export default function FinancialAidCard({ headline, text, link }) {
  return (
    <ShadowCard customClassNames="inst-school-profile-financial-aid-card">
      <h3 className="inst-text-h2">{headline}</h3>
      <p className="inst-text-p">{text}</p>
      <a href={link} target="_blank" rel="noreferrer">Learn More</a>
    </ShadowCard>
  );
}

FinancialAidCard.propTypes = {
  headline: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}