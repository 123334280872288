import { Outlet } from 'react-router-dom';
import TabNav from '../../components/InstitutionUI/TabNav';
import SectionHeader from '../../components/SectionHeader';
import './InstitutionPricingModelsLayout.scss';

export default function InstitutionPricingModelsLayout() {
  return (
    <div className="inst-pricing-models">
      <SectionHeader title="Pricing Models" />
      <TabNav tabs={[
        { label: 'Outcome-Based', to: '', end: true },
        { label: 'Feature-Based', to: 'features' },
        { label: 'Leads-Based', to: 'leads' }
      ]} />
      <div className="inst-pricing-models__content">
        <Outlet />
      </div>
    </div>
  );
}