import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { programSizeOptions } from '../../shared/formOptions';
import ControlledMultiSelect from '../../components/ControlledMultiSelect';
import Field from '../../components/Field';
import { studentPropTypes } from '../../shared/propTypes';
import next from '../../assets/student/images/right-carat-black.svg';
import SurveyNavButtonWrapper from '../../components/StudentSurveys/SurveyNavButtonWrapper';
import './StudentOnboardingForm.scss';

export default function StudentOnboardingSchoolPrefsForm({ defaultValues, saveData, goBack }) {
  const { control, handleSubmit } = useForm({ defaultValues });

  return (
    <form onSubmit={handleSubmit(saveData)} className="student-onboarding-form">
      <Field>
        <label htmlFor="programSize">I prefer</label>&nbsp;
        <ControlledMultiSelect
          control={control}
          defaultValue={[]}
          name="programSize"
          options={programSizeOptions}
          customClassNames="student-survey-select"
          placeholder="Select all that apply"
          singleOptionValue="No preference"
        />
      </Field>

      <Field>
        <SurveyNavButtonWrapper>
          <button onClick={goBack} className="prev">
            <img src={next} alt="Go Back" />
          </button>
          <button type="submit" className="next">
            <img src={next} alt="Save & Next" />
          </button>
        </SurveyNavButtonWrapper>
      </Field>
    </form>
  )
}

StudentOnboardingSchoolPrefsForm.defaultProps = {
  defaultValues: {
    programSize: '',
  },
}

StudentOnboardingSchoolPrefsForm.propTypes = {
  defaultValues: PropTypes.shape({
    programSize: studentPropTypes.programSize,
  }),
  saveData: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
}