import PropTypes from 'prop-types';
import send from '../../assets/inst/images/send.svg';
import MailToETSLink from '../MailToETSLink';
import { useFirebasePrograms } from '../../context/FirebaseProgramDataContext';

export default function ContactStudentButton({ alias }) {
  const { activeProgram } = useFirebasePrograms();

  if (alias && activeProgram) return (
    <MailToETSLink subject={`Question - TO: ${alias}, FROM: ${activeProgram.programTitle}`}>
      <img src={send} alt="Send a message to this student" />
    </MailToETSLink>
  );

  return  null;
}

ContactStudentButton.propTypes = {
  alias: PropTypes.string.isRequired,
}