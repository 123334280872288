import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useFirebaseAuth } from '../context/FirebaseAuthContext';

export default function RequireAuth({ requiredRole, children }) {
  const { authState, userRole } = useFirebaseAuth();
  const location = useLocation();

  if (authState === 'loading') {
    return <h1>Loading...</h1>;
  }

  if (!userRole) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (userRole !== requiredRole) {
    return <Navigate to={userRole === 'student' ? '/students' : '/institutions'} />
  }

  return children;
}

RequireAuth.propTypes = {
  requiredRole: PropTypes.oneOf(['student', 'institution']).isRequired,
  children: PropTypes.node,
};