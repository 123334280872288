import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './Button.scss';

export default function ButtonLink({ to, variant, children }) {
  return (
    <Link to={to} className={`inst-btn inst-btn--${variant}`}>
      {children}
    </Link>
  )
}

ButtonLink.defaultProps = {
  variant: 'primary'
}

ButtonLink.propTypes = {
  to: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['primary', 'outline']),
  children: PropTypes.node,
}