import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import ControlledProgramMultiSelect from '../../components/StudentSurveys/ControlledProgramMultiSelect';
import { useFirebasePrograms } from '../../context/FirebaseProgramDataContext';
import Field from '../../components/Field';
import next from '../../assets/student/images/right-carat-black.svg';
import './StudentOnboardingForm.scss';
import SurveyNavButtonWrapper from '../../components/StudentSurveys/SurveyNavButtonWrapper';

export default function StudentOnboardingSelectSchoolsForm({ saveData, goBack }) {
  const { control, handleSubmit } = useForm();
  const { programsList } = useFirebasePrograms();

  const structureAndSaveData = (data) => {
    const { targetPrograms } = data;
    const structuredData = targetPrograms.map(val => {
      const values = val.split('-');
      return {
        programId: values[0],
        programTitle: values[1],
        schoolName: values[2],
      };
    });
    saveData({ targetPrograms: structuredData });
  }

  return (
    <form onSubmit={handleSubmit(structureAndSaveData)} className="student-onboarding-form">
      <Field>
        <label htmlFor="targetPrograms">The schools I am interested in are</label><br />
        <ControlledProgramMultiSelect
          control={control}
          defaultValue={[]}
          name="targetPrograms"
          options={programsList.map(({ programTitle, schoolName, programId }) => ({
            // serialized value allows text search by either field
            value: `${programId}-${programTitle}-${schoolName}`,
            programTitle,
            schoolName,
          }))}
          customClassNames="student-survey-select school-select"
          placeholder="Select all that apply"
        />
      </Field>
      <Field>
        <SurveyNavButtonWrapper>
          <button onClick={goBack} className="prev">
            <img src={next} alt="Go Back" />
          </button>
          <button type="submit" className="next">
            <img src={next} alt="Save & Next" />
          </button>
        </SurveyNavButtonWrapper>
      </Field>
    </form>
  )
}

StudentOnboardingSelectSchoolsForm.propTypes = {
  saveData: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
}