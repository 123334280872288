import { Outlet, NavLink, Link, useLocation } from 'react-router-dom';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState
} from 'react-accessible-accordion';
import { useFirebaseAuth } from '../../context/FirebaseAuthContext';
import ScrollToTop from '../../components/ScrollToTop';
import ShadowCard from '../../components/ShadowCard';
import MessagingPopup from '../../components/InstitutionMessaging/MessagingPopup';
import logo from '../../assets/inst/images/logo.svg';
import homeIcon from '../../assets/icons/home.svg';
import searchIcon from '../../assets/icons/search.svg';
import folderIcon from '../../assets/icons/folder.svg';
import questionIcon from '../../assets/icons/question.svg';
import lightningIcon from '../../assets/icons/lightning.svg';
import profileIcon from '../../assets/icons/profile-check.svg';
import logoutIcon from '../../assets/icons/logout.svg';
import cartIcon from '../../assets/icons/cart.svg';
import contact from '../../assets/inst/images/contact.svg';
import carat from '../../assets/inst/images/down-carat-black.svg';
import './InstitutionLayout.scss';

export default function InstitutionLayout() {
  const { signOut } = useFirebaseAuth();
  const location = useLocation();

  return (
    <>
      <ScrollToTop />
      <div className="inst-layout-container">
        <aside className="inst-layout-container__sidebar">
          <div className="inst-layout-container__sidebar-content">
            <Link to="/institutions">
              <img className="logo" src={logo} alt="Grad Connector" />
            </Link>
            <nav>
              <ul>
                <li>
                  <NavLink to="" end><img src={homeIcon} alt="" /> Dashboard</NavLink>
                </li>
                <li>
                  <NavLink to="recruiting" end className="justify">
                    <span><img src={searchIcon} alt="" /> Recruiting Tools</span>
                    <img src={carat} alt="" className="carat" />
                  </NavLink>
                </li>
                {location?.pathname?.includes('recruiting') ? (
                  <li>
                    <ul>
                      <li><NavLink to="recruiting/studentsearch">Student Search</NavLink></li>
                      <li><NavLink to="recruiting/candidatebuilder">Candidate Builder</NavLink></li>
                      <li><NavLink to="recruiting/purchaselist">Purchase a List</NavLink></li>
                      <li><NavLink to="recruiting/recommendations">Recommendations</NavLink></li>
                      <li>
                        <NavLink to="recruiting/favorites">
                          Favorites & Matches
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                ) : null}
                <li><NavLink to="applications"><img src={folderIcon} alt="" /> Applicants</NavLink></li>
                <li><NavLink to="tips"><img src={questionIcon} alt="" /> Tips & Best Practices</NavLink></li>
                <li>
                  <Accordion allowZeroExpanded>
                    <AccordionItem>
                      <AccordionItemState>
                        {({ expanded }) => (
                          <AccordionItemHeading>
                            <AccordionItemButton className={`accordion__button justify ${expanded ? 'expanded' : ''}`}>
                              <span><img src={lightningIcon} alt="" /> Insights</span>
                              <img src={carat} alt="" className="carat" />
                            </AccordionItemButton>
                          </AccordionItemHeading>
                        )}
                      </AccordionItemState>
                      <AccordionItemPanel>
                        <ul>
                          <li><NavLink to="studentinsights">Student Insights</NavLink></li>
                          <li><NavLink to="peerprograms">Peer Programs</NavLink></li>
                        </ul>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </li>
              </ul>

              <hr />

              <ul>
                <li>
                  <NavLink
                    to="schoolprofile"
                    className={({ isActive }) => isActive || location?.pathname?.includes('programprofile') ? 'active' : ''}
                  >
                    <img src={profileIcon} alt="" /> Profile
                  </NavLink>
                </li>
                <li><button onClick={signOut} className="logout"><img src={logoutIcon} alt="" /> Sign Out</button></li>
                <li><NavLink to="pricingmodels"><img src={cartIcon} alt="" /> Pricing Models</NavLink></li>
              </ul>
            </nav>
            <ShadowCard customClassNames="contact-us">
              <img src={contact} alt="" />
              <p className="inst-text-p-large">How are we doing?</p>
              <p className="inst-text-p-small">We want to know what you think about this website</p>
              <a href={`mailto:${process.env.REACT_APP_ETS_EMAIL}`} target="_blank" rel="noreferrer">Start Here</a>
            </ShadowCard>
          </div>
        </aside>
        <div className="inst-layout-container__main">
          <Outlet />
        </div>
        <MessagingPopup />
      </div>
    </>
  );
}