import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import ControlledSingleSelect from '../../components/ControlledSingleSelect';
import Field from '../../components/Field';
import { pronounOptions } from '../../shared/formOptions';
import { countryOptions } from '../../shared/formOptions';
import { comingSoonLong, comingSoonShort } from '../../shared/appText';
import './StudentApplicationForms.scss';

export default function StudentApplicationStudentProfileForm({ defaultValues, saveUpdates }) {
  const { control, register, watch, handleSubmit, setValue, formState: { errors, isValid } } = useForm({ defaultValues, mode: 'all' });
  const watchUsePermanentAddress = watch('mailingAddress.sameAsPermanent');

  useEffect(() => {
    if (watchUsePermanentAddress) {
      setValue('mailingAddress.city', '', { shouldValidate: true });
      setValue('mailingAddress.state', '', { shouldValidate: true });
      setValue('mailingAddress.country', '', { shouldValidate: true });
    }
  }, [watchUsePermanentAddress, setValue]);

  return (
    <form onSubmit={handleSubmit(saveUpdates)} className="student-app-form">
      <p>Great work! It looks like you&apos;re ahead of the game because you&apos;ve completed your student profile already. We&apos;ve populated your information here.</p>
      
      <fieldset>
        <legend>Personal Information</legend>

        <Field>
          <label>
            Alias<br />
            <input type="text" {...register('alias')} readOnly />
          </label>
        </Field>

        <Field>
          <label>
            Name<br />
            <input type="text" disabled placeholder={comingSoonLong} />
          </label>
        </Field>

        <Field>
          <label>
            Preferred First Name<br />
            <input type="text" disabled placeholder={comingSoonLong} />
          </label>
        </Field>
      </fieldset>

      <hr />

      <fieldset>
        <legend>Biographical Information</legend>

        <Field error={errors?.gender}>
          <label>
            Gender *<br />
            <input
              type="text"
              className={errors?.gender ? 'error' : ''}
              {...register('gender', { required: 'Required' })}
              placeholder="Write here"
            />
          </label>
        </Field>

        <Field error={errors?.pronouns}>
          <label htmlFor="pronouns">Pronouns *</label><br />
          <ControlledSingleSelect
            control={control}
            name="pronouns"
            defaultValue={''}
            rules={{ required: 'Required' }}
            options={pronounOptions}
            customClassNames="student-app-select"
            placeholder="Select one"
          />
        </Field>

        <Field error={errors?.birthYear}>
          <label>
            Year of Birth *<br />
            <input
              type="text"
              className={errors?.birthYear ? 'error' : ''}
              {...register('birthYear', {
                required: 'Required',
                pattern: {
                  value: /^(19|20)\d{2}$/,
                  message: 'You must enter a year: YYYY',
                }
              })}
              placeholder="Write here"
            />
          </label>
        </Field>

        <Field error={errors?.birthCountry}>
          <label htmlFor="birthCountry">Country of Birth *</label><br />
          <ControlledSingleSelect
            control={control}
            name="birthCountry"
            defaultValue=""
            options={countryOptions.map((c) => ({ value: c, label: c }))}
            rules={{ required: 'Required' }}
            customClassNames="student-app-select"
            placeholder="Select one"
          />
        </Field>
      </fieldset>

      <hr />

      <fieldset>
        <legend>Permanent Address</legend>

        <Field>
          <label>
            Street<br />
            <input type="text" disabled placeholder={comingSoonLong} />
          </label>
        </Field>

        <Field error={errors?.permanentAddress?.city}>
          <label>
            City *<br />
            <input
              type="text"
              className={errors?.permanentAddress?.city ? 'error' : ''}
              {...register('permanentAddress.city', { required: 'Required' })}
              placeholder="Write here"
            />
          </label>
        </Field>

        <div className="flex">
          <Field>
            <label>
              State<br />
              <input type="text" disabled placeholder={comingSoonShort} />
            </label>
          </Field>

          <Field>
            <label>
              ZIP<br />
              <input type="text" disabled placeholder={comingSoonShort} />
            </label>
          </Field>
        </div>

        <Field error={errors?.permanentAddress?.country}>
          <label htmlFor="permanentAddress.country">Country *</label><br />
          <ControlledSingleSelect
            control={control}
            name="permanentAddress.country"
            defaultValue=""
            options={countryOptions.map((c) => ({ value: c, label: c }))}
            rules={{ required: 'Required' }}
            customClassNames="student-app-select"
            placeholder="Select one"
          />
        </Field>
      </fieldset>

      <hr />

      <fieldset>
        <legend>Mailing Address</legend>

        <Field>
          <label className="checkbox">
            <input type="checkbox" {...register('mailingAddress.sameAsPermanent')} />
            My mailing address is the same as my permanent address
          </label>
        </Field>

        <Field>
          <label>
            Street<br />
            <input type="text" disabled placeholder={comingSoonLong} />
          </label>
        </Field>

        <Field error={errors?.mailingAddress?.city}>
          <label>
            City{watchUsePermanentAddress ? '' : ' *'}<br />
            <input
              type="text"
              disabled={watchUsePermanentAddress}
              className={errors?.mailingAddress?.city ? 'error' : ''}
              {...register('mailingAddress.city', { required: watchUsePermanentAddress ? false : 'Required' })}
              placeholder="Write here"
            />
          </label>
        </Field>

        <div className="flex">
          <Field>
            <label>
              State<br />
              <input type="text" disabled placeholder={comingSoonShort} />
            </label>
          </Field>

          <Field>
            <label>
              ZIP<br />
              <input type="text" disabled placeholder={comingSoonShort} />
            </label>
          </Field>
        </div>

        <Field error={errors?.mailingAddress?.country}>
          <label htmlFor="mailingAddress.country">Country{watchUsePermanentAddress ? '' : ' *'}</label><br />
          <ControlledSingleSelect
            control={control}
            name="mailingAddress.country"
            defaultValue=""
            options={countryOptions.map((c) => ({ value: c, label: c }))}
            disabled={watchUsePermanentAddress}
            rules={{ required: watchUsePermanentAddress ? false : 'Required' }}
            customClassNames="student-app-select"
            placeholder="Select one"
          />
        </Field>
      </fieldset>

      <hr />

      <fieldset>
        <legend>Contact Information</legend>

        <Field>
          <label>
            Email<br />
            <input type="text" disabled placeholder={comingSoonLong} />
          </label>
        </Field>

        <Field>
          <label>
            Phone<br />
            <input type="text" disabled placeholder={comingSoonLong} />
          </label>
        </Field>

        <fieldset>
          <legend>Would you like information on financial aid resources?</legend>
          <div className="flex">
            <label className="radio"><input type="radio" {...register('needFinancialAid')} value={true} /> &nbsp; Yes</label>
            <label className="radio"><input type="radio" {...register('needFinancialAid')} value={false} /> &nbsp; No</label>
          </div>
        </fieldset>
      </fieldset>

      <Field>
        <button type="submit" disabled={!isValid}>Continue</button>
      </Field>
    </form>
  )
}

StudentApplicationStudentProfileForm.propTypes = {
  defaultValues: PropTypes.shape({
    alias: PropTypes.string,
    gender: PropTypes.string,
    pronouns: PropTypes.string,
    birthYear: PropTypes.string,
    birthCountry: PropTypes.string,
    permanentAddress: PropTypes.shape({
      city: PropTypes.string,
      state: PropTypes.string,
      country: PropTypes.string,
    }),
    mailingAddress: PropTypes.shape({
      sameAsPermanent: PropTypes.bool,
      city: PropTypes.string,
      state: PropTypes.string,
      country: PropTypes.string,
    }),
    needFinancialAid: PropTypes.string,
  }),
  saveUpdates: PropTypes.func.isRequired,
};