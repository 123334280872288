import PropTypes from 'prop-types';
import plusIcon from '../../assets/student/images/add.svg';
import './AddFormButton.scss';

export default function AddFormButton({ onClick, children }) {
  return (
    <button onClick={onClick} className="profile-add-form-button">
      {children} <img src={plusIcon} alt="" />
    </button>
  );
}

AddFormButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
}