import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { studentPropTypes } from '../../shared/propTypes';
import { degreeOptions, startDateOptions, cipFieldOptions } from '../../shared/formOptions';
import ControlledMultiSelect from '../../components/ControlledMultiSelect';
import ControlledSingleSelect from '../../components/ControlledSingleSelect';
import Field from '../../components/Field';
import SurveyNavButtonWrapper from '../../components/StudentSurveys/SurveyNavButtonWrapper';
import next from '../../assets/student/images/right-carat-black.svg';
import './StudentOnboardingForm.scss';

export default function StudentOnboardingProgramGoalsForm({ defaultValues, saveData, goBack }) {
  const { control, handleSubmit } = useForm({ defaultValues });

  return (
    <form onSubmit={handleSubmit(saveData)} className="student-onboarding-form">
      <Field>
        <label htmlFor="seekingDegree">I&apos;m interested in pursuing</label>&nbsp;
        <ControlledMultiSelect
          control={control}
          defaultValue={[]}
          name="seekingDegree"
          options={degreeOptions}
          singleOptionValue="Still Deciding"
          customClassNames="student-survey-select"
          placeholder="Select all that apply"
        />
      </Field>

      <Field>
        <label htmlFor="fieldOfStudy">Field I&apos;m interested in&nbsp;</label>
        <ControlledSingleSelect
          control={control}
          defaultValue={null}
          name="fieldOfStudy"
          options={cipFieldOptions}
          customClassNames="student-survey-select"
          placeholder="Select one"
        />
      </Field>

      <Field>
        <label htmlFor="expectedStartDate">I&apos;m planning to start school in</label>&nbsp;
        <ControlledMultiSelect
          control={control}
          defaultValue={[]}
          name="expectedStartDate"
          options={startDateOptions}
          singleOptionValue="Undecided"
          customClassNames="student-survey-select"
          placeholder="Select all that apply"
        />
      </Field>

      <Field>
        <SurveyNavButtonWrapper>
          <button type="button" onClick={goBack} className="prev">
            <img src={next} alt="Go Back" />
          </button>
          <button type="submit" className="next">
            <img src={next} alt="Save & Next" />
          </button>
        </SurveyNavButtonWrapper>
      </Field>
    </form>
  );
}

StudentOnboardingProgramGoalsForm.propTypes = {
  defaultValues: PropTypes.shape({
    seekingDegree: studentPropTypes.seekingDegree,
    fieldOfStudy: studentPropTypes.fieldOfStudy,
    expectedStartDate: studentPropTypes.expectedStartDate,
  }),
  saveData: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
}