import { useMemo } from 'react';
import { useFirebaseStudent } from '../../../context/FirebaseStudentContext';
import { useFirebasePrograms } from '../../../context/FirebaseProgramDataContext';
import ProgramCard from '../../../components/StudentSearch/ProgramCard';
import { EmptyPlaceholder, TopSection } from './FavoriteProgramsPage';
import './StudentFavorites.scss';

export default function StudentFavoriteMatchPage() {
  const { favoriteProgramsList } = useFirebaseStudent();
  const { programs } = useFirebasePrograms();

  const faveMatches = useMemo(() => {
    if (!favoriteProgramsList || !favoriteProgramsList.length || !programs) return [];
    return favoriteProgramsList.filter(p => p.byStudent && p.byProgram).map(p => programs[p.programId]);
  }, [favoriteProgramsList, programs]);

  return (
    <main className="student-favorites">
      {faveMatches.length ? (
        <>
          <TopSection />
          <ul>
            {faveMatches.map(p => (
              <li key={p.programId}>
                <ProgramCard program={p} />
              </li>
            ))}
          </ul>
        </>
      ) : (
        <EmptyPlaceholder text="You haven&apos;t matched with any programs yet! Make sure you set your preferences and let&apos;s find a program that best matches you!" />
      )}
    </main>
  );
}