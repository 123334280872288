import { useMemo } from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';
import { useFirebaseAuth } from '../../context/FirebaseAuthContext';
import { useStudentSurveyStatus } from '../../context/FirebaseStudentContext';
import logo from '../../assets/student/images/grad-connector.svg';
import logoutIcon from '../../assets/icons/logout.svg';
import './StudentOnboardingLayout.scss';
import SurveySteps from '../../components/StudentSurveys/SurveySteps';

export default function StudentOnboardingLayout() {
  const {
    showBasicsSurvey,
    showProgramsSurvey,
    showEducationSurvey,
    showWorkExperienceSurvey
  } = useStudentSurveyStatus();
  const { signOut } = useFirebaseAuth();
  const location = useLocation();

  const steps = useMemo(() => {
    return [
      {
        label: 'Basic Information',
        completed: '/students/surveys/schools',
        url: '/students/surveys',
        isComplete: !showBasicsSurvey,
      },
      {
        label: 'School Search',
        completed: '/students/surveys/education',
        url: '/students/surveys/schools',
        isComplete: !showProgramsSurvey,
      },
      {
        label: 'Academic Background',
        completed: '/students/surveys/workexperience',
        url: '/students/surveys/education',
        isComplete: !showEducationSurvey,
      },
      {
        label: 'Work Experience',
        url: '/students/surveys/workexperience',
        isComplete: !showWorkExperienceSurvey,
      },
      { label: 'Complete Onboarding' }
    ];
  }, [showBasicsSurvey, showProgramsSurvey, showEducationSurvey, showWorkExperienceSurvey]);

  const completedSurveyIndex = useMemo(() => {
    return steps?.findIndex(s => location?.pathname?.includes(s.completed));
  }, [location?.pathname, steps]);

  return (
    <div className="student-onboarding-layout-container">
      <nav className="student-onboarding-layout-container__nav">
        <div className="nav-content">
          <Link to="/students">
            <img className="logo" src={logo} alt="Grad Connector" />
          </Link>
          <button onClick={signOut}>
            <img src={logoutIcon} alt="" /> Sign Out
          </button>
        </div>
      </nav>
      <div className="student-onboarding-layout-container__main">
        <div className="main__progress">
          <SurveySteps lastCompleteIndex={completedSurveyIndex} steps={steps} />
        </div>
        <div className="main__content">
          <Outlet />
        </div>
      </div>
    </div>
  );
}