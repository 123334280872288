import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFirebaseStudent, useStudentSurveyStatus } from '../../../context/FirebaseStudentContext';
import { useFirebasePrograms } from '../../../context/FirebaseProgramDataContext';
import StudentOnboardingSelectSchoolsForm from '../../../forms/StudentOnboarding/StudentOnboardingSelectSchoolsForm';
import useKeypress from '../../../hooks/useKeypress';
import DashboardLink from '../../../components/StudentSurveys/DashboardLink';
import ProgressCircle from '../../../components/ProgressCircle';
import handingEmailIllus from '../../../assets/student/images/survey-handing-email.svg';
import { colors } from '../../../shared/colors';
import './SurveyPages.scss';

const prevSurveyPath = '/students/surveys/schools';
const nextSurveyPath = '/students/surveys/education';

export default function StudentSchoolsSurveyAddPage() {
  const navigate = useNavigate();
  const [screenView, setScreenView] = useState('form');
  const { student, batchCreateApplications } = useFirebaseStudent();
  const { profilePercentComplete } = useStudentSurveyStatus();
  const { programs } = useFirebasePrograms();

  const prevScreen = () => {
    if (screenView === 'success' || screenView === 'continue') {
      setScreenView('form');
    } else {
      navigate(prevSurveyPath);
    }
  }

  const nextScreen = () => {
    if (screenView === 'success' || screenView === 'continue') {
      navigate(nextSurveyPath)
    }
  }

  useKeypress('ArrowRight', nextScreen);
  useKeypress('ArrowLeft', prevScreen);

  const createAppsForTargetPrograms = (targetPrograms) => {
    const appData = targetPrograms.map(p => ({
      programId: p.programId,
      programTitle: p.programTitle,
      schoolId: programs?.[p.programId]?.schoolId,
      schoolName: programs?.[p.programId]?.schoolName,
      deadline: '2022-06-01',
      alias: student?.alias,
    }));
    batchCreateApplications(appData, `${appData.length} applications created!`);
  }

  return (
    <main className="student-onboarding-survey">
      <DashboardLink />

      {screenView === 'form' ? (
        <>
          <p className="student-text-p-large">Let&apos;s get to know your school preferences better.</p>
          <StudentOnboardingSelectSchoolsForm
            saveData={(data) => {
              const { targetPrograms } = data;
              if (targetPrograms?.length) {
                createAppsForTargetPrograms(targetPrograms);
                setScreenView('success');
              } else {
                setScreenView('continue');
              }
            }}
            goBack={prevScreen}
          />
        </>
      ) : null}

      {screenView === 'success' ? (
        <div className="centered">
          <img src={handingEmailIllus} alt="" />
          <p className="student-text-h2">We&apos;ve imported this information into your application tool.</p>
          <p className="student-text-p-large">Continue completing your profile.</p>
          <Link to={nextSurveyPath} className="btn">Continue</Link><br />
          <Link to="/students" className="student-cta-link">I Will Come Back Later To Complete My Profile</Link>
        </div>
      ) : null}

      {screenView === 'continue' ? (
        <div className="centered">
          <ProgressCircle size={168} fgColor={colors.green} bgColor={colors.lightLime} percentComplete={profilePercentComplete} />
          <p className="student-text-h2">Your profile is {profilePercentComplete}% complete! Let&apos;s keep going! 👏</p>
          <p className="student-text-p-large">This information will be saved to your profile and can be imported into your applications when you&apos;re ready.</p>
          <p className="student-text-p-large">Would you like to continue to complete your profile?</p>
          <Link to={nextSurveyPath} className="btn">Yes, Continue</Link>
          <Link to="/students" className="student-cta-link">I Will Come Back Later To Complete My Profile</Link>
        </div>
      ) : null}
    </main>
  );
}