import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { appSections } from './InProgressAppCard';
import ShadowCard from '../ShadowCard';
import ProgressCircle from '../ProgressCircle';
import './DesktopAppsCarousel.scss';
import { colors } from '../../shared/colors';
import { formatAppDate } from '../../shared/dates';
import logo from '../../assets/student/images/logo_default.jpeg';

export const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1000 },
    items: 3,
  }
}

function AppCarouselCard({ app }) {
  const completeSections = appSections.map(s => s.key).filter(key => app?.[key]);
  const percentComplete = completeSections?.length ? Math.round((completeSections.length / appSections.length) * 100) : 0;

  return (
    <Link to={`/students/applications/${app.programId}`}>
      <ShadowCard customClassNames="app-card">
        <div className="app-card__top">
          <div>
            <ProgressCircle
              percentComplete={percentComplete}
              fgColor={colors.blue}
              bgColor="white"
              size={96}
            />
          </div>
          <div>
            <p className="student-text-p-small">⏳ Deadline: {formatAppDate(app.deadline)}</p>
          </div>
        </div>
        <div className="app-card__bottom">
          <img src={logo} alt="" height={43} />
          <div>
            <h3 className="student-text-h3">{app.programTitle}</h3>
            <p className="student-text-p-small">{app.schoolName}</p>
          </div>
        </div>
      </ShadowCard>
    </Link>
  );
}

AppCarouselCard.propTypes = {
  app: PropTypes.shape({
    programId: PropTypes.string,
    programTitle: PropTypes.string,
    schoolName: PropTypes.string,
    deadline: PropTypes.string,
    programInfo: PropTypes.object,
    studentProfile: PropTypes.object,
    citizenship: PropTypes.object,
    education: PropTypes.arrayOf(PropTypes.object),
    testScores: PropTypes.object,
    resume: PropTypes.bool,
    personalStatement: PropTypes.bool,
    recommendations: PropTypes.bool,
  })
}

export default function DesktopAppsCarousel({ applications }) {
  return (
    <div className="desktop-apps-carousel student-carousel">
      <Carousel responsive={responsive} autoPlay={false}>
        {applications.map(app => <AppCarouselCard app={app} key={app.programId} />)}
      </Carousel>
    </div>
  );
}

DesktopAppsCarousel.propTypes = {
  applications: PropTypes.arrayOf(PropTypes.object),
}