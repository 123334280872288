import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import './TabNav.scss';

export default function TabNav({ tabs }) {
  return (
    <nav className="inst-tab-nav">
      <ul>
        {tabs.map(tab => (
          <li key={tab.label}>
            <NavLink to={tab.to} className={({ isActive }) => isActive || tab.active ? 'active' : ''} end={tab.end}>
              {tab.label}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
}

TabNav.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    end: PropTypes.bool,
    active: PropTypes.bool,
  })),
}
