import './PurchaseListHome.scss';
import ShadowCard from '../../../components/ShadowCard';
import PropTypes from 'prop-types';
import { orderHistory, savedSearches } from './PurchaseListData';
import listPurchaseSvg from '../../../assets/inst/images/list-purchase.svg';
import trash from '../../../assets/inst/images/delete.svg';
import pencil from '../../../assets/inst/images/pencil.svg';
import repeat from '../../../assets/inst/images/repeat.svg';

export default function InstitutionPurchaseListHomePage({changeView}) {
  return (
    <div className="list-purchase-home">
      <ShadowCard>
        <div className="list-purchase-info">
          <div className="list-purchase-info-header">
            <img src={listPurchaseSvg} alt=""/>
            <div className="list-purchase-info-header-explainer">
              <h2 className="inst-text-h2">Purchase a customized list of students that aligns with your program&lsquo;s goals.</h2>
              <p className="inst-text-p">Use our comprehensive database of student criteria to create your own customized list of viable student prospects. While one-on-one communication is shown to to have the most success for application submission, these lists can be used for broader communications like promoting an open house or a webinar.</p>
            </div>
          </div>
          <div className="list-purchase-info-body">
            <h4 className="inst-text-h4">Let&lsquo;s Get Started</h4>
            <p className="inst-text-p"><span className="inst-text-h3">1</span> Plan ahead and save yourself time. You can set up your searches at any point and include the desired output timing so you&lsquo;ll be ready for your upcoming campaign. You can easily copy previous orders, too.</p>
            <p className="inst-text-p"><span className="inst-text-h3">2</span> Be among the first to reach the newest names by setting up weekly or monthly recurring orders by using the criteria that is important to you. Our database is updated biweekly so new names are consistently available.</p>
            <p className="inst-text-p"><span className="inst-text-h3">3</span> Most successful campaigns involve multiple touch points at carefully timed intervals. With one-time only fees for names, you can increase the efficiency of your campaign by consistently communicating with prospects.</p>
            <button onClick={() => changeView('search')}>Start a New Purchase</button>
          </div>
        </div>
      </ShadowCard>
      <div className="saved-searches">
        <h3 className="inst-text-h3">Saved Searches</h3>
        <div className="saved-searches-cards">
          {
            savedSearches.map((s, key) => (
              <ShadowCard key={key}>
                <div className="saved-search">
                  <div className="saved-search-top inst-text-h4">
                    <div>{s.name}</div>
                    <div className="icons">
                      <img src={trash} alt="" />
                      <img role="presentation" onClick={() => changeView('search')} src={pencil} alt="" />
                    </div>
                  </div>
                  <div className="saved-search-bottom">
                    <div className="description">
                      <span className="inst-text-label">Last Modified</span>
                      <span className="inst-text-p">{s.lastModified}</span>
                    </div>
                    <div className="links">
                      <button onClick={() => changeView('review-order')} className="order-button inst-text-p">Order</button>
                      <button onClick={() => changeView('search')} className="details-button inst-text-p">See Search Details</button>
                    </div>
                  </div>
                </div>
              </ShadowCard>
            ))
          }
        </div>
      </div>
      <div className="order-history">
        <h3 className="inst-text-h3">Order History</h3>
        <div className="order-history-cards">
          {
            orderHistory.map((o, key) => (
              <ShadowCard key={key}>
                <div className="order-card">
                  <div className="order-card-top inst-text-h4">
                    <div>Order #{o.orderNumber}</div>
                  </div>
                  <div className="order-card-bottom">
                    <div>
                      {o.recurringPurchase && <div className="recurring-purchase">
                        <img src={repeat} alt="" />
                        <span className="inst-text-label">Recurring Purchase</span>
                      </div>}
                      <div className="info">
                        <span className="inst-text-label">Total Registrants</span>
                        <span className="inst-text-p">{o.totalRegistrants}</span>
                      </div>
                      <div className="info">
                        <span className="inst-text-label">Date Purchased</span>
                        <span className="inst-text-p">{o.datePurchased}</span>
                      </div>
                    </div>
                    <div className="links">
                      <button onClick={() => changeView('review-order')} className="details-button inst-text-p">View Order Details</button>
                    </div>
                  </div>
                </div>
              </ShadowCard>
            ))
          }
        </div>
      </div>
    </div>
  );
}

InstitutionPurchaseListHomePage.propTypes = {
  changeView: PropTypes.func
};