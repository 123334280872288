import { useState, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFirebaseStudent, useStudentSurveyStatus } from '../../../context/FirebaseStudentContext';
import StudentOnboardingBasicInfoForm from '../../../forms/StudentOnboarding/StudentOnboardingBasicInfoForm';
import useKeypress from '../../../hooks/useKeypress';
import DashboardLink from '../../../components/StudentSurveys/DashboardLink';
import ProgressCircle from '../../../components/ProgressCircle';
import { colors } from '../../../shared/colors';
import './SurveyPages.scss';

const nextSurveyPath = '/students/surveys/goals';

export default function StudentBasicInfoSurveyIntroPage() {
  const navigate = useNavigate();
  const [showSuccess, setShowSuccess] = useState(false);
  const { student, addOrUpdateProfile } = useFirebaseStudent();
  const { profilePercentComplete } = useStudentSurveyStatus();

  const studentBasicInfoDefaultValues = useMemo(() => ({
    alias: student?.alias || '',
    isInternational: false,
    isFirstGeneration: false,
    pronouns: '',
    ethnicity: '',
    ethnicGroups: [],
    citizenshipCountry: '',
    stateOfResidence: '',
    ...student?.basicInfo,
  }), [student?.basicInfo, student?.alias]);

  const prevScreen = () => {
    if (showSuccess) setShowSuccess(false);
  }

  const nextScreen = () => {
    if (showSuccess) navigate(nextSurveyPath);
    else setShowSuccess(true);
  }

  useKeypress('ArrowRight', nextScreen);
  useKeypress('ArrowLeft', prevScreen);

  const saveData = (data) => {
    addOrUpdateProfile({ basicInfo: data }, 'Your profile has been saved!');
  }

  if (student) return (
    <main className="student-onboarding-survey">
      <DashboardLink />

      {!showSuccess ? (
        <>
          <p className="student-text-p-large">Let&apos;s start with your basic information.</p>
          {studentBasicInfoDefaultValues ? (
            <StudentOnboardingBasicInfoForm
              defaultValues={studentBasicInfoDefaultValues}
              saveData={(data) => {
                saveData(data);
                setShowSuccess(true);
              }}
            />
          ) : null}
        </>
      ) : null}

      {showSuccess ? (
        <div className="centered">
          <ProgressCircle size={168} fgColor={colors.green} bgColor={colors.lightLime} percentComplete={profilePercentComplete} />
          <p className="student-text-h2">Well done! 🎉</p>
          <p className="student-text-h2">You completed {profilePercentComplete}% of your profile.</p>
          <p className="student-text-p-large">Let&apos;s keep going! The more information you can provide, the more accurate your recommendations will be.</p>
          <button type="button" onClick={() => navigate(nextSurveyPath)} className="btn">Continue</button>
          <Link to="/students" className="student-cta-link">I&apos;ll Get Back To This</Link>
        </div>
      ) : null}
    </main>
  );

  return null;
}