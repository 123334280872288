import { useState, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFirebaseStudent } from '../../../context/FirebaseStudentContext';
import StudentOnboardingProgramGoalsForm from '../../../forms/StudentOnboarding/StudentOnboardingProgramGoalsForm';
import useKeypress from '../../../hooks/useKeypress';
import DashboardLink from '../../../components/StudentSurveys/DashboardLink';
import handingEmailIllus from '../../../assets/student/images/survey-handing-email.svg';
import './SurveyPages.scss';

const prevSurveyPath = '/students/surveys';
const nextSurveyPath = '/students/surveys/schools';

export default function StudentBasicInfoSurveyGoalsPage() {
  const navigate = useNavigate();
  const [showSuccess, setShowSuccess] = useState(false);
  const { student, addOrUpdateProfile } = useFirebaseStudent();

  const studentBasicInfoDefaultValues = useMemo(() => ({
    seekingDegree: [],
    fieldOfStudy: null,
    expectedStartDate: [],
    ...student?.basicInfo,
  }), [student?.basicInfo]);

  const prevScreen = () => {
    if (showSuccess) setShowSuccess(false);
    else navigate(prevSurveyPath);
  }

  const nextScreen = () => {
    if (showSuccess) navigate(nextSurveyPath);
    else setShowSuccess(true);
  }

  useKeypress('ArrowRight', nextScreen);
  useKeypress('ArrowLeft', prevScreen);

  const saveData = (data) => {
    addOrUpdateProfile({ basicInfo: data }, 'Your profile has been saved!');
  }

  if (student) return (
    <main className="student-onboarding-survey">
      <DashboardLink />

      {!showSuccess ? (
        <>
          <p className="student-text-p-large">Let&apos;s start with your basic information.</p>
          {studentBasicInfoDefaultValues ? (
            <StudentOnboardingProgramGoalsForm
              defaultValues={studentBasicInfoDefaultValues}
              saveData={(data) => {
                saveData(data);
                setShowSuccess(true);
              }}
              goBack={prevScreen}
            />
          ) : null}
        </>
      ) : null}

      {showSuccess ? (
        <div className="centered">
          <img src={handingEmailIllus} alt="" />
          <p className="student-text-h2">Planning is an important first step on your grad school journey. We already started making you a to-do list.</p>
          <p className="student-text-p-large">Would you like to find programs that match your interests?</p>
          <div className="flex">
            <Link to={nextSurveyPath} className="btn">Yes, Continue</Link>
            <Link to="/students/todos" className="btn-outline">View To-Do List</Link>
          </div>
          <Link to="/students" className="student-cta-link">Come Back To Survey Later, And Go To Dashboard</Link>
        </div>
      ) : null}
    </main>
  );

  return null;
}