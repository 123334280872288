import { useState, useEffect } from 'react';
import { useFirebaseStudent, useStudentSurveyStatus } from '../../../context/FirebaseStudentContext';
import Modal from '../../../components/Modal';
import StudentAboutMeForm from '../../../forms/StudentProfile/StudentAboutMeForm';
import StudentExperienceForm from '../../../forms/StudentProfile/StudentExperienceForm';
import StudentEducationForm from '../../../forms/StudentProfile/StudentEducationForm';
import StudentCertificateForm from '../../../forms/StudentProfile/StudentCertificateForm';
import BasicInfoBlock from '../../../components/StudentProfile/BasicInfoBlock';
import StudentBasicInfoForm from '../../../forms/StudentProfile/StudentBasicInfoForm';
import StudentGREForm from '../../../forms/StudentProfile/StudentGREForm';
import SectionHeader from '../../../components/SectionHeader';
import SurveyLink from '../../../components/StudentSurveys/SurveyLink';
import ShadowCard from '../../../components/ShadowCard';
import MessagingPopup from '../../../components/StudentMessaging/MessagingPopup';
import { comingSoonShort } from '../../../shared/appText';
import { formatProfileDate } from '../../../shared/dates';
import './ProfilePage.scss';
import ProfileListItemBlock from '../../../components/StudentProfile/ProfileListItemBlock';
import GREScoreBlock from '../../../components/StudentProfile/GREScoreBlock';
import ImportFromLinkedInButton from '../../../components/StudentProfile/ImportFromLinkedInButton';
import AddFormButton from '../../../components/StudentProfile/AddFormButton';

export default function StudentProfilePage() {
  const { student, addOrUpdateProfile, addOrUpdateListItem, removeListItem } = useFirebaseStudent();
  const { showBasicsSurvey } = useStudentSurveyStatus();
  const [showForm, setShowForm] = useState(null);

  useEffect(() => {
    setShowForm(null);
  }, [student]);

  const getDefaultValues = (activeForm, studentData) => {
    if (!activeForm || activeForm.key === null || !studentData[activeForm.type]) {
      return null;
    } else {
      return studentData[activeForm.type][activeForm.key];
    }
  }

  if (student) {
    return (
      <div className="student-profile">
        <SectionHeader title="Profile" />
        <main className="student-profile__content">

          <section className="section">
            <BasicInfoBlock
              name={student?.alias}
              data={student?.basicInfo}
              editData={() => setShowForm({ type: 'basicInfo', key: null })}
            />
            <Modal
              closeModal={() => setShowForm(null)}
              title="Add Basic Info"
              isOpen={showForm?.type === 'basicInfo'}
            >
              <StudentBasicInfoForm
                defaultValues={student?.basicInfo ? student.basicInfo : null}
                saveData={data => addOrUpdateProfile({ basicInfo: data}, 'Profile updated!')}
              />
            </Modal>
          </section>

          <section className="section">
            {showBasicsSurvey ? (
              <SurveyLink
                to="/students/surveys"
                text="Answer these prompts to help us get to know you better."
              />
            ) : null}
          </section>

          <section className="section">
            <h2 className="student-text-h2">About Me</h2>
            <StudentAboutMeForm
              defaultValues={{ aboutMe: student?.aboutMe || '' }}
              saveData={(data) => addOrUpdateProfile(data, 'Profile updated!')}
            />
          </section>

          <section className="section">
            <SurveyLink
              text="Don't like talking about yourself? Use this tool to paint a picture of who you are as a future grad student."
              comingSoon
            />
          </section>

          <section className="section">
            <h2 className="student-text-h2">Career Objectives <span className="coming-soon">{comingSoonShort}</span></h2>
            <div className="dotted-box">
              <button disabled className="primary-button">Add Objectives</button>
            </div>
          </section>

          <section className="section">
            <div className="section__header">
              <h2 className="student-text-h2">Experience</h2>
              {student?.workExperience ? (
                <AddFormButton onClick={() => setShowForm({ type: 'workExperience', key: null })}>
                  Add Experience
                </AddFormButton>
              ) : null}
            </div>
            {student?.workExperience ? (
              <ShadowCard>
                {Object.keys(student.workExperience).map((key) => (
                  <ProfileListItemBlock
                    key={key}
                    editItem={() => setShowForm({ type: 'workExperience', key })}
                    deleteItem={() => removeListItem('workExperience', key, 'Experience deleted!')}
                  >
                    <h3 className="student-text-h3">{student.workExperience[key]?.title}</h3>
                    <p className="student-text-p-small">
                      {student.workExperience[key]?.company}<br />
                      {formatProfileDate(student.workExperience[key]?.startDate)} - {formatProfileDate(student.workExperience[key]?.endDate)}
                    </p>
                    <p className="student-text-p-small">{student.workExperience[key]?.description}</p>

                  </ProfileListItemBlock>
                ))} 
              </ShadowCard>
            ) : (
              <div className="dotted-box">
                <div className="dotted-box__top">
                  <button type="button" className="primary-button" onClick={() => setShowForm({ type: 'workExperience', key: null })}>
                    Add Experience
                  </button>
                  <ImportFromLinkedInButton />
                </div>

                <hr data-content="Or" />

                <div className="dotted-box__bottom">
                  <SurveyLink
                    to="/students/surveys/workexperience"
                    text="Answer some questions about your work experience."
                  />
                </div>
              </div>
            )}
            <Modal
              closeModal={() => setShowForm(null)}
              title="Add Experience"
              isOpen={showForm?.type === 'workExperience'}
            >
              <StudentExperienceForm
                defaultValues={getDefaultValues(showForm, student)}
                saveData={(data) => addOrUpdateListItem('workExperience', data, 'Experience saved!')}
              />
            </Modal>
          </section>

          <section className="section">
            <div className="section__header">
              <h2 className="student-text-h2">Educational Background</h2>
              {student?.education ? (
                <AddFormButton onClick={() => setShowForm({ type: 'education', key: null })}>
                  Add Education
                </AddFormButton>
              ) : null}
            </div>
            {student?.education ? (
              <ShadowCard>
                {Object.keys(student.education).map((key) => (
                  <ProfileListItemBlock
                    key={key}
                    editItem={() => setShowForm({ type: 'education', key })}
                    deleteItem={() => removeListItem('education', key, 'Education record deleted!')}
                  >
                    <h3 className="student-text-h3">{student.education[key]?.schoolName}</h3>
                    <p className="student-text-p-small">
                      {student.education[key]?.degree}, {student.education[key]?.major}<br />
                      {formatProfileDate(student.education[key]?.startDate)} - {formatProfileDate(student.education[key]?.endDate)}<br />
                      {student.education[key]?.grade}
                    </p>
                    <p className="student-text-p-small">{student.education[key]?.description}</p>
                  </ProfileListItemBlock>
                ))}
              </ShadowCard>
            ) : (
              <div className="dotted-box">
                <div className="dotted-box__top">
                  <button type="button" className="primary-button" onClick={() => setShowForm({ type: 'education', key: null })}>
                    Add Education
                  </button>
                  <ImportFromLinkedInButton />
                </div>

                <hr data-content="Or" />

                <div className="dotted-box__bottom">
                  <SurveyLink
                    to="/students/surveys/education"
                    text="Answer some questions about your educational background."
                  />
                </div>
              </div>
            )}
            <Modal
              closeModal={() => setShowForm(null)}
              title="Add Education"
              isOpen={showForm?.type === 'education'}
            >
              <StudentEducationForm
                defaultValues={getDefaultValues(showForm, student)}
                saveData={(data) => addOrUpdateListItem('education', data, 'Education saved!')}
              />
            </Modal>
          </section>

          <section className="section">
            <h2 className="student-text-h2">Test Scores</h2>
            {student?.assessments?.gre ? (
              <ShadowCard>
                <GREScoreBlock
                  editItem={() => setShowForm({ type: 'assessments', key: null })}
                  data={student.assessments.gre}
                />
              </ShadowCard>
            ) : (
              <div className="dotted-box">
                <button type="button" className="primary-button" onClick={() => setShowForm({ type: 'assessments', key: null })}>Add Scores</button>
              </div>
            )}
            <Modal
              closeModal={() => setShowForm(null)}
              title="Add GRE Score"
              isOpen={showForm?.type === 'assessments'}
            >
              <StudentGREForm
                defaultValues={student?.assessments?.gre ? student.assessments.gre : null}
                saveData={data => addOrUpdateProfile({ assessments: { gre: data } }, 'GRE scores saved!')}
              />
            </Modal>
          </section>

          <section className="section">
            <div className="section__header">
              <h2 className="student-text-h2">Certificates</h2>
              {student?.certificates ? (
                <AddFormButton onClick={() => setShowForm({ type: 'certificates', key: null })}>
                  Add Certificate
                </AddFormButton>
              ) : null}
            </div>
            {student?.certificates ? (
              <ShadowCard>
                {Object.keys(student.certificates).map((key) => (
                  <ProfileListItemBlock
                    key={key}
                    editItem={() => setShowForm({ type: 'certificates', key })}
                    deleteItem={() => removeListItem('certificates', key, 'Certificate deleted!')}
                  >
                    <h3 className="student-text-h3">{student.certificates[key]?.title}</h3>
                    <p className="student-text-p-small">
                      {student.certificates[key]?.issuingOrgName}<br />
                      {formatProfileDate(student.certificates[key]?.issueDate)}
                    </p>
                    <p className="student-text-p-small">{student.certificates[key]?.description}</p>
                  </ProfileListItemBlock>
                ))}
              </ShadowCard>
            ) : (
              <div className="dotted-box">
                <button type="button" className="primary-button" onClick={() => setShowForm({ type: 'certificates', key: null })}>Add Certificates</button>
              </div>
            )}
            <Modal
              closeModal={() => setShowForm(null)}
              title="Add Certificate"
              isOpen={showForm?.type === 'certificates'}
            >
              <StudentCertificateForm
                defaultValues={getDefaultValues(showForm, student)}
                saveData={(data) => addOrUpdateListItem('certificates', data, showForm?.key, 'Certificate saved!')}
              />
            </Modal>
          </section>
        </main>
        <MessagingPopup />
      </div>
    );
  }

  return <p>Loading...</p>
}