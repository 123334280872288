import { Link } from 'react-router-dom';
import { useFirebaseSchool } from '../../../context/FirebaseSchoolContext';
import StudentCard from '../../../components/InstitutionUI/StudentCard';
import SectionHeader from '../../../components/SectionHeader';
import './RecommendationsPage.scss';

export default function InstitutionRecommendationsPage() {
  const { recommendedStudentsList } = useFirebaseSchool();

  return (
    <main className="inst-recommendations">
      <SectionHeader title="Recommendations" />
      <div className="inst-recommendations__content">
        <div className="tip-card">
          <div>
            <h2>Recruiting Tip</h2>
            <p>Your profile may be the most important because it&apos;s the first thing that students will see when searching for programs they are interested in. It&apos;s also where your recommendations from GradConnector will be generated. The more detail you can provide, the more appealing your program will be to prospective students and the more accurate your recommendations will be.</p>
          </div>
          <Link to="/institutions/schoolprofile" className="btn--outline">Complete Your Profile</Link>
        </div>
        <h2 className="inst-text-h4">You have {recommendedStudentsList.length} recommendations</h2>
        <div className="grid">
          {recommendedStudentsList.map(student => (
            <StudentCard student={student} key={student.studentId} />
          ))}
        </div>
      </div>
    </main>
  );
}