import { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFirebaseStudent } from '../../context/FirebaseStudentContext';
import heartEmpty from '../../assets/student/images/heart-empty.svg';
import heartFilled from '../../assets/student/images/heart-filled.svg';
import './FavoriteButtons.scss';

export default function FavoriteButtons({ programId, onClickCallback }) {
  const { favoritePrograms, addOrUpdateFavoriteProgram } = useFirebaseStudent();

  const favorite = useMemo(() => {
    if (programId && favoritePrograms) return favoritePrograms[programId];
    return null;
  }, [favoritePrograms, programId]);

  const toggleFavorite = useCallback(() => {
    const value = !favorite?.byStudent;
    addOrUpdateFavoriteProgram(programId, { byStudent: value }, 'Favorite updated!');
    if (onClickCallback) onClickCallback(value);
  }, [favorite, programId, addOrUpdateFavoriteProgram, onClickCallback]);

  return (
    <div className="student-favorite__wrapper">
      {favorite?.byProgram ? <span>It&apos;s a match!</span> : null}
      <button onClick={toggleFavorite}>
        {favorite?.byStudent ? (
          <>
            <img src={heartFilled} alt="" />
            Remove from Favorites
          </>
        ) : (
          <>
            <img src={heartEmpty} alt="" />
            Add to Favorites
          </>
        )}
      </button>
    </div>
  );

}

FavoriteButtons.propTypes = {
  programId: PropTypes.string.isRequired,
  onClickCallback: PropTypes.func,
}

