import PropTypes from 'prop-types';
import MailToETSLink from '../MailToETSLink';
import contact from '../../assets/student/images/contact-white.svg';
import './ContactUsButton.scss';

export default function ContactUsButton({ fromIdentifier, toIdentifier }) {
  return (
    <MailToETSLink
      subject={`Question - TO: ${toIdentifier}, FROM: ${fromIdentifier}`}
      customClassNames="student-contact-us-button"
    >
      <img src={contact} alt="" /> Contact Us
    </MailToETSLink>
  );
}

ContactUsButton.propTypes = {
  fromIdentifier: PropTypes.string,
  toIdentifier: PropTypes.string,
}