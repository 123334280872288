import PropTypes from 'prop-types';
import './SurveyNavButtonWrapper.scss';

export default function SurveyNavButtonWrapper({ children }) {
  return (
    <div className="student-survey-nav-buttons">
      {children}
    </div>
  );
}

SurveyNavButtonWrapper.propTypes = {
  children: PropTypes.node,
}