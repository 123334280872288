import './PurchaseListPlaceOrder.scss';
import PropTypes from 'prop-types';
import arrow from '../../../assets/inst/images/arrow-left.svg';

export default function InstitutionPurchaseListPlaceOrderPage({changeView}) {
  return (
    <div className="purchase-list-review">
      <button onClick={() => changeView('review-order')} className="back-button inst-text-p"><img src={arrow} alt="" /> Back</button>
      <h2 className="inst-text-h2">New Search</h2>
      <div className="section">
        <div className="price-details">
          <div className="price-detail">
            <label htmlFor="registrants" className="inst-text-label">
              <span className="form-label">Registrants</span>
              <input className="inst-text-p" disabled placeholder="89" type="text" />
            </label>
          </div>
          <div className="price-detail">
            <span className="inst-text-label form-label">Price Per Registrant</span>
            <span className="inst-text-p">$0.75</span>
          </div>
          <div className="price-detail">
            <span className="inst-text-label form-label">Total Tax</span>
            <span className="inst-text-p">$0.00</span>
          </div>
          <div className="price-detail">
            <span className="inst-text-label form-label">Total (USD)</span>
            <span className="inst-text-p">$66.75</span>
          </div>
        </div>
      </div>
      <div className="order-number">
        <span className="inst-text-label form-label">Purchase Order Number</span>
        <input type="text" />
      </div>
      <button onClick={() => changeView('confirmation')} className="green-button inst-text-label">Place Order</button>
    </div>
  );
}

InstitutionPurchaseListPlaceOrderPage.propTypes = {
  changeView: PropTypes.func
};