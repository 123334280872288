import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import ShadowCard from '../../../components/ShadowCard';
import PopupCard from '../../../components/StudentUI/PopupCard';
import { currencyFormatter } from '../../../shared/numbers';
import commentIcon from '../../../assets/inst/images/comment-icon.svg';
import instIcon from '../../../assets/inst/images/inst-icon.svg';
import targetIcon from '../../../assets/inst/images/target-icon.svg';
import info from '../../../assets/inst/images/info-green.svg';
import './PricingModels.scss';

export default function InstitutionPricingOutcomesPage() {
  const { register, watch } = useForm({
    defaultValues: {
      recs: 100,
      apps: 10,
      enrollments: 2,
      list: 1000,
    },
    mode: 'all',
  });

  const watchRecs = watch('recs');
  const watchApps = watch('apps');
  const watchEnrollments = watch('enrollments');
  const watchList = watch('list');

  const totalCost = useMemo(() => {
    const recsCost = watchRecs * 5;
    const appsCost = watchApps * 25;
    const enrollmentsCost = watchEnrollments * 1000;
    const listCost = watchList * 1.29;
    return recsCost + appsCost + enrollmentsCost + listCost;
  }, [watchRecs, watchApps, watchEnrollments, watchList]);

  return (
    <main className="inst-pricing-models">
      <div className="intro">
        <h2 className="inst-text-h2">Pricing Model 1: Outcome-based Model</h2>
        <p className="inst-text-p gray">
          We use a completely <strong>results-driven</strong> model in our pricing structure.<br />
          This means the amount you pay is <strong>based on what you expect to receive from GradConnector</strong>.
        </p>
      </div>

      <div className="grid-2 section section--green inst-text-label">
        <div>Service Utilized and Received</div>
        <div>Cost</div>
      </div>

      <ShadowCard customClassNames="grid-2 section section--white">
        <div>
          <h3 className="inst-text-h3">Using the Service</h3>
          <ul>
            <li>Create program profile</li>
            <li>Set up recruitment criteria</li>
            <li>Analyze the diversity and skill levels of student pool</li>
            <li>Receive guidance on how to expand applicant pool and improve ROI</li>
            <li>Access to your program&apos;s performance data</li>
            <li>View the performance of peer programs</li>
          </ul>
        </div>
        <div>
          <p className="cost">Free</p>
        </div>
      </ShadowCard>

      <ShadowCard customClassNames="grid-2 section section--outline">
        <div className="flex">
          <img src={commentIcon} alt="" />
          <div>
            <p className="inst-text-h2">Student Recommendation & Engagement</p>
            <p className="inst-text-p-large">Increase program visibility and awareness by favoriting students and communicating directly with them on the platform.</p>
          </div>
        </div>
        <div>
          <p className="inst-text-h2 flex-baseline">
            <span className="cost">{currencyFormatter.format(5)}</span>
            /student
            <div>
              <PopupCard
                triggerButton={
                  <button className="info-btn">
                    <img src={info} alt="More information" />
                  </button>
                }
                position="right"
                on={['hover', 'focus', 'click']}
              >
                <div className="inst-pricing-popup">
                  <p>Conditions:</p>
                  <ul>
                    <li>Service fees must be prepaid.</li>
                    <li>100 Students: $500 (minimum)</li>
                    <li>500 Students: $2,500</li>
                  </ul>
                </div>
              </PopupCard>
            </div>
          </p>
          <p className="inst-text-p-small">Purchase student info in blocks of hundreds at $5/student (100, 200, 300, etc.)</p>
          <p className="inst-text-p-small gray">Please speak to a rep regarding pricing for amounts greater than 500.</p>
        </div>
        <div className="flex">
          <img src={instIcon} alt="" />
          <div>
            <p className="inst-text-h2">Student Applications per Year</p>
            <p className="inst-text-p-large">Encourage and convert engaged candidates to submit their application directly in the GradConnector platform.</p>
          </div>
        </div>
        <div>
          <p className="inst-text-h2">
            <span className="cost">{currencyFormatter.format(25)}</span>
            /application
          </p>
        </div>
        <div className="flex">
          <img src={targetIcon} alt="" />
          <div>
            <p className="inst-text-h2">Student Enrollment per Year</p>
            <p className="inst-text-p-large">This is the cost per student that enrolls in your program as a direct result of GradConnector&apos;s unique matching tool.</p>
          </div>
        </div>
        <div>
          <p className="inst-text-h2">
            <span className="cost">{currencyFormatter.format(1000)}</span>
            /enrollment
          </p>
        </div>
      </ShadowCard>

      <ShadowCard customClassNames="grid-2 section section--white">
        <div>
          <p className="inst-text-h3">List Purchase</p>
          <p className="inst-text-p gray">Purchase list of prospective students using customized searches. Lists can be exported to be used in your CRM or other direct marketing tools.</p>
        </div>
        <div>
          <p className="inst-text-h2">
            <span className="cost">{currencyFormatter.format(1.29)}</span>
            /student
          </p>
          <p className="inst-text-p-small">Minimum 1,000 students</p>
        </div>
      </ShadowCard>

      <hr />

      <h2 className="inst-text-h1 center space-y">Estimate Your Cost</h2>

      <form>
        <div className="estimates-grid">
          <div className="heading">&nbsp;</div>
          <div className="heading green">Your Expectation</div>
          <div className="heading">Example 1</div>
          <div className="heading">Example 2</div>
        </div>

        <ShadowCard customClassNames="estimates-grid subgrid">
          <div className="row1 flex">
            <img src={commentIcon} alt="" />
            <div>
              <p className="inst-text-h4">Student Rec/Engagement</p>
              <p className="inst-text-p-small gray">Min 100 students, purchase in increments of 100.  Cost $5/student. </p>
            </div>
          </div>
          <div className="green row1 flex-center">
            <label>
              <input type="number" {...register('recs', { min: 100 })} /><br />
              Students
            </label>
          </div>
          <div className="row1 flex-center">
            <p className="inst-text-h4">100 Students</p>
          </div>
          <div className="row1 flex-center">
            <p className="inst-text-h4">500 Students</p>
          </div>

          <div className="flex">
            <img src={instIcon} alt="" />
            <div>
              <p className="inst-text-h4">Student Applications per Year</p>
              <p className="inst-text-p-small gray">$25/applicant</p>
            </div>
          </div>
          <div className="green flex-center">
            <label>
              <input type="number" {...register('apps', { min: 0 })} /><br />
              Applications
            </label>
          </div>
          <div className="flex-center">
            <p className="inst-text-h4">10 Applications</p>
            <p className="inst-text-p-small gray">10% Conversion Rate</p>
          </div>
          <div className="flex-center">
            <p className="inst-text-h4">100 Applications</p>
            <p className="inst-text-p-small gray">20% Conversion Rate</p>
          </div>

          <div className="flex">
            <img src={targetIcon} alt="" />
            <div>
              <p className="inst-text-h4">Student Enrollment per Year</p>
              <p className="inst-text-p-small gray">$1,000/enrollment</p>
            </div>
          </div>
          <div className="green flex-center">
            <label>
              <input type="number" {...register('enrollments', { min: 1000 })} /><br />
              Enrollments
            </label>
          </div>
          <div className="flex-center">
            <p className="inst-text-h4">1 Enrollment</p>
            <p className="inst-text-p-small gray">10% Conversion Rate</p>
          </div>
          <div className="flex-center">
            <p className="inst-text-h4">20 Enrollments</p>
            <p className="inst-text-p-small gray">20% Conversion Rate</p>
          </div>
        </ShadowCard>

        <div className="estimates-grid">
          <div>
            <p className="inst-text-h4">List Purchase</p>
            <p className="inst-text-p-small gray">$1.29/student, Minimum 1000 students</p>
          </div>
          <div className="green flex-center">
            <label>
              <input type="number" {...register('list')} /><br />
              Students
            </label>
          </div>
          <div className="flex-center">
            <p className="inst-text-h4">1,000 students</p>
          </div>
          <div className="flex-center">
            <p className="inst-text-h4">0 students</p>
          </div>
          
          <div className="total inst-text-label">Total Cost</div>
          <div className="green total flex-center">
            <span className="cost">{currencyFormatter.format(totalCost)}</span>
          </div>
          <div className="total flex-center">
            <span className="cost">{currencyFormatter.format(3040)}</span>
          </div>
          <div className="total flex-center">
            <span className="cost">{currencyFormatter.format(25000)}</span>
          </div>
        </div>
      </form>

    </main>
  )
}