import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Field from '../../components/Field';
import deleteIcon from '../../assets/student/images/delete.svg';
import check from '../../assets/student/images/check-green-circle.svg';
import { comingSoonShort } from '../../shared/appText';
import './StudentToDoForm.scss';

export default function StudentToDoForm({ defaultValues, saveData, markComplete, deleteItem }) {
  const { register, handleSubmit, formState: { errors, isValid } } = useForm({ defaultValues, mode: 'onBlur' });

  return (
    <div className="student-todo-form">
      <div className="student-todo-form__buttons">
        {markComplete ? <button onClick={markComplete}><img src={check} alt="" /> Mark As Done</button> : null}
        {deleteItem ? <button onClick={deleteItem}><img src={deleteIcon} alt="" /> Delete Task</button> : null}
      </div>
      <form onSubmit={handleSubmit(saveData)}>
        <Field error={errors?.name}>
          <label>
            <span className="sr-only">Task Name *</span>
            <input type="text" {...register('name', { required: 'Required' })} placeholder="Write task here" />
          </label>
        </Field>
        
        <Field error={errors?.name}>
          <label>
            Due Date<br />
            <input type="date" {...register('dueDate', { required: 'Required' })} />
          </label>
        </Field>

        <Field>
          <label htmlFor="notes">Notes</label>
          <textarea {...register('notes')} placeholder="Add any notes" />
        </Field>

        <Field>
          <label>
            Category
            <input type="text" disabled placeholder={comingSoonShort} />
          </label>
        </Field>

        <Field>
          <label>
            Priority Level
            <input type="text" disabled placeholder={comingSoonShort} />
          </label>
        </Field>

        <Field>
          <button type="submit" disabled={!isValid}>Save</button>
        </Field>
      </form>
    </div>
  );
}

StudentToDoForm.defaultProps = {
  defaultValues: {
    name: '',
    dueDate: null,
    value: '',
    notes: '',
  },
};

StudentToDoForm.propTypes = {
  defaultValues: PropTypes.shape({
    name: PropTypes.string,
    dueDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    value: PropTypes.string,
    notes: PropTypes.string,
  }),
  saveData: PropTypes.func.isRequired,
  markComplete: PropTypes.func,
  deleteItem: PropTypes.func,
};