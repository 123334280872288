import PropTypes from 'prop-types';

export default function Field({ error, center, children }) {
  return (
    <div className={`field ${center ? 'center' : ''} ${error ? 'error' : ''}`}>
      {children}
      {error?.message ? <div className="error-msg">{error.message}</div> : null}
    </div>
  );
}

Field.propTypes = {
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  center: PropTypes.bool,
  children: PropTypes.node.isRequired,
}