import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { studentPropTypes } from '../../shared/propTypes';
import ShadowCard from '../ShadowCard';
import FavoriteButtons from '../InstitutionFavorites/FavoriteButtons';
import MostCompatible from '../InstitutionUI/MostCompatible';
import ContactStudentButton from '../InstitutionStudentSearch/ContactStudentButton';
import { stateOptions } from '../../shared/formOptions';
import './StudentCard.scss';

const LabelValue = ({ label, children }) => (
  <p>
    <span className="inst-text-label">{label}</span><br />
    <span className="inst-text-p">
      {children}
    </span>
  </p>
);

LabelValue.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default function StudentCard({ student }) {

  const graduatedFrom = useMemo(() => {
    if (!student?.education) return null;
    const degrees = Object.keys(student.education).map(key => student.education[key]);
    return degrees[0].schoolName;
  }, [student?.education]);

  const locationDisplayValue = useMemo(() => {
    if (!student?.basicInfo?.stateOfResidence) return '';
    return stateOptions.find(opt => opt.value === student.basicInfo.stateOfResidence)?.label;
  }, [student?.basicInfo?.stateOfResidence]);
  
  return (
    <ShadowCard customClassNames="inst-student-card">
      <div className="inst-student-card__top">
        <Link to={`/institutions/recruiting/studentsearch/${student.studentId}`}>{student.alias}</Link>
        {student.recommend ? <MostCompatible /> : null}
      </div>
      <div className="inst-student-card__content">
        <LabelValue label="Seeking">
          {student.basicInfo?.seekingDegree?.join(', ') || '—'}
        </LabelValue>
        <LabelValue label="Intended Start Date">
          {student.basicInfo?.expectedStartDate?.join(', ') || '—'}
        </LabelValue>
        <LabelValue label="Graduated From">
          {graduatedFrom || '—'}
        </LabelValue>
        <LabelValue label="Location">
          {locationDisplayValue || '—'}
        </LabelValue>
        <div className="inst-student-card__buttons">
          <FavoriteButtons studentId={student.studentId} />
          <ContactStudentButton alias={student.alias} />
        </div>
      </div>
    </ShadowCard>
  );
}

StudentCard.propTypes = {
  student: PropTypes.shape({
    studentId: PropTypes.string,
    alias: studentPropTypes.alias,
    basicInfo: PropTypes.shape({
      seekingDegree: studentPropTypes.seekingDegree,
      expectedStartDate: studentPropTypes.expectedStartDate,
      stateOfResidence: studentPropTypes.stateOfResidence,
    }),
    education: PropTypes.object,
    recommend: PropTypes.bool,
  }),
}