import { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFirebaseSchool } from '../../context/FirebaseSchoolContext';
import heartEmpty from '../../assets/inst/images/heart.svg';
import heartFilled from '../../assets/inst/images/heart-filled.svg';
import './FavoriteButtons.scss';

export default function FavoriteButtons({ studentId, onClickCallback }) {
  const { favoriteStudents, addOrUpdateFavoriteStudent } = useFirebaseSchool();

  const favorite = useMemo(() => {
    if (studentId && favoriteStudents) return favoriteStudents[studentId];
    return null;
  }, [favoriteStudents, studentId]);

  const toggleFavorite = useCallback(() => {
    const value = !favorite?.byProgram;
    addOrUpdateFavoriteStudent(studentId, { byProgram: value }, 'Favorite updated!');
    if (onClickCallback) onClickCallback(value);
  }, [favorite, studentId, addOrUpdateFavoriteStudent, onClickCallback]);

  return (
    <div className="inst-favorite__wrapper">
      {favorite?.byStudent && !favorite?.byProgram ? <span>You&apos;ve been favorited!</span> : null}
      {favorite?.byStudent && favorite?.byProgram ? <span>It&apos;s a match!</span> : null}
      <button onClick={toggleFavorite}>
        {favorite?.byProgram ? (
          <img src={heartFilled} alt="Remove from Favorites" width={20} />
        ) : (
          <img src={heartEmpty} alt="Add to Favorites" width={20} />
        )}
      </button>
    </div>
  );

}

FavoriteButtons.propTypes = {
  studentId: PropTypes.string.isRequired,
  onClickCallback: PropTypes.func,
}

