import { useStudentSurveyStatus } from '../../context/FirebaseStudentContext';
import { colors } from '../../shared/colors';
import ProgressCircle from '../ProgressCircle';



export default function ProfileProgressCircle() {
  const { profilePercentComplete } = useStudentSurveyStatus();

  return (
    <div>
      <ProgressCircle
        percentComplete={profilePercentComplete}
        fgColor={colors.green}
        bgColor={colors.lightLime}
      />
    </div>
  )
}