import { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import heart from '../../assets/inst/images/heart-gray.svg';
import './InstitutionNotificationsDrawer.scss';

const notifications = [
  'Phillip Mango favorited your program! Check out their profile and see if they\'re a match for your program.',
  'You matched with Hannah Morgan! Start a conversation with them to see if they\'re a potential candidate for your program.',
  'Brad Cavendish favorited your program! Check out their profile and see if they\'re a match for your program.',
  'You matched with Keisha Brown! Start a conversation with them to see if they\'re a potential candidate for your program.',
];

export default function InstitutionNotificationsDrawer({ customClassNames, children }) {
  const [showNotifications, setShowNotifications] = useState(false);


  return (
    <>
      <button className={customClassNames} onClick={() => setShowNotifications(true)}>
        {children}
        <span className="student-notification-count">{notifications.length}</span>
      </button>
      <Modal
        closeModal={() => setShowNotifications(false)}
        title="Notifications"
        isOpen={showNotifications}
        modalClassName="student-notification-panel"
      >
        <div className="student-notification-list">
          <h1 className="inst-text-h2">Notifications</h1>
          {notifications.map((msg, index) => (
            <div key={index} className="notification-list-item">
              <img src={heart} alt="" />
              <div>
                <p className="inst-text-p-large">{msg}</p>
                <p className="inst-text-p-small">5 minutes ago</p>
              </div>
            </div>
          ))}
        </div>
      </Modal>
    </>
  )
}

InstitutionNotificationsDrawer.propTypes = {
  customClassNames: PropTypes.string,
  children: PropTypes.node.isRequired,
};