import { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useFirebaseAuth } from '../../context/FirebaseAuthContext';
import ShadowCard from '../../components/ShadowCard';
import Field from '../../components/Field';
import loginIllus from '../../assets/login.svg';
import logo from '../../assets/student/images/grad-connector.svg';
import './Login.scss';

const required = 'This is a required field. Please fill out before continuing.'

export default function LoginPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { authState, userRole, signIn } = useFirebaseAuth();
  const [attempted, setAttempted] = useState(false);
  const { register, handleSubmit, formState: { errors, isValid } } = useForm({ defaultValues: { email: '', password: '' }, mode: 'all' });

  const from = location.state?.from?.pathname;

  useEffect(() => {
    if (userRole) {
      if (attempted && from) {
        navigate(from, { replace: true });
      } else {
        navigate(userRole === 'student' ? '/students' : '/institutions');
      }
    }
  }, [userRole, attempted, from, navigate]);

  const attemptSignIn = ({ email, password }) => {
    setAttempted(true);
    signIn(email, password);
  }

  return (
    <main className="login">
      <div className="login__header">
        <img src={logo} alt="GradConnect" />
      </div>
      <div className="login__content">
        <ShadowCard customClassNames="form__wrapper">
          <h1 className="student-text-title">Welcome to GradConnector!</h1>
          <p className="student-text-h2">Please sign in</p>
          {authState === 'error' ? <p className="error-msg">Your email or password are incorrect.</p> : null}
          <form onSubmit={handleSubmit(attemptSignIn)}>
            <Field error={errors?.email || authState === 'error'}>
              <label>
                Email *
                <input type="email" {...register('email', { required })} placeholder="Write here" />
              </label>
            </Field>
            <Field error={errors?.password || authState === 'error'}>
              <label>
                Password *
                <input type="password" {...register('password', { required })} placeholder="Password here" />
              </label>
            </Field>

            <p><Link to="/forgotpassword">Forgot Password? 👀</Link></p>

            <Field center>
              <button type="submit" disabled={attempted && authState === 'loading' || !isValid}>Sign In</button>
            </Field>
          </form>
        </ShadowCard>
        <div>
          <img src={loginIllus} alt="" height={435} />
        </div>
      </div>
    </main>
  );
}