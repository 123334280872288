import { useMemo } from 'react';
import { useFirebaseSchool } from '../../../context/FirebaseSchoolContext';
import { TopSection, EmptyPlaceholder } from './FavoriteStudentsPage';
import StudentCard from '../../../components/InstitutionUI/StudentCard';
import './InstFavorites.scss';

export default function InstitutionFavoriteMatchPage() {
  const { favoriteStudentsList, students } = useFirebaseSchool();

  const matches = useMemo(() => {
    if (!favoriteStudentsList || !favoriteStudentsList.length || !students) return [];
    return favoriteStudentsList.filter(s => s.byStudent && s.byProgram).map(s => ({ ...students[s.studentId], studentId: s.studentId }));
  }, [favoriteStudentsList, students]);

  return (
    <main className="inst-favorites">
      {matches.length ? (
        <>
          <TopSection totalText={`You have ${matches.length} match${matches.length === 1 ? '' : 'es'}`} />
          <ul className="grid">
            {matches.map(s => (
              <li key={s.studentId}>
                <StudentCard student={s} />
              </li>
            ))}
          </ul>
        </>
      ) : (
        <EmptyPlaceholder text="You haven't Matched with any students yet. Make sure your Program Profile is up-to-date and you are utilizing the search tools such as the Customized Candidate Builder and the Recommendations features to maximize your Match potential." />
      )}
    </main>
  );
}