import { useEffect } from 'react';

const interactiveElTags = ['INPUT', 'SELECT', 'TEXTAREA'];

export default function useKeypress(key, action) {
  useEffect(() => {
    function onKeyUp(e) {
      if (e.key === key) {
        if (!interactiveElTags.includes(document.activeElement?.tagName) && document.activeElement?.tabIndex !== 0) {
          action();
        }
      }
    }
    window.addEventListener('keyup', onKeyUp);
    return () => window.removeEventListener('keyup', onKeyUp);
  }, [key, action]);
  return null;
}