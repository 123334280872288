import InfoTag from './InfoTag';
import PopupCard from '../StudentUI/PopupCard';
import info from '../../assets/student/images/info-dark-green.svg';
import './MostCompatible.scss';

export default function MostCompatible() {
  return (
    <div className="inst-most-compatible">
      <PopupCard
        triggerButton={
          <button>
            <InfoTag>Most Compatible <img src={info} alt="" /></InfoTag>
          </button>
        }
        position="bottom right"
        on={['hover', 'focus', 'click']}
      >
        <div className="inst-most-compatible-popup">
          <p>This student&apos;s qualifications and preferences best matches your program criteria making them a strong application candidate.</p>
        </div>
      </PopupCard>
    </div>
  );
}