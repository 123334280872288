import { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Modal from '../../../components/Modal';
import TodoBlock from '../../../components/StudentTodos/TodoBlock';
import { useFirebaseStudent, useStudentSystemTodos } from '../../../context/FirebaseStudentContext';
import StudentToDoForm from '../../../forms/StudentTodos/StudentToDoForm';
import AddButton from '../../../components/StudentUI/AddButton';
import down from '../../../assets/student/images/down-carat-green.svg';
import todo from '../../../assets/student/images/todo-illus.svg';
import './TodoStyles.scss';

export default function StudentToDoPlanner() {
  const [showForm, setShowForm] = useState(null);
  const { student, addOrUpdateListItem, removeListItem } = useFirebaseStudent();
  const { profileTodos, programSearchTodos, applicationTodos, studentTodos } = useStudentSystemTodos();

  const now = useMemo(() => new Date(), []);
  const today = useMemo(() => new Date(now.getTime() + now.getTimezoneOffset() * 60000), [now]);
  const tomorrow = useMemo(() => new Date().setDate(Number(today.getDate() + 1)), [today]);
  const nextWeek = useMemo(() => new Date().setDate(Number(today.getDate() + 7)), [today]);
  const nextMonth = useMemo(() => new Date().setDate(Number(today.getDate() + 30)), [today]);

  useEffect(() => {
    setShowForm(null);
  }, [student]);

  const getDefaultValues = (activeForm, studentData) => {
    if (!activeForm || activeForm.key === null || !studentData[activeForm.type]) {
      return null;
    } else {
      return studentData[activeForm.type][activeForm.key];
    }
  }

  const dueTodayFilter = useCallback((item) => {
    const preConverted = new Date(item.dueDate);
    const itemDate = new Date(preConverted.getTime() + preConverted.getTimezoneOffset() * 60000);

    return (
      !item.complete
      && itemDate.getDate() === today.getDate()
      && itemDate.getMonth() === today.getMonth()
      && itemDate.getFullYear() === today.getFullYear()
    );
  }, [today]);

  const dueTomorrowFilter = useCallback((item) => {
    const preConverted = new Date(item.dueDate);
    const itemDate = new Date(preConverted.getTime() + preConverted.getTimezoneOffset() * 60000);
    return !item.complete && itemDate > today && itemDate <= tomorrow;
  }, [today, tomorrow]);

  const dueThisWeekFilter = useCallback((item) => {
    const preConverted = new Date(item.dueDate);
    const itemDate = new Date(preConverted.getTime() + preConverted.getTimezoneOffset() * 60000);
    return !item.complete && itemDate > tomorrow && itemDate <= nextWeek;
  }, [tomorrow, nextWeek]);

  const dueThisMonthFilter = useCallback((item) => {
    const preConverted = new Date(item.dueDate);
    const itemDate = new Date(preConverted.getTime() + preConverted.getTimezoneOffset() * 60000);
    return !item.complete && itemDate > nextWeek && itemDate <= nextMonth;
  }, [nextWeek, nextMonth]);

  const dueTodayTasks = useMemo(() => {
    return [
      ...profileTodos,
      ...programSearchTodos,
      ...applicationTodos,
      ...studentTodos,
    ].filter(dueTodayFilter);
  }, [profileTodos, programSearchTodos, applicationTodos, studentTodos, dueTodayFilter]);

  const dueTomorrowTasks = useMemo(() => {
    return [
      ...profileTodos,
      ...programSearchTodos,
      ...applicationTodos,
      ...studentTodos,
    ].filter(dueTomorrowFilter);
  }, [profileTodos, programSearchTodos, applicationTodos, studentTodos, dueTomorrowFilter]);

  const dueThisWeekTasks = useMemo(() => {
    return [
      ...profileTodos,
      ...programSearchTodos,
      ...applicationTodos,
      ...studentTodos,
    ].filter(dueThisWeekFilter);
  }, [profileTodos, programSearchTodos, applicationTodos, studentTodos, dueThisWeekFilter]);

  const dueThisMonthTasks = useMemo(() => {
    return [
      ...profileTodos,
      ...programSearchTodos,
      ...applicationTodos,
      ...studentTodos,
    ].filter(dueThisMonthFilter);
  }, [profileTodos, programSearchTodos, applicationTodos, studentTodos, dueThisMonthFilter]);

  const firstSection = useMemo(() => {
    if (dueTodayTasks?.length) {
      return 'today';
    } else if (dueTomorrowTasks?.length) {
      return 'tomorrow';
    } else if (dueThisWeekTasks?.length) {
      return 'thisWeek';
    } else if (dueThisMonthTasks?.length) {
      return 'thisMonth';
    } else {
      return '';
    }

  }, [dueTodayTasks, dueTomorrowTasks, dueThisWeekTasks, dueThisMonthTasks]);

  return (
    <main className="student-todos-page">
      <div className="col">
        <div className="col__header">
          <h1>Planner</h1>
          <AddButton onClick={() => setShowForm({ type: 'todos', index: null })}>
            add a task
          </AddButton>
        </div>

        {student && firstSection ? (
          <Accordion className="student-todo-accordion" preExpanded={[firstSection]} allowZeroExpanded>
            <AccordionItem uuid="today" activeClassName="accordion__item--expanded">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <div>
                    Today
                    <span className="count">{dueTodayTasks.length} Task{dueTodayTasks.length === 1 ? '' : 's'}</span>
                  </div>
                  <img src={down} alt="" />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                {dueTodayTasks.map((data) => (
                  <TodoBlock
                    data={data}
                    key={data.key || data.id}
                    editData={data.id ? () => setShowForm({ type: 'todos', key: data.id }) : null}
                  />
                ))}
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem uuid="tomorrow" activeClassName="accordion__item--expanded">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <div>
                    Tomorrow
                    <span className="count">{dueTomorrowTasks.length} Task{dueTomorrowTasks.length === 1 ? '' : 's'}</span>
                  </div>
                  <img src={down} alt="" />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                {dueTomorrowTasks.map((data) => (
                  <TodoBlock
                    data={data}
                    key={data.key || data.id}
                    editData={data.id ? () => setShowForm({ type: 'todos', key: data.id }) : null}
                    // deleteItem={() => removeListItem('todos', key, 'To do removed!')}
                  />
                ))}
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem uuid="thisWeek" activeClassName="accordion__item--expanded">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <div>
                    This Week
                    <span className="count">{dueThisWeekTasks.length} Task{dueThisWeekTasks.length === 1 ? '' : 's'}</span>
                  </div>
                  <img src={down} alt="" />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                {dueThisWeekTasks.map((data) => (
                  <TodoBlock
                    data={data}
                    key={data.key || data.id}
                    editData={data.id ? () => setShowForm({ type: 'todos', key: data.id }) : null}
                    // deleteItem={() => removeListItem('todos', key, 'To do removed!')}
                  />
                ))}
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem uuid="thisMonth" activeClassName="accordion__item--expanded">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <div>
                    This Month
                    <span className="count">{dueThisMonthTasks.length} Task{dueThisMonthTasks.length === 1 ? '' : 's'}</span>
                  </div>
                  <img src={down} alt="" />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                {dueThisMonthTasks.map((data) => (
                  <TodoBlock
                    data={data}
                    key={data.key || data.id}
                    editData={data.id ? () => setShowForm({ type: 'todos', key: data.id }) : null}
                    // deleteItem={() => removeListItem('todos', key, 'To do removed!')}
                  />
                ))}
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        ) : null}
      </div>

      <div className="col">
        <img src={todo} alt="" width={300} className="illustration" />
      </div>

      <Modal
        closeModal={() => setShowForm(null)}
        title="Add To Do"
        isOpen={showForm?.type === 'todos'}
        modalClassName="student-todo-panel"
      >
        <StudentToDoForm
          defaultValues={getDefaultValues(showForm, student)}
          saveData={(data) => addOrUpdateListItem('todos', data, 'To do saved!')}
          markComplete={showForm?.key ? () => {
            addOrUpdateListItem('todos', { ...getDefaultValues(showForm, student), complete: true }, 'Nice work! ✨ You\'ve finished this task.');
            setShowForm(null);
          } : null}
          deleteItem={showForm?.key ? () => {
            removeListItem('todos', showForm.key, 'To do removed!');
            setShowForm(null);
          } : null}
        />
      </Modal>
    </main>
  );
}