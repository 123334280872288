import PropTypes from 'prop-types';
import search from '../../assets/icons/search-gray.svg';
import './SearchInput.scss';

export default function SearchInput({ placeholder }) {
  return (
    <input
      className="inst-search-input"
      placeholder={placeholder}
      style={{ backgroundImage: `url(${search})`}}
    />
  )
}

SearchInput.propTypes = {
  placeholder: PropTypes.string,
};