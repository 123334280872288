import { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Modal from '../Modal';
import ControlledMultiSelect from '../ControlledMultiSelect';
import ControlledSearchInput from './ControlledSearchInput';
import { useFirebasePrograms, defaultFilters } from '../../context/FirebaseProgramDataContext';
import { stateOptions } from '../../shared/formOptions';
import './Filters.scss';
import { comingSoonLong, comingSoonShort } from '../../shared/appText';
import downCarat from '../../assets/student/images/down-carat-pink.svg';
import { studentCampusSettingFilters, studentLevelFilters, studentProgramFormatFilters, studentSchoolTypeFilters } from '../../shared/filterOptions';

const searchResultsUrl = '/students/explore/search';

export function FiltersForm({ defaultValues, updateFilters, closeModal, readOnly }) {
  const navigate = useNavigate();
  const { register, control, handleSubmit, reset } = useForm({ defaultValues });

  const handleReset = () => {
    updateFilters(defaultFilters);
    reset(defaultFilters);
  }

  const setFiltersAndViewResults = (data) => {
    updateFilters(data);
    navigate(searchResultsUrl);
    closeModal();
  }

  return (
    <form onSubmit={handleSubmit(setFiltersAndViewResults)} className="search-filter-list">
      <Accordion preExpanded={['level']} onChange={handleSubmit(updateFilters)} allowMultipleExpanded={true}>
        <AccordionItem uuid="level">
          <AccordionItemHeading>
            <AccordionItemButton>
              <span>Level</span>
              <img src={downCarat} alt="" />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <fieldset>
              <legend className="sr-only">Level</legend>
              {studentLevelFilters.map(filter => (
                <label key={filter.value} htmlFor={filter.value} className="checkbox">
                  <span>{filter.label}</span>
                  <input type="checkbox" {...register('levels')} id={filter.value} value={filter.value} />
                </label>
              ))}
            </fieldset>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="location">
          <AccordionItemHeading>
            <AccordionItemButton>
              <span>Location</span>
              <img src={downCarat} alt="" />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <fieldset className="location">
              <legend className="sr-only">Location</legend>
              <div>
                <label htmlFor="state" className="student-text-label">Search by State</label><br />
                <ControlledMultiSelect
                  control={control}
                  defaultValue={[]}
                  name="state"
                  options={stateOptions}
                  customClassNames="filter-states"
                  placeholder="Search states"
                  isSearch
                />
              </div>

              <div>
                <label className="student-text-label">
                  Search by Distance
                  <input type="text" disabled placeholder={comingSoonLong} />
                </label>
              </div>
            </fieldset>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="setting">
          <AccordionItemHeading>
            <AccordionItemButton>
              <span>Campus Setting</span>
              <img src={downCarat} alt="" />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <fieldset>
              <legend className="sr-only">Campus Setting</legend>
              {studentCampusSettingFilters.map(filter => (
                <label key={filter.value} className="checkbox">
                  <span>{filter.label}</span>
                  <input type="radio" {...register('setting')} value={filter.value} id={filter.value} />
                </label>
              ))}
            </fieldset>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="schoolType">
          <AccordionItemHeading>
            <AccordionItemButton>
              <span>School Type</span>
              <img src={downCarat} alt="" />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <fieldset>
              <legend className="sr-only">School Type</legend>
              {studentSchoolTypeFilters.map(filter => (
                <label key={filter.value} className="checkbox">
                  <span>{filter.label}</span>
                  <input type="radio" {...register('schoolType')} value={filter.value} id={filter.value} />
                </label>
              ))}
            </fieldset>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="programType">
          <AccordionItemHeading>
            <AccordionItemButton>
              <span>Program Format</span>
              <img src={downCarat} alt="" />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <fieldset>
              <legend className="sr-only">Program Format</legend>
              {studentProgramFormatFilters.map(filter => (
                <label key={filter.value} className="checkbox">
                  <span>{filter.label}</span>
                  <input type="checkbox" {...register('programType')} value={filter.value} id={filter.value} />
                </label>
              ))}
            </fieldset>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="studentSupport">
          <AccordionItemHeading>
            <AccordionItemButton>
              <span>Tuition Cost (Per Semester)</span>
              <img src={downCarat} alt="" />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="student-text-p-italic">{comingSoonLong}</p>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
      <div className="buttons">
        <button type="submit" className="student-text-label search-button" disabled={readOnly}>Search</button>
        <button type="button" onClick={handleReset} className="student-cta-link" disabled={readOnly}>Reset Filters</button>
        {readOnly ? <span className="coming-soon">{comingSoonShort}</span> : null}
      </div>
    </form>
  )
}

FiltersForm.propTypes = {
  defaultValues: PropTypes.object,
  updateFilters: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
}

function SearchForm({ defaultValues, updateFilters, filterButton, showSearchButton }) {
  const formRef = useRef();
  const navigate = useNavigate();
  const { control, handleSubmit, setValue } = useForm({ defaultValues });

  const onSubmit = (data) => {
    updateFilters(data);
    navigate(searchResultsUrl);
  }

  const resetSearchTerm = () => {
    setValue('title', '');
    updateFilters({ title: '' });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} ref={formRef} className="search">
      <div className="flex">
        <ControlledSearchInput
          control={control}
          name="title"
          defaultValue=""
          placeholder="Business, engineering, journalism"
          reset={resetSearchTerm}
        />
        {filterButton}
      </div>
      {showSearchButton ? (
        <button type="submit">Search</button>
      ) : null}
    </form>
  );
}

SearchForm.propTypes = {
  defaultValues: PropTypes.object,
  updateFilters: PropTypes.func.isRequired,
  filterButton: PropTypes.node.isRequired,
  showSearchButton: PropTypes.bool,
};

export default function Filters({ showSearchButton }) {
  const [showModal, setShowModal] = useState(false);
  const { filters, setFilters } = useFirebasePrograms();

  const updateFilters = (data) => {
    setFilters({
      ...filters,
      ...data,
    });
  }

  return (
    <div className="search-filter-container">
      <SearchForm
        defaultValues={{ title: filters?.title || '' }}
        updateFilters={updateFilters}
        showSearchButton={showSearchButton}
        filterButton={<button type="button" className="student-cta-link" onClick={() => setShowModal(true)}>Filters</button>}
      />
      <Modal
        closeModal={() => setShowModal(false)}
        title="Filters"
        isOpen={showModal}
      >
        <FiltersForm
          defaultValues={filters}
          updateFilters={updateFilters}
          closeModal={() => setShowModal(false)}
        />
      </Modal>
    </div>
  );
}

Filters.propTypes = {
  showSearchButton: PropTypes.bool,
}