import { Routes, Route, Navigate } from 'react-router-dom';
import { FirebaseStudentProvider } from '../context/FirebaseStudentContext';
import { FirebaseProgramDataProvider } from '../context/FirebaseProgramDataContext';
import { FirebaseSchoolProvider } from '../context/FirebaseSchoolContext';
import LoginPage from '../pages/Auth/LoginPage';
import ForgotPasswordPage from '../pages/Auth/ForgotPasswordPage';
import StudentLayout from '../layouts/Students/StudentLayout';
import StudentOnboardingLayout from '../layouts/Students/StudentOnboardingLayout';
import StudentDashboardPage from '../pages/Students/Dashboard/DashboardPage';
import StudentProfilePage from '../pages/Students/Profile/ProfilePage';
import StudentExplorePage from '../pages/Students/Explore/ExplorePage';
import StudentApplicationsPage from '../pages/Students/Applications/ApplicationsPage';
import StudentApplicationPage from '../pages/Students/Application/ApplicationPage';
import StudentResourcesPage from '../pages/Students/Resources/ResourcesPage';
import StudentResourceArticlePage from '../pages/Students/ResourceArticle/ResourceArticlePage';
import StudentResourceArticlesPage from '../pages/Students/ResourceArticles/ResourceArticlesPage';
import StudentCalculateROIPage from '../pages/Students/CalculateROI/CalculateROIPage';
import StudentToDoLayout from '../layouts/Students/StudentToDoLayout';
import StudentToDoByGroup from '../pages/Students/Todos/TodoByGroup';
import StudentToDoPlanner from '../pages/Students/Todos/TodoPlanner';
import StudentSchoolProfileOverviewPage from '../pages/Students/SchoolProfile/SchoolProfileOverviewPage';
import StudentSchoolProfileProgramsListPage from '../pages/Students/SchoolProfile/SchoolProfileProgramsListPage';
import StudentSchoolProfileStudentsPage from '../pages/Students/SchoolProfile/SchoolProfileStudentsPage';
import StudentProgramProfileOverviewPage from '../pages/Students/ProgramProfile/ProgramProfileOverviewPage';
import StudentProgramProfileAdmissionsPage from '../pages/Students/ProgramProfile/ProgramProfileAdmissionsPage';
import StudentProgramProfileFacultyPage from '../pages/Students/ProgramProfile/ProgramProfileFacultyPage';
import StudentProgramProfileCostsPage from '../pages/Students/ProgramProfile/ProgramProfileCostsPage';
import StudentProgramProfileFAQPage from '../pages/Students/ProgramProfile/ProgramProfileFAQPage';
import StudentBasicInfoSurveyIntroPage from '../pages/Students/Onboarding/BasicInfoSurveyIntroPage';
import StudentBasicInfoSurveyGoalsPage from '../pages/Students/Onboarding/BasicInfoSurveyGoalsPage';
import StudentSchoolsSurveyIntroPage from '../pages/Students/Onboarding/SchoolsSurveyIntroPage';
import StudentSchoolsSurveyAddPage from '../pages/Students/Onboarding/SchoolsSurveyAddPage';
import StudentSchoolsSurveyPrioritiesPage from '../pages/Students/Onboarding/SchoolsSurveyPrioritiesPage';
import StudentSchoolsSurveySizePage from '../pages/Students/Onboarding/SchoolsSurveySizePage';
import StudentSchoolsSurveyPreferencesPage from '../pages/Students/Onboarding/SchoolsSurveyPreferencesPage';
import StudentOnboardingEducationPage from '../pages/Students/Onboarding/EducationHistorySurveyPage';
import StudentOnboardingWorkPage from '../pages/Students/Onboarding/WorkHistorySurveyPage';
import StudentFavoriteProgramsPage from '../pages/Students/FavoritePrograms/FavoriteProgramsPage';
import StudentFavoriteByProgramsPage from '../pages/Students/FavoritePrograms/FavoriteByProgramsPage';
import StudentFavoriteMatchPage from '../pages/Students/FavoritePrograms/FavoriteMatchPage';
import StudentProgramResultsPage from '../pages/Students/SearchResults/SearchResultsPage';
import DocumentsPage from '../pages/Students/Documents/DocumentsPage';
import CareersPage from '../pages/Students/Careers/CareersPage';
import AssessmentPage from '../pages/Students/Assessment/AssessmentPage';
import RequireAuth from '../routes/RequireAuth';
import StudentExploreLayout from '../layouts/Students/StudentExploreLayout';
import StudentSchoolProfileLayout from '../layouts/Students/StudentSchoolProfileLayout';
import StudentProgramProfileLayout from '../layouts/Students/StudentProgramProfileLayout';
import StudentFavoriteProgramsLayout from '../layouts/Students/StudentFavoriteProgramsLayout';
import InstitutionLayout from '../layouts/Institutions/InstitutionLayout';
import InstitutionStudentSearchLayout from '../layouts/Institutions/InstitutionStudentSearchLayout';
import InstitutionApplicationsLayout from '../layouts/Institutions/InstitutionApplicationsLayout';
import InstitutionDashboardPage from '../pages/Institutions/Dashboard/DashboardPage';
import InstitutionFavoriteStudentsLayout from '../layouts/Institutions/InstitutionFavoriteStudentsLayout';
import InstitutionFavoriteByStudentsPage from '../pages/Institutions/Favorites/FavoriteByStudentsPage';
import InstitutionFavoriteMatchPage from '../pages/Institutions/Favorites/FavoriteMatchPage';
import InstitutionFavoriteStudentsPage from '../pages/Institutions/Favorites/FavoriteStudentsPage';
import InstitutionRecruitingLandingPage from '../pages/Institutions/RecruitingLanding/RecruitingLandingPage';
import InstitutionStudentSearchHomePage from '../pages/Institutions/StudentSearch/HomePage';
import InstitutionSearchResultsPage from '../pages/Institutions/StudentSearch/SearchResultsPage';
import InstitutionRecommendationsPage from '../pages/Institutions/Recommendations/RecommendationsPage';
import InstitutionApplicationsPage from '../pages/Institutions/Applications/ApplicationsPage';
import InstitutionApplicationPage from '../pages/Institutions/Applications/ApplicationPage';
import InstitutionPurchaseListPage from '../pages/Institutions/PurchaseList/PurchaseList';
import InstitutionCandidateBuilderHomePage from '../pages/Institutions/CandidateBuilder/HomePage';
import InstitutionCandidateBuilderResultsPage from '../pages/Institutions/CandidateBuilder/ResultsPage';
import InstitutionStudentProfilePage from '../pages/Institutions/StudentSearch/StudentProfilePage';
import InstitutionSwitchProgramPage from '../pages/Institutions/SwitchProgram/InstitutionSwitchProgramPage';
import InstitutionPeerProgramsInsightsPage from '../pages/Institutions/Insights/PeerProgramsInsightsPage';
import InstitutionTipsAndBestPracticesPage from '../pages/Institutions/TipsAndBestPractices/TipsAndBestPractices';
import InstitutionTipsAndBestPracticesRoiPage from '../pages/Institutions/TipsAndBestPractices/TipsAndBestPracticesROI';
import InstitutionTipsAndBestPracticesTrendsPage from '../pages/Institutions/TipsAndBestPractices/TipsAndBestPracticesTrends';
import InstitutionStudentInsightsPage from '../pages/Institutions/Insights/StudentInsightsPage.js';
import InstitutionSchoolProfileLayout from '../layouts/Institutions/InstitutionSchoolProfileLayout';
import InstitutionProgramProfileLayout from '../layouts/Institutions/InstitutionProgramProfileLayout';
import InstitutionSchoolProfileOverviewPage from '../pages/Institutions/Profile/SchoolProfileOverviewPage';
import InstitutionSchoolProfileProgramsListPage from '../pages/Institutions/Profile/SchoolProfileProgramsListPage';
import InstitutionSchoolProfileStudentsPage from '../pages/Institutions/Profile/SchoolProfileStudentsPage';
import InstitutionProgramProfileOverviewPage from '../pages/Institutions/Profile/ProgramProfileOverviewPage';
import InstitutionProgramProfileAdmissionsPage from '../pages/Institutions/Profile/ProgramProfileAdmissionsPage';
import InstitutionProgramProfileFacultyPage from '../pages/Institutions/Profile/ProgramProfileFacultyPage';
import InstitutionProgramProfileCostsPage from '../pages/Institutions/Profile/ProgramProfileCostsPage';
import InstitutionProgramProfileFAQPage from '../pages/Institutions/Profile/ProgramProfileFAQPage';
import InstitutionPricingModelsLayout from '../layouts/Institutions/InstitutionPricingModelsLayout';
import InstitutionPricingOutcomesPage from '../pages/Institutions/PricingModels/OutcomesPage';
import InstitutionPricingFeaturesPage from '../pages/Institutions/PricingModels/FeaturesPage';
import InstitutionPricingLeadsPage from '../pages/Institutions/PricingModels/LeadsPage';

export default function AllRoutes() {
  return (
    <Routes>
      <Route path="/" exact element={<LoginPage />} />
      <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
      <Route path="/students/*" element={
        <RequireAuth requiredRole="student">
          <FirebaseProgramDataProvider>
            <FirebaseStudentProvider>
              <Routes>
                <Route path="/surveys/" element={<StudentOnboardingLayout />}>
                  <Route index element={<StudentBasicInfoSurveyIntroPage />} />
                  <Route path="goals" element={<StudentBasicInfoSurveyGoalsPage />} />
                  <Route path="schools/">
                    <Route index element={<StudentSchoolsSurveyIntroPage />} />
                    <Route path="add" element={<StudentSchoolsSurveyAddPage />} />
                    <Route path="priorities" element={<StudentSchoolsSurveyPrioritiesPage />} />
                    <Route path="preferences" element={<StudentSchoolsSurveyPreferencesPage />} />
                    <Route path="size" element={<StudentSchoolsSurveySizePage />} />
                    <Route path="*" element={<Navigate replace to="" />} />
                  </Route>
                  <Route path="education" element={<StudentOnboardingEducationPage />} />
                  <Route path="workexperience" element={<StudentOnboardingWorkPage />} />
                  <Route path="*" element={<Navigate replace to="" />} />
                </Route>
                <Route path="/" element={<StudentLayout />}>
                  <Route index element={<StudentDashboardPage />} />
                  <Route path="profile" element={<StudentProfilePage />} />
                  <Route path="/favorites/" element={<StudentFavoriteProgramsLayout />}>
                    <Route index element={<StudentFavoriteProgramsPage />} />
                    <Route path="byprograms" element={<StudentFavoriteByProgramsPage />} />
                    <Route path="matches" element={<StudentFavoriteMatchPage />} />
                    <Route path="*" element={<Navigate replace to="" />} />
                  </Route>
                  <Route path="/explore/" element={<StudentExploreLayout />}>
                    <Route index element={<StudentExplorePage />} />
                    <Route path="search" index element={<StudentProgramResultsPage />} />
                    <Route path="schools/">
                      <Route index element={<Navigate replace to="/students/explore" />} />
                      <Route path=":schoolId/" element={<StudentSchoolProfileLayout />}>
                        <Route index element={<StudentSchoolProfileOverviewPage />} />
                        <Route path="programs" element={<StudentSchoolProfileProgramsListPage />} />
                        <Route path="students" element={<StudentSchoolProfileStudentsPage />} />
                        <Route path="*" element={<Navigate replace to="" />} />
                      </Route>
                    </Route>
                    <Route path="programs/">
                      <Route index element={<Navigate replace to="/students/explore" />} />
                      <Route path=":programId/" element={<StudentProgramProfileLayout />}>
                        <Route index element={<StudentProgramProfileOverviewPage />} />
                        <Route path="admissions" element={<StudentProgramProfileAdmissionsPage />} />
                        <Route path="faculty" element={<StudentProgramProfileFacultyPage />} />
                        <Route path="costs" element={<StudentProgramProfileCostsPage />} />
                        <Route path="faq" element={<StudentProgramProfileFAQPage />} />
                        <Route path="*" element={<Navigate replace to="" />} />
                      </Route>
                    </Route>
                    <Route path="*" element={<Navigate replace to="" />} />
                  </Route>
                  <Route path="applications" element={<StudentApplicationsPage />} />
                  <Route path="applications/:programId" element={<StudentApplicationPage />} />
                  <Route path="resources/*" element={
                    <Routes>
                      <Route path="/" element={<StudentResourcesPage />}>
                        <Route path="articles" element={<StudentResourceArticlesPage />} />
                        <Route path="calculateroi" element={<StudentCalculateROIPage />} />
                        <Route path=":articleId" element={<StudentResourceArticlePage />} />
                      </Route>
                    </Routes>
                  } />
                  <Route path="todos" element={<StudentToDoLayout />}>
                    <Route index element={<StudentToDoByGroup />} />
                    <Route path="planner" element={<StudentToDoPlanner />} />
                    <Route path="*" element={<Navigate replace to="" />} />
                  </Route>
                  <Route path="documents" element={<DocumentsPage />}/>
                  <Route path="careers" element={<CareersPage/>}/>
                  <Route path='assessments' element={<AssessmentPage/>}/>
                  <Route path="*" element={<Navigate replace to="" />} />
                </Route>
              </Routes>
            </FirebaseStudentProvider>
          </FirebaseProgramDataProvider>
        </RequireAuth>
      } />
      <Route path="/institutions/*" element={
        <RequireAuth requiredRole="institution">
          <FirebaseProgramDataProvider>
            <FirebaseSchoolProvider>
              <Routes>
                <Route path="/" element={<InstitutionLayout />}>
                  <Route index element={<InstitutionDashboardPage />} />
                  <Route path="recruiting/">
                    <Route index element={<InstitutionRecruitingLandingPage />} />
                    <Route path="studentsearch/" element={<InstitutionStudentSearchLayout />}>
                      <Route index element={<InstitutionStudentSearchHomePage />} />
                      <Route path="results" element={<InstitutionSearchResultsPage />} />
                      <Route path=":studentId" element={<InstitutionStudentProfilePage />} />
                      <Route path="*" element={<Navigate replace to="" />} />
                    </Route>
                    <Route path="candidatebuilder/">
                      <Route index element={<InstitutionCandidateBuilderHomePage />} />
                      <Route path="results" element={<InstitutionCandidateBuilderResultsPage />} />
                      <Route path="*" element={<Navigate replace to="" />} />
                    </Route>
                    <Route path="purchaselist" element={<InstitutionPurchaseListPage />} />
                    <Route path="favorites/" element={<InstitutionFavoriteStudentsLayout />}>
                      <Route index element={<InstitutionFavoriteStudentsPage />} />
                      <Route path="bystudents" element={<InstitutionFavoriteByStudentsPage />} />
                      <Route path="matches" element={<InstitutionFavoriteMatchPage />} />
                      <Route path="*" element={<Navigate replace to="" />} />
                    </Route>
                    <Route path="recommendations" element={<InstitutionRecommendationsPage />} />
                  </Route>
                  <Route path="applications" element={<InstitutionApplicationsLayout />}>
                    <Route index element={<InstitutionApplicationsPage />} />
                    <Route path=":studentId" element={<InstitutionApplicationPage />} />
                  </Route>
                  <Route path="peerprograms" element={<InstitutionPeerProgramsInsightsPage />} />
                  <Route path="studentinsights" element={<InstitutionStudentInsightsPage />} />
                  <Route path="tips">
                    <Route index element={<InstitutionTipsAndBestPracticesPage />} />
                    <Route path="improve-roi" element={<InstitutionTipsAndBestPracticesRoiPage />} />
                    <Route path="profile-trends" element={<InstitutionTipsAndBestPracticesTrendsPage />} />
                  </Route>
                  <Route path="schoolprofile" element={<InstitutionSchoolProfileLayout />}>
                    <Route index element={<InstitutionSchoolProfileOverviewPage />} />
                    <Route path="programs" element={<InstitutionSchoolProfileProgramsListPage />} />
                    <Route path="students" element={<InstitutionSchoolProfileStudentsPage />} />
                    <Route path="*" element={<Navigate replace to="" />} />
                  </Route>
                  <Route path="programprofile/:programId" element={<InstitutionProgramProfileLayout />}>
                    <Route index element={<InstitutionProgramProfileOverviewPage />} />
                    <Route path="admissions" element={<InstitutionProgramProfileAdmissionsPage />} />
                    <Route path="faculty" element={<InstitutionProgramProfileFacultyPage />} />
                    <Route path="costs" element={<InstitutionProgramProfileCostsPage />} />
                    <Route path="faq" element={<InstitutionProgramProfileFAQPage />} />
                    <Route path="*" element={<Navigate replace to="" />} />
                  </Route>
                  <Route path="pricingmodels" element={<InstitutionPricingModelsLayout />}>
                    <Route index element={<InstitutionPricingOutcomesPage />} />
                    <Route path="features" element={<InstitutionPricingFeaturesPage />} />
                    <Route path="leads" element={<InstitutionPricingLeadsPage />} />
                    <Route path="*" element={<Navigate replace to="" />} />
                  </Route>
                </Route>
                <Route path="/switchprogram" element={<InstitutionSwitchProgramPage />} />
                <Route path="*" element={<Navigate replace to="" />} />
              </Routes>
            </FirebaseSchoolProvider>
          </FirebaseProgramDataProvider>
        </RequireAuth>
      } />
      <Route path="*" element={<h1>Not Found</h1>} />
    </Routes>
  );
}