import PropTypes from 'prop-types';
import {
  citizenshipStatusOptions,
  countryOptions,
  degreeOptions,
  ethnicGroupOptions,
  ethnicityOptions,
  pronounOptions,
  startDateOptions,
  stateOptions,
  programTypeOptions,
  schoolTypeOptions,
  campusSettingOptions,
  programSizeOptions
} from './formOptions';

export const studentPropTypes = {
  alias: PropTypes.string,
  isInternational: PropTypes.bool,
  isFirstGeneration: PropTypes.bool,
  pronouns: PropTypes.oneOf([...pronounOptions.map(o => o.value), '']),
  ethnicity: PropTypes.oneOf([...ethnicityOptions.map(o => o.value), '']),
  ethnicGroups: PropTypes.arrayOf(PropTypes.oneOf([...ethnicGroupOptions.map(o => o.value), ''])),
  citizenshipStatus: PropTypes.oneOf([...citizenshipStatusOptions.map(o => o.value), '']),
  citizenshipCountry: PropTypes.oneOf([...countryOptions, '']),
  stateOfResidence: PropTypes.oneOf([...stateOptions.map(o => o.value), '']),
  seekingDegree: PropTypes.arrayOf(PropTypes.oneOf([...degreeOptions.map(o => o.value), ''])),
  fieldOfStudy: PropTypes.number,
  expectedStartDate: PropTypes.arrayOf(PropTypes.oneOf([...startDateOptions.map(o => o.value), ''])),
  targetStates: PropTypes.arrayOf(PropTypes.oneOf([...stateOptions.map(o => o.value), ''])),
  prestigePriority: PropTypes.arrayOf(PropTypes.number),
  costPriority: PropTypes.arrayOf(PropTypes.number),
  locationPriority: PropTypes.arrayOf(PropTypes.number),
  programFormatPriority: PropTypes.arrayOf(PropTypes.number),
  programType: PropTypes.arrayOf(PropTypes.oneOf([...programTypeOptions.map(o => o.value), ''])),
  schoolType: PropTypes.arrayOf(PropTypes.oneOf([...schoolTypeOptions.map(o => o.value), ''])),
  settingType: PropTypes.arrayOf(PropTypes.oneOf([...campusSettingOptions.map(o => o.value), ''])),
  costRange: PropTypes.arrayOf(PropTypes.number),
  programSize: PropTypes.oneOf([...programSizeOptions.map(o => o.value), '']),
};

export const studentEducationPropTypes = {
  schoolName: PropTypes.string,
  major: PropTypes.string,
  degree: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  grade: PropTypes.string,
  isCurrent: PropTypes.bool,
  description: PropTypes.string,
};

export const studentWorkPropTypes = {
  isCurrent: PropTypes.bool,
  title: PropTypes.string,
  company: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  description: PropTypes.string,
};

export const studentCertificatePropTypes = {
  title: PropTypes.string,
  issuingOrgName: PropTypes.string,
  issueDate: PropTypes.string,
  expireDate: PropTypes.string,
  description: PropTypes.string,
};

export const studentGrePropTypes = {
  test: PropTypes.string,
  dateTaken: PropTypes.string,
  verbal: PropTypes.number,
  quantitative: PropTypes.number,
  analyticalWriting: PropTypes.number,
};