import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Field from '../Field';
import Modal from '../Modal';
import ControlledMultiSelect from '../ControlledMultiSelect';
import ControlledSlider from '../ControlledSlider';
import {
  stateOptions,
  cipFieldOptions,
  countryOptions,
  ethnicGroupOptions,
  degreeOptions,
  startDateOptions,
} from '../../shared/formOptions';
import { institutionGenderOptions } from '../../shared/filterOptions';
import { generateLastXYears } from '../../shared/numbers';
import downCarat from '../../assets/inst/images/down-carat-green.svg';
import './Filters.scss';

const filterDefaultValues = {
  major: '',
  gpaRange: [0, 4],
  graduationYear: '',
  citizenshipCountry: '',
  citizenshipStatus: '',
  ethnicGroups: '',
  gender: '',
  earliestStartDate: '',
  latestStartDate: '',
  degree: '',
  greVerbal: [130, 170],
  greQuantitative: [130, 170],
  greWriting: [0, 6],
  state: '',
  zipCode: '',
  distance: [100],
}

const yearOptions = generateLastXYears(20).map(x => ({ value: x, label: x }));

const citizenshipStatusOptions = ['Domestic', 'International', 'Both'].map(x => ({ value: x, label: x }));
export function FiltersForm({ goTo, defaultValues, closeModal }) {
  const { control, register, reset } = useForm({ defaultValues });

  const handleReset = () => {
    reset(filterDefaultValues);
  }

  return (
    <form className="inst-search-filter-list">
      <Accordion preExpanded={['undergradMajor']} allowMultipleExpanded={true}>
        <AccordionItem uuid="undergradMajor">
          <AccordionItemHeading>
            <AccordionItemButton>
              <span>Undergrad Major(s)</span>
              <img src={downCarat} alt="" />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <Field>
              <label htmlFor="major" className="sr-only">Major</label>
              <ControlledMultiSelect
                control={control}
                options={cipFieldOptions}
                name="major"
                placeholder="Choose all that apply"
                customClassNames="inst-filters-multiselect"
              />
            </Field>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="gpaRange">
          <AccordionItemHeading>
            <AccordionItemButton>
              <span>GPA</span>
              <img src={downCarat} alt="" />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <Field>
              <label htmlFor="gpaRange" className="sr-only">GPA</label>
              <ControlledSlider
                control={control}
                name="gpaRange"
                min={0}
                max={4}
                step={0.1}
                displayThumb={value => value.toFixed(1)}
                customClassNames="inst-filters-slider"
              />
            </Field>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="graduationYear">
          <AccordionItemHeading>
            <AccordionItemButton>
              <span>Graduate Year</span>
              <img src={downCarat} alt="" />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <Field>
              <label htmlFor="graduationYear" className="sr-only">Graduate Year</label>
              <ControlledMultiSelect
                control={control}
                name="graduationYear"
                options={yearOptions}
                placeholder="Choose all that apply"
                customClassNames="inst-filters-multiselect"
              />
            </Field>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="diversity">
          <AccordionItemHeading>
            <AccordionItemButton>
              <span>Diversity</span>
              <img src={downCarat} alt="" />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <fieldset>
              <legend className="sr-only">Diversity</legend>

              <Field>
                <label htmlFor="citizenshipCountry">Country of Citizenship</label>
                <ControlledMultiSelect
                  control={control}
                  name="citizenshipCountry"
                  options={countryOptions.map(x => ({ value: x, label: x }))}
                  placeholder="Choose all that apply"
                  customClassNames="inst-filters-multiselect"
                />
              </Field>

              <div className="flex">
                {citizenshipStatusOptions.map(opt => (
                  <label key={opt.value} className="radio">
                    <input type="radio" {...register('citizenshipStatus')} value={opt.value} id={opt.value} />
                    <span>{opt.label}</span>
                  </label>
                ))}
              </div>

              <Field>
                <label htmlFor="ethnicGroups">Ethnic Background</label>
                <ControlledMultiSelect
                  control={control}
                  name="ethnicGroups"
                  options={ethnicGroupOptions}
                  placeholder="Choose all that apply"
                  customClassNames="inst-filters-multiselect"
                />
              </Field>

              <Field>
                <label htmlFor="gender">Gender</label>
                <ControlledMultiSelect
                  control={control}
                  name="gender"
                  options={institutionGenderOptions}
                  placeholder="Choose all that apply"
                  customClassNames="inst-filters-multiselect"
                />
              </Field>
            </fieldset>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="expectedStartDate">
          <AccordionItemHeading>
            <AccordionItemButton>
              <span>Expected Start Date</span>
              <img src={downCarat} alt="" />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <fieldset>
              <legend className="sr-only">Expected Start Date</legend>

              <Field>
                <label htmlFor="earliestStartDate">Earliest Start Date</label>
                <ControlledMultiSelect
                  control={control}
                  name="earliestStartDate"
                  placeholder="Choose all that apply"
                  options={startDateOptions.filter(opt => opt.value !== 'Undecided')}
                  customClassNames="inst-filters-multiselect"
                />
              </Field>

              <Field>
                <label htmlFor="latestStartDate">Latest Start Date</label>
                <ControlledMultiSelect
                  control={control}
                  name="latestStartDate"
                  placeholder="Choose all that apply"
                  options={startDateOptions.filter(opt => opt.value !== 'Undecided')}
                  customClassNames="inst-filters-multiselect"
                />
              </Field>
            </fieldset>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="degree">
          <AccordionItemHeading>
            <AccordionItemButton>
              <span>Degree Level Pursuing</span>
              <img src={downCarat} alt="" />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <fieldset>
              <legend className="sr-only">Degrees</legend>
              {degreeOptions.filter(opt => opt.value !== 'Still Deciding').map(opt => (
                <label key={opt.value} className="checkbox">
                  <input type="checkbox" {...register('degree')} value={opt.value} id={opt.value} />
                  <span>{opt.label}</span>
                </label>
              ))}
            </fieldset>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="greScoreRange">
          <AccordionItemHeading>
            <AccordionItemButton>
              <span>GRE Score Range</span>
              <img src={downCarat} alt="" />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <fieldset>
              <legend className="sr-only">GRE Score Range</legend>
              
              <Field>
                <label htmlFor="greVerbal">Verbal Reasoning</label>
                <ControlledSlider
                  control={control}
                  name="greVerbal"
                  min={130}
                  max={170}
                  step={10}
                  displayThumb={value => value}
                  customClassNames="inst-filters-slider"
                />
              </Field>

              <Field>
                <label htmlFor="greQuantitative">Quantitative Reasoning</label>
                <ControlledSlider
                  control={control}
                  name="greQuantitative"
                  min={130}
                  max={170}
                  step={10}
                  displayThumb={value => value}
                  customClassNames="inst-filters-slider"
                />
              </Field>

              <Field>
                <label htmlFor="greWriting">Analytical Writing</label>
                <ControlledSlider
                  control={control}
                  name="greWriting"
                  defaultValue={[0]}
                  min={0}
                  max={6}
                  step={1}
                  displayThumb={value => value}
                  customClassNames="inst-filters-slider"
                />
              </Field>
            </fieldset>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="location">
          <AccordionItemHeading>
            <AccordionItemButton>
              <span>Location</span>
              <img src={downCarat} alt="" />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <fieldset>
              <legend className="sr-only">Location</legend>

              <Field>
                <label htmlFor="state">Search by State</label>
                <ControlledMultiSelect
                  control={control}
                  name="state"
                  options={stateOptions}
                  isSearch
                  placeholder="Search"
                  customClassNames="inst-filters-multiselect"
                />
              </Field>

              <Field>
                <label htmlFor="zipCode">Search by Zip Code</label>
                <ControlledMultiSelect
                  control={control}
                  name="zipCode"
                  options={[]}
                  isSearch
                  placeholder="This feature will be available soon"
                  customClassNames="inst-filters-multiselect"
                />
              </Field>
              
              <Field>
                <label htmlFor="distance">Distance</label>
                <ControlledSlider
                  control={control}
                  name="distance"
                  defaultValue={[100]}
                  min={0}
                  max={150}
                  step={10}
                  displayThumb={(value) => `${value} miles`}
                  customClassNames="inst-filters-slider"
                />
              </Field>
            </fieldset>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
      <div className="buttons">
        {goTo ? (
          <Link onClick={closeModal} to={goTo} className="inst-text-label search-button">Show Results</Link>
        ) : (
          <button type="button" onClick={closeModal} className="ints-text-label search-button">Show Results</button>
        )}
        <button type="button" onClick={handleReset} className="inst-cta-link">Clear Filters</button>
      </div>
    </form>
  )
}

FiltersForm.propTypes = {
  goTo: PropTypes.string,
  defaultValues: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
}

export default function Filters({ goTo }) {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="inst-search-filter-container">
      <button className="inst-cta-link" onClick={() => setShowModal(true)}>Filters</button>
      <Modal
        closeModal={() => setShowModal(false)}
        title="Filters"
        isOpen={showModal}
      >
        <FiltersForm
          goTo={goTo}
          defaultValues={filterDefaultValues}
          closeModal={() => setShowModal(false)}
        />
      </Modal>
    </div>
  );
}

Filters.propTypes = {
  goTo: PropTypes.string,
}