import PropTypes from 'prop-types';
import PopupCard from './PopupCard';
import { comingSoonShort } from '../../shared/appText';
import './PrivacyPopup.scss';
export default function PrivacyPopup({ triggerButton }) {
  return (
    <PopupCard
      triggerButton={triggerButton}
    >
      <div className="privacy-contents">
        <p className="student-text-h3">Who can see this? <span className="student-text-p-small-italic">{comingSoonShort}</span></p>
        <label className="checkbox">
          Graduate Schools <input type="checkbox" checked disabled />
        </label>
        <label className="checkbox">
          Job Recruiters <input type="checkbox" disabled />
        </label>
        <label className="checkbox">
          Community Members <input type="checkbox" disabled />
        </label>
        <label className="checkbox">
          Public <input type="checkbox" disabled />
        </label>
        <label className="checkbox">
          Only Me <input type="checkbox" disabled />
        </label>
      </div>
    </PopupCard>
  );
}

PrivacyPopup.propTypes = {
  triggerButton: PropTypes.node,
}