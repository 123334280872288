import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ShadowCard from '../ShadowCard';
import AppStatus from './AppStatus';
import ContactStudentButton from '../InstitutionStudentSearch/ContactStudentButton';
import './AppCard.scss';

export default function AppCard({ name, status, studentId }) {

  return (
    <ShadowCard customClassNames="inst-app-card">
      <h2>
        <Link to={status === 'in-progress' ? `/institutions/recruiting/studentsearch/${studentId}` : studentId} className="inst-app-card">
          {name}
        </Link>
      </h2>
      <div className="inst-app-card__buttons">
        <AppStatus status={status} />
        <ContactStudentButton alias={name} />
      </div>
    </ShadowCard>
  );
}

AppCard.propTypes = {
  name: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['in-progress', 'submitted', 'reviewed']).isRequired,
  studentId: PropTypes.string.isRequired,
};