import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import carat from '../../assets/student/images/down-carat-black.svg';
import logo from '../../assets/student/images/logo_default.jpeg';
import './MessagingPopup.scss';

const messages = [
  {
    program: 'English Literature, Seton Hall University',
    lead: 'Mary Smith, Program Advisor',
    message: 'Thank you for contacting us. We look forward to discussing our program with you and answering any questions you may have.',
    date: 'Jan 26',
  },
  {
    program: 'MBA, University of Michigan',
    lead: 'Tom Jones, Program Coordinator',
    message: 'It was great to speak with you and I hope I answered your questions. I look forward to reviewing your application',
    date: 'Jan 26',
  },
  {
    program: 'Nurse Practitioner, Johns Hopkins University',
    lead: 'Jane Brown, Program Coordinator',
    message: 'Looking forward to reviewing your application. Please let me know if I can be of any further assistance.',
    date: 'Jan 26',
    count: 2,
  }
];

export default function MessagingPopup() {
  return (
    <div className="student-messaging-popup">
      <Accordion allowZeroExpanded>
        <AccordionItem uuid="messages" activeClassName="accordion__item--expanded">
          <AccordionItemHeading>
            <AccordionItemButton>
              <span>
                Messaging <span className="count">2</span>
              </span>
              <img src={carat} alt="" />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            {messages.map(m => (
              <div className="message" key={m.program}>
                <img src={logo} alt="" />
                <div>
                  <p>
                    <span className="student-text-h3">{m.program}</span>
                    <span className="gray">{m.lead}</span>
                  </p>
                  <p>{m.message}</p>
                </div>
                <div className="col">
                  <span className="gray">{m.date}</span>
                  {m.count ? <span className="count">{m.count}</span> : null}
                </div>
              </div>
            ))}
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
}