import { useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useFirebasePrograms } from '../../context/FirebaseProgramDataContext';
import ProgramMainImageCard from '../../components/StudentPrograms/ProgramMainImageCard';
import BackLink from '../../components/BackLink';
import TabNav from '../../components/StudentUI/TabNav';
import ContactUsButton from '../../components/StudentMessaging/ContactUsButton';
import { useFirebaseStudent } from '../../context/FirebaseStudentContext';
import './StudentSchoolProfileLayout.scss';

export default function StudentSchoolProfileLayout() {
  const { schoolId } = useParams();
  const { schools } = useFirebasePrograms();
  const { student } = useFirebaseStudent();

  const data = useMemo(() => {
    if (schoolId && schools) return schools[schoolId];
    return null;
  }, [schoolId, schools]);

  if (data && student) return (
    <div className="student-school-profile">
      <div className="student-school-profile__top">
        <BackLink to="/students/explore" label="Back" />
        <ProgramMainImageCard
          imageUrl={data.imageFilename}
          imageAlt={data.schoolName}
          schoolName={data.schoolName}
          website={data.website}
        />
      </div>
      <TabNav
        tabs={[
          { label: 'Overview', to: '' },
          { label: 'Programs', to: 'programs' },
          { label: 'Graduate Student Demographics', to: 'students' }
        ]}
      />
      <div className="student-school-profile__content">
        <Outlet />
      </div>
      <ContactUsButton toIdentifier={data.schoolName} fromIdentifier={student.alias} />
    </div>
  );

  return null;
}