import { Outlet } from 'react-router-dom';
import SectionHeader from '../../components/SectionHeader';

export default function InstitutionStudentSearchLayout() {
  return (
    <div style={{ width: '100%' }}>
      <SectionHeader title="Applicants" />
      <Outlet />
    </div>
  );
}