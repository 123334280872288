import { Controller } from 'react-hook-form';
import { Range } from 'react-range';
import PropTypes from 'prop-types';
import './ControlledSlider.scss';

export default function ControlledSlider({ control, defaultValue, min, max, step, name, displayThumb, customClassNames, rangeLabels }) {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field: { onChange, value, ref }}) => (
        <div className={`range-container ${customClassNames}`}>
          <Range
            ref={ref}
            min={min}
            max={max}
            step={step}
            values={value}
            onChange={onChange}
            renderTrack={({ props, children }) => (
              <div {...props} className="range-track">{children}</div>
            )}
            renderThumb={({ index, props }) => (
              <div {...props} className="range-thumb">
                <div />
                <div className="range-thumb__value">{displayThumb(value[index])}</div>
              </div>
            )}
            allowOverlap={false}
          />
          {rangeLabels ? (
            <div className="range-labels">
              {rangeLabels.map(label => <span key={`${name}-${label}`}>{label}</span>)}
            </div>
          ) : null}
        </div>
      )}
    />
  )
}

ControlledSlider.propTypes = {
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.arrayOf(PropTypes.number),
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  name: PropTypes.string,
  displayThumb: PropTypes.func.isRequired,
  customClassNames: PropTypes.string,
  rangeLabels: PropTypes.arrayOf(PropTypes.string),
};