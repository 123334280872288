import { Link } from 'react-router-dom';
import PopupCard from '../StudentUI/PopupCard';
import home from '../../assets/student/images/home.svg';
import './DashboardLink.scss';

export default function DashboardLink() {
  return (
    <div className="student-survey-dashboard-link">
      <PopupCard
        position="bottom right"
        triggerButton={<button type="button" className="dashboard"><img src={home} alt="" /> Go To Dashboard</button>}
      >
        <div className="student-leave-survey-warning">
          <p>We have saved your progress. Are you sure you want to leave?</p>
          <Link to="/students">Leave</Link>
        </div>
      </PopupCard>
    </div>
  );
}