import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useStudentSurveyStatus, useStudentApplicationStatus, useFirebaseStudent } from '../../../context/FirebaseStudentContext';
import SectionHeader from '../../../components/SectionHeader';
import Filters from '../../../components/StudentSearch/Filters';
import SurveyLink from '../../../components/StudentSurveys/SurveyLink';
import ProfileProgressCircle from '../../../components/StudentProfile/ProfileProgressCircle';
import ProgressSteps from '../../../components/ProgressSteps';
import DesktopAppsCarousel from '../../../components/StudentApplications/DesktopAppsCarousel';
import ProgramRecsCarousel from '../../../components/StudentPrograms/ProgramRecsCarousel';
import ShadowCard from '../../../components/ShadowCard';
import ShortcutCards from '../../../components/StudentDashboard/ShortcutCards';
import FavoritesLink from '../../../components/StudentFavorites/FavoritesLink';
import MessagingPopup from '../../../components/StudentMessaging/MessagingPopup';
import initIllus from '../../../assets/student/images/dashboard-get-started.svg';
import searchRightIllus from '../../../assets/student/images/dashboard-search-right.svg';
import searchLeftIllus from '../../../assets/student/images/dashboard-search.svg';
import applyIllus from '../../../assets/student/images/dashboard-apply.svg';
import doneIllus from '../../../assets/student/images/dashboard-done.svg';
import todoIllus from '../../../assets/student/images/dashboard-todo.svg';
import roiIllus from '../../../assets/student/images/dashboard-roi.svg';
import './DashboardPage.scss';

export default function StudentDashboardPage() {
  const { showBasicsSurvey, showProgramsSurvey, profilePercentComplete } = useStudentSurveyStatus();
  const { inProgressApps, submittedApps } = useStudentApplicationStatus();
  const { favoriteProgramsList } = useFirebaseStudent();

  const shortcutCards = useMemo(() => {
    const cards = [
      {
        title: 'To-Do',
        text: 'We can help you stay organized as you navigate the grad school search and application process.',
        img: todoIllus,
        linkTo: '/students/todos',
      },
      {
        title: 'Calculate ROI',
        text: 'Make sure you\'re getting the best bang for your buck with this exclusive tool.',
        img: roiIllus,
        linkTo: '/students/resources/calculateroi',
      },
    ];

    if (!inProgressApps?.length && !showProgramsSurvey) {
      cards.push(    {
        title: 'Explore',
        text: 'Search hundreds of schools and programs that match your goals and criteria.',
        img: searchRightIllus,
        linkTo: '/students/explore'
      });
    }

    return cards;
  }, [inProgressApps, showProgramsSurvey]);

  const progressContent = {
    'init': {
      text: 'Let\'s get to know you better!',
      img: initIllus
    },
    'personal-info': { 
      text: 'Search hundreds of schools and programs that match your goals and criteria',
      img: searchRightIllus,
    },
    'search': {
      text: 'You\'ve narrowed down your list, now it\'s time to start applying',
      img: applyIllus,
    },
    'start-apps': {
      text: 'You\'ve narrowed down your list, now it\'s time to start applying',
      img: applyIllus,
    },
    'submit-apps': {
      text: 'You\'ve submitted your best work. Now just wait for those acceptance letters!',
      img: doneIllus,
    },
  };

  const lastCompleteStep = useMemo(() => {
    if (submittedApps?.length) {
      return 'submit-apps';
    } else if (inProgressApps?.length) {
      return 'start-apps';
    } else if (favoriteProgramsList?.length) {
      return 'search';
    } else if (!showBasicsSurvey) {
      return 'personal-info';
    } else {
      return 'init';
    }
  }, [inProgressApps, submittedApps, showBasicsSurvey, favoriteProgramsList]);

  const profileButtonText = useMemo(() => {
    if (profilePercentComplete === 100) return 'Well Done!👏🏽';
    if (profilePercentComplete >= 80) return 'Almost there!';
    return 'Start Here';
  }, [profilePercentComplete]);

  return (
    <div className="student-dashboard">
      <SectionHeader title="Dashboard" />
      <main className="student-dashboard__content">
        <p className="student-text-title">Welcome</p>
        
        <section className="section">
          <h2 className="student-text-h2">Your progress</h2>
          <div className="progress">
            <ShadowCard>
              <h3 className="student-text-p-large">Your Profile</h3>
              <ProfileProgressCircle />
              {profilePercentComplete === 100 ? (
                <p className="student-text-p-large-bold">{profileButtonText}</p>
              ) : (
                <Link to="/students/profile" className="student-text-label progress-button">{profileButtonText}</Link>
              )}
            </ShadowCard>

            <ShadowCard>
              <div className="progress-instructions">
                <img src={progressContent[lastCompleteStep].img} alt="" />
                <p className="student-text-p-large progress-text">{progressContent[lastCompleteStep].text}</p>
              </div>
              <ProgressSteps
                steps={[
                  { label: 'Personal Information', isComplete: lastCompleteStep === 'personal-info' },
                  { label: 'Search Schools', isComplete: lastCompleteStep === 'search' },
                  { label: 'Start Applications', isComplete: lastCompleteStep === 'start-apps' },
                  { label: 'Submit Applications', isComplete: lastCompleteStep === 'submit-apps' }
                ]}
              />
            </ShadowCard>
          </div>
        </section>

        {showBasicsSurvey ? (
          <section className="section">
            <SurveyLink
              to="surveys"
              text="These additional questions will help program advisors find you more easily."
            />
          </section>
        ) : null}

        {inProgressApps?.length ? (
          <section className="section dashboard-apps">
            <div className="section__header">
              <h2 className="student-text-h2">Your Applications</h2>
              <Link to="/students/applications" className="student-cta-link">View All</Link>
            </div>
            <DesktopAppsCarousel applications={inProgressApps} />
          </section>
        ) : (
          <>
            {!showProgramsSurvey ? (
              <section className="section dashboard-recs">
                <div className="section__header">
                  <h2 className="student-text-h2">Suggested Schools</h2>
                  <FavoritesLink />
                </div>
                <ProgramRecsCarousel />
              </section>
            ) : (
              <section className="section dashboard-filters">
                <h2 className="student-text-h2">Explore</h2>
                <ShadowCard customClassNames="dashboard-search">
                  <div className="dashboard-search__content">
                    <h3 className="student-text-h1">Start your search here</h3>
                    <p className="student-text-p-large">Search and explore hundreds of programs to find the right match for you.</p>
                    <Filters />
                  </div>
                  <div className="dashboard-search__image">
                    <img src={searchLeftIllus} alt="" />
                  </div>
                </ShadowCard>
              </section>
            )}
          </>
        )}

        {showProgramsSurvey ? (
          <section className="section">
            <SurveyLink
              to="surveys/schools"
              text="Answer a few short questions to help us target the best programs for you."
            />
          </section>
        ) : null}

        <section className="section">
          <h2 className="student-text-h2">Shortcuts</h2>
          <ShortcutCards cards={shortcutCards} />
        </section>
      </main>
      <MessagingPopup />
    </div>
  );
}