import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DOMpurify from 'dompurify';
import './ResourceArticlePage.scss';

export default function StudentResourceArticlePage() {
  const { articleId } = useParams();
  const [article, setArticle] = useState();

  const fetchArticle = async (slug) => {
    const articleRes = await fetch(`${window.location.origin}/student-resources/${slug}.json`);
    const json = await articleRes.json();
    json.html = DOMpurify.sanitize(json.html).replaceAll('a href=', 'a target="_blank" href=');
    setArticle(json);
  }

  useEffect(() => {
    fetchArticle(articleId);
  }, [articleId]);

  if (article) return (
    <div>
      <div className="article-page">
        <img src={`/student-resources/${article.imagePath}`} alt="" />
        <div dangerouslySetInnerHTML={{ __html: article.html }} />
      </div>
    </div>
  )

  return null;
}
