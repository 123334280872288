import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useFirebasePrograms } from '../../../context/FirebaseProgramDataContext';
import EditButton from '../../../components/InstitutionProfile/EditButton';
import DataValueBox from '../../../components/InstitutionProfile/DataValueBox';
import FinancialAidCard from '../../../components/InstitutionProfile/FinancialAidCard';
import ShadowCard from '../../../components/ShadowCard';
import { currencyFormatter } from '../../../shared/numbers';

export default function InstitutionProgramProfileCostsPage() {
  const { programId } = useParams();
  const { programs } = useFirebasePrograms();

  const data = useMemo(() => {
    if (programs && programId) return programs[programId];
    return null;
  }, [programs, programId]);

  if (data) return (
    <main className="school-profile-page">
      <section className="section">
        <div className="section__header">
          <h2 className="inst-text-h2">Tuition Per Semester</h2>
          <EditButton />
        </div>
        <ShadowCard customClassNames="grid padded">
          <DataValueBox value={currencyFormatter.format(data.tuitionInState)} label="In-State Students" />
          <DataValueBox value={currencyFormatter.format(data.tuitionOutOfState)} label="Out-Of-State Students" />
        </ShadowCard>
      </section>

      <section className="section">
        <div className="section__header">
          <h2 className="inst-text-h2">Financial Aid Options</h2>
          <EditButton />
        </div>
        {data.scholarships.map(s => (
          <FinancialAidCard headline={s.headline} text={s.text} link={s.link} key={s.headline} />
        ))}
      </section>
    </main>
  );

  return null;
}