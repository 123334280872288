import { Link } from 'react-router-dom';
import { useStudentSurveyStatus } from '../../../context/FirebaseStudentContext';
import Filters from '../../../components/StudentSearch/Filters';
import ProgramRecsCarousel from '../../../components/StudentPrograms/ProgramRecsCarousel';
import ShadowCard from '../../../components/ShadowCard';
import SurveyLink from '../../../components/StudentSurveys/SurveyLink';
import FavoritesLink from '../../../components/StudentFavorites/FavoritesLink';
import MessagingPopup from '../../../components/StudentMessaging/MessagingPopup';
import searchImg from '../../../assets/student/images/dashboard-search.svg';
import faves from '../../../assets/student/images/explore-favorites.svg';
import './ExplorePage.scss';

export default function StudentExplorePage() {
  const { showProgramsSurvey } = useStudentSurveyStatus();

  // TODO: Should suggested schools only show if survey is started?
  return (
    <>
      <main className="student-explore">
        <section className="section">
          <ShadowCard customClassNames="student-explore__search">
            <FavoritesLink />
            <div className="content">
              <img src={searchImg} alt="" />
              <h2 className="student-text-h1">Start your search here</h2>
              <p className="student-text-p-large">Search and explore hundreds of programs to find the right match for you.</p>
              <Filters showSearchButton />
            </div>
          </ShadowCard>
        </section>

        { showProgramsSurvey ? (
          <section className="section">
            <SurveyLink
              to="/students/surveys/schools"
              text="Answering these five questions will help us find your top matches and recommendations."
            />
          </section>
        ) : null }

        <section className="section">
          <div className="section__header">
            <h2 className="student-text-h2">Recommendations</h2>
            <FavoritesLink />
          </div>
          <ProgramRecsCarousel />
        </section>

        { !showProgramsSurvey ? (
          <section className="section">
            <SurveyLink
              to="/students/surveys/schools"
              text="Don't see what you're looking for? Update your preferences to receive more recommendations."
            />
          </section>
        ) : null }

        <section className="section">
          <ShadowCard customClassNames="favorites-card">
            <div className="image">
              <img src={faves} alt="" />
            </div>
            <div className="text">
              <h2>Favorites</h2>
              <p>View your favorited programs that  best match your preferences and which programs favorited you as a prospective student. Mutual favoriting makes a match! Then take the next step by messaging an advisor or starting an application.</p>
              <Link to="/students/favorites">Go to Favorites</Link>
            </div>
          </ShadowCard>
        </section>
      </main>
      <MessagingPopup />
    </>
  );
}