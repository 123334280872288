import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { hotjar } from 'react-hotjar';
import { FirebaseAuthProvider } from './context/FirebaseAuthContext';
import AllRoutes from './routes/AllRoutes';
import './App.scss';

function App() {

  useEffect(() => {
    if (process.env.REACT_APP_HJID) {
      hotjar.initialize(process.env.REACT_APP_HJID, 6);
    }
  }, []);

  return (
    <FirebaseAuthProvider>
      <div>
        <Toaster position="top-center" toastOptions={{
          style: {
            backgroundColor: '#000',
            color: '#FFF',
            padding: '0.2rem 1rem',
            fontFamily: 'degular-display',
            fontWeight: '600',
            fontSize: '1rem',
            maxWidth: 600,
          }
        }} />
      </div>
      <AllRoutes />
    </FirebaseAuthProvider>
  );
}

export default App;
