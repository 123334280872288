import { useState, useEffect, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, onAuthStateChanged, signOut as firebaseSignOut, sendPasswordResetEmail } from 'firebase/auth';
import { ref, onValue } from 'firebase/database';
import toast from 'react-hot-toast';
import { setUserProperties } from 'firebase/analytics';
import { analytics, auth, db } from '../firebase';

const errorMessages = {
  'auth/invalid-user-token': 'Your credentials are no longer valid. You must sign in again.',
  'auth/network-request-failed': 'Network connection error. Please try again.',
  'auth/too-many-requests': 'Too many requests. Wait a moment and try again.',
  'auth/user-disabled': 'This account has been disabled. Please contact your admin.',
  'auth/user-token-expired': 'Your credentials have expired. Please sign in again.',
  'auth/web-storage-unsupported': 'Your browser is not supported. Try signing in in Chrome.',
  'auth/user-not-found': 'User not found. Please contact your admin.',
  'auth/wrong-password': 'Incorrect password.'
};

const FirebaseAuthContext = createContext(undefined);

function FirebaseAuthProvider({ children }) {
  const [authState, setAuthState] = useState('loading');
  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [userPrivileges, setUserPrivileges] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (!firebaseUser) {
        setAuthState('idle');
      } else {
        setUser(firebaseUser);
        getThisUser(firebaseUser.uid);
        setUserProperties(analytics, { 'crm_id': firebaseUser.uid });
      }
    });
    return () => unsubscribe;
  }, []); // eslint-disable-line

  const getErrorMessage = (code) => {
    return errorMessages[code] || 'Something went wrong.';
  }

  const getThisUser = async (uid) => {
    try {
      const userRoleRef = ref(db, `/userRoles/${uid}`);
      onValue(userRoleRef, (snapshot) => {
        const data = snapshot.val();
        setUserRole(data.role);
        if (data.role === 'institution') {
          setUserPrivileges({ school: data.school, programs: Object.keys(data.programs) });
        }
        setAuthState('idle');
      });
    } catch (e) {
      setAuthState('error');
      toast.error(getErrorMessage(e.code));
    }
  }

  const signIn = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      toast.success('Welcome!');
    } catch (e) {
      setAuthState('error');
    }
  }

  const signOut = async () => {
    try {
      await firebaseSignOut(auth);
      setUser(null);
      setUserRole(null);
      toast.success('You have successfully signed out');
      navigate('/');
    } catch (e) {
      toast.error(getErrorMessage(e.code));
    }
  }

  const requestPasswordReset = async (email, handleSuccess, handleError) => {
    try {
      await sendPasswordResetEmail(auth, email, {
        url: window.location.origin,
      });
      handleSuccess('Success! Check your email for instructions');
    } catch (e) {
      handleError(getErrorMessage(e.code));
    }
  }

  return (
    <FirebaseAuthContext.Provider value={{
      user,
      userRole,
      userPrivileges,
      authState,
      signIn,
      signOut,
      requestPasswordReset,
    }}>
      {children}
    </FirebaseAuthContext.Provider>
  );
}

FirebaseAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function useFirebaseAuth() {
  const context = useContext(FirebaseAuthContext);

  if (context === undefined) {
    throw new Error('useFirebaseAuth must be used within a FirebaseAuthProvider');
  }

  return context;
}

export { FirebaseAuthProvider, useFirebaseAuth };
