import './CalculateROIPage.scss';
import StudentCalculateROIForm from '../../../forms/CalculateROI/CalculateROIForm';
import StudentCalculateROIGraph from './CalculateROIGraph';
import { useState } from 'react';

export default function StudentCalculateROIPage() {
  const [view, setView] = useState('form');
  const [graphData, setGraphData] = useState({});

  const showForm = () => {
    setView('form');
  }

  const showGraph = (formData) => {
    setGraphData(formData);
    setView('graph');
  }

  return (
    <div className="roi-page">
      <h2 className="student-text-h2">Return on Investment</h2>
      <p className="student-text-p">Investing in your education is one of the largest investments you will make in your lifetime. But like any other investment, you want to make wise, well-informed decisions. This information will help you determine whether the cost of a graduate degree will ultimately pay off in your career.</p>
      {
        view === 'form' ? <StudentCalculateROIForm showGraph={showGraph} formData={graphData}/> :
          <StudentCalculateROIGraph graphData={graphData} showForm={showForm}/>
      }
    </div>
  );
}
