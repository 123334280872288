// FAKE DATA
export const savedSearches = [
  {
    name: 'Search 1',
    lastModified: '02/17/2022 9:44'
  },
  {
    name: 'Search 2',
    lastModified: '02/18/2022 9:44'
  },
  {
    name: 'Search 3',
    lastModified: '02/19/2022 9:44'
  }
];

export const orderHistory = [
  {
    orderNumber: 1,
    recurringPurchase: true,
    totalRegistrants: 89,
    datePurchased: '02/17/2022'
  },
  {
    orderNumber: 2,
    recurringPurchase: true,
    totalRegistrants: 89,
    datePurchased: '02/18/2022'
  },
  {
    orderNumber: 3,
    recurringPurchase: false,
    totalRegistrants: 89,
    datePurchased: '02/19/2022'
  }
];