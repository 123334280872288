import PropTypes from 'prop-types';
import './ProgressCircle.scss';

function Circle({ percentComplete, color, size }) {
  const radius = size * 0.5 * 0.7;
  const circumference = 2 * Math.PI * radius;
  const strokePercent = ((100 - percentComplete) * circumference) / 100;
  const strokeDashoffset = percentComplete ? strokePercent : 0;
  return (
    <circle
      r={radius}
      cx={size * 0.5}
      cy={size * 0.5}
      fill="transparent"
      stroke={percentComplete && strokePercent !== circumference ? color : ''}
      strokeWidth={size * 0.5 * 0.12}
      strokeDasharray={circumference}
      strokeDashoffset={strokeDashoffset}
      strokeLinecap="round"
    />
  )
}

Circle.propTypes = {
  percentComplete: PropTypes.number,
  color: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
};

export default function ProgressCircle({ percentComplete, fgColor, bgColor, size, label, inst, children }) {
  return (
    <div className="progress-circle" style={{ width: size, height: size }}>
      <svg width={size} height={size} className="progress-circle">
        <g transform={`rotate(-90, ${size / 2}, ${size / 2})`}>
          <Circle color={bgColor} size={size} percentComplete={100} />
          <Circle color={fgColor} percentComplete={percentComplete} size={size} />
        </g>
      </svg>
      <div className={`progress-circle__text ${inst ? 'progress-circle__text--inst' : ''}`}>
        {!children ? (
          <>
            <span style={{ fontSize: `${parseInt(size / 60)}em` }} className="percent">{percentComplete}%</span>
            <span style={{ fontSize: `${size / 170}em`}} className="label">{label}</span>
          </>
        ) : children }
      </div>
    </div>
  );
}

ProgressCircle.defaultProps = {
  size: 200,
  label: 'Complete'
}

ProgressCircle.propTypes = {
  percentComplete: PropTypes.number.isRequired,
  fgColor: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  size: PropTypes.number,
  label: PropTypes.string,
  inst: PropTypes.bool,
  children: PropTypes.node,
}